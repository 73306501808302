/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
* Chart to be used on a form, analysing detail information on parent record
*/
import { Component, Input, AfterViewInit, ViewContainerRef } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../form/app-form-control';
import { FormControlInterface } from '../form/form-control-interface';
import * as Highcharts from 'highcharts';
import Boost from 'highcharts/modules/boost';
import noData from 'highcharts/modules/no-data-to-display';
import More from 'highcharts/highcharts-more';
import { AbstractObject } from 'src/app/model/abstract-object';

/** Do this initialization for all charts.... even if they do not use ChartComponent */

Boost(Highcharts);
noData(Highcharts);
More(Highcharts);
noData(Highcharts);

export type ChartDataItem = {
    name: string;
    y: number;
};

export class ChartField extends Field {
    chart: Highcharts.Chart;
    chartOptions: Highcharts.Options;
    lazyTab = true;
    
    setValue(item: AbstractObject, readonly: boolean) {
        super.setValue(item, readonly);
        if (this.chart) {
            this.chart.update(this.control.value)
        }       
    }
}
@Component({
    selector: 'app-chart',
    templateUrl: './chart.component.html',
    styleUrls: ['./chart.component.scss'],
    standalone: true
})
export class ChartComponent implements AfterViewInit, FormControlInterface {

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    chartField: ChartField;
    //data: any[] = [];
    chartRandomId: string = 'chartId-' + Math.floor(Math.random() * 1000000000);

    /*
    options: Highcharts.Options = {
        chart: {
            type: 'pie'
        },
        credits: { enabled: false },

        title: null,
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        */
    /*
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: false,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Schedule Share',
        colorByPoint: true,
        data: this.data,
        type: 'pie'
    }],
    */
    //};

    chart: Highcharts.Chart;

    /* ChartJS
    @ViewChild('pieCanvas') private pieCanvas: ElementRef;
    pieChart: any;
    backgroundColors = [
      '#95a5a6',
      '#9b59b6',
      '#f1c40f',
      '#e74c3c',
      '#2ecc71',
      '#3498db',
    ];
  */

    constructor() {
        console.log('Made Chart Component');
    }

    public static make(name: string, calculateValue: () => ChartDataItem[], chartOptions: Highcharts.Options, options: Partial<Field> = {}) {
        const field: ChartField = new ChartField({ name, calculateValue }, options);
        field.chartOptions = chartOptions;
        field.sendServer = false;
        field.formControlFactory = ChartComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(ChartComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    setupChart() {
        this.chartField = (this.control.field as ChartField);
        console.log('Initializing ' + this.chartRandomId, document.getElementById(this.chartRandomId));
        this.chart = Highcharts.chart(this.chartRandomId, this.chartField.chartOptions);
        if (this.control.value) {
            this.chart.update(this.control.value);
        }
        this.chartField.chart = this.chart;
    }
    /*
    this.chart.update({ series: [{ name: 'Schedule Trend', colorByPoint: true, type: 'pie', data: [
      {name: 'Item 1', y: 123},
      {name: 'Item 2', y: 456},
    ] }] });
    */
    /*
        console.log('Initializing Chart');
        console.log(this.control);
        this.control.value.forEach( item => {
          this.data.push({ name: item.name, y: item.value});
        });
        console.log(this.data);
        this.options.series[0].data = this.data;
        Highcharts.chart('container', this.options);
        console.log('Completed');
    */

    ngAfterViewInit(): void {

        this.setupChart();
        /*
    const labels: string[] = [];
    const values: number[] = [];
    const colors: string[] = [];

    this.control.value.forEach( data => {
      labels.push(data.name);
      values.push(data.value);
      colors.push(this.backgroundColors[labels.length]);
    });

    this.chartLabels = labels;
    this.chartData = values;

    this.pieChartBrowser(labels, values, colors);
    */
    }


    /* ChartJS Example
    pieChartBrowser(labels: string[], values: number[], colors: string[]): void {
      Chart.register(...registerables);
      console.log(labels, values, colors);
       colors = [
        '#2ecc71',
        '#3498db',
        '#95a5a6',
        '#9b59b6',
        '#f1c40f',
        '#e74c3c'
      ]

      this.zone.runOutsideAngular( () => this.pieChart = new Chart(this.pieCanvas.nativeElement, {
        type: 'pie',
        data: {
          labels,
          datasets: [{
            backgroundColor: colors,
            data: values
          }]
        }
      }));
    }
    */
}
