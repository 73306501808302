/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { inject } from '@angular/core';
import { RequestCategoriesService } from '../modules/crm/request-categories.service';
import { RequestPrioritiesService } from '../modules/crm/request-priorities.service';

import { Field } from '../shared/field/Field';
import { FieldMaker } from '../shared/field/FieldMaker';
import { PicklistField } from '../shared/field/PicklistField';
import { FormButtonComponent } from '../shared/form/form-button/form-button.component';
import { FormCheckboxComponent } from '../shared/form/form-checkbox/form-checkbox.component';
import { FormComboBoxComponent } from '../shared/form/form-combo-box/form-combo-box.component';
import { FormDateTimeComponent } from '../shared/form/form-date-time/form-date-time.component';
import { FormNumberComponent } from '../shared/form/form-number/form-number.component';
import { FormPicklistComponent } from '../shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from '../shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from '../shared/form/form-text/form-text.component';
import { required } from '../shared/validators';
import { AbstractObject, uuid } from './abstract-object';
import { Action } from './action';
import { Attachment } from './attachment';
import { Person } from './person';
import { RequestTemplate } from './RequestTemplate';

import { Team } from './team';
import { Omc } from './Omc';
import { Unit } from './unit';
import { User } from './user';
import { DateHelper } from '../shared/dateHelper';
import { Memo } from './memo';
import { PreferredSupplier, Supplier } from './supplier';
import { MailItem } from './mailMerge';

export class RequestBudget extends AbstractObject {
    bCodeId!: uuid;
    scheduleId!: uuid;
    amount = 0;
}
export class SrState extends AbstractObject {
    sortOrder: number;
}

export class BillingState extends AbstractObject {

}
export class ServiceRequest extends AbstractObject {
    title: string;
    description: string;
    srStatus: uuid;
    subStatus: uuid;
    srCloseStatus: uuid;
    completedAt?: number;
    ownerReadAt: number;

    agentTeamId: uuid;

    reportedByPersonId: uuid;
    reportedByName: string;

    ownedById: uuid;

    actions: Action[] = [];
    attachments: Attachment[] = [];
    memos?: Memo[] = [];
    mailItems?: MailItem[] = [];

    templateId: uuid;
    template: RequestTemplate;
    data: unknown;
    dataFields?: AbstractObject[] = [];
    requestBudget?: RequestBudget[] = [];
    childRequests?: ServiceRequest[] = [];

    supplierId?: uuid;
    supplier?: Supplier;


    refNr?: number;

    categoryId: uuid;
    priorityId: uuid;


    plannedDate: string;

    omcId: uuid;
    omc: Omc;

    jobId: uuid;

    parentId: uuid;
    parent: ServiceRequest;

    team: Team;

    unitId: uuid;
    unit: Unit;

    personId: uuid;
    person: Person;

    openMonth: string;

    constructor(o: Partial<ServiceRequest> = {}) {
        super(o);
    }

    static srOpen = { id: 'open', name: 'Open' } as SrState;
    static srResolved = { id: 'resolved', name: 'Resolved' } as SrState;
    static srAccepted = { id: 'accepted', name: 'Accepted' } as SrState;

    static srStates: SrState[] = [ServiceRequest.srOpen, ServiceRequest.srResolved, ServiceRequest.srAccepted];

    static billOmc = {id: 'omc', name: 'To Be Billed To OMC'} as BillingState
    static billOwner = { id: 'owner', name: 'To Be Billed To Owner' } as BillingState
    static billedOmc = { id: 'billedOmc', name: 'Billed To OMC' } as BillingState
    static billedOwner = { id: 'billedOwner', name: 'Billed To Owner' } as BillingState
    static included = { id: 'included', name: 'Included in SLA' } as BillingState
    static billingStates: BillingState[] = [
        ServiceRequest.billOmc, ServiceRequest.billOwner, ServiceRequest.billedOmc,
        ServiceRequest.billedOwner, ServiceRequest.included
    ];


    static srOverdue = { id: 'overdue', name: 'Overdue' } as SrState;
    static srPlanned = { id: 'planned', name: 'Planned' } as SrState;
    static srUnplanned = { id: 'unplanned', name: 'Unplanned' } as SrState;
    static srWaiting = {id: 'waiting', name: 'Waiting'} as SrState;

    static srComplete = { id: 'complete', name: 'Complete' } as SrState;
    static srDuplicate = { id: 'duplicate', name: 'Duplicate' } as SrState;
    static srCancelled = { id: 'cancelled', name: 'Cancelled' } as SrState;
    static srNoAction = { id: 'noAction', name: 'No Action Required' } as SrState;

    static srActionStates = [ServiceRequest.srOverdue, ServiceRequest.srPlanned,
        ServiceRequest.srUnplanned, ServiceRequest.srWaiting];

    static srCloseStates = [ServiceRequest.srComplete, ServiceRequest.srDuplicate,
        ServiceRequest.srCancelled, ServiceRequest.srNoAction
    ];

    static srSubStates = ServiceRequest.srActionStates.concat(ServiceRequest.srCloseStates)

    static getOpenMonthField() {
        return FormTextComponent.make('Month Opened', 'openMonth', { visible: Field.noShow, sendServer: false, readonly: true });
    }
    static getOpenWeekField() {
        return FormTextComponent.make('Week Opened', 'openWeek', { visible: Field.noShow, sendServer: false, readonly: true });
    }
    static getCloseMonthField() {
        return FormTextComponent.make('Month Closed', 'closeMonth', { visible: Field.noShow, sendServer: false, readonly: true });
    }
    static getCloseWeekField() {
        return FormTextComponent.make('Month Opened', 'closeWeek', { visible: Field.noShow, sendServer: false, readonly: true });
    }

    static getRefField() {
        return FormNumberComponent.make('Reference', 'refNr', { format: 'plain' },
            { disable: true, formColumn: 4, cellOpts: { heading: 'Ref' } }
        );
        //return FieldMaker.id({ label: 'Reference', cellOpts: { heading: 'Ref' }, visible: Field.showAll, disable: true, formColumn: 3 });
    }
    static getTemplateIdField() {
        return FieldMaker.idHolder('templateId');
    }

    static getOmcField(): PicklistField {
        return FormPicklistComponent.make('Owner Management Company', 'omcId', 'omc',
            { items: [] }, { validators: [required] })
    }
    static getAgentField(): PicklistField {
        return FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
            { items: [] }, { validators: [required] })
    }

    static getTitleField() {
        return FormTextComponent.make('Title - A shortname for this request', 'title', { cellOpts: { heading: 'Title' }, validators: [required] });
    }

    static getTitleLinkField() {
        return FormButtonComponent.makeLink('Request Title', 'title', '/crm/requests/${id}');
    }

    static getDescriptionField() {
        return FormTextAreaComponent.make('Detailed description of what is needed', 'description',
            { cellOpts: { heading: 'Description' }, validators: [required], visible: Field.formOnly }
        );
    }

    static getSupplierField() {
        return FormComboBoxComponent.make('Supplier', 'supplierId', 'supplier', {
            items: [], allowSelectNone: true, detailView: 'suppliers/preferred/${value}',
            optionDisplayValue: (o: PreferredSupplier) => o ? o.name + (o.code ? ' (' + o.code + ')' : '') : ''
        }, {
            formColumn: 1,
        });
    }

    static getParentField() {
        return FormPicklistComponent.make('Parent', 'parentId', 'parent', {
            items: [], allowSelectNone: true, detailView: 'crm/requests/${value}',
            optionDisplayValue: o => o ? o.refNr + ':' + o.title : ''
        }, {
            formColumn: 1,
        });
    }

    static getReportedByField() {
        return FormPicklistComponent.make('Reported By', 'reportedByPersonId', 'reportedByName', { selfPopulate: true }, { formColumn: 4, disable: true });
    }

    static getBillableField() {
        return FormPicklistComponent.make('Billable', 'billingStatus', null,
            { items: ServiceRequest.billingStates, allowSelectNone: true },
            { formColumn: 3 }
        );
        //return FormCheckboxComponent.make('Billable', 'billable', { formColumn: 3 });
    }

    static getOOHoursField() {
        return FormCheckboxComponent.make('Out Of Hours', 'ooHours', { formColumn: 2 });
    }

    static getCategoryField() {
        return FormPicklistComponent.make('Category', 'categoryId', 'category', {
            service: inject(RequestCategoriesService)
        }, { formColumn: 3 }
        );
    }

    static getPriorityField() {
        return FormPicklistComponent.make('Priority', 'priorityId', 'priority', {
            service: inject(RequestPrioritiesService)
        }, { formColumn: 3 }
        );
    }

    static getOwnedByField(users: User[]) {
        return FormComboBoxComponent.make('Owned By', 'ownedById', 'ownedBy', { items: users }, { formColumn: 3 });
    }
    static getOwnedByROField() {
        return FormTextComponent.make('Owned By', 'ownedBy.name', { cellOpts: { heading: 'Owned By' }, readonly: true, sendServer: false });
    }

    static getStatusField() {
        return FormPicklistComponent.make('Status', 'srStatus', null,
            { items: ServiceRequest.srStates, allowSelectNone: false },
            { readonly: true, formColumn: 3, sendServer: false }
        );
    }

    static getSubStatusField() {
        return FormPicklistComponent.make('Action Status', 'subStatus', null,
            { items: ServiceRequest.srSubStates, allowSelectNone: false },
            { readonly: true, formColumn: 3, sendServer: false }
        );
    }

    static getStateField() {
        return FormPicklistComponent.make('Status', 'srStatus', null,
            { items: ServiceRequest.srStates, allowSelectNone: false }, { readonly: true, formColumn: 4, sendServer: false }
        );
    }

    static getCreatedField() {
        return FormDateTimeComponent.make('Created', 'createdAt', { readonly: true, disable: true, formColumn: 4 });
    }

    static getOwnerReadAtField() {
        return FormDateTimeComponent.make('Owner Read', 'ownerReadAt', {
            readonly: true, disable: true, sendServer: false, formColumn: 4, hint: 'Date read by owner'
        });
    }

    static getCompletedField() {
        return FormDateTimeComponent.make('Completed', 'completedAt', { readonly: true, disable: true, formColumn: 4 });
    }

    static getLastUpdatedField() {
        return FormDateTimeComponent.make('Last Updated', 'updatedAt', { readonly: true, disable: true, formColumn: 4 });
    }

    static templateMapper(template: RequestTemplate, sr: ServiceRequest, user: User): ServiceRequest {

        if (template) {
            sr.title = template.name;
            sr.description = template.description;
            sr.templateId = template.id;
            sr.categoryId = template.categoryId;
            sr.priorityId = template.priorityId;

            sr.ownedById = sr.omc ? Omc.getUserId(sr.omc, template.ownerOmcRole) : user.id;
            if (!sr.ownedById) {
                sr.ownedById = user.id;
            }

            for (const actionTemplate of template.actions.sort((a, b) => a.offset - b.offset)) {
                const ownerId = sr.omc ? Omc.getUserId(sr.omc, actionTemplate.ownerOmcRole) : null;

                const newAction = new Action({
                    title: actionTemplate.name,
                    templateId: actionTemplate.id,
                    template: actionTemplate,
                    teamId: sr.teamId,
                    omcId: sr.omcId,
                    ownedById: ownerId ? ownerId : user.id,
                    createdById: user.id,
                    offset: actionTemplate.offset,
                    timeSpent: actionTemplate.timeSpent
                });

                if (sr.plannedDate) {
                    const actionDt = DateHelper.addDays(sr.plannedDate, actionTemplate.offset);
                    if (!DateHelper.beforeToday(actionDt)) {
                        newAction.plannedDate = actionDt;
                    } else {
                        newAction.plannedDate = DateHelper.today();
                    }
                }

                sr.actions.push(newAction);
            }
        }
        return sr;
    }
}
