/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { NavItem } from 'src/app/model/sys/nav-item';
import { NavRoute } from '../../NavRoute';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDivider } from '@angular/material/divider';

@Component({
    selector: 'app-nav-item',
    templateUrl: './nav-item.component.html',
    styleUrls: ['./nav-item.component.scss'],
    standalone: true,
    imports: [MatExpansionModule, RouterLink, NgClass, MatIconModule, MatTooltipModule, MatDivider]
})

export class NavItemComponent {

    @Input() item: NavItem;
    @Input() showMenuText = true;
    @Input() asChild = false;
    @Input() selectedItem: NavItem;
    @Output() opened: EventEmitter<NavItem> = new EventEmitter();
    @Output() selected: EventEmitter<NavItem> = new EventEmitter();

    constructor(private router: Router) { }

    onOpen() {
        this.opened.emit(this.item);
    }

    selectOption(item: NavItem) {
        this.selected.emit(item);
    }

    selectChild(item: NavItem) { // Pass it up to parent
        this.selected.emit(item);
    }

    myRouterLink(item: NavItem) {
        if (!item.url) {
            return null;
        }

        if (item.views?.length > 0) {
            return ['/' + item.url, { _view: item.views[0].id }];
        }

        return ['/' + item.url];

    }

    itemIcon(item: NavItem) {
        //console.warn(item);
        if (item.url && !item['open']) {
            const nr = NavRoute.getRoute(item.url);
            if (nr && nr.icon) {
                return nr.icon;
            }
        }
        if (item.icon) {
            return item.icon;
        }
        return '';
    }

    /*
    private _item: NavItem;
    private _route: NavRoute

    @Input() set item(ni: NavItem) {
        this._item = ni;
        this._route = NavRoute.getRoute(ni.url);
    }

    public get item(): NavItem { return this._item }

    public get route(): NavRoute { return this._route }
    */

}
