/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { formatCurrency } from "@angular/common";
import moment from "moment";
import { Field } from "src/app/shared/field/Field";

export class ImportField {
    private name: string;
    private heading: string;

    private _value: unknown;
    private errors: string[] = [];
    private hasErrors = false;

    private allowedOptions: Map<unknown, unknown> = null;
    private isRequired = false;
    private isBMDate = false;
    private isCurrency = false;
    private isObject = false;
    private isHtml = false;
    private isNumber = false;
    private isEmpty = false;
    private bmLineFeeds = false;

    public skipField = false;

    constructor(name: string, heading: string = null) {
        this.name = name;
        if (heading === null) {
            this.heading = name;
        } else {
            this.heading = heading;
        }
    }

    private validateWithFn: (value: string, setValue?: (any) => void) => string | null;

    allow(options: Map<unknown, unknown>) {
        this.allowedOptions = options;
        return this;
    }

    require() {
        this.isRequired = true;
        return this;
    }
    private isNumeric(str: unknown) {
        if (typeof str !== "string") {
            return false; // we only process strings!
        }
        return !isNaN(+str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    }

    private setNumberValue(value: unknown) {
        if (value) {
            if ((typeof value === 'string' || value instanceof String)) {

                value = value.replace(',', '');
                if ((value as string).trim() === '') {
                    this._value = null;
                } else if (this.isNumeric(value)) {
                    this._value = +value;
                } else {
                    this._value = value;
                    console.log('Non Numeric at all', {value})
                    this.addError('Not Numeric');
                }
            } else {
                this._value = value;
                if (typeof value !== 'number') {
                    console.log('Non Numeric really', { value })
                    this.addError('Not Numeric');
                }
            }
        } else {
            this._value = 0;
        }
    }

    private setBMDateValue(value: unknown) {
        if (value) {
            if ((typeof value === 'string' || value instanceof String)) {
                if (value === 'NULL') {
                    this._value = '';
                } else {
                    if (moment(value as string, 'DDMMMYYYY').isValid()) {
                        const date = moment(value as string, 'DDMMMYYYY');
                        this._value = date.format('yyyy-MM-DD');
                    } else {
                        this.addError('Expected string value formatted as ddMmmYYYY - Found ' + value);
                    }
                }
            } else {
                this.addError('Expected string value formatted as ddMmmYYYY - Found non String ' + value);
            }
        }
    }

    validateUsing(withFn: (value, setValue?: (val) => void) => string | null) {
        this.validateWithFn = withFn;
        return this;
    }

    forceValue(value: unknown) {
        this._value = value;
    }

    setValue(value: unknown) {

        this._value = value;

        if (this.isCurrency || this.isNumber) {
            this.setNumberValue(value);
        }

        if (this.isBMDate) {
            this.setBMDateValue(value);
        }

        if (this.isRequired) {
            if (Field.isEmpty(value)) {
                this.addError('Required field not provided');
            }
        }

        if (this.isEmpty) {
            if (value !== undefined && value !== null && (value as string).trim() !== '') {
                console.warn({ field: this, value });
                this.addError('Expected field to be empty - Found [' + value + ']');
            }
        }

        if (this.allowedOptions) {
            if (this.allowedOptions.has(value)) {
                this._value = this.allowedOptions.get(value);
            } else {
                this.addError('Value ' + value + ' is not allowed');
            }
        }

        if (this.bmLineFeeds) {
            while ((this._value as string).indexOf(String.fromCharCode(8)) > 0) {
                this._value = (this._value as string).replace(String.fromCharCode(8), String.fromCharCode(10));
            }

        }

        if (this.validateWithFn) {
            const err = this.validateWithFn(this._value as string, this.forceValue.bind(this));
            if (err) {
                this.addError(err);
            }
        }

        return this;
    }

    getName() {
        return this.name;
    }

    getHeading() {
        return this.heading;
    }
    setHeading(heading: string) {
        this.heading = heading;
    }

    getValue() {
        return this._value;
    }

    private formatCurrency() {
        const v = this._value as number;
        if (v !== 0) {
            return formatCurrency(this.getValue() as number, 'EN-ie', '€');
        } else {
            return '';
        }
    }

    getFormattedValue() {
        if (this.isCurrency) {
            return this.formatCurrency();
        }
        if (this.isObject) {
            return this.getValue ? 'Yes' : 'No'
        }
        return this.getValue();
    }

    public addError(msg: string) {
        this.hasErrors = true;
        if (this.heading && this.heading !== this.name) {
            msg += $localize` on field '${this.heading}' (${this.name})`;
        } else {
            msg += $localize` on field ${this.name}`;
        }
        console.error(msg);
        this.errors.push(msg);
    }

    public clearErrors() {
        this.hasErrors = false;
        this.errors = [];
    }

    public errorCount(): number {
        return this.errors.length;
    }

    public fromBMDate() {
        this.isBMDate = true;
        return this;
    }

    public withBMLineFeeds() {
        this.bmLineFeeds = true;
        return this;
    }

    public asHtml() {
        this.isHtml = true;
        return this;
    }

    public asObject() {
        this.isObject = true;
        return this;
    }

    public asCurrency() {
        this.isCurrency = true;
        return this;
    }

    public asNumber() {
        this.isNumber = true;
        return this;
    }

    public expectEmpty() {
        this.isEmpty = true;
        return this;
    }

    public hasError() {
        if (this.errors.length > 0 || this.hasErrors) {
            return true;
        } else {
            return false;
        }
    }

    public getErrors() {
        return this.errors;
    }

    public getHeaderField(): HeaderField {
        return new HeaderField(this.heading);
    }
}

export class HeaderField extends ImportField {
    constructor(name: string) {
        super(name);
        this.allow(new Map([[name, 'ok']])).require();
    }
}
