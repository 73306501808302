
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from "src/app/model/abstract-object";
import { Field } from "src/app/shared/field/Field";
import { ImportInput } from "./ImportInput";
import { ImportRow } from "./ImportRow";

export class ImportDocNote {
    note: string;
    deleteHandler?: () => void;
    needsFix?: boolean;
    childNotes?: ImportDocNote[];

    constructor(defaults: Partial<ImportDocNote> = {}) {
        Object.assign(this, defaults);
    }

    clearChildren() {
        if (this.childNotes === undefined || this.childNotes === null) {
            this.childNotes = [];
        }
        this.childNotes.length = 0;
    }
    addChildNote(text: string, needsFix = false) {
        const newNote = new ImportDocNote();
        newNote.note = text;
        newNote.needsFix = needsFix;
        if (this.childNotes === undefined || this.childNotes === null) {
            this.childNotes = [];
        }
        this.childNotes.push(newNote);
    }
}

export class ImportDoc {
    private rows: ImportRow[] = [];
    private inputs: ImportInput[] = [];
    private errors: string[] = [];
    private errorCount = 0;
    private _completed = 0;
    get completed() {
        return this._completed;
    }
    set completed(c: number) {
        this._completed = c;
    }

    private totalLines = 0;
    private processedLines = 0;

    private generalFirstLine = 0;

    private notes: ImportDocNote[] = [];

    getGeneralRows(): ImportRow[] {
        return this.rows.slice(this.generalFirstLine);
    }

    setFirstRow(n: number) {
        if (n >= this.rows.length) {
            console.error('Cannot set first line greater than number of rows', { n, rows: this.rows });
            this.generalFirstLine = this.rows.length - 1;
        } else {
            this.generalFirstLine = n;
        }
    }

    getRows(): ImportRow[] {
        return this.rows;
    }

    getNotes() {
        return this.notes;
    }

    addNote(note: string, deleteHandler: () => void = null, needsFix = false) {
        const item = new ImportDocNote();
        item.note = note; item.deleteHandler = deleteHandler; item.needsFix = needsFix;
        this.notes.push(item);
        return item;
    }

    addNoteItem(idn: ImportDocNote) {
        this.notes.push(idn);
    }

    deleteNote(item: ImportDocNote, fireHandler = false) {
        const idx = this.notes.indexOf(item);
        if (idx > 0) {
            this.notes.splice(idx, 1);
            if (item.deleteHandler && fireHandler) {
                item.deleteHandler();
            }
        } else {
            console.warn('Note item not found ', { item, notesLen: this.notes.length, notes: this.notes });
        }
    }

    getRowsAsData(): AbstractObject[] {
        const data: AbstractObject[] = [];
        for (const row of this.rows) {
            data.push(row.getDataAsItem());
        }
        return data;
    }

    getInputs(): ImportInput[] {
        return this.inputs;
    }

    getInput(name: string): ImportInput {
        return this.inputs.find(ii => ii.getName() === name);
    }

    clearInputs() {
        this.inputs = [];
    }

    clearRows() {
        this.rows = [];
    }

    hasErrors(): boolean {
        if (this.errorCount > 0) {
            return true;
        } else {
            return false;
        }
    }

    needsFix(): boolean {
        if (this.notes.find(n => n.needsFix)) {
            return true;
        } else {
            return false;
        }
    }

    getErrorCount() {
        return this.errorCount;
    }

    add(row: ImportRow) {
        this.errorCount += row.errorCount();
        this.rows.push(row);
    }

    setRows(rows: ImportRow[]) {
        this.rows = rows;
        //this.notes = '';
        this.notes = [];
        this.finalise();
    }

    addInputAtTop(input: ImportInput) {
        this.inputs.unshift(input);
    }

    addInput(input: ImportInput) {
        this.inputs.push(input);
    }

    removeInputForField(fld: Field) {
        const input = this.inputs.find(i => i.getFields().find(ifld => ifld === fld));
        if (input === undefined) {
            console.error('Could not find field ', { fld, inputs: this.inputs });
            return null;
        } else {
            const idx = this.inputs.indexOf(input);
            this.inputs.splice(idx, 1);
            return input;
        }
    }

    removeInput(input: ImportInput) {
        const idx = this.inputs.indexOf(input);
        if (idx >= 0) {
            this.inputs.splice(idx, 1);
        } else {
            console.warn('Could not find input', { doc: this, input });
        }
    }

    finalise() {
        this.errorCount = 0;
        for (const row of this.rows) {
            row.markErrors();
            this.errorCount += row.errorCount();
            for (const field of row.getFields()) {
                this.errorCount += field.errorCount();
            }
        }
    }

    reportSuccess() {
        this.setRows([]);
        this.clearInputs();
        this.addInput(new ImportInput('Import completed successfully', null, ''));
    }

    reportFailure() {
        console.error(this);
        alert('Import Failed!');
    }
}
