
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
export type ImportRole = 'owner' | 'ownerOccupier' | 'keyholder' | 'tenant'

export class ImportPerson {
    firstName?: string;
    lastName: string;
    title?: string;
    email: string;
    phone: string;
    notes: string;
    address?: string;
    role: ImportRole;
}

export class NameSplitter {

    static splitName(list: ImportPerson[], name: string, phoneText: string, email: string, role: ImportRole, address: string = null) {

        const phoneInfo = NameSplitter.parsePhone(phoneText);

        if (NameSplitter.isCompany(name)) {
            list.push({
                lastName: name,
                phone: phoneInfo.phone,
                notes: phoneInfo.notes,
                email, role,
                address
            })
        } else {
            const parts = NameSplitter.splitTwoNames(name);
            const firstPerson = NameSplitter.parseName(parts[0]);
            firstPerson.phone = phoneInfo.phone;
            firstPerson.notes = phoneInfo.notes,
            firstPerson.email = email;
            firstPerson.role = role;
            firstPerson.address = address;
            list.push(firstPerson);

            if (parts.length > 1) {
                const secondPerson = NameSplitter.parseName(parts[1].trim());
                secondPerson.role = role;
                secondPerson.phone = phoneInfo.phone;
                secondPerson.notes = phoneInfo.notes;
                if (email) {
                    secondPerson.email = email + '+2ndEmail';
                }

                list.push(secondPerson);
            }
        }
    }

    private static parsePhone(phoneText: string) {
        if (phoneText) {
            const firstIndex = phoneText.indexOf(String.fromCharCode(10));
            if (firstIndex > 0) {
                const firstEntry = phoneText.substring(0, firstIndex);
                const phone = firstEntry.replace('Mobile: ', '').trim();
                const notes = phoneText.replace(firstEntry + String.fromCharCode(10), '').replace('Other:', '').trim();
                return { phone: phone || null, notes: notes || null };
            } else if (firstIndex === 0) {
                return { phone: null, notes: phoneText.replace(String.fromCharCode(10) + 'Other:', '').trim()};
            } else {
                const phone = phoneText.replace('Mobile: ', '').trim();
                return { phone: phone || null, notes: null };
            }
        } else {
            return { phone: null, notes: null };
        }
    }

    private static parseName(name: string) : ImportPerson{
        //nameSimpleSplit(name, row: ImportRow, isFirst = true) {
        let names = name.split(' ');
        let title = ''

        const titles = "Mr |Mrs |Dr |Miss |Ms |Prof |Rt Hon ";
        const titleParts = name.split(new RegExp(titles, 'i'));

        if (titleParts.length === 2) {
            title = name.replace(titleParts[1], '').trim();
            name = name.replace(title, '').trim()
            names = name.split(' ');
        }

        let firstName = '';
        let lastName = name;
        if (names.length > 1) {
            firstName = names[0].trim();
            lastName = name.replace(firstName + ' ', '').trim();
        }
        lastName = lastName.replace("’", "'");
        return { title, firstName, lastName} as ImportPerson;
    }

    private static splitTwoNames(name: string) {
        const splitRegex = ' & | and ';
        const parts = name.trim().split(new RegExp(splitRegex, 'i'));
        NameSplitter.checkFirstAndFirstSurname(parts);
        return parts;
    }

    private static checkFirstAndFirstSurname(parts) {
        /* Make Mr & Mrs Smith into Mr Smith & Mrs Smith
        */
        if (parts.length === 2 && parts[0].indexOf(' ') < 0) {
            const secondNameParts = parts[1].trim().split(' ');
            parts[0] += ' ' + secondNameParts[1];
        }
    }

    private static isCompany(name: string) {
        const companyRegex = ' Limited| Ltd.| Ltd| Council| Fund| Partners| Association| Pension|  Group';
        const companyParts = name.trim().split(new RegExp(companyRegex, 'i'));

        if (companyParts.length > 1) {
            return true;
        }
        return false;
    }

}
