
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from "src/app/model/abstract-object";
import { ImportField } from "./ImportField";

export class ImportRow {
    private fields: ImportField[] = [];
    private errors: string[] = [];
    private finalErrors: string[] = null;
    public hasErrors = false;
    private lineNr = 0;
    public skipRow = false;

    constructor(fields: ImportField[] = []) {
        this.fields = fields;
    }

    public getFields() {
        return this.fields;
    }

    public getFullText() {
        let ret = ''
        for (const f of this.fields) {
            const value = f.getValue();
            ret += `"${value ? value : ''}"`;
        }
        return ret;
    }

    public getTextSample() {
        let ret = ''
        for (const f of this.fields) {
            const value = f.getValue();
            ret += value + ', ';
        }
        return ret.substring(0, 50);
    }

    public getNewHeaderRow() {
        const newHeaderRow = new ImportRow();
        for (const f of this.fields) {
            newHeaderRow.add(f.getHeaderField());
        }
        return newHeaderRow;
    }

    public add(fld: ImportField) {
        this.fields.push(fld);
        return this;
    }

    public remove(fldName: string) {
        const fld: ImportField = this.getField(fldName);
        const idx = this.fields.indexOf(fld);
        this.fields.splice(idx, 1);
    }

    public addHeaderField(text) {
        this.add(new ImportField(text).allow(new Map([[text, text]])).require());
    }

    public parse(data: unknown[]) {
        const fields = this.getFields();
        data.forEach((value, index) => {
            if (fields[index]) {
                fields[index].setValue(value);
                if (fields[index].hasError()) {
                    console.warn('Parse Error', { field: fields[index], data, errors: fields[index].getErrors() });
                }
            } else {
                let msg = 'No corresponding field definition for index ' + index + ' Data:';
                data.forEach(value => msg += value + ',');
                msg += ' has ' + data.length + ' elements, but only ' + fields.length + ' expected';
                this.addError(msg);
            }

        });
        if (fields.length > data.length) {
            this.addError('Row expects ' + fields.length + ' fields, but only found ' + data.length);
        }
        return this;
    }

    public addError(msg: string) {
        this.hasErrors = true;
        console.error(msg);
        this.errors.push(msg);
    }

    public markErrors() {
        this.finalErrors = [... this.errors];
        for (const f of this.fields) {
            for (const m of f.getErrors()) {
                this.finalErrors.push(m as string);
            }
        }
        if (this.finalErrors.length > 0) {
            this.hasErrors = true;
        } else {
            this.hasErrors = false;
        }
    }

    public getErrors() {
        return this.finalErrors;
    }

    public errorCount(): number {
        return this.errors.length;
    }
    public fullErrorCount(): number {
        let cnt = this.errors.length;
        for (const f of this.getFields()) {
            cnt += f.getErrors().length;
        }
        return cnt;
    }

    public hasField(fieldName: string): boolean {
        return this.fields.find(f => f.getName() === fieldName) ? true : false;
    }

    public getNrValue(fieldName: string): number {
        return this.getValue(fieldName) as number;
    }
    public get<t>(fieldName: string) : t {
        return this.getValue(fieldName) as t;
    }

    public getStringValue(fieldName: string): string {
        return this.getValue(fieldName) as string;
    }

    public getField(fldName: string) {
        return this.fields.find(f => f.getName() === fldName);
    }

    public getValue(fieldName: string): unknown {
        const fld = this.getField(fieldName);
        if (fld) {
            return fld.getValue();
        } else {
            this.addError('Undefined field: ' + fieldName);
            return '';
        }
    }

    public setValue(fieldName: string, value: unknown) {
        const fld = this.fields.find(f => f.getName() === fieldName);
        fld.setValue(value);
        return this;
    }

    getDataAsItem(): AbstractObject {
        const newItem = {};
        for (const f of this.fields) {
            newItem[f.getName()] = f.getValue();
        }
        return newItem as AbstractObject;
    }

    static Blank(fldCnt: number) {
        const flds = [];
        for (let i = 0; i < fldCnt; i++) {
            flds.push(new ImportField('empty' + i).allow(new Map([['', null]])))
        }
        return new ImportRow(flds);
    }
}
