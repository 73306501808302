/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, OnInit } from '@angular/core';
import { PayzService } from '../../payzService';
import { first } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';
import { NavRoute } from 'src/app/shared/NavRoute';
import { MatCardModule } from '@angular/material/card';
@Component({
    selector: 'app-confirm-page',
    templateUrl: './confirm-page.component.html',
    styleUrls: ['./confirm-page.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class ConfirmPageComponent implements OnInit {

    static readonly navRoute = new NavRoute('payments/:sessionId', ConfirmPageComponent, '');

    instructionReceived = false;
    payz = null;
    payzMandate = null;
    sessionId: string = null;
    isMandate = false;
    payzChecked = false;

    constructor(private payzSvc: PayzService,
        protected activeRoute: ActivatedRoute,) {
    }

    ngOnInit(): void {
        combineLatest([this.activeRoute.params, this.activeRoute.queryParamMap]).subscribe(
            ([params, queryParams]) => {
                this.sessionId = params.sessionId;
                this.instructionReceived = true;
                if (queryParams.has('isMandate')) {
                    console.log('Is a mandate', queryParams, params);
                    this.isMandate = true;
                    this.payzSvc.getMandate(params.sessionId).pipe(first()).subscribe(payzMandate => {
                        this.payzMandate = payzMandate;
                        this.payzChecked = true;
                    });
                } else {
                    console.log('Not a mandate', queryParams, params);
                    this.isMandate = false;
                    this.payzSvc.getSession(params.sessionId).pipe(first()).subscribe(payz => {
                        this.payz = payz;
                        this.payzChecked = true;
                    });
                }
            }
        );
    }
}
