<h2 class="dialog-banner">Edit Transaction Date on {{bankIn?.refNr}}</h2>
<mat-dialog-content class="mat-typography" style="max-width: 400px">
    <app-field-set [fieldSet]="fieldSet" [isPhone]="true" [initComplete]="ready"></app-field-set>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button (click)="saveChanges()" [disabled]="!fieldSet.formGroup?.valid" color="primary"
        cdkFocusInitial>Save</button>
</mat-dialog-actions>
