/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
* Create a bar chart to display above a table
* Yes - OK - It is a column chart really, but never heard anyone in real world call it a column chart...
*/
import { AbstractObject } from "src/app/model/abstract-object";
import { AbstractChart } from "./abstract-chart";
import { Field } from "../../field/Field";
import { PicklistField } from "../../field/PicklistField";

export class TrendLine extends AbstractChart {

    height = 200;

    getTooltip() {
        return {
            pointFormat: '{series.name}: {point.y} <br/><b>{point.pct:.1f}%</b>'
        };
    }

    getChartTitle() {
        return { text: this.trendField.label, style: { fontSize: '14px', fontWeight: 'bold' } };
    }

    categories: string[] = [];

    protected createChartOptions() {

        const options = {
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'area',
                height: this.height,
            },
            title: this.getChartTitle(),
            credits: { enabled: false },
            tooltip: {
                shared: true,
                headerFormat: '<span style="font-size:12px"><b>{point.key}</b></span><br>'
            },
            xAxis: {
                categories: this.categories,
            },
            yAxis: {
                title: { text: (this.numberField ? this.numberField.label : 'Count') },
            },
            //legend: {enabled: false},
            plotOptions: {
                area: {
                    stacking: 'normal',
                    lineColor: '#666666',
                    lineWidth: 1,
                    marker: {
                        enabled: false,
                        lineWidth: 1,
                        lineColor: '#666666'
                    }
                },
            },
            series: this.series
        };
        return options;
    }

    private getValue(field: Field, o: AbstractObject) {
        if (field instanceof PicklistField) {
            return field.getDisplayValue(o)
        } else {
            return field.getValue(o);
        }
    }

    protected analyseData() {
        const mapper: Map<string, Map<number, { total: number }>> = new Map();

        const plMapper: Map<number, string> = new Map();

        //let grandTotal = 0;
        //const type = this.trendField.label;

        this.series = [];
        this.categories = [];

        this.data.map(o => {
            const trendValue = this.getValue(this.trendField, o);
            const pickValue = this.picklist.getValue(o);

            if (trendValue) {
                if (!mapper.has(trendValue)) {
                    mapper.set(trendValue, new Map())
                    this.categories.push(trendValue);
                }
                const oTrendMap = mapper.get(trendValue);

                if (!plMapper.has(pickValue)) {
                    plMapper.set(pickValue, this.picklist.getDisplayValueFromId(pickValue));
                }

                if (!oTrendMap.has(pickValue)) {
                    oTrendMap.set(pickValue, { total: 0 });
                }
                const oPickMap = oTrendMap.get(pickValue);

                const tValue = (this.numberField ? this.numberField.getValue(o) : 1);
                oPickMap.total = oPickMap.total + tValue;
                //grandTotal += tValue;
            }
        });

        this.categories = this.categories.sort();

        for (const k of plMapper.keys()) {
            this.series.push({ name: plMapper.get(k), data: this.getSeriesData(mapper, k) })
        }
    }

    private getSeriesData(mapper: Map<string, Map<number, { total: number }>>, k: number) {
        const data = [];
        for (const c of this.categories) {
            const catEntry = mapper.get(c).get(k);
            if (catEntry) {
                data.push(catEntry.total);
            } else {
                data.push(0);
            }

        }
        return data;
    }
}
