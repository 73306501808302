/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule } from '@angular/material/tabs';
import { uuid } from 'src/app/model/abstract-object';
import { BankItem } from 'src/app/model/bankAccount';
import { Txn } from 'src/app/model/txn';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { TableComponent } from 'src/app/shared/table/table.component';
import { BankReconiliationService } from '../bank-reconciliation.service';

@Component({
  selector: 'app-unmatched-txn',
  standalone: true,
  imports: [TableComponent, MatTabsModule, MatIconModule, CurrencyPipe, MatButtonModule],
  templateUrl: './unmatched-txn.component.html',
  styleUrl: './unmatched-txn.component.scss'
})
export class UnmatchedTxnComponent implements OnChanges{

    @Input() txnList: Txn[] = [];
    @Input() bankItem: BankItem;
    @Output() removeMatch = new EventEmitter();
    @Output() removeUnmatched = new EventEmitter<Map<uuid, Txn>>();

    bankRecService = inject(BankReconiliationService);

    matchValue = 0;
    diffValue = 0;

    selectedList: Map<uuid, Txn> = new Map();

    selectField = FormButtonComponent.makeTableButton(' ', 'selected', this.matchItem.bind(this),
        {
            calculateValue: (o) => {
                if (this.selectedList.has(o.id)) {
                    return 'radio_button_checked';
                } else {
                    return 'radio_button_unchecked'
                }
            }, tableIconStyler: () => ({'color': 'green'})
        }, 'icon');

    readonly fields = [
        this.selectField,
        FormPicklistComponent.make('Type', 'typeId', 'type', { items: Txn.TYPES }, { readonly: true }),
        FormTextComponent.make('Ref', 'refNr'),
        FormDateComponent.make('Transaction Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
        FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
        FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
    ];

    readonly config = new FormConfig(
        {
            fieldSet: new FieldSet(
                {
                    fields: this.fields,
                }
            ),
            mode: 'list',
        }
    );

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.txnList) {
            this.selectedList.clear();
        }
        if (changes.bankItem) {
            this.calcMatchValue()
        }
    }

    matchItem(item: Txn) {
        if (this.selectedList.has(item.id)) {
            this.selectedList.delete(item.id);
        } else {
            this.selectedList.set(item.id, item);
        }
        this.calcMatchValue();
    }

    calcMatchValue() {
        this.matchValue = 0;
        this.diffValue = 0;
        this.selectedList.forEach((item) => {
            this.matchValue += item.debit - item.credit;
        });
        if (this.bankItem) {
            this.diffValue = this.bankItem.transactionAmount - this.matchValue;
        }
    }

    reconcile() {
        const txns = Array.from(this.selectedList.values());
        this.bankRecService.markReconciled(this.bankItem, txns).subscribe(
            (done) => {
                if (done) {
                    this.removeUnmatched.emit(this.selectedList);
                    this.removeMatch.emit();
                }
            }
        );
    }
}
