/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { SafeUrlPipe } from 'src/app/shared/pipes/safe-url.pipe';
import { LeadProfileService } from '../../lead-profile.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ApprovalStatus } from '../demos.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

class Demo {
    title: string;
    text?: string;
    url: string;
    duration: number;
    category: string[];
}
class DemoProgress {
    watched = 0;
    started = 0;
}

@Component({
  selector: 'app-show-demos',
  standalone: true,
    imports: [MatProgressSpinnerModule, MatIconModule, SafeUrlPipe, MatProgressBarModule, MatCardModule,
        MatTooltipModule, MatButtonModule, NgTemplateOutlet, MatExpansionModule, MatTabsModule],
  templateUrl: './show-demos.component.html',
  styleUrl: './show-demos.component.scss'
})
export class ShowDemosComponent {
    math = Math;
    screenWidth = 800;

    @Output() requestAccess = new EventEmitter();
    @Input() approval: ApprovalStatus;

    lps = inject(LeadProfileService);

    isLoading = true;
    selectedDemo: Demo;

    timeOutID: number;

    isPhone = false;

    demoProgress : {[key:string] : DemoProgress}

    videoPath = 'https://clgassets.fra1.cdn.digitaloceanspaces.com/videos/';

    categories: {name: string, count: number}[] = [];

    demos: Demo[] = [
        {
            title: `An Introduction to OurOMC`,
            text: `An explanation of why OurOMC exists, what makes software for OMCs special and how OurOMC solves the problem`,
            url: 'introduction.mp4',
            duration: 441, category: ['Introductory']
        },
        {
            title: 'Voting - Making decisions in your OMC',
            text: `A ballot of the members is not necessarily binding on the directors.
                    However, if decisions have to be made for the good of the community,
                    that is a lot easier if you know what it is the community wants!`,
            url: 'voting.mp4',
            duration: 544, category: ['Owners', 'Introductory']
        },
        {
            title: 'Effective Communications - Owners, Residents & Directors working together',
            text: `Stop wasting money on mailshots and newsletters.
                    Let OurOMC handle your communications with members and residents!`,
            url: 'posts.mp4',
            duration: 327, category: ['Owners', 'Introductory']
        },
        {
            title: 'Document Management - Where does your OMC keep important documents?',
            text: ` Can you share documents & files with Owners, Residents or Directors as appropriate?
                    Can you create your own wiki style pages? Watch to see how you can with OurOMC
                    `,
            url: 'document-management.mp4',
            duration: 316, category: ['Owners']
        },
        {
            title: 'Budgeting & Billing - Preparing the budget and issuing charges',
            text: `Whether it is a simple flat charge to each unit, or a complex multi schedule budget
                    OurOMC makes it a breeze to produce the budget, share and approve it with the members,
                    then automatically send the service charges to the members
                    `,
            url: 'budgeting.mp4',
            duration: 670, category: ['Owners', 'Directors', 'Accounting']
        },
        {
            title: 'OurOMC for Property Management Agents',
            url: 'agents.mp4',
            text: `OurOMC provides the tools to enable agents to manage multiple OMCs, efficiently, from a single login.
            We provide the tools to allow agents spend less time on the mundane and focus on adding value to your property`,
            duration: 598, category: ['Agents']
        },
        {
            title: 'Managing Service Charges',
            url: 'accounts-receivable.mp4',
            text: `Full service charge accounting lets you manually issue a service charge if needs be,
            enter credit notes, record receipts and see what is outstanding`,
            duration: 299, category: ['Directors', 'Accounting']
        },
        {
            title: 'Purchasing - Getting the money in is only half the story',
            url: 'purchases.mp4',
            text: ` You also need to track how the money goes out!
                    Watch this video to see how OurOMC enables auditability by attaching images of all purchases.
                    Allows directors to approve payments,
                    and how the system learns to make transactions easier to enter next time.`,
            duration: 428, category: ['Directors', 'Accounting']
        },
        {
            title: 'Banking Integration - Do you know how much is in the bank?',
            text: `Online banking integration allows all of your banking transaction be automatically
                    pulled into your accounts, saving hours of work on the book keeping.`,
            url: 'banking.mp4',
            duration: 456, category: ['Directors', 'Accounting']
        },
        {
            title: 'Financial Reporting',
            text: `OurOMC provides full financial accounting. See how the reports can be easily produced and shared`,
            url: 'financial-reports.mp4',
            duration: 389, category: ['Directors', 'Accounting']
        },
        {
            title: 'Managing Requests from members',
            url: 'requests.mp4',
            text: `A complete request management process to allow users to create requests,
                requests to be tracked, with supporting images, documents and communications all in one place.`,
            duration: 838, category: ['Agents']
        },
        {
            title: 'Proactive Maintenance',
            url: 'proactive-maintenance.mp4',
            text: `It is one thing to be reactive to what people ask for.
                    But there are loads of things that need to happen, no one asks for, they are just expected.
                    Contract renewals, Servicing, see how OurOMC can make sure nothing ever gets missed again.`,
            duration: 414, category: ['Directors', 'Agents']
        },
    ]

    constructor(public screenSvc: IsNarrowService) {
        this.screenSvc.detectVeryNarrow().subscribe(this.resize.bind(this));
        this.screenSvc.detectNarrow().subscribe(this.resize.bind(this));
        this.resize();

        this.demos.forEach( d => {
            d.category.forEach( c => {
                const ctgy = this.categories.find(cat => cat.name === c)
                if (ctgy) {
                    ctgy.count++;
                } else {
                    this.categories.push({name: c, count: 1});
                }
            })
        })
    }

    resize() {
        this.isPhone = false;
        if (this.screenSvc.isPhone()) {
            this.isPhone = true;
            this.screenWidth = 320;
        } else if (this.screenSvc.isTablet()) {
            this.screenWidth = 600;
        } else {
            this.screenWidth = 800;
        }
    }

    onTabChanged() {
        this.selectDemo(null);
    }

    requestAccessClick(hasCode: boolean) {
        this.requestAccess.emit(hasCode);
    }

    selectDemo(demo: Demo) {
        if (demo && this.selectedDemo !== demo) {
            this.selectedDemo = demo;
            this.isLoading = true
            this.updateProgress(demo, true);
        } else {
            this.selectedDemo = null;
            this.cancelTimeout();
        }
    }

    loadProgress() {
        if (!this.demoProgress) {
            const s = window.localStorage.getItem('demoProgress');
            this.demoProgress = {};
            if (s) {
                this.demoProgress = JSON.parse(s);
            }
        }
    }

    cancelTimeout() {
        if (typeof this.timeOutID === "number") {
            window.clearTimeout(this.timeOutID);
        }
    }
    saveProgress() {
        window.localStorage.setItem('demoProgress', JSON.stringify(this.demoProgress));
    }

    getProgressItem(demo: Demo) : DemoProgress{
        if (!this.demoProgress[demo.url]) {
            this.demoProgress[demo.url] = new DemoProgress();
        }
        return this.demoProgress[demo.url];
    }

    getProgress(demo: Demo) {
        this.loadProgress();
        return this.getProgressItem(demo).watched;
    }

    getTooltip(demo: Demo) {
        this.loadProgress();
        const d = this.getProgressItem(demo);
        if (d && d.watched && d.watched === 100) {
            return $localize`You already watched this video`;
        } else if (d && d.watched) {
            return $localize `You have watched ${Math.round(d.watched)}% of this video so far`;
        } else {
            return $localize `You have not watched this video yet`;
        }
    }

    updateProgress(demo: Demo, reset = false) {
        const d = this.getProgressItem(demo);
        if (reset) {
            this.cancelTimeout();
            d.watched = 0;
            d.started = Date.now();
        } else {
            if (d.started && d.watched) {
                const videoEl = document.getElementById('demoVideoElement') as HTMLVideoElement;
                if(videoEl && videoEl.duration && videoEl.currentTime) {
                    const newWatched = videoEl.currentTime / videoEl.duration * 100
                    if (newWatched !== d.watched) {
                        d.watched = newWatched;
                        this.saveProgress()
                    }
                }
            } else {
                d.watched = 1;
            }
            this.saveProgress();
        }
        this.timeOutID = window.setTimeout(() => this.updateProgress(demo),5000);
    }
}
