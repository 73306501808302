/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject, Input, Optional, ViewContainerRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogContent } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ChatItem } from 'src/app/model/chat-item';
import { Field } from 'src/app/shared/field/Field';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { AbstractHttpService } from '../../abstract-http.service';
import { AppFormControl, ControlOn } from '../../form/app-form-control';
import { FieldSet, LAYOUT_OPTIONS } from '../../form/field-set/field-set.component';
import { FormRichTextComponent } from '../../form/form-rich-text/form-rich-text.component';
import { FormTextComponent } from '../../form/form-text/form-text.component';
import { FormConfig } from "../../form/FormConfig";
import { ChatField } from '../chat-field';
import { ChatUpsertComponent } from '../chat-upsert/chat-upsert.component';
import { FieldMaker } from '../../field/FieldMaker';
import { ConfirmDialogService } from '../../dialogs/confirmDialog';
import { IsNarrowService } from '../../is-narrow.service';
import { PickDialogComponent } from '../../dialogs/pick-dialog/pick-dialog.component';
import { Person } from 'src/app/model/person';
import { MyInjector } from 'src/app/app.module';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { Privilege } from 'src/app/model/role';
import { User } from 'src/app/model/user';
import { MatDividerModule } from '@angular/material/divider';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ChatEntryComponent } from '../chat-entry/chat-entry.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { ChatFollowersComponent } from '../chat-followers/chat-followers.component';
import { NgTemplateOutlet, NgClass } from '@angular/common';


@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.html',
    styleUrls: ['./chat.component.scss'],
    standalone: true,
    imports: [MatDialogContent, NgTemplateOutlet, ChatFollowersComponent, MatButtonModule, MatIconModule, ChatEntryComponent, MatTooltipModule, MatMenuModule, MatDividerModule, NgClass]
})
export class ChatComponent {

    hierarchyItems: ChatItem[] = [];

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    chatField: ChatField;

    chatItems: ChatItem[] = [];

    isPhone = false;

    personPrivilege: Privilege;
    addOthers = false;

    constructor(private dialog: MatDialog, private chatItemSvc: UnitService, private currentUserSvc: CurrentUserService,
        private cds: ConfirmDialogService, isNarrowSvc: IsNarrowService,
        @Optional() public dialogRef: MatDialogRef<ChatComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: { chatField: ChatField, addNew: boolean }) {

        if (dialogOptions?.chatField) {
            this.chatField = dialogOptions.chatField;
            this.control = this.chatField.control as AppFormControl;

            if (dialogOptions.addNew) {
                /* No idea why this might ever have been required...
                const chat = this.chatField.chat;
                if (this.getChatItems().length === 0
                && chat.followers.length === 1
                && chat.followers[0].userId === currentUserSvc.currentUser.id) {
                    this.escalateToManagement();
                } else {
                    this.chatUpsert({chatItem: null});
                }
                */
                this.chatUpsert({ chatItem: null });
            }
        }

        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result });

        this.currentUserSvc.getCurrentUser().subscribe( () => {
            if (this.currentUserSvc.currentUser.inAgency) {
                this.addOthers = true; // May still get error, but server will prevent if not actually allowed
                // Should really set this when setting the value, so we can allow for current team...
            } else {
                const priv = User.getPathPrivilege(this.currentUserSvc.currentUser, PersonPageComponent.navRoute.url);
                if (priv.getIndex) {
                    this.addOthers = true;
                }
            }
        })
    }

    public static make(service: AbstractHttpService, options: Partial<ChatField> = {}, allowReplies = true) {
        const field: ChatField = new ChatField(service,
            { label: 'Conversation', value: 'comments', visible: Field.noShow, sendServer: false },
            options
        );
        field.chat.allowReplies = allowReplies;
        field.formControlFactory = ChatComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(ChatComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
        componentRef.instance.chatField = ctl.field as ChatField;
    }

    follow() {
        this.cds.open('Follow this item', 'Do you want to receive mail notifications for updates and comments on this item', () => {
            const chat = this.chatField.chat;
            this.chatField.chat.service.follow(chat.parentId, this.currentUserSvc.currentUser.id, null, chat.teamId).subscribe(
                (f) => {
                    if (f) {
                        this.chatField.chat.followers.push(f);
                    }
            })
        });
    }

    isFollowing(): boolean {
        if (this.chatField.chat.followers.find((f) => f.personUserId === this.currentUserSvc.currentUser.id)) {
            return true;
        } else {
            return false;
        }
    }

    stopFollowing() {
        this.cds.open('Stop Following', 'Do you want to stop receiving mail notifications for updates and comments on this item',
            () => {
                const idx = this.chatField.chat.followers.findIndex(
                    (f) => f.personUserId === this.currentUserSvc.currentUser.id
                );
                if (idx < 0) {
                    this.cds.alert('Cannot stip following', 'you are not following in the first place');
                } else {
                    this.chatField.chat.service.stopFollowing(this.chatField.chat.followers[idx]).subscribe((success) => {
                        if (success) {
                            this.chatField.chat.followers.splice(idx, 1);
                        }
                    });
                }
            });
    }

    /*
    addChatItem() {
        this.upsertChatItem();

        //this.upsertAction().subscribe(o => {
        //  this.actionsField.control.addRow(o, true, false);
        //});

    }
    */
    getChatItems() {
        return (this.control.field as ChatField).chat.hierarchyItems;
    }

    private getChatItemFields(): Field[] {
        console.log('Creating field', { comp: this, fld: this.chatField });
        const flds = [];

        const parentName = this.chatField.chat.parentTitle;

        flds.push(FieldMaker.id()),
            flds.push(FieldMaker.rev()),
            flds.push(FieldMaker.idHolder('requestId'));
        flds.push(FormTextComponent.make('Parent', 'parent',
            { readonly: true, calculateValue: () => parentName })
        );
        flds.push(FormRichTextComponent.make('Comment', 'content', { cellOpts: { width: '50%' } }));

        return flds;
    }

    private createNewCI(): Observable<ChatItem> {
        return of(new ChatItem());
    }

    escalateToManagement() {
        this.cds.open('Escalate to management', 'Add all members of the management team', () => {
            const chat = this.chatField.chat;
            this.chatField.chat.service.escalate(chat.parentId, chat.followers, chat.teamId).subscribe(() => {
                console.log('successful');
            });
        });
    }

    addPersonAsFollower(addFromAgentTeam = false) {
        const personSvc = MyInjector.instance.get(PersonService);

        const config = new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    Person.getTitleField(),
                    FormTextComponent.make('First Name', 'firstName'),
                    FormTextComponent.make('Last Name', 'lastName'),
                    FormTextComponent.make('User Name', 'user.name', { sendServer: false }),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`People`,
        });

        let forceTeamId = null;

        if (this.currentUserSvc.currentUser.inAgency) {
            config.fieldSet.fields.push(FormTextComponent.make('Telephone', 'phone'),);
            config.fieldSet.fields.push(FormTextComponent.make('Email', 'email'));
            config.fieldSet.fields.push(FieldMaker.notes());
            if (!addFromAgentTeam) {
                forceTeamId = this.chatField.chat.teamId
            }
        } else if (addFromAgentTeam) {
            forceTeamId = this.currentUserSvc.currentTeam.primaryAgentId;
        }

        const dialogRef = this.dialog.open(PickDialogComponent, {
            data:
            {
                config,
                service: personSvc,
                showSearch: true,
                forceTeamId: forceTeamId,
            }
        });

        dialogRef.afterClosed().subscribe(person => {
            if (!person) {
                return; // no one selected
            } else if (!person.email) {
                this.cds.alert('Cannot add person', Person.fullName(person) + ' has no email address ');
            } else if (this.chatField.chat.followers.find(fw => ( fw.personUserId && (fw.personUserId === person.userId)))) {
                this.cds.alert('Cannot add ', Person.fullName(person)
                    + ' their email is already in the list ');
            } else {
                const pId = this.chatField.chat.parentId;

                this.chatField.chat.service.follow(pId, person.userId, person.id, forceTeamId).subscribe((f => {
                    if (f) {
                        this.chatField.chat.followers.push(f);
                    }
                }))
            }
        });
    }

    chatUpsert($event) {
        const dialogRef = this.dialog.open(ChatUpsertComponent,
            {
                data:
                {
                    chatField: this.control.field,
                    replyTo: $event.chatItem,
                }
            }
        );

        dialogRef.afterClosed().subscribe(newComment => {
            /* If showing comments in a pop up modal, with zero comments
            and no comment was added, better close the comments modal...
            */
            if (this.dialogRef && !newComment && this.chatField.chat.comments.length === 0) {
                this.dialogRef.close();
            }
        });
    }

    /*
    upsertChatItem(ci: ChatItem = null) {

        const fields = this.getChatItemFields();

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Comment for [${this.chatField.chat.parentTitle}]`,
                fieldSet: new FieldSet(
                    {
                        fields: fields,
                        formLayout: [{ cells: [{}] }]
                    }
                ),
                mode: ci ? 'edit' : 'new',
                objectFactory: this.createNewCI.bind(this),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.chatItemSvc,
                    id: ci ? ci.id : null,
                }
            }
        );

        return dialogRef.afterClosed();

        //dialogRef.afterClosed().pipe(first()).pipe<Action>(map(o => {
        //    return o;
        //}));

    }
*/
    setSortMode(ascending = true) {
        this.chatField.chat.mode.order = ascending ? 'ascending' : 'descending';
        this.chatField.sortChat();
    }

    setNested(nested) {
        this.chatField.chat.mode.nested = nested;
        this.chatField.sortChat();
        /* Expands all...
        for (const ce of this.chatField.chat.hierarchyItems) {
            ce.showReplies = nested;
        }
        */
    }
}
