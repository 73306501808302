/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class PurchaseService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + Txn.TYPE.PURCHASE.code;
    protected cache: Txn[];
    protected typeString = this.ajaxPath + Txn.TYPE.PURCHASE.name;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    approve(object: Txn): Observable<Txn> {
        return this.doApproval(object, true);
    }

    unapprove(object: Txn): Observable<Txn> {
        return this.doApproval(object, false);
    }

    private doApproval(object: Txn, approve: boolean): Observable<Txn> {
        const url = `${this.baseUrl}/${(approve ? 'approve' : 'unapprove')}/${object.id}/${object.revision}`;
        return this.http.put<Txn>(url, object, this.httpOptions)
            .pipe(
                map((data: Txn) => {
                    return data;
                }),
                tap(() => {
                    if (approve) {
                        this.messageService.show($localize`Invoice has been approved for payment`);
                    } else {
                        this.messageService.show($localize`Invoice approval has been removed`);
                    }

                }),
                catchError(this.handleOneError<Txn>(approve ? 'Purchase Approval' : 'Purchase Unapproval', object)
                )
            )
    }

}
