/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, ViewChild, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { BankAccountService } from '../bank-account.service';
import { BankReconiliationService } from './bank-reconciliation.service';
import { CurrencyPipe, NgTemplateOutlet } from '@angular/common';
import { ListComponent } from './list/list.component';
import { ActivatedRoute } from '@angular/router';
import { BankAccount, BankItem } from 'src/app/model/bankAccount';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { Unit } from 'src/app/model/unit';
import { Person } from 'src/app/model/person';
import { Txn } from 'src/app/model/txn';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { ReconciliationItemsComponent } from './reconciliation-items/reconciliation-items.component';
import { TableComponent } from 'src/app/shared/table/table.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { Panel } from './panel';
import { unitPanel } from './unit-panel';
import { peoplePanel } from './people-panel';
import { paymentPanel } from './payment-panel';
import { invoicesPanel } from './invoices-panel';
import { purchasePanel } from './purchase-panel';
import { receiptPanel } from './receipt-panel';
import { Supplier } from 'src/app/model/supplier';
import { supplierPanel } from './supplier-panel';
import { MatDialog } from '@angular/material/dialog';
import { DateHelper } from 'src/app/shared/dateHelper';
import { Cycle } from 'src/app/model/cycle';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Frequency } from 'src/app/model/frequency';
import { UnmatchedTxnComponent } from './unmatched-txn/unmatched-txn.component';
import { BankChargesAction } from '../bank-account-page/bank-charges-action';
import { BankTransferAction } from '../bank-account-page/bank-transfer-action';

@Component({
    selector: 'app-bank-reconciliation',
    standalone: true,
    imports: [NgTemplateOutlet, CurrencyPipe, MatTabsModule, MatIconModule, UnmatchedTxnComponent,
        MatButtonModule, FormsModule, TableComponent, MatTooltipModule, ReconciliationItemsComponent],
    templateUrl: './bank-reconciliation.component.html',
    styleUrl: './bank-reconciliation.component.scss'
})
export class BankReconciliationComponent {
    @ViewChild(ReconciliationItemsComponent) recItemsComp: ReconciliationItemsComponent;
    static readonly navRoute = new NavRoute('txn/bankRec', ListComponent, 'balance')
        .setItemComponent(BankReconciliationComponent)

    dialog = inject(MatDialog);
    screenSvc = inject(IsNarrowService);
    bankSvc = inject(BankAccountService);
    bankRecSvc = inject(BankReconiliationService);
    activeRoute = inject(ActivatedRoute);
    bank: BankAccount;
    paysLeft: Map<uuid, number> = new Map();

    showPanel: Panel;

    searchTermPerson: string;
    searchTermUnit: string;
    searchTermSupplier: string;
    searchTooltip = $localize`Type text to search for and press enter key to see data"`

    units: Unit[] = [];
    people: Person[] = [];
    suppliers: Supplier[];
    unitPeople: PersonUnitRole[] = [];
    invoices: Txn[] = [];
    purchases: Txn[] = [];
    unmatchedTxns: Txn[] = [];
    //payments: Txn[] = [];
    //receipts: Txn[] = [];

    reconcilingItem: BankItem;

    bankField = FormPicklistComponent.make('Bank', 'id', null, { service: this.bankSvc }).setupControl();

    constructor() {
        this.activeRoute.params.subscribe(params => this.setUp(params));
    }
    tableHeight() {
        return this.screenSvc.screenHeight - 475
    }

    paysToGo(currentCycle: Cycle, u: Unit) {
        if (!this.paysLeft.has(u.frequencyId)) {
            const daysIn = DateHelper.isoDaysSince(currentCycle.from);
            const daysTo = DateHelper.isoDaysTo(currentCycle.to);
            const perAnnum = Frequency.getFrequency(u.frequencyId).perAnnum;

            let paysExpected = u.frequencyId as number;
            if (daysTo > 0) {
                paysExpected = Math.trunc(daysIn / (daysIn + daysTo) * (perAnnum));
            }

            this.paysLeft.set(u.frequencyId, perAnnum - paysExpected);
        }
        return this.paysLeft.get(u.frequencyId);
    }

    setUp(params) {
        this.bankRecSvc.getOne(params.itemId, params._forceTeam).subscribe(
            (b: BankAccount) => {
                this.bank = b;
                //this.payments = b.payments;
                //this.receipts = b.receipts;
                this.invoices = b.outstandingInvoices;
                this.purchases = b.purchaseInvoices;
                this.suppliers = b.suppliers;
                this.unmatchedTxns = b.unmatchedTxn;

                const panels = [
                    unitPanel, peoplePanel, paymentPanel, invoicesPanel, purchasePanel,
                    receiptPanel, supplierPanel
                ];
                panels.forEach( p => {
                    p.recItemsComponent = this.recItemsComp;
                    p.reconciliationComponent = this;
                    p.bankAccount = this.bank;
                })

                b.units.forEach(u => {
                    u.invoices = 0;
                    u.outstanding = 0;
                    b.outstandingInvoices.filter(oi => oi.unitId === u.id).forEach(oi => {
                        u.invoices += 1;
                        u.outstanding += oi.outstanding;
                    })
                    if (u.outstanding > 0) {
                        u['payment'] = u.outstanding / this.paysToGo(b.currentCycle, u);
                    } else {
                        u['payment'] = 0;
                    }
                    b.unitPeople.filter(pur => pur.unitId === u.id).forEach(pur => {
                        this.concatNames(u, b.people.find(p => p.id === pur.personId));
                    })
                });
            }
        );
    }

    concatNames(u: Unit, per: Person) {
        if (per && u) {
            if (per.unitName) {
                per.unitName += ', ';
            } else {
                per.unitName = '';
            }
            per.unitName += u.name;
            per.invoices = per.invoices ? per.invoices + u.invoices : u.invoices;
            per.outstanding = per.outstanding ? per.outstanding + u.outstanding : u.outstanding;
            if (u.ownerFullName) {
                u.ownerFullName += ', ';
            } else {
                u.ownerFullName = '';
            }
            u.ownerFullName += per.fullName
        } else {
            console.warn('What Ho...', { u, per });
        }
    }

    sortDiffs(items, item: BankItem, bestDiff: number, panel: Panel, diffField = 'diffAmount', amtField = 'outstanding') {
        items.forEach((i) => i[diffField] = Math.abs(i[amtField] - Math.abs(item.transactionAmount)));

        panel.items = items.sort((a, b) => a.diffAmount - b.diffAmount).slice();
        panel.item = item;

        const nextDiff = panel.items[0]  ? (panel.items[0][diffField]/item.transactionAmount*100) : -1;
        if (nextDiff > -1 && nextDiff < bestDiff) {
            bestDiff = nextDiff;
            this.showPanel = panel;
        }
        return bestDiff;
    }

    matchItems(item: BankItem, items: Unit[]|Person[]|Supplier[], panel: Panel) {
        console.log('Matching Against', items);
        const description = item.description.toUpperCase();
        panel.item = this.reconcilingItem;

        if (this.matchWords([description.trim()], items, panel) >= 0) { // An exact match of full text
            return 0;
        }

        const words = description.split(' ');
        const cleanWords = [];
        words.forEach ( (w) => {
            w = w.replace(/[^0-9a-z]/gi, '');
            if (w) {
                cleanWords.push(w)
            }
        }); // Clean all non alphaNumerics frmo the words.
        const wordCount = cleanWords.length;
        let hasMatched = false;
        for (let i = wordCount; i > 0; i--) {
            if (!hasMatched) {
                if (this.matchWords(cleanWords, items, panel, i) >= 0) {
                    hasMatched = true;
                }
            }
        }
        if (hasMatched) {
            return 0
        }
        panel.item = null;
        return -1;
    }

    matchWords(words: string[], items: {name?:string, fullName?: string, address?:string}[], panel: Panel, minMatches = 1) {
        const matches = items.filter((u) => {
            let hasWords = 0;
            words.forEach( word => {
                if (u.name?.toUpperCase().includes(word)
                || u.fullName?.toUpperCase().includes(word)
                || u.address?.toUpperCase().includes(word)) {
                    hasWords += 1;
                }
            })
            return hasWords >= minMatches;
        })
        if (this.goodMatch(matches, items)) {
            console.log('Good Match', {words, minMatches, matches});
            panel.items = matches as AbstractObject[];
            this.showPanel = panel;
            return 0;
        }
        return -1;
    }

    goodMatch(matches, fullList) {
        if (matches.length > 0 && matches.length < Math.min(10, (fullList.length / 10))) {
            return true;
        }
        return false;
    }

    itemMatch(txn: Txn, item: BankItem) {
        //if (Math.abs(txn.debit + txn.credit - item.transactionAmount) < 0.005) {
        if ((txn.debit === item.transactionAmount) || (txn.credit === 0-item.transactionAmount)) {
            return true;
        } else {
            return false;
        }
    }

    itemLessThan(txn: Txn, item: BankItem) {
        //if (Math.abs(txn.debit + txn.credit - item.transactionAmount) < 0.005) {
        if ((txn.debit && txn.debit < item.transactionAmount)
        || (txn.credit && txn.credit < (0 - item.transactionAmount))) {
            return true;
        } else {
            return false;
        }
    }

    selectItem(item: BankItem) {
        this.reconcilingItem = item;
        const existingMatches = this.bank.unmatchedTxn.filter(t => this.itemMatch(t, item));
        if (existingMatches.length > 0) {
            this.showPanel = null;
            this.unmatchedTxns = existingMatches;
            return;
        } else {
            this.unmatchedTxns = this.bank.unmatchedTxn.filter(t => this.itemLessThan(t, item));
        }
        let bestDiff = 100;

        if (item.transactionAmount > 0) {
            /*
            bestDiff = this.sortDiffs(this.bank.receipts, item, bestDiff, receiptPanel, 'diffAmount', 'debit');
            if (bestDiff > 0 && bestDiff < 2) {
                return; // receipt within 2%, lets sort that...
            }
            */


            if ((this.matchItems(item, this.bank.units, unitPanel) >= 0)
             || (this.matchItems(item, this.bank.people, peoplePanel) >= 0)) {
                return;
            }
            bestDiff = this.sortDiffs(this.bank.outstandingInvoices, item, bestDiff, invoicesPanel);
            bestDiff = this.sortDiffs(this.bank.units.filter( o => o.outstanding), item, bestDiff, unitPanel, 'diffAmount', 'payment');
            this.sortDiffs(this.bank.people.filter(o => o.outstanding), item, bestDiff, peoplePanel);
        } else {
            if (this.matchItems(item, this.bank.suppliers, supplierPanel) >= 0) {
                return;
            }
            //bestDiff = this.sortDiffs(this.payments, item, bestDiff, paymentPanel);
            bestDiff = this.sortDiffs(this.purchases, item, bestDiff, purchasePanel);
            this.sortDiffs(this.suppliers, item, bestDiff, supplierPanel);
        }
    }

    unitSearch() {
        this.units = this.bank.units.filter(u => {
            if (u.name.toUpperCase().includes(this.searchTermUnit.toUpperCase())) {
                return true;
            }
            return false
        });
        unitPanel.items = this.units;
        unitPanel.item = this.reconcilingItem;
        this.showPanel = unitPanel;
    }

    personSearch() {
        this.people = this.bank.people.filter(pur => {
            if (pur.fullName.toUpperCase().includes(this.searchTermPerson.toUpperCase())) {
                return true;
            }
            return false
        });
        peoplePanel.items = this.people;
        peoplePanel.item = this.reconcilingItem;
        this.showPanel = peoplePanel;
    }

    supplierSearch() {
        this.suppliers = this.bank.suppliers.filter(u => {
            if (u.name.toUpperCase().includes(this.searchTermSupplier.toUpperCase())) {
                return true;
            }
            return false
        });
        supplierPanel.items = this.suppliers;
        supplierPanel.item = this.reconcilingItem;
        this.showPanel = supplierPanel;
    }

    removeMatch() {
        this.recItemsComp.removeItem(this.reconcilingItem);
        this.reconcilingItem = null;
        this.unmatchedTxns = this.bank.unmatchedTxn;
        this.showPanel = null;
    }

    removeUnmatched(items: Map<uuid, Txn>) {
        for (const item of items.values()) {
            this.bank.unmatchedTxn = this.bank.unmatchedTxn.filter(t => t.id !== item.id);
        }
        this.unmatchedTxns = this.bank.unmatchedTxn;
    }
/*
    paymentSearch() {
        paymentPanel.items = this.payments;
        paymentPanel.item = this.reconcilingItem;
        this.showPanel = paymentPanel;
    }

    receiptSearch() {
        receiptPanel.items = this.receipts;
        receiptPanel.item = this.reconcilingItem;
        this.showPanel = receiptPanel;
    }
*/
    invoiceSearch() {
        invoicesPanel.items = this.invoices;
        invoicesPanel.item = this.reconcilingItem;
        this.showPanel = invoicesPanel;
    }

    purchaseSearch() {
        purchasePanel.items = this.purchases;
        purchasePanel.item = this.reconcilingItem;
        this.showPanel = purchasePanel;
    }

    createTransfer() {
        const action = new BankTransferAction();
        action.setup(this.bank);
        action.setBankItem(this.reconcilingItem);
        action.action().subscribe(x => {
            if (x) {
                this.removeMatch();
            }
        });
    }

    createCharge() {
        const action = new BankChargesAction();
        action.setup(this.bank);
        action.setBankItem(this.reconcilingItem);
        action.action().subscribe( x => {
            if (x) {
                this.removeMatch();
            }
        });
    }

    showUnmatchedTxn() {
        this.unmatchedTxns = this.bank.unmatchedTxn;
        this.showPanel = null;
    }

    canDeactivate() {
        return true;
    }
}
