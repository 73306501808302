/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'fromNow',
  standalone: true,
})
export class FromNowPipe implements PipeTransform {

  transform(dateUnixtime: number): string {
    return dateUnixtime ? moment(dateUnixtime * 1000).fromNow() : 'Bad Date';
  }

}
