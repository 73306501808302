/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { NavRoute } from 'src/app/shared/NavRoute';
import { BCodeService } from '../../budget/bcode.service';
import { ArTxnService } from '../ar-txn.service';
import { User } from 'src/app/model/user';
import { ChartParms } from 'src/app/model/sys/nav-item';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-ar-page',
    templateUrl: './ar-page.component.html',
    styleUrls: ['./ar-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class ArPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.LEDGER.AR.code, ArPageComponent, 'call_received').setCharts([
        { type: 'bar', picklist: 'typeId' } as ChartParms,
        { type: 'pie', picklist: 'bCodeId', number: 'credit', excludeZero: true } as ChartParms,
        { type: 'pie', picklist: 'bCodeId', number: 'debit', excludeZero: true } as ChartParms
    ]).setViews((u: User) => {
        return [
            {
                id: 'currentYear',
                name: 'Current Year',
                filterFields: {
                    refNr: null,
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                },
            }, {
                id: 'outstanding',
                name: 'Outstanding',
                filterFields: {
                    outstanding: '>=0.01',
                },
                sort: 'createdAt'
            }
        ]
    });


    readonly path = Txn.LEDGER.AR.code;

    config = new FormConfig({
        navRoute: ArPageComponent.navRoute,
        title: Txn.LEDGER.AR.name,
        help: $localize`Financial transactions`,
        fieldSet: new FieldSet({
            fields: this.dataSvc.getFields(),
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Txn()), // this.newFactory.bind(this),
        newOptions: [
            { name: $localize`New ${Txn.TYPE.INVOICE.name}`, basePath: `/${Txn.TYPE.INVOICE.code}/NEW`, params: {} },
            { name: $localize`New ${Txn.TYPE.CREDIT_NOTE.name}`, basePath: `/${Txn.TYPE.CREDIT_NOTE.code}/NEW`, params: {} }
        ],
        pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
    });

    constructor(public dataSvc: ArTxnService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute) {
        super();
    }

    newFactory(): Observable<Txn> {
        const txn = new Txn();
        txn.typeId = +this.activeRoute.snapshot.queryParamMap.get('typeId');
        console.warn(txn, this.activeRoute.snapshot);
        return of(txn);
    }

}
