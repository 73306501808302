/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { Field } from '../../field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
@Component({
    selector: 'app-form-image',
    templateUrl: './form-image.component.html',
    styleUrls: ['./form-image.component.scss'],
    standalone: true
})
export class FormImageComponent  implements FormControlInterface {

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static make(label: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new Field({ label, value, type: 'image' }, options);
        field.formControlFactory = FormImageComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormImageComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }
}
