/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { User } from 'src/app/model/user';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { ConfirmDialogService } from '../dialogs/confirmDialog';
import { MessageService, MsgDef } from '../message.service';
import { NavigationService } from '../navigation/navigation.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})

export class LoggedInGuard {

    constructor(private router: Router, private currentUserService: CurrentUserService,
        private nav: NavigationService, private msg: MessageService, private cds: ConfirmDialogService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (!this.currentUserService.gotUser) {
            return this.currentUserService.getCurrentUser().pipe(map(() => {
                this.mayActivate(route, state);
            }))
        } else {
            return this.mayActivate(route, state);
        }
    }
    mayActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.currentUserService.gotUser) {
            let allowed = false;
            const user: User = this.currentUserService.currentUser;
            const path = route.routeConfig.path;
            /* Path only ends /NEW if we have special NEW component with formConfig.forceNew, otherwise comes in as /:itemId where itemId = NEW*/
            const basePath = path.endsWith('/NEW') ? path.replace('/NEW', '') : path.replace('/:itemId', '');
            if (user.currentTeam === null) {
                //this.router.navigate([UserProfileComponent.navRoute.url]); // ERROR Accessing navRoutes from here...
                this.msg.show(new MsgDef($localize`You are not associated to an OMC, you could request to register with one`, 'warn'));
                //this.router.navigate(['/myRequests']);
                this.router.navigate(['/unknownUser'])
                return false;
            }

            const nav = this.nav.getItem(basePath);
            const forceTeam = route.params._forceTeam;
            const privilege = User.getPathPrivilege(user, basePath, forceTeam);

            if (path === basePath && (privilege.getIndex || privilege.post && nav?.showToPoster)) {
                allowed = true;
            } else if (path.includes(':itemId') && privilege.get) {
                allowed = true;
            } else if (path.endsWith('/NEW') && privilege.post) {
                allowed = true;
            } else {
                const teamName = User.getActiveTeamName(user, forceTeam);
                const title = $localize`${teamName} has not given you access to '/${basePath}`;

                const msg = $localize`${user.name} does not have privileges to access '/${basePath}' for ${teamName}.
                It may be that you need to login as a different user or switch into a different team to access '/${basePath}'
                `;
                this.cds.alert(title, msg);
            }

            if (!allowed) {
                const onBasePath = window.location.pathname.startsWith('/' + basePath);
                console.log('User does not have permissions to navigate', {
                    user, nav, forceTeam, privilege, href: window.location, basePath, onBasePath
                });
                if (onBasePath) {
                    // User already on the view that they do not have permissions for?
                    // Could have been passed a URL from a user with higher permissions OR
                    // Switched Team without permissions in new team for the current route
                    if (window.location.href !== '/') {
                        console.warn('Already on ' + basePath, { stateUrl: state.url, basePath, state, route })
                        this.router.navigate(['/']);
                    }
                }
            }
            return allowed;
        } else {
            const queryParams = { returnUrl: state.url };
            this.router.navigate(['/login'], { queryParams, skipLocationChange: true });
            return false;
        }
    }
}
