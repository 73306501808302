/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { UntypedFormGroup } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { Field } from 'src/app/shared/field/Field';
import { GridRow } from 'src/app/shared/grid/grid-row';

export type ControlOn = 'form' | 'grid';
export class UpdateCount {
    static counter = 0;
}

export class AppFormControl extends UntypedFormControl {
    field: Field;
    isGridCell = false;

    constructor(field: Field) {
        super('', {
            validators: field.validators,
            asyncValidators: field.asyncValidators,
            updateOn: field.type === 'number' || field.type === 'date' ? 'blur' : 'change'
        });
        this.field = field;

        /* if (field.validators) {
          this.setValidators(field.validators);
        } */
        if (field.valueChanges) {
            this.valueChanges.subscribe((newValue) => field.valueChanges(newValue, this.field));
        }
        if (field.statusChanges) {
            this.statusChanges.subscribe((newStatus) => field.statusChanges(newStatus, this.field));
        }
        if (field.disable) {
            this.disable({ emitEvent: false });
        }
    }

    setField(field: Field): AppFormControl {
        this.field = field;
        return this;
    }
    /*
      getParentGrid() {
        return this.parent as GridRow;
      }
    */
    getErrorMessage(): string {
        let ret = '';
        if (this.errors) {
            Object.keys(this.errors).forEach(key => {
                ret = ret + this.errors[key].formattedMsg + '. ';
            });
        }
        return ret;
    }

    setParentFormField(field: string, value: unknown) {
        if (this.parent && this.parent.parent && this.parent.parent.parent) {
            (this.parent.parent.parent as UntypedFormGroup).controls[field].setValue(value);
        } else {
            console.warn('Unable to set [' + field + '] on parent form', value, this);
        }
    }

    getRow(): GridRow {
        return this.parent as GridRow;
    }

    noShow() {
        this.field.visible.phone = false;
        this.field.visible.computer = false;
        this.field.visible.form = false;
        return this;
    }
    /*
      heading(heading: string) {
        this.field.cellOpts.heading = heading;
        return this;
      }
      */
}
