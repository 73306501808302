/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { FormControlInterface } from 'src/app/shared/form/form-control-interface';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { NumberField, NumberOpts } from 'src/app/shared/field/NumberField';
import { Field } from '../../field/Field';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

class AppNumberControl extends AppFormControl {
    field: NumberField
}

@Component({
    selector: 'app-form-number',
    templateUrl: './form-number.component.html',
    styleUrls: ['./form-number.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, MatTooltipModule, NgClass, FormsModule,
        ReactiveFormsModule, FormErrorComponent, MatIconModule]
})
export class FormNumberComponent implements FormControlInterface {

    @Input() on: ControlOn = 'form';

    private _control: AppNumberControl;
    private _size = 9;
    // https://angular.io/api/common/PercentPipe
    // https://angular.io/api/common/DecimalPipe#digitsinfo
    // https://angular.io/api/common/CurrencyPipe#digitsinfo
    private _minIntDigits = 9;
    private _minFractionDigits = 0;
    private _maxFractionDigits = 0;

    @Input()
    set control(ctl: AppNumberControl) {
        //if (ctl && ctl.field) {
        //  console.log(ctl, ctl.field);
        const format = ctl.field.numberOpts.formatParms;
        if (format) {
            //console.log(format);
            this._minIntDigits = +format.substring(0, 1);
            this._minFractionDigits = +format.substring(2, 3);
            this._maxFractionDigits = +format.substring(4, 5);
            //this._size = this._minIntDigits + this._maxFractionDigits + 1;
        }
        if (ctl.field.numberOpts.width) {
            this._size = ctl.field.numberOpts.width;
        }
        //}
        this._control = ctl;
    }
    get control(): AppNumberControl {
        return this._control;
    }

    public static make(label: string, value: string, numberOpts: NumberOpts = {}, options: Partial<Field> = {}) {
        const field: NumberField = new NumberField({ label, value }, (options as NumberField));
        field.numberOpts = { ...field.numberOpts, ...numberOpts }
        field.formControlFactory = FormNumberComponent.createComponent;
        return field;
    }

    public static makePercent(label: string, value: string, options: Partial<NumberField> = {}) {
        /*  */
        const field: NumberField = new NumberField({ label, value }, options);
        field.numberOpts.format = 'percent';
        field.numberOpts.width = 6;
        field.numberOpts.formatParms = '2.2-2';

        field.formControlFactory = FormNumberComponent.createComponent;
        return field;
    }

    public static makeCurrency(label: string, value: string, options: Partial<NumberField> = {}) {
        /*  */
        const field: NumberField = new NumberField({ label, value }, options);
        field.numberOpts.format = 'currency';
        field.numberOpts.width = 9;
        field.numberOpts.formatParms = '1.2-2';

        field.formControlFactory = FormNumberComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppNumberControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormNumberComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    saveNumberValue(event) {
        let rawValue = this.control.field.getStringNumericValue(event.target.value);
        if (this.control.field.numberOpts.format === 'percent') {
            rawValue = rawValue / 100;
        }
        if (rawValue !== this.control.value) {
            if (this.control.isGridCell) {
                const gridControl = this.control.getRow().getParent() as GridControl;
                if (gridControl.hasFooter) {
                    const fldName = this.control.field.name;
                    const currentTotal = gridControl.totals.get(fldName);
                    gridControl.totals.set(fldName, currentTotal - this.control.value + rawValue);
                }
            }
            this.control.setValue(rawValue);
            this.control.markAsTouched();
            this.control.markAsDirty();
        }

        const formattedValue = this.control.field.formatValue(rawValue);
        event.target.value = formattedValue;

    }

    size(): number {
        return this._size;
    }
}
