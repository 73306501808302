/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { MatDialog } from "@angular/material/dialog";
import { MyInjector } from "src/app/app.module";
import { ServiceRequest } from "src/app/model/serviceRequest";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { SendSupplierComponent } from "./send-supplier.component";

export class SendSupplierAction implements IFormAction {
    name = $localize`Mail Supplier`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'local_shipping';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Mail or print request';
    tipText: string;
    request: ServiceRequest;

    dialog = MyInjector.instance.get(MatDialog);

    action() {
        return this.dialog.open(SendSupplierComponent, { data: this.request }).afterClosed();
    }

    setup(sr: ServiceRequest) {
        this.request = sr;
        this.show = true;
    }
}
