
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { MatDialog } from '@angular/material/dialog';
import { MyInjector } from 'src/app/app.module';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { PersonService } from './person.service';
import { Person } from 'src/app/model/person';
import { MergePersonComponent } from './merge-person/merge-person.component';

export class MergePersonAction implements IFormAction {
    name = $localize`Merge`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'arrow_and_edge';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText = $localize `Merge this person record and all related data into another person record`;

    person: Person;
    mergeIntoPerson: Person;

    personSvc = MyInjector.instance.get(PersonService);
    dialog = MyInjector.instance.get(MatDialog);

    constructor() { }

    action() {
        const dialogRef = this.dialog.open(MergePersonComponent, {data: this.person});
        return dialogRef.afterClosed();
    }

    setup(person: Person) {
        this.person = person;
        this.show = true;
    }
}
