/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { ActionTemplate } from './ActionTemplate';
import { Frequency } from './frequency';

export class RequestTemplate extends AbstractObject {
    name: string;
    description: string;

    agentTeamId: uuid;
    title?: string; // not really there...

    categoryId: uuid;
    priorityId: uuid;
    frequencyId: uuid;
    frequency: Frequency;

    recurring_jobs_count: string;
    open_requests_count: string;

    actions: ActionTemplate[] = [];

    ownerOmcRole: string;
    followerOmcRole: string;

    constructor(o: Partial<RequestTemplate> = {}) {
        super(o);
    }
}
