import { Observable, of } from "rxjs";
import { ActionColor, IFormAction } from "../form/form.component";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { MyInjector } from "src/app/app.module";
import { OmcAgent } from "src/app/model/OmcAgent";
import { Omc } from 'src/app/model/Omc';
import { maxChars, minChars, required, uniqueGrid } from "../validators";
import { FormTextComponent } from "../form/form-text/form-text.component";
import { Person } from "src/app/model/person";
import { FieldSet, LAYOUT_OPTIONS } from "../form/field-set/field-set.component";
import { MatDialog } from "@angular/material/dialog";
import { FormRichTextComponent } from "../form/form-rich-text/form-rich-text.component";
import { GridField } from "../grid/grid-field";
import { PersonService } from "src/app/pages/person-page/person.service";
import { MailItem } from "src/app/model/mailMerge";
import { FieldSetDialogOptions, FieldSetDialogComponent } from "../dialogs/field-set-dialog/field-set-dialog.component";
import { CommsTemplateType } from "src/app/model/CommsTemplate";
import { map } from "rxjs/operators";
import { FieldMaker } from "../field/FieldMaker";
import { FormPicklistComponent } from "../form/form-picklist/form-picklist.component";
import { Attachment } from "src/app/model/attachment";

/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
export class SendEmailFormAction implements IFormAction {
    name = $localize`Send Email`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'forward_to_inbox';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText: string;

    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    personService = MyInjector.instance.get(PersonService);
    dialog = MyInjector.instance.get(MatDialog);

    subjectField = FormTextComponent.make('Subject', 'title', { validators: [required, maxChars(200), minChars(10)], });
    contentField = FormRichTextComponent.make('Content', 'description', { validators: [required] }).setCommsTemplate(CommsTemplateType.PERSON);
    attachmentOptions : Attachment[] = [];
    attachmentField = new GridField({
            field: { value: 'attachments', cellOpts: { heading: 'Attachments' },  },
            objFactory: () => of(new Attachment()),
            rowFactory: () => [
                FormPicklistComponent.make('Attachment (to send a file, first attach it to person)', 'id',null, {items: this.attachmentOptions}),
                FieldMaker.deleteGridRow(),
            ],
            gridValidator: [uniqueGrid('id')]
        })

    omcAgents: OmcAgent[] = [];
    omcs: Omc[] = [];

    person: Person;

    //constructor(private addFn: () => Observable<AbstractObject>, private linksTo: LinksTo) { }
    constructor(private mergeGrid: GridField) { }

    action() { // config: FormConfig
        this.openSendMailDialog();
        return null;
    }

    setup(item: Person) {
        this.person = item;
        this.show = true;
        if (item.email) {
            this.disabled = false;
            this.tipText = "Send a mail to " + item.fullName;
            this.attachmentOptions = this.person.attachments;
        } else {
            this.disabled = true;
            this.tipText = "We have no email address for " + item.fullName + " cannot send email";
        }
    }

    private createNewMail(): MailItem {
        const a = new MailItem();
        a.toAddress = this.person.fullName + ' <' + this.person.email + '>';
        return a;
    }

    sendMail() : Observable<MailItem> {
        const s = this.subjectField.getFormValue();
        const c = this.contentField.getFormValue();
        const a = this.attachmentField.getFormValue();

        return this.personService.sendMail(this.person, s, c, a).pipe(
            map(mailItem => {
                if (mailItem) {
                    this.mergeGrid.control.addRow(mailItem, true, true);
                }
                return mailItem;
            })
        );
    }

    openSendMailDialog() {
        const fieldSet = new FieldSet({
            fields: [
                FormTextComponent.make('To', 'toAddress', { readonly: true }),
                this.subjectField,
                this.contentField,
                this.attachmentField,
            ],
            formLayout: LAYOUT_OPTIONS.simpleDialog
        })
        fieldSet.setValue(this.createNewMail());
        const data = {
            fieldSet,
            saveFn: this.sendMail.bind(this),
            saveText: 'Send',
            title: 'Send Email',
            minHeight: '680px'
        } as FieldSetDialogOptions

        this.dialog.open(FieldSetDialogComponent, {data} );
    }
}
