/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Post } from './post';

export class ChatItem extends AbstractObject {
    constructor(o: Partial<ChatItem> = {}) {
        super(o);
    }
    authorId?: uuid;
    post?: Post;
    postId?: uuid;
    parentId?: uuid;
    parent: ChatItem;
    imageId?: uuid;
    content?: string;
    replies?: ChatItem[] = [];
    isIcon: boolean;
    needsResponse?:boolean;
    /** only for display, not storage */
    showReplies?: boolean;
}
