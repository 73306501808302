/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { TxnPageComponent } from '../../txn-page/txn-page.component';
import { BCode } from 'src/app/model/bcode';
import { Cycle } from 'src/app/model/cycle';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { BCodePageComponent } from 'src/app/modules/budget/b-code-page/b-code-page.component';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, CurrencyPipe, NgClass } from '@angular/common';
import { CtlHolderComponent } from '../../../../shared/form/ctl-holder/ctl-holder.component';

type Balance = { bCodeId: uuid, debits: number, credits: number, calcTotal: number, count: number }
type ApiResponse = { cycle: Cycle, cycles: Cycle[], balances: Balance[], previous: Balance[], bcodes: BCode[], types: AbstractObject[] }

@Component({
    selector: 'app-balance-sheet',
    templateUrl: './balance-sheet.component.html',
    styleUrls: ['../balances.scss'],
    standalone: true,
    imports: [CtlHolderComponent, NgTemplateOutlet, MatCardModule, RouterLink, CurrencyPipe, NgClass]
})
export class BalanceSheetComponent {
    static readonly navRoute = new NavRoute('txn/balanceSheet', BalanceSheetComponent, 'rubric');

    private http = inject(HttpClient);
    private currentUserSvc = inject(CurrentUserService);
    private activeRoute = inject(ActivatedRoute);
    private router = inject(Router);

    companyName: string
    cycle: Cycle = null;
    bcodes: BCode[] = [];
    balances: Balance[];
    types: AbstractObject[] = BCode.BALANCE_SHEET_TYPES;
    ready = false;
    hideZero = true;
    myData: ApiResponse;

    total = 0;
    profit = 0;

    prevTotal = 0;
    prevProfit = 0;

    displayedColumns: string[] = ['name', 'sort', 'count', 'credits', 'debits'];

    cycleField = FormPicklistComponent.make('Cycle', 'cycleId', null, {
        items: [],
        refreshes: [(cycle: Cycle) => {
            this.navigateTo(cycle.id);
        }]
    }).setupControl();

    fromField = FormDateComponent.make('From', 'from', { readonly: true, disable: true }).setupControl();
    toField = FormDateComponent.make('To', 'to', { readonly: true, disable: true }).setupControl();
    zerosField = FormCheckboxComponent.make('Hide Zeros', 'hideZeros', {
        valueChanges: (value) => {
            this.hideZero = value;
            this.refreshData();
        }
    }).setupControl()

    constructor() {
        this.activeRoute.params.subscribe(params => this.setUp(params));
        this.zerosField.control.setValue(true, { emitEvent: false });
        this.currentUserSvc.getCurrentUser().subscribe(user => {
            this.companyName = user.currentTeam.legalName;
            if (this.activeRoute?.snapshot?.params.cycleId) {
                this.getData(this.activeRoute.snapshot.params.cycleId);
            } else {
                this.getData(user.currentTeam.currentPeriod.cycleId);
            }
        })
    }


    canDeactivate() {
        return true;
    }
    private setUp(parms: Params) {
        if (parms.cycleId) {
            this.getData(parms.cycleId);
        }
    }
    private navigateTo(cycleId: uuid) {
        this.router.navigate([BalanceSheetComponent.navRoute.url, {cycleId}]);
    }

    getData(cycleId: uuid) {
        const url = AbstractHttpService.ajaxPath + 'txn/balanceSheet/' + cycleId;

        this.http.get<ApiResponse>(url).subscribe(result => {
            this.myData = result;
            this.cycleField.setPicklistItems(this.myData.cycles);
            this.cycleField.control.setValue(this.myData.cycle.id, { emitEvent: false });
            this.fromField.control.setValue(this.myData.cycle.from, { emitEvent: false })
            this.toField.control.setValue(this.myData.cycle.to, { emitEvent: false })
            this.refreshData();
        })
    }


    refreshData() {
        this.cycle = this.myData.cycle
        Cycle.warnOpenPeriods(this.cycle);
        this.balances = this.myData.balances
        this.bcodes = [];

        this.profit = 0;
        this.total = 0;
        this.prevProfit = 0;
        this.prevTotal = 0;

        this.balances.forEach(b => {
            b.calcTotal = (+b.debits - +b.credits);
            const bc = this.myData.bcodes.find( c => c.id === b.bCodeId);
            if (bc) {
                if (this.types.find(t => t.id === bc.typeId)) {
                    this.total += b.calcTotal;
                } else {
                    this.profit -= +b.calcTotal;
                }
            }
        })

        this.myData.previous.forEach(b => {
            b.calcTotal = (+b.debits - +b.credits);
            const bc = this.myData.bcodes.find(c => c.id === b.bCodeId);
            if (bc) {
                if (this.types.find(t => t.id === bc.typeId)) {
                    this.prevTotal += +b.calcTotal;
                } else {
                    this.prevProfit -= +b.calcTotal;
                }
            }
        })

        this.myData.bcodes.forEach((bc: BCode) => {
            const bal = this.balances.find(o => o.bCodeId === bc.id);
            if (bal) {
                bc.total = bal.calcTotal;
                bc.count = bal.count;
            } else {
                bc.total = 0;
                bc.count = 0;
            }

            const prev = this.myData.previous.find(o => o.bCodeId === bc.id);
            if (prev) {
                bc.prevTotal = prev.calcTotal;
                bc.prevCount = prev.count;
            } else {
                bc.prevTotal = 0;
                bc.prevCount = 0;
            }
            bc.type = this.types.find(o => o.id === bc.typeId);

            if (!this.hideZero || bc.total !== 0 || bc.prevTotal !== 0) {
                this.bcodes.push(bc);
            }
        });

        this.types.forEach(type => {
            type['bcodes'] = this.bcodes.filter(o => o.typeId == type.id);
            type['total'] = 0;
            type['prevTotal'] = 0;
            type['bcodes'].forEach( bc => {
                type['total'] += bc.total;
                type['prevTotal'] += bc.prevTotal;
            })
        })
    }

    getAccountTxn(bcode: BCode) {
        return ['/' + TxnPageComponent.navRoute.url, { bCodeId: bcode?.id, txnCycleId: this.cycle?.id }]
    }

    getAccountLink(bcode: BCode) {
        return BCodePageComponent.navRoute.getIdUrl(bcode.id);
    }
}
