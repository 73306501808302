/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { AppFormControl, ControlOn } from '../form/app-form-control';
import { FormControlInterface } from '../form/form-control-interface';

@Component({
    selector: 'app-notes',
    template: '',
    standalone: true,
})
export class MemosComponent implements FormControlInterface {

    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(MemosComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }
}
