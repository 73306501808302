/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommsTemplateType } from 'src/app/model/CommsTemplate';
import { DialogBannerComponent } from 'src/app/shared/dialogs/dialog-banner-component';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { RequestService } from '../../request.service';
import { required } from 'src/app/shared/validators';
import { Supplier } from 'src/app/model/supplier';
import { MatTooltip } from '@angular/material/tooltip';
import { SupplierService } from 'src/app/modules/supply/supplier.service';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { MatIconModule } from '@angular/material/icon';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { NgTemplateOutlet } from '@angular/common';
import { MessageService } from 'src/app/shared/message.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';

@Component({
  selector: 'app-send-supplier',
  standalone: true,
  imports: [ DialogBannerComponent, MatDialogModule, CtlHolderComponent, NgTemplateOutlet,
    MatButtonModule, MatTooltip, MatIconModule],
  templateUrl: './send-supplier.component.html',
  styleUrl: './send-supplier.component.scss'
})
export class SendSupplierComponent {

    dialogRef = inject(MatDialogRef);
    sr = inject(MAT_DIALOG_DATA);
    srSvc = inject(RequestService);
    supplierSvc = inject(SupplierService);
    dialog = inject(MatDialog);
    msgSvc = inject(MessageService);
    cds = inject(ConfirmDialogService);

    toSuppliers : {supplier: Supplier, in: boolean}[] = [];
    supplierField = FormComboBoxComponent.make('Supplier', 'supplierId', 'supplier', {
        service: this.supplierSvc,
    }).setupControl();

    constructor() {
        if (this.sr.supplierId) {
            this.insertSupplier(this.sr.supplier)
        }
    }

    mailText = FormRichTextComponent.make('Mail Text', 'mailText', {validators: [required]})
    .setupControl()
    .setCommsTemplate(CommsTemplateType.REQUEST);

    removeSupplier(s) {
        const idx = this.toSuppliers.indexOf(s);
        this.toSuppliers.splice(idx, 1);
    }

    pickSupplierFormConfig(): FormConfig {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FormTextComponent.make('Name', 'name'),
                    FormTextComponent.make('Contact', 'contactPerson'),
                    FormPhoneComponent.make('Code', 'code'),
                    FormPhoneComponent.make('Phone', 'phone'),
                    FormEmailComponent.make('Email', 'email'),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`Suppliers`,
            mode: 'list',
        });
    }

    insertSupplier(supplier: Supplier) {
        if (supplier) {
            const n = { supplier, in: false }
            if (supplier.email) {
                n.in = true;
            }
            this.toSuppliers.push(n);
        }
    }

    addSupplier() {
        const dialogRef = this.dialog.open(PickDialogComponent,
            {
                data: {
                    config: this.pickSupplierFormConfig(),
                    service: this.supplierSvc,
                    showSearch: true,
                }
            });
        dialogRef.afterClosed().subscribe( s => this.insertSupplier(s));
    }

    mailSupplier() {
        const emails = [];
        const suppliers = [];
        this.toSuppliers.forEach ( ts => { if (ts.supplier.email) {
            emails.push(ts.supplier.email);
            suppliers.push(ts.supplier.id);
        }})
        if (emails.length > 0) {
            const t = $localize `Send mails to suppliers`;
            const m = $localize `Messages will be sent to ${emails.join(',')}.
            There will be ${this.sr.attachments.length} attachments included on the mails`;
            this.cds.open(t, m, () => {
                this.srSvc.sendSupplierMails(this.sr, this.mailText.control.value, suppliers).subscribe(
                    s => {
                        this.msgSvc.show('Mails sent successfully');
                        this.dialogRef.close(s);
                    }, f => this.msgSvc.showError(f, "Sending Supplier Mails"),
                );
            })
        } else {
            this.msgSvc.show('Choose some suppliers with email addresses to send to');
        }

    }

    printPDF() {
        this.srSvc.getPrintPDF(this.sr, this.mailText.control.value);
    }


}
