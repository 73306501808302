/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from "@angular/core";
import { UnitService } from "./unit.service";
import { Person } from "src/app/model/person";
import { uuid } from "src/app/model/abstract-object";
import { catchError, map } from "rxjs/operators";
import { Unit } from "src/app/model/unit";

@Injectable({
    providedIn: 'root'
})
export class MyUnitService extends UnitService {
    protected baseUrl = this.ajaxPath + 'units/myUnits';

    addPerson(unitId: uuid, p: Person, teamId: uuid) {
        const url = `${this.baseUrl}/${unitId}/person`
        return this.http.post(url, p, this.getSimpleOptions(teamId));
    }

    removePerson(unitId: uuid, id:uuid, teamId: uuid = null) {
        const url = `${this.baseUrl}/${unitId}/person/${id}`;
        return this.http.delete<Unit>(url, this.getSimpleOptions(teamId)).pipe( map( r => {
            this.updateCachedItem(r);
            return r;
        }), catchError(this.handleError<Unit>('get' + this.typeString, null)));
    }
}
