/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { Ballot, Option } from 'src/app/model/ballot';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { NavRoute } from 'src/app/shared/NavRoute';
import { required } from 'src/app/shared/validators';
import { UserService } from '../../user/user.service';
import { BallotService } from '../ballot-page/ballot.service';
import { QuestionService } from './question.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { CommsTemplateType } from 'src/app/model/CommsTemplate';

@Component({
    selector: 'app-question-page',
    templateUrl: './question-page.component.html',
    styleUrls: ['./question-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class QuestionPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('social/questions', QuestionPageComponent, 'question_answer');

    ballotField = FormPicklistComponent.make('Ballot or meeting this question will be asked in', 'ballotId', 'ballot',
        { service: this.ballotSvc, optionDisplayValue: o => o.title }, { validators: [required] }
    );
    optionsField = new GridField({
        field: { value: 'options', label: 'Options', formRow: 2, visible: Field.formOnly },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormTextComponent.make('Voting Option', 'name'),
            FormTextComponent.make('Description of voting option', 'content', { cellOpts: { width: "80%" } }),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => of(new Option()),
        newOptionText: 'Add a new voting option to this question',
    });

    config = new FormConfig({
        navRoute: QuestionPageComponent.navRoute,
        title: $localize`Question`,
        help: $localize`Question to be voted on`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.ballotField,
                FormTextComponent.make('Question', 'question', { formColumn: 2, validators: [required] }),
                FormDateTimeComponent.make('Voting Starts', 'opensAt'),
                FormDateTimeComponent.make('Voting Finishes At', 'closesAt'),
                FormCheckboxComponent.make('Allow Freeform Comment', 'allowFreetext'),
                FormRichTextComponent.make('Details', 'content', { formColumn: 2 }).setCommsTemplate(CommsTemplateType.QUESTION),
                this.optionsField,
            ],
            formLayout: [{ cells: [{ width: "33%" }, { width: "66%" }] },
            { cells: [{ colspan: 5 }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new Ballot())
    });

    constructor(public dataSvc: QuestionService,
        public dialog: MatDialog,
        protected userSvc: UserService,
        protected ballotSvc: BallotService) {
        super();
    }
}
