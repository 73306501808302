/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

/*
 * Accepts a character string in format 'YYYY-MM-DDTHH-mm-ss'
 * It expects that to be a GMT Date, as returned by wordpress
 * converts it into Months | Weeks | Days | Hours | minutes | seconds Ago
 *
 * Usage:
 *   dateGMT | OoGmtAgo
 * Example:
 *   {{ '2020-08-14T11:49:19' | OoGmtAgo }}
 *   formats to: 'One hour ago', if you called it at 12:49 GMT on August 14th 2020
*/
@Pipe({
  name: 'GmtAgo',
  standalone: true
})
export class GmtAgoPipe implements PipeTransform {
  transform(dateGmt: string): string {
    let fmt = 'YYYY-MM-DD HH:mm:ss';
    if (!dateGmt) {
        return ''; // allow null without error
    }
    if (dateGmt.substring(10, 11) === "T") {
      fmt = 'YYYY-MM-DDTHH-mm-ss';
    }
    return moment.utc(dateGmt, fmt).fromNow();
  }
}
