/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';
import { RequestCategory } from 'src/app/model/RequestCategory';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { Field } from 'src/app/shared/field/Field';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { maxChars, minWords, required } from 'src/app/shared/validators';
import { OmcService } from '../../agent/omc.service';
import { CurrentUserService } from '../../user/current-user.service';
import { MyRequestService } from '../my-request.service';
import { RequestCategoriesService } from '../request-categories.service';
import { MatButtonModule } from '@angular/material/button';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { NgTemplateOutlet } from '@angular/common';
import { ImageUploadRequestComponent } from '../service-request-page/image-upload-request/image-upload-request.component';

@Component({
    selector: 'app-new-user-request-detail',
    templateUrl: './new-user-request-detail.component.html',
    styleUrls: ['./new-user-request-detail.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, CtlHolderComponent, MatButtonModule, ImageUploadRequestComponent]
})
export class NewUserRequestDetailComponent implements OnInit, OnDestroy {

    @Input()
    existingList: ServiceRequest[] = [];

    @Output()
    selectRequest = new EventEmitter<ServiceRequest>();

    categories: RequestCategory[] = [];
    catsToShow: {fileName: string, name: string, item: RequestCategory}[]; //[];
    width =  5;

    showingCategory: RequestCategory = null;
    showingSubCategory: RequestCategory = null;

    showingCategories: RequestCategory[] = [];

    /** Originally, as user could potentially own units in multiple OMCs,
     *  we attempted to allow them create requests for any of their OMCs from this screen
     * no longer possible, but we still need to send the omcId on the request.
    */
    showOmcField = false;
    omcField = FormComboBoxComponent.make('Owner Management Company', 'omcId', null, { items: [] },
        {validators: [required], calculateValue: (omc) => omc.id} );
    omcCtl = this.omcField.makeControl();

    showingQuestions: Field[] = [];
    titleCtl = FormTextComponent.make('Name for this request', 'title',
        {hint: 'Short name to refer to this request by. Think of this like the subject of an email',
            validators: [required, minWords(3), maxChars(100)]
        }).makeControl();

    descriptionCtl = FormTextAreaComponent.make('Description of what needs doing', 'description',
        {validators: [ required, minWords(3), maxChars(512) ]}
    ).makeControl();

    subscriptions: Subscription[] = [];
    isPhone = false;

    constructor(private catSvc: RequestCategoriesService, private requestSvc: MyRequestService, private fb: FormBuilder,
        private omcSvc: OmcService,
        private cds: ConfirmDialogService, private currentUserSvc: CurrentUserService,
        private myElementRef: ElementRef, private isNarrow: IsNarrowService) {

            this.resetForm();
    }

    resetForm() {
        this.titleCtl.setValue(null);
        this.descriptionCtl.setValue(null);
    }

    ngOnInit(): void {
        this.catSvc.getAvailable().subscribe( cats => {
            this.categories = cats as RequestCategory[];
            this.showTopLevelCategories();
        });
        this.subscriptions.push(this.isNarrow.detectVeryNarrow().subscribe(result => { this.isPhone = result; }));
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach ( s => s.unsubscribe());
    }

    showTopLevelCategories() {
        this.showCategories(this.categories.filter( o => o.parentId === null));
    }

    showCategories(items) {
        this.catsToShow = [];
        items.forEach ( item => {
            this.catsToShow.push({ fileName: item?.fileName, name: item.notes ? item.notes : item.name, item });
        })
    }

    getFileName(c) {
        return RequestCategory.getRequestIconUrl(c?.fileName);
    }

    showAll() {
        this.showingCategory = null;
        this.showingSubCategory = null;
        this.showingQuestions = [];
        this.showingCategories = [];
        this.showTopLevelCategories();
    }

    showParent() {
        this.showingQuestions = [];
        if (this.showingSubCategory?.parentId) {
            this.selectItem(this.categories.find(o => o.id === this.showingSubCategory.parentId));
            this.showingSubCategory = null;
        } else if (this.showingCategory?.parentId) {
            this.selectItem(this.categories.find(o => o.id === this.showingCategory.parentId));
        } else {
            this.showAll();
        }
    }

    refreshOmcs(c: RequestCategory) {
        this.omcSvc.getAvailable(c.teamId).subscribe( omcs => {
            this.omcField.picklist.items = omcs;
            if (omcs.length === 1) {
                this.omcField.setValue(omcs[0], true);
                this.showOmcField = false;
            } else {
                this.showOmcField = true;
            }
        })
    }

    selectItem(c: RequestCategory) {
        if (c.userNotes) {
            this.cds.alert($localize `Cannot create request`, c.userNotes);
            return;
        }
        this.refreshOmcs(c);
        const subcats = this.categories.filter (o => o.parentId === c.id);
        this.showingSubCategory = null;
        this.showingCategory = c;
        const idx = this.showingCategories.indexOf(c);
        if (idx >= 0) {
            this.showingCategories.splice(idx + 1);
        } else {
            this.showingCategories.push(c);
        }


        if (subcats.length === 0) {
            this.showSubCategoryDetails(c)
        } else {
            this.showCategories(subcats);
        }
    }

    showSubCategoryDetails(subCat: RequestCategory) {
        this.showingQuestions = [];
        this.showingSubCategory = subCat;
        subCat.questions.forEach( q => {
            let f: Field;
            if (q.dataType === 'text') {
                f = FormTextComponent.make(q.name, q.name);
            } else if (q.dataType === 'date') {
                f = FormDateComponent.make(q.name, q.name);
            } else if (q.dataType === 'currency') {
                f = FormNumberComponent.makeCurrency(q.name, q.name);
            } else if (q.dataType === 'longText') {
                f = FormTextAreaComponent.make(q.name, q.name);
            } else if (q.dataType === 'number') {
                f = FormNumberComponent.make(q.name, q.name, {});
            } else if (q.dataType === 'picklist') {
                const options = q.options.split(';');
                const plOptions = {items: []};
                options.forEach(o => plOptions.items.push({id: o, name: o}));
                f = FormPicklistComponent.make(q.name, q.name, null, plOptions);
            }
            f.makeControl();
            this.showingQuestions.push(f);
        })
    }

    validate() {
        if (this.titleCtl.invalid) {
            this.titleCtl.markAsDirty();
            this.titleCtl.markAsTouched()
            return false;
        }
        if (this.descriptionCtl.invalid) {
            this.descriptionCtl.markAsDirty();
            this.descriptionCtl.markAsTouched();
            return false;
        }
        return true;
    }

    postNewRequest() {
        const agentTeamId = this.showingSubCategory.teamId;
        const msg = $localize `Create new request for ${this.showingSubCategory.name} now?`;
        if (!this.validate()) {
            return;
        }
        this.cds.open('Create New Request', msg, () => {
            const sr = this.getRequestDtls();
            sr.agentTeamId = agentTeamId;
            sr.teamId = this.currentUserSvc.currentUser.currentTeam.id;
            this.requestSvc.postAgentRequest(sr, agentTeamId).subscribe( srSaved => {
                if (srSaved) {
                    this.existingList.push(srSaved);
                    this.selectRequest.emit(srSaved);
                    this.resetForm();
                }
            });
        });
    }

    getRequestDtls() {
        const sr = new ServiceRequest();
        sr.categoryId = this.showingSubCategory.id;
        sr.reportedByPersonId = this.currentUserSvc.currentUser.currentProfileId;
        sr.omcId = this.omcCtl.value;
        sr.title = this.titleCtl.value;
        sr.description = this.descriptionCtl.value;
        this.setCustomData(sr);
        return sr;
    }


    setCustomData(sr: ServiceRequest) {
        const customData = {}

        if (this.showingQuestions.length > 0) {
            this.showingQuestions.forEach( fld => {
                if (fld.control.dirty) {
                    customData[fld.name] = fld.control.value;
                }
            })
        }

        sr.data = customData;
    }

}
