/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Person } from 'src/app/model/person';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { required } from 'src/app/shared/validators';
import { MyUnitService } from '../../unit/my-unit.service';
import { RoleService } from 'src/app/pages/role-page/role.service';
import { Role } from 'src/app/model/role';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { MatButtonModule } from '@angular/material/button';
import { combineLatest } from 'rxjs';
import { CurrentUserService } from '../../user/current-user.service';
import { MessageService } from 'src/app/shared/message.service';
import { MyRequestService } from '../my-request.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { Unit } from 'src/app/model/unit';

@Component({
  selector: 'app-add-person-unit-dialog',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './add-person-unit-dialog.component.html',
  styleUrl: './add-person-unit-dialog.component.scss'
})
export class AddPersonUnitDialogComponent {

    dialogRef = inject(MatDialogRef<AddPersonUnitDialogComponent>);
    data : {unit: Unit} = inject(MAT_DIALOG_DATA);

    unitSvc = inject(MyUnitService);
    roleSvc = inject(RoleService);
    cus = inject(CurrentUserService);
    myrSvc = inject(MyRequestService);
    msgSvc = inject(MessageService);

    unitField = FormPicklistComponent.make('Unit', 'unitId', null,
        { items: [] }, { disable: true, formColumn: 2 }
    );

    roleField = FormPicklistComponent.make('Role', 'roleId', 'role',
        { items: [], refreshes: [] }, { validators: [required] }
    );

    roleFilter(r: Role) {
        if (r.globalRoleId !== Role.ROLE.OWNER.id
            && r.globalRoleId !== Role.ROLE.OWNER_OCCUPIER.id
            && r.unitLinked) {
            return true;
        }
        return false;
    }
    fieldSet = new FieldSet();
    ready = false;

    constructor() {
        combineLatest([
                this.roleSvc.get(true),
                this.unitSvc.get(true),
                this.cus.getCurrentUser()
            ]).subscribe( ([rs, us]) => {
            this.roleField.setPicklistItems( rs.filter(this.roleFilter.bind(this)));
            this.unitField.setPicklistItems(us);
            this.ready = true;
            this.fieldSet.setFields(this.getPersonFields());
            this.fieldSet.setValue(this.defaultData());
        });
    }

    getResidentRole() {
        return this.roleField.picklist.items.find((r: Role) => r.globalRoleId === Role.ROLE.RESIDENT.id);
    }

    defaultData() {
        const p = new Person();
        p.unitId = this.data.unit.id;
        return p;
    }

    getPersonFields() {
        const fields = [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('requestId'),
            this.unitField,
            this.roleField,
            Person.getTitleField(),
            FormTextComponent.make('First Name', 'firstName'),
            FormTextComponent.make('Last Name', 'lastName', { validators: [required] }),
            FormTextComponent.make('Telephone', 'phone'),
            FormEmailComponent.make('Email', 'email'),
            FieldMaker.notes().override({ formColumn: 2 }),
        ];
        return fields;
    }

    savePerson() {
        const u = this.unitField.control.value;
        const p = this.fieldSet.getFormValue() as Person;
        const t = this.cus.currentUser.currentTeam.id;
        this.unitSvc.addPerson(u,p,t).subscribe ( (response: ServiceRequest) => {
            console.log(response);
            this.myrSvc.updateCachedItem(response);
            this.dialogRef.close(response);
        }, failure => this.msgSvc.showError(failure, 'Adding Person to Unit'))
    }
}
