<!--
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
-->
<app-dialog-banner text="{{config.title}} Picklist" [dialogRef]="dialogRef"></app-dialog-banner>
<mat-dialog-content [style.height.px]="height" [style.width.px]="width">
  @if (config.objectFactory) {
  @if (!addOnly) {
  <mat-tab-group mat-align-tabs="start" mat-stretch-tabs="false">
    <mat-tab label="Select {{config.title}}" i18n-label>
      <ng-container *ngTemplateOutlet="tableView">
      </ng-container>
    </mat-tab>
    @if (config.objectFactory) {
    <mat-tab label="New {{config.title}}" i18n-label>
      <app-form [config]="config" [dialogRef]="dialogRef"></app-form>
    </mat-tab>
    }
  </mat-tab-group>
  } @else {
  <app-form [config]="config" [dialogRef]="dialogRef"></app-form>
  }
  } @else {
  <!-- h2 mat-dialog-title i18n>Select {{config.title}}</h2 -->
  <ng-container *ngTemplateOutlet="tableView">
  </ng-container>
  }
</mat-dialog-content>
<ng-template #tableView>
  @if (showSearch) {
  <mat-form-field [style.width.px]="width - 80" style="margin: 10px;">
    <mat-label>Search</mat-label>
    <input matInput id="search" style="width: 100px" (input)="filter($event.target.value)" autocomplete="off"
      placeholder="Search">
  </mat-form-field>
  }
  @if(listLimited && !showList) {
    <p style="margin-left: 10px">Too many items to show ({{dataItems.length}}) - Lists shows after you enter 3 or more characters</p>
  }
  @if (showList) {
<table mat-table [dataSource]="dataSource" style="width:100%" class="ouromc-table">
    @for (field of columns; track field; let colIndex = $index) {
    <ng-container [(matColumnDef)]="field.name">
        <th mat-header-cell *matHeaderCellDef>{{field.cellOpts.heading}}</th>
        <td mat-cell *matCellDef="let focusItem" [matTooltip]="field.toolTip" [style]="field.cellOpts.style"
            [width]="field.cellOpts.width">
            <app-table-cell [field]="field" [focusItem]="focusItem"></app-table-cell>
        </td>
    </ng-container>
    }
    <tr mat-header-row *matHeaderRowDef="columnNames;" style="height: 48px"></tr>
    <tr mat-row *matRowDef="let row; columns: columnNames;" (click)="selectItem(row)" style="height: 48px"></tr>
</table>
  }

</ng-template>
