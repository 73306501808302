/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})

export class CanDeactivateGuard {

  canDeactivate(component: CanComponentDeactivate) {

    if (!component.canDeactivate) {
      console.error('CanDeactiveGuard added to component, but not implemented on this component', component);
    }

    return component.canDeactivate && component.canDeactivate();

  }
}
