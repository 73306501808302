/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { DefaultBCode } from './bcode';
import { Period } from './period';
import { User } from './user';
export class Team extends AbstractObject {
    constructor(o: Partial<Team> = {}) {
        super(o);
    }

    legalName: string;
    defaultAddress: string;

    users?: User[];

    currentPeriod?: Period;

    primaryAgentId?: uuid;
    primaryAgentName?: string;

    defaultBCodes: DefaultBCode[];
}


