/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AppFormControl } from '../shared/form/app-form-control';
import { AbstractObject, uuid } from './abstract-object';
import { Attachment } from './attachment';
import { ChatItem } from './chat-item';
import { Forum } from './forum';
import { ServiceRequest } from './serviceRequest';

export class Ballot extends AbstractObject {
    title: string;
    content: string;
    forum: Forum;
    forumId: uuid;
    areaId: uuid;
    areaName: string;
    coreId: uuid;
    coreName: string;

    opensAt: number;
    closesAt: number;
    questions: Question[] = [];
    votes: Vote[] = [];
    perUnit: 0 | 1 = 1;
    notificationSentAt?: number;
    mailsSent?:  number;
    attachments?: Attachment[] = [];
    comments?: ChatItem[] = [];
    requests: ServiceRequest[] = []

    static options: AbstractObject[] = [
        { id: 1, name: 'One vote per unit' },
        { id: 0, name: 'One vote per user' }
    ]
}

export class Vote extends AbstractObject {
    ballotId: uuid;
    optionId: uuid;
    questionId: uuid;
    unitId: uuid;
    userId: uuid;
}

export class Question extends AbstractObject {
    question = '';
    content: string;
    ballot: Ballot;
    ballotId: uuid;
    forum: Forum;
    forumId: uuid;
    opensAt: number;
    closesAt: number;
    options: Option[] = [new Option({ name: 'Yes' }), new Option({ name: 'No' }), new Option({ name: 'Abstain' })];
    /** Calculated Fields... */
    votesCast: Vote[] = [];
    voteCastById: uuid;
    voteCastOn: string;
    votedOptionId: uuid;
    resultControl: AppFormControl;
    static voteOpened(q: Question) {
        if (q?.opensAt && (q.opensAt * 1000) < new Date().getTime()) {
            return true;
        } else {
            return false;
        }
    }

    static voteClosed(q: Question) {
        if (q?.closesAt && (q.closesAt * 1000) < new Date().getTime()) {
            return true;
        } else {
            return false;
        }
    }

    static voteCanBeClosed(q: Question) {
        if (Question.voteOpened(q) && !Question.voteClosed(q)) {
            return true;
        } else {
            return false;
        }
    }
}

export class Option extends AbstractObject {
    answer = '';
    description? = '';
    votes: number;
}
