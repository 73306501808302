/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/
import { HttpParams } from '@angular/common/http';
import { uuid } from 'src/app/model/abstract-object';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';

class RboRow {
    omcId: uuid;
    omcName: string;
    actions: number;
    open: number;
    done: number;
}

class RboData {
    startDate: string;
    startTS: number;
    rows: RboRow[];
}

export class RboChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/rbo';
    readonly route = ServiceRequestPageComponent.navRoute;
    readonly yAxisText: string = "Count Requests";
    title = "Busiest OMCs by Requests";

    dataParams = new HttpParams().set('week', 0).set('show', 'all');

    categories: string[] = [];

    //series: { name: string, y: number, route: NavRoute, routeParams: Params, comt: string }[] = [];
    openSeries = { name: 'To Do', data: [] };
    doneSeries = { name: 'Completed', data: [] }
    series = [this.openSeries, this.doneSeries];

    startDate = '';

    options = [
        { name: 'All OMCs', action: this.allOMCs.bind(this) },
        { name: 'My OMCs', action: this.myOMCs.bind(this) },
        { name: 'My Requests', action: this.myRequests.bind(this) }
    ];

    myRequests() {
        this.dataParams = this.dataParams.set('show', 'myrequests');
        this.getData();
    }

    myOMCs() {
        this.dataParams = this.dataParams.set('show', 'myomcs');
        this.getData();
    }

    allOMCs() {
        this.dataParams = this.dataParams.set('show', 'all');
        this.getData();
    }

    resetChart() {
        this.categories = [];
        this.openSeries = { name: 'Open Requests', data: [] };
        this.doneSeries = { name: 'Completed Requests', data: [] };
        this.series = [this.openSeries, this.doneSeries];
    }

    getSubTitle() {
        return { text: 'Includes all requests to do and done since ' + this.startDate };
    }

    getRouteParamsOpen(i: RboRow): unknown {
        const parms = { omcId: i.omcId, srStatus: 'open', _sort: 'refNr' };
        return this.getShowParms(parms);
    }

    getRouteParamsDone(i: RboRow, since: string): unknown {
        const parms = { omcId: i.omcId, completedAt: '>=' + since, _sort: 'completedAt' };
        return this.getShowParms(parms);
    }

    protected setupData(data: RboData) {
        const route = this.route;
        this.startDate = data.startDate;

        for (const i of data.rows) {
            this.categories.push(i.omcName);
            i.done = Number(i.done);
            i.open = Number(i.open); // PHP considers a MYSQL Sum Result to be a string...

            this.openSeries.data.push({
                name: i.omcName,
                y: (i.open),
                route,
                routeParams: this.getRouteParamsOpen(i)
            });

            this.doneSeries.data.push({
                name: i.omcName,
                y: (i.done),
                route, routeParams:
                    this.getRouteParamsDone(i, data.startDate)
            });
        }
    }

    clickEvent($event) {
        this.router.navigate([$event.point.route.url, $event.point.routeParams]);
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            colors: ['orange', 'green'],
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'column',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: {point.y}'
            },
            xAxis: {
                categories: this.categories,
            },
            yAxis: {
                min: 0,
                title: { text: this.yAxisText }
            },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                }
            },
            series: this.series
        } as unknown as Highcharts.Options;
    }
}
