
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Field } from 'src/app/shared/field/Field';
import { Schedule } from 'src/app/model/schedule';
import { ScheduleCriteria } from 'src/app/model/schedule-criteria';
import { Unit } from 'src/app/model/unit';
import { UnitType } from 'src/app/model/unit-type';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { maxChars, required } from 'src/app/shared/validators';
import { UnitCategoryService } from 'src/app/modules/unit/unit-type-page/unit-category.service';
import { UnitTypeService } from 'src/app/modules/unit/unit-type-page/unit-type.service';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { ActionHandler } from 'src/app/modules/budget/schedule-action-handler';
import { ScheduleService } from 'src/app/modules/budget/schedule.service';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { Person } from 'src/app/model/person';
import { Frequency } from 'src/app/model/frequency';
import { SchedulePortion } from 'src/app/model/schedule-portion';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { BCodeService } from '../bcode.service';
import { HttpParams } from '@angular/common/http';
import { BCode } from 'src/app/model/bcode';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-schedule-page',
    templateUrl: './schedule-page.component.html',
    styleUrls: ['./schedule-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class SchedulePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('budgets/schedules', SchedulePageComponent, 'horizontal_distribute');

    actionHandler = new ActionHandler();

    chargeTypeField = FormPicklistComponent.make('Charge Type', 'chargeTypeId', null,
        { items: Schedule.chargeTypes },
        { valueChanges: this.actionHandler.changeChargeType.bind(this.actionHandler), validators: [required] }
    );

    criteriaGrid: GridField = new GridField({
        field: { label: 'Criteria', value: 'criteria', formRow: 2 },
        rowFactory: this.criteriaRowFactory.bind(this),
        objFactory: () => of(new ScheduleCriteria()),
    });

    portionGrid = new GridField({
        field: { label: 'Unit Apportionment', value: 'portions', formRow: 2 }, rowFactory: () => [
            FieldMaker.id({ visible: Field.showAll }),
            FormTextComponent.make('unitId', 'unit.id', { name: 'unitId', visible: Field.noShow }),
            FormTextComponent.make('Unit Object', 'unit', { sendServer: false, visible: Field.noShow }),
            FormButtonComponent.makeLink('Unit', 'unit.name', '/units/${unitId}'),
            FormTextComponent.make('Type', 'unit.type.name', { sendServer: false, disable: true }),
            FormTextComponent.make('Category', 'unit.category.name', { sendServer: false, disable: true }),
            FormButtonComponent.makeLink('Owner', 'name', '/people/${personId}', {
                calculateValue: (o: SchedulePortion) => Person.fullName(o.unit.owner)
            }),
            FormNumberComponent.make('Portion', 'portion', {}, {
                disable: true,
                cellOpts: { width: '80px', style: 'text-align: center' },
                valueChanges: this.actionHandler.needsToBeApplied.bind(this.actionHandler)
            }),
            FormNumberComponent.make('Percent', 'percent',
                { format: 'percent', formatParms: '2.3-3' },
                { disable: true, cellOpts: { width: '80px', style: 'text-align: center' } },
            ),
        ],
        objFactory: () => of(new ScheduleCriteria()),
    });

    fields: Field[] = [
        FieldMaker.id(),
        FieldMaker.rev(),
        FormTextComponent.make('Name', 'name', { validators: [required, maxChars(20)] }),

        this.chargeTypeField,

        FormPicklistComponent.make('Income Account', 'incomeBCodeId', 'incomeBCode',
            { service: this.bCodeSvc, serviceFilter: new HttpParams().set('typeId', BCode.TYPE.INCOME.id) },
            { formColumn: 1, validators: [required] }
        ),

        FormPicklistComponent.make('Billing Frequency', 'frequencyId', 'frequency',
            { items: [Frequency.YEARLY], optionDisplayValue: o => o.name + ' (' + o.perAnnum + ')' },
            { formColumn: 2, validators: [required] }
        ),

        FormCheckboxComponent.make('Sinking Fund', 'sinkingFund', { formColumn: 2 }),

        FormPicklistComponent.make('Prepayments Account', 'prepaidBCodeId', 'prepaidBCode',
            { service: this.bCodeSvc, serviceFilter: new HttpParams().set('typeId', BCode.TYPE.LIABILITY.id) },
            { formColumn: 2, validators: [required] }
        ),

        FormTextComponent.make('Total Unit matches', '', { name: 'totalUnitCount', formColumn: 3, disable: true, visible: Field.formOnly }),
        FormTextComponent.make('Total Portions', 'totalPortionCount', { formColumn: 3, disable: true, visible: Field.formOnly }),

        FieldMaker.notes({ formColumn: 4 }),
    ];

    config = new FormConfig({
        navRoute: SchedulePageComponent.navRoute,
        title: $localize`Schedule`,
        help: $localize`Schedules determine how costs will be allocated to unit owners`,
        fieldSet: new FieldSet({
            fields: [
                ...this.fields,
                this.criteriaGrid,
                this.portionGrid,
            ],
            formValidator: this.actionHandler.validate.bind(this.actionHandler),
            valueChanges: this.actionHandler.applyIfNeeded.bind(this.actionHandler),
            formLayout: [{ cells: [{ width: '30%' }, { width: '20%' }, { width: '20%' }, { width: '30%' }] },
            { cells: [{ colspan: 4, pageTab: 'yes' }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        beforeSave: this.actionHandler.beforeSave.bind(this.actionHandler),
        afterSave: this.actionHandler.afterSave.bind(this.actionHandler),
        objectFactory: () => of(new Schedule()),

        // actions: [new FormActionButton({ name: 'Apply', icon: 'approval', action: this.actionHandler.calculate.bind(this.actionHandler) })],
        initNotification: new Subject<UntypedFormGroup>(),
    });

    constructor(public dataSvc: ScheduleService,
        public typeService: UnitTypeService, public categoriesService: UnitCategoryService,
        private unitService: UnitService, private bCodeSvc: BCodeService) {
        super();

        forkJoin({
            units: this.unitService.get().pipe(first()),
            unitTypes: this.typeService.get().pipe(first()),
            formGroup: this.config.initNotification.pipe(first()),
        }).subscribe((val) => {
            this.actionHandler.units = val.units as Unit[];
            this.actionHandler.unitTypes = val.unitTypes as UnitType[];
            this.actionHandler.formGroup = val.formGroup;
            this.actionHandler.portionMap();
            this.actionHandler.enableCriteriaPortions(val.formGroup.get('chargeTypeId').value);
            this.actionHandler.calculate();
        });
    }

    criteriaRowFactory() {
        return [
            FieldMaker.id(),

            FormPicklistComponent.make('Type', 'typeId', 'type',
                { service: this.typeService, refreshes: ['categoryId'] },
                { valueChanges: this.actionHandler.needsToBeApplied.bind(this.actionHandler) }
            ),

            FormPicklistComponent.make('Category', 'categoryId', 'category', { service: this.categoriesService },
                {
                    refresh: (o: UnitType, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.categories; control.setValue(null); } },
                    valueChanges: this.actionHandler.needsToBeApplied.bind(this.actionHandler),
                }),

            FormCheckboxComponent.make('Include', 'include', { valueChanges: this.actionHandler.needsToBeApplied.bind(this.actionHandler) }),

            FormNumberComponent.make('Portion Per Unit', 'portion', {}, {
                cellOpts: { width: '80px' },
                disable: this.chargeTypeField.control.value === Schedule.chargeId.byType ? false : true,
                valueChanges: this.actionHandler.needsToBeApplied.bind(this.actionHandler)
            }),

            FormNumberComponent.make('Unit matches', '', {}, { cellOpts: { width: '80px' }, name: 'unitCount', disable: true }),
            FormNumberComponent.make('Total Portions', 'portionCount', {}, { cellOpts: { width: '80px' }, disable: true }),
            FieldMaker.deleteGridRow({
                clickMethod: function (ctl: AppFormControl) {
                    ctl.getRow().delete.bind(ctl.getRow())();
                    this.needsToBeApplied(true);
                    this.calculate(this.formGroup.value);
                }.bind(this.actionHandler)
            }),
        ];
    }
}
