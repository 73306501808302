/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Type } from "@angular/core";
import { Params, Route } from "@angular/router";
import { uuid } from "../model/abstract-object";
import { ChartParms, TableView } from "../model/sys/nav-item";
import { User } from "../model/user";
import { CanDeactivateGuard } from "./guards/can-deactivate.guard";
import { LoggedInGuard } from "./guards/loggedin.guard";

export class NavRoute {
    private _url: string;
    private _component: Type<unknown>;
    private _itemComponent: Type<unknown>;
    private _newComponent: Type<unknown>;

    private viewMethod: (u: User) => TableView[];
    private _userViews: TableView[] = [];

    private _icon: string;

    public _charts: ChartParms[];

    constructor(url: string, component: Type<unknown>, icon: string, itemComponent:Type<unknown> = null) {
        this._url = url;
        this._component = component;
        this._itemComponent = itemComponent ? itemComponent : component;
        this._newComponent = component;
        this._icon = icon;

        if (NavRoute.index.has(url)) {
            console.error('Already have path ', {url, paths: NavRoute.index});
        }
        NavRoute.index.set(url, this);
    }

    public get icon() {
        return this._icon;
    }

    public setIcon(icon: string) {
        this._icon = icon;
        return this;
    }

    public configureUserViews(user: User) {
        if (this._userViews.length === 0) {
            this._userViews = this.getViews(user);
        }
    }

    public get userViews() {
        return this._userViews;
    }

    public get url() {
        return this._url;
    }

    public get itemUrl() {
        return this._url + '/:itemId';
    }
    public getListRouterLink(parms: Params = {}) {
        return ['/' + this.url, parms];
    }

    public getIdUrl(id: uuid) {
        return '/' + this.itemUrl.replace(':itemId', id as string)
    }

    public get newUrl() {
        return this._url + '/NEW';
    }

    public get openRoute() {
        return {
            path: this.url,
            component: this._component,
            pathMatch: 'full',
            data: { animation: 'openRoute' }
        } as Route;
    }
    public get openItemRoute() {
        return {
            path: this.itemUrl,
            component: this._itemComponent,
            pathMatch: 'full',
            data: { animation: 'itemRoute' }
        } as Route;
    }

    public setNewComponent(c: Type<unknown>) {
        this._newComponent = c;
        return this;
    }

    public setItemComponent(c: Type<unknown>) {
        this._itemComponent = c;
        return this;
    }

    public get listRoute() {
        return {
            path: this.url,
            component: this._component,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [LoggedInGuard],
            pathMatch: 'full',
            data: { animation: 'listRoute' }
        } as Route;
    }

    public get itemRoute() {
        return {
            path: this.itemUrl,
            component: this._itemComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [LoggedInGuard],
            pathMatch: 'full',
            data: { animation: 'itemRoute' }
        } as Route;
    }

    public get newRoute() {
        return {
            path: this.newUrl,
            component: this._newComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [LoggedInGuard],
            pathMatch: 'full',
            data: { animation: 'newRoute' }
        } as Route;
    }


    setViews( method: (u: User) => TableView[]) {
        this.viewMethod = method;
        return this;
    }

    setCharts(cp: ChartParms[]) {
        this._charts = cp;
        return this;
    }

    getCharts() {
        return this._charts;
    }

    getViews(u: User) {
        return this.viewMethod ? this.viewMethod(u) : [];
    }

    private static index: Map<string, NavRoute> = new Map();

    public static getRoute(path) : NavRoute {
        if (!NavRoute.index.has(path)) {
            if (path !== 'demos') {
                console.error('Do not have path ', {path, paths: NavRoute.index});
            }
        }
        return NavRoute.index.get(path);
    }
}
