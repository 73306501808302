/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { NgTemplateOutlet } from '@angular/common';
import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us',
  standalone: true,
  imports: [ NgTemplateOutlet],
  templateUrl: './about-us.component.html',
  styleUrl: './about-us.component.scss'
})
export class AboutUsComponent {

    assets = 'https://clgassets.fra1.cdn.digitaloceanspaces.com/team-photos/';

}
