/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { BCode } from './bcode';
import { Cycle } from './cycle';
import { Period } from './period';
import { Schedule } from './schedule';

export class BudgetSchedule extends AbstractObject {
  bCodeId: uuid;
  bCode: BCode;
  scheduleId: uuid;
  schedule: Schedule;
  periodId: uuid;
  period: Period;
  cycleId: uuid;
  cycle: Cycle;
  plannedSpend: number;
  // Calculated only fields
  previousBudget: number;

  constructor(o: Partial<BudgetSchedule> = {}) {
    super(o);
    if (!o.bCodeId && o.bCode) {
      this.bCodeId = o.bCode.id;
    }
    if (!o.scheduleId && o.schedule) {
      this.scheduleId = o.schedule.id;
    }
    if (!o.periodId && o.period) {
      this.periodId = o.period.id;
    }
  }
}
