/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { Field } from 'src/app/shared/field/Field';
import { FORUM_PRIVS, Forum, ForumMember, ForumRole } from 'src/app/model/forum';
import { PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { maxChars, required, uniqueGrid } from 'src/app/shared/validators';
import { UserService } from '../../user/user.service';
import { ForumService } from './forum.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { RoleService } from 'src/app/pages/role-page/role.service';
import { Role } from 'src/app/model/role';
import { ForumPeopleAction } from './forum-people/forum-people.component';
import { GridRow } from 'src/app/shared/grid/grid-row';

@Component({
    selector: 'app-forum',
    templateUrl: './forum.component.html',
    styleUrls: ['./forum.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class ForumComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('social/channels', ForumComponent, 'diversity_3');

    roles: Role[] = [];

    pickUserDialogFormConfig: FormConfig = new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FormTextComponent.make('Name', 'name', { validators: [required, maxChars(20)] }),
                FormTextComponent.make('EMail', 'email'),
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        title: $localize`Users`,
        mode: 'list',
    });

    roleRowFactory(o: ForumRole) {
        const row = [
            FieldMaker.id({ visible: Field.noShow }),
            FormPicklistComponent.make('Role', 'roleId', 'role',
                { items: this.roles }, {
                readonly: (o?.id || o?.role) ? true : false,
                cellOpts: { width: '10em' }
            }
            )
        ]
        FORUM_PRIVS.forEach(p => {
            row.push(FormCheckboxComponent.make(p.name, p.id as string),);
        })
        row.push(FieldMaker.rev());
        row.push(FieldMaker.deleteGridRow());
        return row;
    }

    roleField = new GridField({
        field: { value: 'forumRoles', formColumn: 2, cellOpts: { heading: 'Roles' }, visible: Field.formOnly },
        rowFactory: this.roleRowFactory.bind(this),
        objFactory: () => of(new ForumRole()),
        gridValidator: [uniqueGrid('roleId')]
    });

    membersField = new GridField({
        field: { value: 'members', formRow: 2, cellOpts: { heading: 'Members' }, visible: Field.formOnly },
        rowFactory: () => [
            FieldMaker.id({ visible: Field.noShow }),
            FieldMaker.idHolder('userId'),
            FormTextComponent.make('Name', 'user.name', { readonly: true, sendServer: false }),
            FormTextComponent.make('EMail', 'user.email', { readonly: true, sendServer: false }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => {
            const dialogRef = this.dialog.open(PickDialogComponent,
                { data: { config: this.pickUserDialogFormConfig, service: this.userSvc } });
            return dialogRef.afterClosed().pipe(first()).pipe<ForumMember>(
                map(user => {
                    console.log('back', user);
                    if (user) {
                        const newItem = new ForumMember();
                        newItem.userId = user.id;
                        newItem.user = user;
                        return newItem;
                    } else {
                        //return null;
                    }
                })
            );
        },
        gridValidator: [uniqueGrid('userId')]
    });

    config = new FormConfig({
        navRoute: ForumComponent.navRoute,
        title: $localize`Channel`,
        help: $localize`Discussion forums and their membership of who can see and post in them.`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required, maxChars(30)] }),
                FieldMaker.notes(),
                this.roleField,
                //this.membersField,
            ],
            formLayout: [{ cells: [{ width: '33%' }, { width: '66%' }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newForum.bind(this),
        beforeSave: this.beforeSave.bind(this),
        actions: [new ForumPeopleAction()],
        configReady: this.configReady,
    });

    constructor(public dataSvc: ForumService, public dialog: MatDialog,
        protected userSvc: UserService, roleSvc: RoleService) {
        super();
        roleSvc.get(true).subscribe((roles: Role[]) => {
            this.roles = roles;
            this.configReady.next();
        });
    }

    newForum() {
        const f = new Forum();
        this.roles.forEach(r => {
            const fr = new ForumRole();
            fr.roleId = r.id;
            fr.role = r;
            if (r.isAdmin) {
                FORUM_PRIVS.map(f => fr[f.id] = true);
            }
            f.forumRoles.push(fr);
        })

        return of(f);
    }

    beforeSave() {
        const rows = this.roleField.control.gridRows();
        const toDelete: GridRow[] = [];

        rows.forEach(row => {
            let privCnt = 0;
            FORUM_PRIVS.forEach(p => {
                if (row.get(p.id as string).value) {
                    privCnt += 1;
                }
            })

            if (privCnt === 0) {
                toDelete.push(row);
            }
        })
        // directly deleting new rows removes them from grid, making forEach above skip over entries...
        toDelete.forEach(gr => gr.delete());
    }
}
