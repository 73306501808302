/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { inject, Injectable } from '@angular/core';
import { AbstractHttpService } from './abstract-http.service';
import { MailItem, MailStatuses } from '../model/mailMerge';
import { GridField } from './grid/grid-field';
import { Field } from './field/Field';
import { MessageService } from './message.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { FormTextComponent } from './form/form-text/form-text.component';
import { FormButtonComponent } from './form/form-button/form-button.component';
import { FormDateTimeComponent } from './form/form-date-time/form-date-time.component';
import { FormNumberComponent } from './form/form-number/form-number.component';
import { FormPicklistComponent } from './form/form-picklist/form-picklist.component';
import { ByteCountPipe } from './pipes/byte-count.pipe';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';

type GridType = 'recipient'|'model';
@Injectable({
    providedIn: 'root'
})
export class MailService {
    http = inject(HttpClient);
    msgSvc = inject(MessageService);

    getMailItemGrid(dataSvc: AbstractHttpService, type: GridType) {
        return new GridField({
            field: {
                value: 'mailItems', cellOpts: { heading: 'Mailings' },
                formRow: 2, sendServer: false, visible: Field.formOnly,
                tabIcon: 'outgoing_mail'
            },
            rowFactory: (mi: MailItem) => {
                const opener = () => this.showMail(mi, dataSvc, type);
                return [
                    FormTextComponent.make('From', 'fromAddress', { readonly: true }),
                    FormTextComponent.make('To', 'toAddress', { readonly: true }),
                    FormTextComponent.make('Class', 'class', { readonly: true }),
                    FormButtonComponent.makeTextButton('Subject', 'subject', opener),
                    FormDateTimeComponent.make("Sent", 'createdAt', { sendServer: false, readonly: true }),
                    FormTextComponent.make('Size', 'size', { readonly: true, 
                        cellOpts: { width: '20px', style: 'font-size: smaller' },
                        calculateValue: (mi: MailItem) => ByteCountPipe.formatBytes(mi.size)
                    }),
                    FormNumberComponent.make('Status', 'responseCode', {format: 'plain', blankZero: true}, {readonly: true}),
                    FormNumberComponent.make('Attached', 'attachments', {format: 'plain', blankZero: true}, {readonly: true}),
                    FormPicklistComponent.make('Status Text', 'mailStatus', null, {items: MailStatuses}, {readonly: true}),
                ]
            },
        });            
    }
    
    getMail(mailItem: MailItem, dataSvc: AbstractHttpService, gridType: GridType) {
        const linkedId = gridType === 'model' ? mailItem.modelId : mailItem.recipientId;
        const url = `${dataSvc.getBaseUrl()}/getMail/${linkedId}/${mailItem.id}`;
        const params = new HttpParams().set('_forceTeam', mailItem.teamId);
        const headers = new HttpHeaders().append('Accept', 'application/text');

        return this.http.get(url, { params, headers, observe: 'response', responseType: 'text' }).pipe(
            map(x => {
                return x;
            }),
            catchError((error) => {
                this.msgSvc.showError(error, 'Retrieving Mail');
                return of(null);
            })
        )
    }

    showMail(mi: MailItem, dataSvc: AbstractHttpService, gridType: GridType) {
        this.getMail(mi, dataSvc, gridType).subscribe(htmlResponse => {
            if (htmlResponse) {
                const width = screen.availWidth * .8;
                const height = screen.availHeight * .8;
                // Dual monitors needs to push out...
                const top = window.screenTop + (screen.availHeight * .1);
                const left = window.screenLeft + (screen.availWidth * .1);
                const title = "Message Sent To " + mi.toAddress + " : " + mi.subject;
                const baseOptions = "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes";
                const options = `${baseOptions},width=${width},top=${top},left=${left},height=${height}`
                const win = window.open("", title, options);
                const when = new Date(mi.createdAt * 1000).toLocaleDateString('en-IE', { weekday: "long", month: "long", day: "numeric" });
                const time = new Date(mi.createdAt * 1000).toLocaleTimeString('en-IE')
                const from = mi.fromAddress;
                const to = mi.toAddress;
                console.log(from);
                const document = `<html>
                    <div style="margin-left: 20px; margin-right: 20px; line-height: 1.2em">
                    <hr/>
                    <p style="margin-bottom: 0px"><b>From</b>: ${from}</p>
                    <p style="margin-bottom: 0px"><b>To</b>: ${to}</p>
                    <p style="margin-bottom: 0px"><b>Sent</b>: ${when} @ ${time}</p>
                    <p style="margin-bottom: 0px"><b>Subject</b>: ${mi.subject}</p>
                    <hr/>
                    ${htmlResponse.body}
                    </div>
                </html>`
                win.document.title = title;
                win.document.body.innerHTML = document;
            }
        });
    }    


}