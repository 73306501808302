/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Txn } from 'src/app/model/txn';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { CycleService } from 'src/app/modules/budget/cycle.service';
import { PeriodService } from 'src/app/modules/budget/period.service';
import { UnitService } from 'src/app/modules/unit/unit.service';
import { PersonService } from '../../pages/person-page/person.service';
import { BCodeService } from '../budget/bcode.service';
import { Cycle } from 'src/app/model/cycle';
import { Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { BankAccountService } from './bank-account.service';
import { ScheduleService } from '../budget/schedule.service';
import { Action } from 'src/app/model/action';

export class TxnImportChecker {
    invoiceCount: number;
    invoices: Txn[];
    receiptCount: number;
    receipts: Txn[];
    creditCount: number;
    credits: Txn[];
    outstanding: number;
    actions: Action[];
}

@Injectable({
    providedIn: 'root'
})
export class TxnService extends AbstractHttpService {

    // protected baseUrl = this.ajaxPath + 'txns?typeId=' + Txn.TYPE.SALE.id;
    protected baseUrl = this.ajaxPath + 'txns';
    protected cache: Txn[];
    protected typeString = 'Txn';

    constructor(protected http: HttpClient, protected messageService: MessageService,
        protected unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected bCodeSvc: BCodeService, protected bankAccountSvc: BankAccountService,
        protected scheduleSvc: ScheduleService) {
        super();
    }

    getTypeField() {
        return FormPicklistComponent.make('Type', 'typeId', 'type', { items: Txn.TYPES }, { readonly: true });
    }

    getFields(): Field[] {

        return [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormTextComponent.make('RefNr', 'refNr', { readonly: true }),
            FormPicklistComponent.make('Ledger', 'ledgerId', 'ledger', { items: Txn.LEDGERS }),
            this.getTypeField(),
            FormDateComponent.make('Transaction Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
            FormPicklistComponent.make('Bank Account', 'bankAccountId', null, {service: this.bankAccountSvc}, {visible: Field.noShow}),
            FormPicklistComponent.make('Schedule', 'scheduleId', null, { service: this.scheduleSvc }, { visible: Field.noShow }),
            FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle', { service: this.cycleSvc }, { formColumn: 2 }),
            FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod', { service: this.periodSvc },
                { formColumn: 2, refresh: (o: Cycle, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.periods; control.setValue(null); } }, }),
            FormPicklistComponent.make('Account', 'bCodeId', 'bCode', { service: this.bCodeSvc }, { formColumn: 3 }),
            FormButtonComponent.makeLink('Owner', 'ownerName', '/people/${ownerId}', {
                cellOpts: { width: '20%' }, label: 'Primary Owner',
                calculateValue: (o: Txn) => Person.fullName(o.person),
                formColumn: 3
            }),
            FormPicklistComponent.make('Unit', 'unitId', null, { service: this.unitSvc }, { visible: Field.noShow }),
            FormButtonComponent.makeLink('Unit', 'unit.name', '/units/${unit.id}', {
                cellOpts: { width: '10%' }, label: 'Unit',
                formColumn: 3
            }),
            //FormPicklistComponent.make('Unit', 'unitId', 'unit', { service: this.unitSvc }, { formColumn: 3 }),
            FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
            FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }, { formColumn: 4 }),
            FormNumberComponent.make('Total', 'totalValue', { format: 'currency', width: 9, formatParms: '1.0-0' }, {
                visible: Field.noShow, calculateValue: o => ((o as Txn).debit - (o as Txn).credit)
            }),

            new GridField({
                field:
                    { label: 'Details', value: 'details', visible: Field.formOnly, formRow: 2 },
                rowFactory: () => [
                    FieldMaker.id({ label: 'Ref', visible: { phone: true, computer: true, form: true }, footer: { text: 'Totals', style: 'text-align: right' } }),
                    FormPicklistComponent.make('Account', 'bCodeId', 'bCode', { service: this.bCodeSvc },
                        { cellOpts: { width: '50%' } }
                    ),
                    FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                    FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
                    FieldMaker.notes(),
                    FieldMaker.rev(),
                    FieldMaker.deleteGridRow()
                ],
                hasFooter: true
            }),
        ]
    }

    getImportChecks(): Observable<TxnImportChecker> {
        return this.http.get<TxnImportChecker>(this.baseUrl + '/importChecks').pipe(first()).pipe(
            map((data: TxnImportChecker) => {
                return data;
            }),
            catchError(this.handleError<TxnImportChecker>('get' + this.typeString, null))
        );
    }
}
