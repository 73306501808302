/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { MatButtonModule } from '@angular/material/button';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MessageService } from 'src/app/shared/message.service';
import { Field } from 'src/app/shared/field/Field';
import { BankInService } from '../../bank-in.service';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { CycleService } from 'src/app/modules/budget/cycle.service';
import { PeriodService } from 'src/app/modules/budget/period.service';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { validateTxnDate } from 'src/app/shared/validators';

@Component({
  selector: 'app-bank-in-edit',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './bank-in-edit.component.html',
  styleUrl: './bank-in-edit.component.scss'
})
export class BankInEditComponent {
    dialogRef = inject(MatDialogRef<BankInEditComponent>);
    bankIn = inject(MAT_DIALOG_DATA);
    bankInSvc = inject(BankInService);
    cycleSvc = inject(CycleService);
    periodSvc = inject(PeriodService);

    msgSvc = inject(MessageService);

    ready = false;

    fieldSet = new FieldSet();

    oldCycleField: PicklistField = FormPicklistComponent.make('Current Cycle', 'oldTxnCycleId', null,
        { service: this.cycleSvc }, { disable: true }
    );

    oldPeriodField: Field = FormPicklistComponent.make('Current Period', 'oldTxnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        { disable: true }
    );

    oldTxnDateField: Field = FormDateComponent.make('Current Transaction Date', 'oldTxnDate', {disable: true});

    newCycleField: PicklistField = FormPicklistComponent.make('New Cycle', 'txnCycleId', null,
        { service: this.cycleSvc }, { disable: true }
    );

    newPeriodField: Field = FormPicklistComponent.make('New Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        { disable: true }
    );

    newTxnDateField: Field = FormDateComponent.make('New Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' },
        validators: [validateTxnDate(this.newCycleField, this.newPeriodField)],
    });

    fields = [
        FieldMaker.idHolder('id'),
        FieldMaker.rev(),
        this.oldTxnDateField,
        this.oldCycleField,
        this.oldPeriodField,
        this.newTxnDateField,
        this.newCycleField,
        this.newPeriodField,
    ]

    constructor() {
        this.fieldSet.setFields(this.fields);
        this.fieldSet.setValue({
            id: this.bankIn.id,
            revision: this.bankIn.revision,
            oldTxnDate: this.bankIn.txnDate,
            oldTxnPeriodId: this.bankIn.txnPeriodId,
            oldTxnCycleId: this.bankIn.txnCycleId
        } as AbstractObject, false);
        this.ready = true;
    }

    saveChanges() {
        this.bankInSvc.put(this.fieldSet.getFormValue()).subscribe( response => {
            if (response) {
                this.dialogRef.close(response);
            }
        })
    }
}
