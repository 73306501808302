/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Unit } from 'src/app/model/unit';
import { Panel } from "./panel";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { SupplierPageComponent } from "../../supply/supplier-page/supplier-page.component";
import { PurchasePageComponent } from "../purchase-page/purchase-page.component";

export const purchasePanel: Panel = new Panel({
    type: 'purchases',
    icon: 'shopping_basket',
    takeAction(u: Unit) {
        console.warn(u);
    },
    setTitle() {
        this._title = this._items?.length + ' Unpaid purchase invoices'
    },
    setConfig() {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FormButtonComponent.makeDialogOpenButton('Invoice', 'refNr', 'id', PurchasePageComponent),
                    FormButtonComponent.makeDialogOpenButton('Supplier', 'supplierName', 'id', SupplierPageComponent),
                    FormTextComponent.make('Supplier Ref', 'reference'),
                    FormDateComponent.make('Date', 'txnDate'),
                    FormNumberComponent.makeCurrency('Total', 'credit'),
                    FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                    FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
                    FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                        calculateValue: () => 'Pay',
                    }),
                ],
            }),
            mode: 'list'
        })
    }
})
