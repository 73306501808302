<!--
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
-->

<mat-dialog-content [style.width.px]="width">
    <div class="dialogTitleBar primary-background-color ">
        <span>{{getTitle()}}</span>
    </div>
    <div style="float: right; position: relative; margin-top: -32px; margin-right: -22px">
        <button (click)="closeDialog()" class="primary-background-color" style="border:none">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <app-chat-followers [chatField]="data.chatField" [allowRemove]="false"></app-chat-followers>
    <div style="margin-left: 10px; margin-bottom:20px">
        <app-chat-reply [chatItem]="data.replyTo"></app-chat-reply>
    </div>
    <app-ctl-holder [control]="commentControl"></app-ctl-holder>
    <div>
        @if(data.chatField.chat.commentsMayNeedResponse) {
            <mat-checkbox [(ngModel)]="needsResponse" color="primary"
            matTooltip="If you check this box, the status of request will go to waiting, until you receive a response">Needs Response</mat-checkbox>
        }
        <button mat-button mat-raised-button color="primary" style="margin:10px" data-save-comment
            [disabled]="commentControl.pristine" (click)="saveComment()">
            <mat-icon>save</mat-icon>Send
        </button>
        @if(this.needsResponse) {
            <span>When you send comments, the request will go to status waiting until response is received</span>
        }
    </div>
</mat-dialog-content>
