/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Period } from 'src/app/model/period';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { DateHelper } from 'src/app/shared/dateHelper';

@Injectable({
    providedIn: 'root'
})
export class PeriodService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'budgets/periods';
    protected cache: Period[];
    protected typeString = 'Periods';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getPeriod(dateStr: string) : Period{
        if (!this.hasData) {
            const msg = $localize `Cannot getPeriod before period service retrieves data`
            this.messageService.showErrorMessage(msg);
            return null;
        }
        for (const period of this.cache) {
            if (DateHelper.isoDateIn(dateStr, period.from, period.to)) {
                return period;
            }
        }
        const msg = $localize`Period not found for date ${dateStr}`;
        this.messageService.showErrorMessage(msg);
        return null
    }
    
    getOpenPeriod(dateStr: string): Period {
        const prd = this.getPeriod(dateStr);
        if (!prd) {
            return null;
        }
        if (prd.statusId === Period.STATUS.OPEN.id) {
            return prd;
        } else {
            const msg = $localize`Period not open for date ${dateStr}`;
            this.messageService.showErrorMessage(msg);
            return null;
        }
    }

    block(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.CLOSED.id;
        return this.put(period).pipe(first());
    }

    unblock(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.OPEN.id;
        return this.put(period).pipe(first());
    }

    lock(period: Period): Observable<Period> {
        period.statusId = Period.STATUS.LOCKED.id;
        return this.put(period).pipe(first());
    }
}
