/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from "src/app/model/abstract-object";
import { ChartParms } from "src/app/model/sys/nav-item";
import { NumberField } from "../field/NumberField";
import { PicklistField } from "../field/PicklistField";
import { TrendField } from "../field/TrendField";
import { FieldSet } from "../form/field-set/field-set.component";
import { ChartHolder } from "./chart-holder";

export type ChartType = 'pie' | 'bar' | 'trend';

export type AppFilterNavigator = () => void;

export class ChartAnalysis {

    chartHolders: ChartHolder[] = [];
    chartParms: ChartParms[] = [];
    private totalWidth = 0;
    private items: AbstractObject[];
    private refresher: AppFilterNavigator;

    setupCharts(seedCharts: ChartParms[], fs: FieldSet) {
        if (seedCharts === null || seedCharts === undefined) {
            return;
        }
        if (seedCharts !== this.chartParms) {
            this.chartHolders = [];
            this.totalWidth = 0;
            this.chartParms = seedCharts;

            for (const c of seedCharts) {
                this.add(c, fs);
            }
        }
    }

    private add(cp: ChartParms, fieldSet: FieldSet) {
        const pl = fieldSet.getFieldByName(cp.picklist) as PicklistField;
        if (pl === null || pl === undefined) {
            console.error('Unknown Analysis Picklist : ' + cp.picklist, {cp, fieldSet});
        }
        const nr = cp.number ? fieldSet.getFieldByName(cp.number) as NumberField : undefined;
        const trend = cp.trend ? fieldSet.getFieldByName(cp.trend) as TrendField : undefined;
        this.addByField(cp.type, {picklist: pl, number: nr, trend: trend, excludeZero: cp.excludeZero});
    }


    private addByField(type: ChartType, options: {picklist?: PicklistField, number?: NumberField, trend?: TrendField, excludeZero: boolean}) {
        const ch = new ChartHolder(type).setPicklist(options.picklist).setMetric(options.number).setTrend(options.trend);
        ch.excludeZero = options.excludeZero;
        this.chartHolders.push(ch);
        this.totalWidth += ch.getWidth();
        return this;
    }

    refresh(items: AbstractObject[], refresher: AppFilterNavigator) {
        this.items = items;
        this.refresher = refresher;
        this.refreshAll();
    }

    refreshAll() {
        let total = 0;
        for (const ch of this.chartHolders) {
            ch.setRefresher(this.refresher);
            ch.setupData(this.items);
            total += ch.getWidth();
        }
        this.totalWidth = total;
    }

    removeChart(ch: ChartHolder) {
        this.totalWidth -= ch.getWidth();
        const idx = this.chartHolders.indexOf(ch);
        this.chartHolders.splice(idx, 1);
        setTimeout(() => this.refreshAll(), 200);
        //this.refreshAll();
    }

    getWidth() {
        return this.totalWidth;
    }
}
