/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';

import { catchError, first, map } from 'rxjs/operators';

import { HttpClient } from '@angular/common/http';

import { Post } from 'src/app/model/post';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';

/* Injecting in root makes this a shared singleton class, one per app not per component*/
@Injectable({
  providedIn: 'root'
})

export class PostService extends AbstractHttpService {

  posts: Post[] = [];

  protected baseUrl = this.ajaxPath + 'social/posts';

  protected cache; // Not Used
  protected typeString = 'Post';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }

  notify(object: Post) {
    const url = `${this.baseUrl}/notify/${object.id}/${object.revision}`;
    return this.http.post(url, object, this.httpOptions).pipe(
      map((data) => {
        if (data) {
            object.mailsSent = (data as Post).mailsSent;
          this.messageService.show($localize`OurOMC will send notification mail to users and a confirmation mail to you once completed.`);
        }
        return data;
      }),
      catchError(this.handleError('put' + this.typeString))
    ).pipe(first()).subscribe();
  }



  /* Leaving this here as an example of pagination, believe I need it later...
    getMorePosts(page: number, perPage: number): Observable<Post[]> {
      const url = `${this.baseUrl}`; //?page=${page}&per_page=${perPage}`;

      return this.http.get<Post[]>(url)
      .pipe(
        map((data: Post[]) => {
          data.forEach(post => {
            this.posts.push(post);
          });
          return this.posts;
        }),
        tap(_ => console.log('fetched posts')),
        catchError(this.handleError<Post[]>('getPosts', []))
      );
    }

    getPosts(): Observable<Post[]> {
      return this.http.get<Post[]>(this.baseUrl)
      .pipe(
        map((data: Post[]) => {
          this.hasData = true;
          this.posts = data;
          return this.posts;
        }),
        tap(_ => console.log('fetched posts')),
        catchError(this.handleError<Post[]>('getPosts', []))
      );
    }

    getPost(id: uuid, forEdit = false): Observable<Post> {
      const thePost = this.posts.find(post => post.id === id);
      if (thePost && !forEdit) {
        // Should we trust local cache or get it every time?
        return of(thePost);
      } else {
        let url = `${this.baseUrl}/${id}`;
        if (forEdit) {
          url = url + '?context=edit';
        }
        return this.http.get<Post>(url, this.httpOptions)
        .pipe(
          map((data: Post) => data),
          tap(_ => console.log('fetched post ' + id)),
          catchError(this.handleError<Post>('getPostId' + id, new Post()))
        );
      }
    }

    getPostExcerpt(post: Post) {
        if (post.excerpt && post.excerpt.rendered) {
          return post.excerpt.rendered.replace(/<[^>]+>/gm, '');
        } else {
          return '"We have no excerpt for this post...';
        }
    }

    postPost(post: Post): Observable <Post> {
      return this.http.post<Post>(this.baseUrl, post, this.httpOptions)
        .pipe(
          map((data: Post) => {
            this.posts.unshift(data);
            return data;
          }),
        tap( (data: Post) => {
          this.messageService.show(this.msgAdded);
        }),
        catchError(this.handleError<Post>('postPost'))
      );
    }

    putPost(post: Post): Observable <Post> {
      return this.http.put<Post>(`${this.baseUrl}/${post.id}`, post, this.httpOptions)
        .pipe(
          map((data: Post) => {
            this.posts.forEach( (obj, ndx, ary) => {
              if (obj.id === data.id) {
                this.posts[ndx] = data;
              }
            });
            return data;
          }),
        tap( (data: Post) => {
          this.messageService.show(this.msgUpdated);
        }),
        catchError(this.handleError<Post>('putPost'))
      );
    }
  */
}


