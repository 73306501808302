/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

export class OmcRole {
    static readonly manager = { id: 'manager', name: "Property Manager" };
    static readonly admin = { id: 'admin', name: $localize`Administrator` };
    static readonly finance = { id: 'finance', name: $localize`Finance` };
    static readonly caretaker = { id: 'admin', name: $localize`Caretaker` };

    static values = [this.manager, OmcRole.admin, OmcRole.finance, OmcRole.caretaker];
}
