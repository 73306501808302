/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Omc } from 'src/app/model/Omc';
import { Observable, of } from 'rxjs';
import { uuid } from 'src/app/model/abstract-object';
import { catchError, map } from 'rxjs/operators';
import { Team } from 'src/app/model/team';

@Injectable({
    providedIn: 'root'
})
export class OmcService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'agent/omcs';
    protected cache: Omc[];
    protected typeString = 'OMC';

    protected cacheAgentOmcs: Map<uuid, Omc[]> = new Map();

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getAvailable(agentId: uuid): Observable<Omc[]> {
        if (this.cacheAgentOmcs.has(agentId)) {
            return of(this.cacheAgentOmcs.get(agentId));
        }
        return this.http.get<Omc[]>(this.baseUrl + '/available/' + agentId).pipe(map(omcs => {
            this.cacheAgentOmcs.set(agentId, omcs);
            return omcs;
        }), catchError(this.handleError('getAvailableOmcsForAgent', []))) as Observable<Omc[]>;
    }

    getInvites() : Observable<Team[]> {
        return this.http.get<Team[]>(this.baseUrl + '/omcInvites');
    }

    acceptInvites(omcTeam: Team): Observable<Omc> {
        return this.http.post<Omc>(this.baseUrl + '/omcInvites', omcTeam).pipe(map(omc => {
            return omc;
        }), catchError(this.handleOneError('getAvailableOmcsForAgent', []))) as Observable<Omc>;
    }
}
