/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { formatCurrency, CurrencyPipe } from '@angular/common';
import { Cycle } from 'src/app/model/cycle';
import { Unit } from 'src/app/model/unit';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { PayzService } from '../../payzService';
import { MatButtonModule } from '@angular/material/button';
import { FormNumberComponent as FormNumberComponent_1 } from '../../../../shared/form/form-number/form-number.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-part-pay',
    templateUrl: './part-pay.component.html',
    styleUrls: ['./part-pay.component.scss'],
    standalone: true,
    imports: [MatCardModule, FormNumberComponent_1, MatButtonModule, CurrencyPipe]
})
export class PartPayComponent {

    @Input() unit: Unit
    @Input() cycle: Cycle;

    otherPayField = FormNumberComponent.make('Enter the amount you would like to pay and then press the pay now button', 'other',
        { format: 'currency', width: 9, formatParms: '1.2-2' }
    );

    otherPayCtl = this.otherPayField.makeControl() as AppFormControl;

    constructor(private payzSvc: PayzService, private cds: ConfirmDialogService) { }

    partPay() {
        const amount = this.otherPayCtl.value;
        const amountDisp = formatCurrency(amount, 'EN-ie', 'EUR');

        this.cds.open($localize`Make Payment`, $localize`Do you wish to connect to stripe and make a payment of ${amountDisp}. `, () => {
            this.payzSvc.payNow(this.unit.ownerId, this.unit.id, null, amount);
        });
    }

}
