<mat-tab-group>
    <mat-tab>
        <ng-template mat-tab-label>
            @if (bankItem && selectedList.size > 0 && diffValue === 0) {
                <button mat-raised-button color="primary" (click)="reconcile()">
                    Mark {{selectedList.size}} Reconciled
                </button>
            } @else if(selectedList.size === 0) {
                <mat-icon>receipt_long</mat-icon>{{txnList?.length}} Transactions to reconcile
            } @else {
                {{selectedList.size}} Selected, value: {{matchValue | currency}}
                @if(bankItem) {
                    difference: {{diffValue | currency}}
                } @else {
                    select bank item on left
                }
            }

        </ng-template>
        <ng-template matTabContent>
            <div style="height:calc(100vh - 250px)">
                @if(txnList) {
                    <app-table [config]="config" [drilldown]="false" [dataSet]="txnList"></app-table>
                }
            </div>
        </ng-template>
    </mat-tab>
</mat-tab-group>

