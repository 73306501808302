/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { RboChart } from "./RboChart";

export class RboMyOmcChart extends RboChart {
    title = "My Busiest OMCs by Request";
    dataParams = new HttpParams().set('week', 0).set('show', 'myomcs');
}
