/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component, Inject, Optional } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { BCode } from 'src/app/model/bcode';
import { Field } from 'src/app/shared/field/Field';
import { PreferredSupplier } from 'src/app/model/supplier';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormUrlComponent } from 'src/app/shared/form/form-url/form-url.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { BCodeService } from '../../budget/bcode.service';
import { ScheduleService } from '../../budget/schedule.service';
import { PreferredSupplierService } from '../preferred-supplier.service';
import { SupplierService } from '../supplier.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { CurrentUserService } from '../../user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { uuid } from 'src/app/model/abstract-object';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { maxChars, minChars } from 'src/app/shared/validators';
import { Schedule } from 'src/app/model/schedule';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';

@Component({
    selector: 'app-preferred-supplier-page',
    templateUrl: './preferred-supplier-page.component.html',
    styleUrls: ['./preferred-supplier-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class PreferredSupplierPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('suppliers/preferred', PreferredSupplierPageComponent, 'favorite');
    validBCodes: BCode[] = [];
    schedules: Schedule[] = [];

    transactionsField = new GridField({
        field: {
            label: $localize`Transactions`, value: 'txnWithPayments',
            readonly: true, visible: Field.formOnly, formRow: 2, sendServer: false
        }, rowFactory: (o: Txn) => [
            FormButtonComponent.makeLink('Ref', 'refNr', Txn.getTxnLink(o)),
            FormPicklistComponent.make('Type', 'typeId', 'type',
                { items: Txn.TYPES }, { cellOpts: { width: '12em' }, readonly: true }
            ),
            FormDateComponent.make('Date', 'txnDate', { cellOpts: { width: '8em' }, readonly: true }),
            FormButtonComponent.make('Approved', 'approvedAt', {
                type: 'icon', sendServer: false, label: 'Approved',
                cellOpts: { heading: '', width: '1%', style: 'height: 74px, width: 32px, padding: 0px 0px 0px 0px;' },
                calculateValue: (o: Txn) => o.approvedAt ? 'done' : ''
            }),
            FormNumberComponent.makeCurrency('Credit', 'credit', { readonly: true }),
            FormNumberComponent.makeCurrency('Debit', 'debit', { readonly: true }),
            FormNumberComponent.makeCurrency('Outstanding', 'outstanding', { readonly: true }),
        ],
    });

    templateField = new GridField({
        field:
            { label: $localize`Template Transaction`, value: 'template', visible: Field.formOnly, formRow: 2, readonly: true },
        rowFactory: () => [
            FieldMaker.id(),
            FieldMaker.rev(),
            FormPicklistComponent.make('Account', 'bCodeId', null,{ items: this.validBCodes }),
            FormPicklistComponent.make('Schedule', 'scheduleId', null,
                { items: this.schedules }
            ),
            FieldMaker.notes({ cellOpts: { width: '50%' } }),
            FieldMaker.deleteGridRow(),
        ],
    });

    nameField = FormTextComponent.make('Name', 'name', {
        hint: 'The name of the company or sole trader who supplies you a service'
    });
    contactField = FormTextComponent.make('Contact Person', 'contactPerson', {
        hint: 'The name of the person you deal with in the company'
    });
    addressField = FormTextAreaComponent.make('Address', 'address', { formColumn: 1, visible: Field.formOnly  });
    phoneField = FormPhoneComponent.make('Telephone', 'phone', { formColumn: 2, hint: 'Your normal contact phone number' });
    emailField = FormEmailComponent.make('Email', 'email', { formColumn: 2, hint: 'Your normal contact email address' });
    urlField = FormUrlComponent.make('Web URL', 'web', { formColumn: 3,  });

    emergencyPhoneField = FormPhoneComponent.make('Emergency Number', 'emergencyPhone', {
        formColumn: 2, hint: 'Phone number to use in emergencies', visible: Field.formOnly
    });
    accountsEmailField = FormEmailComponent.make('Email Accounts', 'emailAccounts', {
        formColumn: 2, hint: 'Email address to use for accounts queries', visible: Field.formOnly
    });
    vatField = FormTextComponent.make('VAT', 'VAT', {
        formColumn: 3, validators: [maxChars(12), minChars(8)], visible: Field.formOnly,
        hint: 'If supplier is registered for VAT, you should enter their VAT number here'
    });
    trnField = FormTextComponent.make('Tax Registration Number', 'taxRegistrationNr',
        {
            formColumn: 3, validators: [maxChars(12), minChars(8)], visible: Field.formOnly,
            hint: 'If a supplier is not registered for VAT, you still need to report them on form 46G, so you need their tax registration number. For sole traders, this is often their PPS number'
    });
    dunsField = FormTextComponent.make('DUNS', 'DUNS', { formColumn: 3, validators: [maxChars(9)],
        hint: 'A DUNS Number is a unique Dun & Bradstreet identifier that can be used to obtain additional company information, e.g. credit checks etc.',
        visible: Field.formOnly
    });

    codeField = FormTextComponent.make('Code', 'code', {
        formColumn: 4,
        hint: 'A unique code used by te agent to identify a supplier.',
        visible: Field.formOnly
    });

    bicField = FormTextComponent.make('BIC', 'BIC', { formColumn: 4, validators: [maxChars(11), minChars(7)], visible: Field.formOnly });
    ibanField = FormTextComponent.make('IBAN', 'IBAN', { formColumn: 4, validators: [maxChars(34), minChars(16)], visible: Field.formOnly });
    bankField = FormTextComponent.make('Bank Account Name', 'bankAccountName', { formColumn: 4, validators: [maxChars(40)], visible: Field.formOnly });
    bankDetailField = FormTextAreaComponent.make('Bank Name & Address', 'bankNameAddress', { formColumn: 5, visible: Field.formOnly });

    configReady = new ReplaySubject<null>(1);

    config = new FormConfig({
        navRoute: PreferredSupplierPageComponent.navRoute,
        title: $localize`Preferred Supplier`,
        help: $localize`The list of preferred suppliers that the OMC does business with`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.nameField,
                this.contactField,
                this.addressField,
                this.phoneField,
                this.emailField,
                this.emergencyPhoneField,
                this.accountsEmailField,
                this.urlField,
                this.vatField,
                this.trnField,
                this.dunsField,
                this.codeField,
                this.bicField,
                this.ibanField,
                this.bankField,
                FieldMaker.notes({ formColumn: 5, visible: Field.formOnly }),
                this.bankDetailField,
            ],
            formLayout: [{ cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] },
            { cells: [{ colspan: 5, pageTab: 'yes' }] }],
        }),
        service: this.dataSvc,
        mode: 'list',
        beforeEdit: this.beforeEdit.bind(this),
        objectFactory: this.objectFactory.bind(this),
        allowNew: false,
        allowEdit: false,
        allowDelete: false,
        configReady: this.configReady,
    });

    constructor(public dataSvc: PreferredSupplierService, supplierSvc: SupplierService,
        scheduleSvc: ScheduleService, currentUserSvc: CurrentUserService,
        bCodeSvc: BCodeService,
        @Optional() public dialogRef: MatDialogRef<PreferredSupplierPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();

        currentUserSvc.getCurrentUser().subscribe(user => {
            this.currentUser = user;
            if (this.currentUser.inAgency) {
                console.log('In Agency, no transactions...');
                // I should have two seperate pages here, but for now...
                this.dataSvc = supplierSvc as unknown as PreferredSupplierService;
                this.config.allowEdit = true;
                this.config.allowEdit = true;
                this.config.allowNew = true;
            } else {
                this.config.fieldSet.fields.push(this.transactionsField);
                this.config.fieldSet.fields.push(this.templateField);
                bCodeSvc.get(false, new HttpParams().set('typeId', BCode.TYPE.EXPENSE.id + ',' + BCode.TYPE.ASSET.id)).subscribe(bcodes => {
                    for (const c of bcodes) {
                        this.validBCodes.push(c as BCode);
                    }
                });
                scheduleSvc.get(true).subscribe ( ss => {
                    for (const s of ss) {
                        this.schedules.push (s as Schedule);
                    }
                })
            }
            this.configReady.next(null)
        })
    }

    objectFactory(): Observable<PreferredSupplier> {
        return of(new PreferredSupplier());
    }

    beforeEdit(o: PreferredSupplier): PreferredSupplier {
        const payMap: Map<uuid, Txn> = new Map();
        o.txnWithPayments = [];
        for (const txn of o.transactions) {
            if (txn.typeId === Txn.TYPE.BANK_OUT_ALLOC.id) {
                if (!payMap.has(txn.parentId)) {
                    const p = new Txn();
                    p.id = txn.parentId;
                    p.typeId = Txn.TYPE.BANK_OUT.id;
                    p.txnDate = txn.txnDate;
                    p.debit = txn.debit;
                    p.refNr = txn.parent.refNr;
                    payMap.set(txn.parentId, p);
                    o.txnWithPayments.push(p);
                } else {
                    payMap.get(txn.parentId).debit += txn.debit;
                }
            } else {
                o.txnWithPayments.push(txn);
            }
        }
        return o;
    }
}
