/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { minChars, minWords, required } from 'src/app/shared/validators';
import { RequestCategoriesService } from '../request-categories.service';
import { RequestPrioritiesService } from '../request-priorities.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { UserService } from '../../user/user.service';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { RequestService } from '../request.service';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { MatButtonModule } from '@angular/material/button';
import { CurrentUserService } from '../../user/current-user.service';
import { AbstractObject } from 'src/app/model/abstract-object';

@Component({
  selector: 'app-simple-request',
  standalone: true,
  imports: [FieldSetComponent, MatDialogModule, MatButtonModule],
  templateUrl: './simple-request.component.html',
  styleUrl: './simple-request.component.scss'
})
export class SimpleRequestComponent {

    private dialogRef = inject(MatDialogRef<SimpleRequestComponent>);
    public data: Partial<ServiceRequest> = inject(MAT_DIALOG_DATA);

    categorySvc = inject(RequestCategoriesService);
    prioritySvc = inject(RequestPrioritiesService);
    teamUserSvc = inject(UserService);
    requestSvc = inject(RequestService);
    currentUserSvc = inject(CurrentUserService);


    titleField = FormTextComponent.make('Title - A shortname for this request', 'title',
        { validators: [required, minChars(10)], formColumn: 2 }
    );
    descField = FormTextAreaComponent.make('Description', 'description',
        { validators: [required, minWords(3)], formColumn: 2 }
    );

    categoryField = FormPicklistComponent.make('Category', 'categoryId', null, { service: this.categorySvc});
    priorityField = FormPicklistComponent.make('Priority', 'priorityId', null, { service: this.prioritySvc});
    plannedDate = FormDateComponent.make('Planned Date', 'plannedDate');
    ownedByField = FormPicklistComponent.make('Owned By', 'ownedById', 'ownedBy', { });

    ready = false;

    fieldSet = new FieldSet({
        fields: [
            FieldMaker.id(),
            FieldMaker.rev(),
            FieldMaker.idHolder('teamId'),
            FieldMaker.idHolder('omcId'),
            FieldMaker.idHolder('agentTeamId'),
            FieldMaker.idHolder('ballots'),
            this.titleField,
            this.descField,
            this.categoryField,
            this.priorityField,
            this.plannedDate,
            this.ownedByField,
        ], formLayout: [{ cells: [{ width: '10%' }, { width: '90%' }] }],
    })

    constructor() {
        this.teamUserSvc.getAgentUsers().subscribe( users => this.ownedByField.setPicklistItems(users));
        this.currentUserSvc.getCurrentUser().subscribe( x => {
            if (!this.data.id) {
                const sr = new ServiceRequest();
                sr.teamId = x.currentTeam.id;
                sr.omcId = x.inOmc.id;
                sr.agentTeamId = x.inOmc.teamId;
                Object.assign(this.data, sr);
            }
            this.fieldSet.setValue(this.data as AbstractObject);
            this.ready = true
        })
    }

    save() {
        if (this.data.id) {
            this.requestSvc.put(this.fieldSet.getFormValue()).subscribe(updatedSR => {
                this.dialogRef.close(updatedSR);
            })
        } else {
            this.requestSvc.post(this.fieldSet.getFormValue()).subscribe(newSR => {
                this.dialogRef.close(newSR);
            })
        }
    }
}
