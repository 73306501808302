import { ImportRow } from "../ImportRow";
import { ImportPerson, ImportRole, NameSplitter } from "../NameSplitter";

/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
export class BMUtils {

    getOwnerDetails(i: ImportRow, people: ImportPerson[]) {
        const role: ImportRole = i.getValue('ownerOccupied') ? 'ownerOccupier' : 'owner';
        const phone = i.getStringValue('ownerPhone');
        const email = i.getStringValue('ownerEmail');
        let address = i.getStringValue('ownerAddress');
        if (role !== 'owner') {
            address = null;
        }
        NameSplitter.splitName(people, i.getStringValue('ownerName'), phone, email, role, address);
    }

    getKeyholderDetails(i: ImportRow, people: ImportPerson[]) {
        const name = i.getStringValue('keyholderName');
        if (name && name.trim()) {
            const role: ImportRole = 'keyholder';
            const phone = i.getStringValue('keyholderPhone');
            const email = i.getStringValue('keyholderEmail');
            NameSplitter.splitName(people, name, phone, email, role);
        }
    }

    getKeyholder2Details(i: ImportRow, people: ImportPerson[]) {
        const name = i.getStringValue('keyholder2Name');
        if (name && name.trim()) {
            const role: ImportRole = 'keyholder';
            const phone = i.getStringValue('keyholder2Phone');
            const email = i.getStringValue('keyholder2Email');
            NameSplitter.splitName(people, name, phone, email, role);
        }
    }

    getTenantDetails(i: ImportRow, people: ImportPerson[]) {
        const name = i.getStringValue('tenantName');
        if (name && name.trim()) {
            const role: ImportRole = 'tenant';
            let phone = i.getStringValue('tenantPhone');
            if (i.getStringValue('otherTenant')) {
                phone += + "\n" + i.getStringValue('otherTenant');
            }

            const email = i.getStringValue('tenantEmail');
            NameSplitter.splitName(people, name, phone, email, role);
        }
    }
}
