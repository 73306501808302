/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { MatDialog } from "@angular/material/dialog";
import { combineLatest } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { RequestTemplate } from "src/app/model/RequestTemplate";
import { AbstractObject } from "src/app/model/abstract-object";
import { Omc } from 'src/app/model/Omc';
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { first, switchMap } from "rxjs/operators";
import { GridField } from "src/app/shared/grid/grid-field";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { Frequency } from "src/app/model/frequency";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { GridDialogComponent } from "src/app/shared/dialogs/grid-dialog/grid-dialog.component";
import { Field } from "src/app/shared/field/Field";
import { RequestTemplatesService } from "../request-templates.service";
import { RecurringJobService } from "./recurring-job.service";
import { HttpParams } from "@angular/common/http";
import { RecurringJob } from "src/app/model/recurring-job";
import { OmcService } from "../../agent/omc.service";
import { IsNarrowService } from "src/app/shared/is-narrow.service";
import { RequestService } from "../request.service";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";

type rjaType = 'omc'|'template';
export class AddRecurringJobsAction implements IFormAction {
    name = 'Add Recurring Jobs';
    color: ActionColor = 'primary';
    show = false;
    icon = 'event_repeat';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Not Required';
    tipText?: string;
    omc: Omc;
    template: RequestTemplate;

    dialog = MyInjector.instance.get(MatDialog);
    tmplService = MyInjector.instance.get(RequestTemplatesService);
    jobService = MyInjector.instance.get(RecurringJobService);
    omcService = MyInjector.instance.get(OmcService);
    requestService = MyInjector.instance.get(RequestService);
    isNarrowSvc = MyInjector.instance.get(IsNarrowService);
    cds = MyInjector.instance.get(ConfirmDialogService);

    templates: RequestTemplate[] = [];
    omcs: Omc[] = [];


    constructor(private type: rjaType) {}

    getLinkFields() {
        if (this.type === 'omc') {
            return [
                FieldMaker.idHolder('omcId'),
                FormPicklistComponent.make('Template', 'templateId', 'template', { items: this.templates }, { readonly: true }),
            ]
        } else {
            return [
                FieldMaker.idHolder('templateId'),
                FormPicklistComponent.make('Omc', 'omcId', 'omc', { items: this.omcs }, { readonly: true }),
            ]
        }
    }
    rowFactory(rj: RecurringJob) {

        const nextDateField = FormDateComponent.make('Next Needs To Be Planned', 'nextOccurence', {
            cellOpts: {width: '8em'},
        });
        if (!rj?.frequencyId) {
            nextDateField.override({readonly: true});
        }

        return [
            FieldMaker.id(),
            FieldMaker.idHolder('teamId'),
            ...this.getLinkFields(),
            FormPicklistComponent.make('Frequency', 'frequencyId', 'frequency',
                { items: Frequency.ALL_OPTIONS,
                  allowSelectNone: true,
                  refreshes: [o => {
                    const ro = o.id ? false : true;
                    nextDateField.readonly = ro;
                    if (ro) {
                        nextDateField.control.setValue(null, {emitEvent: false});
                    }
                  }
                ]},
            ),
            nextDateField,
            FieldMaker.notes({formColumn: 4, cellOpts: {width: '50%'}, visible: Field.noPhone}),
        ];
    }

    gridField = new GridField({
        field: { value: 'jobs', cellOpts: { heading: 'Recurring Jobs' }},
        rowFactory: this.rowFactory.bind(this)
    });

    //action: (AbstractObject: any, FormConfig: any) => Observable<AbstractObject>;
    makeJob(forItem: RequestTemplate | Omc) {
        const newJob = new RecurringJob();
        const o = (this.type === 'omc' ? this.omc : forItem) as Omc;
        const rt = (this.type === 'omc' ? forItem : this.template) as RequestTemplate;

        newJob.omcId = o.id;
        newJob.omc = o;
        newJob.templateId = rt.id;
        newJob.template = rt;
        newJob.teamId = o.omcTeamId;
        newJob.template = rt;
        newJob.frequencyId = rt.frequencyId;
        newJob.frequency = rt.frequency
        return newJob;
    }

    actionForOmc() {
        const jobParms = new HttpParams().set('omcId', this.omc.id);
        combineLatest([this.tmplService.get(true), this.jobService.get(false, jobParms)])
            .pipe(first()).subscribe(([templates, jobs]) => {
                this.templates = templates as RequestTemplate[];
                let isDirty = false;
                const newJobs = [];

                this.templates.forEach(t => {
                    const exist = jobs.find(j => j['templateId'] === t.id);
                    if (!exist) {
                        const j = this.makeJob(t);
                        if (j.frequencyId) {
                            isDirty = true;
                        }
                        newJobs.push(j);
                    }
                });

                const sortedJobs = newJobs.sort((a, b) => this.jobSorter(a, b));

                //this.gridField.makeControl();
                this.gridField.setValue({ jobs: sortedJobs } as unknown as AbstractObject, false);
                if (isDirty) {
                    this.gridField.control.markAsDirty();
                }
            }
        );
    }

    actionForTemplate() {
        const jobParms = new HttpParams().set('templateId', this.template.id);
        combineLatest([this.omcService.get(true), this.jobService.get(false, jobParms)])
            .pipe(first()).subscribe(([omcs, jobs]) => {
                this.omcs = omcs as Omc[];
                let isDirty = false;
                const newJobs = [];

                this.omcs.forEach(o => {
                    const exist = jobs.find(j => j['omcId'] === o.id);
                    if (!exist) {
                        const j = this.makeJob(o);
                        if (j.frequencyId) {
                            isDirty = true;
                        }
                        newJobs.push(j);
                    }
                });

                const sortedJobs = newJobs.sort((a, b) => this.jobSorter(a, b));

                //this.gridField.makeControl();
                this.gridField.setValue({ jobs: sortedJobs } as unknown as AbstractObject, false);
                if (isDirty) {
                    this.gridField.control.markAsDirty();
                }
            }
            );
    }

    action() {
        if (this.type === 'omc') {
            this.actionForOmc();
        } else {
            this.actionForTemplate();
        }

        const dConfig = {
            data: {
                gridField: this.gridField,
                saveFn: this.save.bind(this)
            },
            disableClose: true
        }

        return this.dialog.open(GridDialogComponent, dConfig).afterClosed();
    }

    jobSorter(a: RecurringJob, b: RecurringJob) {
        const after = 1;
        const before = -1;
        const equal = 0;

        if (a.id) {
            if (b.id) {
                //sort by planned Date
                if (a.nextOccurence == null) {
                    if (b.nextOccurence == null) {
                        return equal;
                    } else {
                        return before;
                    }
                } else if (b.nextOccurence && a.nextOccurence) {
                    return a.nextOccurence >= b.nextOccurence ? after : before
                } else {
                    return equal;
                }
            } else {
                return before;
            }
        } else {
            if (b.id) {
                return after;
            } else {
                // sort alphabetically
                if (this.type === 'omc') {
                    return a.template.name >= b.template.name ? after : before
                } else {
                    return a.omc.name >= b.omc.name ? after : before
                }

            }
        }
    }

    setup(parent) {
        if (!this.isNarrowSvc.isPhone() && (this.type === 'template' || parent.omcTeamId)) {
            this.show = true;
        } else {
            this.show = false;
        }

        if (this.type === 'omc') {
            this.omc = parent;
            this.gridField.label = "Add recurring jobs to " + parent.name;
            this.name = "Setup Jobs";
            this.tipText = "Create list of recurring jobs need to be done for this OMC";
        } else {
            this.template = parent;
            this.name = "Setup Jobs";
            this.tipText = "Add recurring jobs to OMCs who do not already have this template setup";
            this.gridField.label = "Add " + parent.name + " recurring job to OMCs ";
        }
    }

    save() {
        const items = this.gridField.getFormValue() as RecurringJob[];
        const itemsToSave = items.filter(o => o.frequencyId !== null);
        const cnt = itemsToSave.length;

        const title = $localize`Save ${cnt} Jobs`;
        const msg = $localize`Create a new recurring jobs for all jobs where a frequency has been entered `;

        return this.cds.openConfirm(title, msg).pipe (switchMap( response => {
            if (response === 'OK') {
                return this.jobService.postItems(itemsToSave).pipe (
                    switchMap( () => {
                        if (this.type === 'omc') {
                            return this.omcService.getOne(this.omc.id, null)
                        } else {
                            return this.tmplService.getOne(this.template.id, null);
                        }
                    })
                );
            }
        }));
    }
}
