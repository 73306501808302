/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { RequestPriority } from 'src/app/model/RequestPriority';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { required } from 'src/app/shared/validators';
import { RequestPrioritiesService } from '../request-priorities.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';

@Component({
    selector: 'app-request-priorities-page',
    templateUrl: './request-priorities-page.component.html',
    styleUrls: ['./request-priorities-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RequestPrioritiesPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/prios', RequestPrioritiesPageComponent, 'low_priority');

    config = new FormConfig({
        navRoute: RequestPrioritiesPageComponent.navRoute,
        title: $localize`Request Priority`,
        help: $localize`Priority for dealing with requests`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required] }),
                FormCheckboxComponent.make('Default', 'defaultPrio', {hint: 'The default priority to assign to requests. Only one can be the default. To change the default, first remove default flag from current default, then you can save another entry as default'}),
                FieldMaker.notes()
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new RequestPriority()),
    });

    constructor(public dataSvc: RequestPrioritiesService) {
        super();
    }
}
