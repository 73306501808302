/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { inject, Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AbstractObjectList } from "src/app/model/abstract-object";
import { User } from "src/app/model/user";
import { CurrentUserService } from "../../user/current-user.service";
import { ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { GeneralParser } from "./GeneralParserService";
import { ParseOptionBase } from "./ParseOptionBase";
import { ParseOptionBaseMapped } from "./ParseOptionBaseMapped";
import { POBValidators, requiredNumber, validateDatePOB } from "./POBValidators";
import { UnitService } from "../../unit/unit.service";
import { ImportRow } from "../ImportRow";   
import { ImportField } from "../ImportField";
import { BCodeService } from "../../budget/bcode.service";
import { ScheduleService } from "../../budget/schedule.service";
import { ArTxnService } from "../../txn/ar-txn.service";
import { PeriodService } from "../../budget/period.service";
import { Period } from "src/app/model/period";

@Injectable({
    providedIn: 'root'
})
export class ServiceChargeParserService implements ImportParser {

    gps = new GeneralParser();
    unitSvc = inject(UnitService);
    currentUserSvc = inject(CurrentUserService);
    bCodeSvc = inject(BCodeService);
    scheduleSvc = inject(ScheduleService);
    arSvc = inject(ArTxnService);
    periodSvc = inject(PeriodService);

    periods : Period[] = [];

    importDoc: ImportDoc;
    currentUser: User;

    unitNamePOB = new ParseOptionBaseMapped('Unit', 'unitId', [POBValidators.requiredPOB()]).makeSilent();
    txnDatePOB = new ParseOptionBase('Transaction Date', 'txnDate')
        .addValidator(validateDatePOB('DD/MM/YYYY'))
        .addValidator({
        validateRow: (field: ImportField) => {
            const val = field.getValue();
            const prd = this.periods.find( p => p.to >= val && p.from <= val);
            if (!prd) {
                return $localize` There is no period for date ${val}`
            } if (prd.statusId !== Period.STATUS.OPEN.id) {
                return $localize` Period ${prd.name} is not open for transactions`;
            }
            return null;
        }
    });
    notesPOB = new ParseOptionBase('Note', 'notes');
    bCodePOB = new ParseOptionBaseMapped('Budget Code', 'bCodeId', [POBValidators.requiredPOB()]);
    schedulePOB = new ParseOptionBaseMapped('Schedule', 'scheduleId', [POBValidators.requiredPOB()]);
    amountPOB = new ParseOptionBase('Amount', 'amount').addValidator(requiredNumber);
    
    availableImportItems = [
        this.unitNamePOB,
        this.txnDatePOB,
        this.notesPOB,
        this.bCodePOB,
        this.schedulePOB,
        this.amountPOB,
    ];

    setUp() {
        this.importDoc = new ImportDoc();

        return forkJoin({
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            units: this.unitSvc.get(true),
            bCodes: this.bCodeSvc.get(true),
            schedules: this.scheduleSvc.get(true),
            periods: this.periodSvc.get(true)
        }).pipe(map(result => {
            this.currentUser = result.currentUser;
            this.periods = result.periods as Period[];
            this.unitNamePOB.setOptions(result.units, this.importDoc),
            this.bCodePOB.setOptions(result.bCodes, this.importDoc);
            this.schedulePOB.setOptions(result.schedules, this.importDoc);
            return true;
        }));
    }

    parseRows(rows: unknown[][]): ImportDoc {
        this.gps.availableImportItems = this.availableImportItems;
        this.gps.parseRows(rows, this.importDoc);
        return this.importDoc;
    }

    postToServer(): Observable<AbstractObjectList> {
        const postItems = this.gps.getItemsToPost((row: ImportRow) => {
            const u = {id: null, revision: null};
            console.warn('Row ignored', {row});
            /*
            const exists = this.units.find( u => u.name === row.getValue(this.unitNamePOB.fieldName));
            if (exists) {
                u.id = exists.id;
                u.revision = exists.revision;
            }
            */
            return u;
        });
        console.log(postItems);
    
        return this.arSvc.postItems(postItems);
    }
}
