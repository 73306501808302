/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/
import { Params } from '@angular/router';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';

import * as Highcharts from 'highcharts';
import { HttpParams } from '@angular/common/http';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';

class sraData {

    startDate: string;
    startTime: number;

    endDate: string;
    endTime: number;

    rows: { status: string; items: number }[];
}

export class SraChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/sraAll';
    dataParams = new HttpParams().set('week', 0).set('owner', 'ownedBy');

    colors: string[] = [];

    title = "My Service Requests";

    series: { name: string, y: number, pct: number, route: NavRoute, routeParams: Params }[] = [];
    startDate = '';
    startTime: number;

    endDate = '';
    endTime: number;

    options = [
        { name: 'Previous Week', action: this.previousWeek.bind(this) },
        { name: 'Following Week', action: this.nextWeek.bind(this) }
    ];

    previousWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) - 1);
        this.getData();
    }

    resetChart() {
        this.colors = [];
        this.series = [];
    }

    nextWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) + 1);
        this.getData();
    }

    getSubTitle() {
        return super.getSubTitle('Week ' + this.startDate + ' to ' + this.endDate)
    }

    getRouteParams(state: string): Params {
        const routeParams = this.getBaseRouteParams(state);
        const owner = this.dataParams.get('owner');
        if (owner === 'ownedBy') {
            routeParams.ownedById = this.currentUser.id;
        } else if (owner === 'omc') {
            routeParams['omc.managerId'] = this.currentUser.id;
        }

        return routeParams;
    }

    getBaseRouteParams(state: string): Params {
        if (state === 'Planned') {
            this.colors.push('orange');
            return { subStatus: 'planned', srStatus: 'open' };
        } else if (state === 'Overdue') {
            this.colors.push('darkred');
            return { subStatus: 'overdue', srStatus: 'open' };
        } else if (state === 'Unplanned') {
            this.colors.push('red');
            return { subStatus: 'unplanned', srStatus: 'open' };
        } else if (state === 'Resolved') {
            this.colors.push('green');
            return { srStatus: 'resolved', updatedAt: '><' + this.startTime + ':' + this.endTime }
        } else if (state === 'Accepted') {
            this.colors.push('darkgreen');
            return { srStatus: 'accepted', updatedAt: '><' + this.startTime + ':' + this.endTime }
        }
    }

    /* REWORK STATES
    finishedStates() {
        return this.collateStates(this.currentUser.currentTeam.states.filter( o => o.workState > 0));
    }

    openStates() {
        return this.collateStates(this.currentUser.currentTeam.states.filter( o => o.workState <= 0));
    }

    collateStates(states: State[]) {
        let stateIds = '';
        states.map( oi => {
            if (stateIds.length > 0) {
                stateIds += ',';
            }
            stateIds += oi.id;
        });
        return stateIds;
    }

    getRouteParams(state: WorkState) : Params {
        const routeParams = this.getBaseRouteParams(state);
        const owner = this.dataParams.get('owner');
        if (owner === 'ownedBy') {
            routeParams.ownedById = this.currentUser.id;
        } else if (owner === 'omc') {
            routeParams['omc.managerId'] = this.currentUser.id;
        }

        return routeParams;

    }
    getBaseRouteParams(state: WorkState) : Params {
        if (state.id === State.newState.id) {
            return {planned: 0, stateId: this.openStates()};
        } else if (state.id === State.assignedState.id) {
            return {planned: 1, stateId: this.openStates()};
        } else {
            const s = this.currentUser.currentTeam.states.find( o => o.workState === state.id)
            return {stateId: s?.id,
                     updatedAt: '><'+this.startTime+':'+this.endTime};
        }
    }
REWORK STATES to here
*/
    protected setupData(data: sraData) {
        const route = ServiceRequestPageComponent.navRoute;
        this.startDate = data.startDate;
        this.startTime = data.startTime;
        this.endDate = data.endDate;
        this.endTime = data.endTime;

        let total = 0;
        for (const i of data.rows) {
            total += i.items;
        }
        //Simplified states...
        for (const i of data.rows) {
            if (i.items > 0) {
                const routeParams = this.getRouteParams(i.status);
                this.series.push({ name: i.status, y: i.items, pct: Math.round((i.items * 100) / total), route, routeParams })
            }
        }

        /* Rework States
        for (const i of data.rows.sort ( (a, b) => a.id - b.id) ) {
            const workState = ServiceRequest.srStates.find( o => o.id === i.status);
            this.colors.push(workState.color);
            const routeParams = this.getRouteParams(workState);
            this.series.push({name: i.status, y: i.items, pct: Math.round( (i.items * 100) / total), route, routeParams})
        }
        */
    }

    clickEvent($event) {
        this.router.navigate([$event.point.route.url, $event.point.routeParams]);
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            colors: this.colors,
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'pie',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            legend: { enabled: false },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                    dataLabels: {
                        enabled: false,
                        format: '{point.name}',
                        style: { fontSize: 14, textAlign: 'center' },
                        distance: -30
                    },
                    tooltip: {
                        pointFormat: '{point.name}: {point.y} <br/> {point.pct}%'
                    },
                }
            },
            series: [{
                name: 'Request Analysis',
                colorByPoint: true,
                data: this.series
            }]
        } as unknown as Highcharts.Options;
    }
}
