/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { combineLatest, of, ReplaySubject } from 'rxjs';
import { Field } from 'src/app/shared/field/Field';
import { Omc } from 'src/app/model/Omc';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { required } from 'src/app/shared/validators';
import { OmcService } from '../omc.service';
import { RequestTemplatesService } from '../../crm/request-templates.service';
import { NewButtonService } from 'src/app/shared/new-button/new-button.service';
import { NavOption } from 'src/app/model/sys/navOption';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from "src/app/shared/NavRoute";
import { ChartParms } from 'src/app/model/sys/nav-item';
import { UserService } from '../../user/user.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { User } from 'src/app/model/user';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { concatMap, first, map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewTeam } from './createNewTeam';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from '../../user/current-user.service';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { RecurringJobComponent } from '../../crm/recurring-job/recurring-job.component';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { ActionPageComponent } from '../../crm/action-page/action-page.component';
import { AddRecurringJobsAction } from 'src/app/modules/crm/recurring-job/add-recurring-jobs-action';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { DocumentPageComponent } from '../../crm/document-page/document-page.component';
import { BallotPageComponent } from '../../social/ballot-page/ballot-page.component';
import { MONTHLY_TYPE, RequestAnalysisComponent, WEEKLY_TYPE } from '../../crm/request-analysis/request-analysis.component';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { MailMergePageComponent } from '../mail-merge-page/mail-merge-page.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';

@Component({
    selector: 'app-omc-page',
    templateUrl: './omc-page.component.html',
    styleUrls: ['./omc-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class OmcPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('agent/omcs', OmcPageComponent, 'apartment').setCharts([
        { type: 'pie', picklist: 'managerId' } as ChartParms,
        { type: 'bar', picklist: 'managerId', number: 'expectedUnits' } as ChartParms,
        { type: 'pie', picklist: 'typeId' } as ChartParms,
    ]).setViews((u: User) => {
        return [
            {
                id: 'myoomcs',
                name: 'My Managed OMCs',
                filterFields: {
                    managerId: u.id,
                    name: null
                },
            },
            {
                id: 'myadminoomcs',
                name: 'My Administered OMCs',
                filterFields: {
                    adminId: u.id,
                    name: null
                },
            },
            { id: 'allomcs', name: 'All OMCs',
                filterFields: {
                    name: null
                }
             }
        ]
    });

    requestTemplates: RequestTemplate[] = [];
    users: User[] = [];

    months = [{id: 1, name: 'January'},{id: 2, name: 'February'},{id: 3, name: 'March'},{id: 4, name: 'April'},
              {id: 5, name: 'May'},{id: 6, name: 'June'},{id: 7, name: 'July'},{id: 8, name: 'August'},
              {id: 9, name: 'September'},{id: 10, name: 'October'},{id: 11, name: 'November'},{id: 12, name: 'December'}
    ]
    days = this.getDays();

    configReady = new ReplaySubject<null>(1);

    addRecurringAction = new AddRecurringJobsAction('omc');

    mgrField = FormPicklistComponent.make('Manager', 'managerId', 'manager', { items: this.users }, {
        formColumn: 3, validators: [required]
    });

    adminField = FormPicklistComponent.make('Administrator', 'adminId', 'admin',
        { items: this.users, allowSelectNone: true },
        { formColumn: 3, visible: Field.formOnly }
    );

    financeField = FormPicklistComponent.make('Finance', 'financeId', 'finance',
        { items: this.users, allowSelectNone: true },
        { formColumn: 3, visible: Field.formOnly }
    );

    careTakerField = FormPicklistComponent.make('Caretaker', 'caretakerId', 'caretaker',
        { items: this.users, allowSelectNone: true },
        { formColumn: 3, visible: Field.formOnly }
    );

    slugField = FormTextComponent.make('Slug', 'slug', {
        hint: "URL Slug to uniquely identify this team. Note, once team is setup, this cannot be editted",
        formColumn: 3
    });

    finYearEndDay = FormPicklistComponent.make('Year End Day', 'yearEndDay', null, {items:this.days});
    finYearEndMonth = FormPicklistComponent.make('Year End Month', 'yearEndMonth', null,
        {items:this.months},
        {formColumn: 2}
    );

    //FormNumberComponent.make('Recurring Jobs Setup', 'recurring_jobs_count', null, { readonly: true, formColumn: 5}),

    jobsLink = FormButtonComponent.makeNavButton('Jobs',
        RecurringJobComponent.navRoute, o => ({ omcId: o.id, _sort: 'nextOccurence' }),
        { formColumn: 4, value: 'recurring_jobs_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    requestsLink = FormButtonComponent.makeNavButton('Requests',
        ServiceRequestPageComponent.navRoute, o => ({ omcId: o.id, srStatus: 'open', _sort: 'createdAt' }),
        {
            formColumn: 4, value: 'open_requests_count', cellOpts: {
                style: 'text-align: center; max-width: 3em', thColspan: 3, thStyle: 'text-align: centre'
            }
        }
    );

    requestsWeeklyLink = FormButtonComponent.makeNavButton('weekly',
        RequestAnalysisComponent.navRoute, o => ({ omcId: o.id, periodType: WEEKLY_TYPE.id }),
        { formColumn: 4,
            calculateValue: () => 'w', cellOpts: { thStyle: 'display: none' }, toolTip: 'Weekly request analysis'
        });

    requestsMonthlyLink = FormButtonComponent.makeNavButton('monthly',
        RequestAnalysisComponent.navRoute, o => ({ omcId: o.id, periodType: MONTHLY_TYPE.id }),
        {
            formColumn: 4,
            calculateValue: () => 'm', cellOpts: { thStyle: 'display: none' }, toolTip: 'Monthly request analysis'
        });


    unitsLink = FormButtonComponent.makeNavButton('Actual Units',
        UnitPageComponent.navRoute, o => ({ _forceTeam: (o as Omc).omcTeamId }),
        { formColumn: 4, value: 'units_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    peopleLink = FormButtonComponent.makeNavButton('People',
        PersonPageComponent.navRoute, o => ({ _forceTeam: (o as Omc).omcTeamId }),
        { formColumn: 4, value: 'people_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    documentLink = FormButtonComponent.makeNavButton('Documents',
        DocumentPageComponent.navRoute, o => ({ _forceTeam: (o as Omc).omcTeamId }),
        { formColumn: 4, value: 'documents_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    todoLink = FormButtonComponent.makeNavButton('Actions',
        ActionPageComponent.navRoute, o => ({ omcId: o.id, completedAt: '', _sort: 'plannedDate' }),
        { formColumn: 4, value: 'todo_actions_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    ballotLink = FormButtonComponent.makeNavButton('Ballots',
        BallotPageComponent.navRoute, o => ({ _forceTeam: (o as Omc).omcTeamId, _sortDesc: 'closesAt' }),
        { formColumn: 4, value: 'ballots_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );
    mergesLink = FormButtonComponent.makeNavButton('Merges',
        MailMergePageComponent.navRoute, o => ({ _forceTeam: (o as Omc).omcTeamId }),
        { formColumn: 4, value: 'mail_merges_count', cellOpts: { style: 'text-align: center; max-width: 3em' } }
    );

    config = new FormConfig({
        navRoute: OmcPageComponent.navRoute,
        title: $localize`Owner Management Company`,
        help: $localize`The Owner Management Companies (OMCs) an agent does business with`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required] }),
                FormPicklistComponent.make('Type', 'typeId', null, { items: Omc.omcTypes }, { validators: [required] }),
                //                FormTextComponent.make('Manager', 'manager.name', { readonly: true, visible: Field.noForm, sendServer:false }),
                FormTextComponent.make('Legal Name', 'legalName', { formColumn: 1, visible: Field.formOnly }),
                FormTextAreaComponent.make('Default Address', 'address',
                    { formColumn: 2,
                        visible: Field.formOnly,
                        hint: 'Default address to add to the end of all unit addresses'
                    }),
                this.mgrField,
                this.adminField,
                this.financeField,
                this.careTakerField,
                FieldMaker.notes({ formColumn: 2, visible: Field.formOnly }),
                this.slugField,
                this.jobsLink, //FormNumberComponent.make('Recurring Jobs Setup', 'recurring_jobs_count', null, {readonly: true, formColumn: 5}),
                this.requestsLink,
                this.requestsWeeklyLink,
                this.requestsMonthlyLink,
                this.todoLink,
                this.ballotLink,
                this.mergesLink,
                FormNumberComponent.make('Expected Units', 'expectedUnits', null,
                    { formColumn: 1, validators: [required], cellOpts: { style: 'text-align: center; max-width: 3em' } }),
                //FormNumberComponent.make('Actual Units', 'units_count', null, { formColumn: 5, readonly: true }),
                this.finYearEndMonth,
                this.finYearEndDay,
                this.unitsLink,
                this.peopleLink,
                this.documentLink,
            ],
            formLayout: [{ cells: [{ width: '10%' }, { width: '10%' }, { width: '10%' }, { width: '10%' }, { width: '1%' }] }]
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newOMC.bind(this),
        configReady: this.configReady,
        beforeEdit: this.beforeEdit.bind(this),
        actions: [this.addRecurringAction],
        newOptions: [
            { name: $localize`New OMC`, basePath: OmcPageComponent.navRoute.newUrl },
            { name: $localize`Accept invite from OMC`, basePath: OmcPageComponent.navRoute.newUrl, params: {addExisting: 1} }
        ]
    });

    constructor(public dataSvc: OmcService, userSvc: UserService,
        private tmplService: RequestTemplatesService, private dialog: MatDialog, private http: HttpClient,
        private activeRoute: ActivatedRoute, private cds: ConfirmDialogService, private router: Router,
        private nbs: NewButtonService, currentUserSvc: CurrentUserService) {
        super();
        combineLatest([
            tmplService.get(true).pipe(first()),
            userSvc.getUsers().pipe(first()),
            currentUserSvc.getCurrentUser()
        ]).subscribe(
            ([templates, users, currentUser]) => {
                this.requestTemplates = templates as RequestTemplate[];
                this.users = users as User[];
                this.mgrField.setPicklistItems(this.users);
                this.adminField.setPicklistItems(this.users);
                this.financeField.setPicklistItems(this.users);
                this.careTakerField.setPicklistItems(this.users);

                this.currentUser = currentUser as User;
                this.config.actions.push(new CreateNewTeam(this.http, this.currentUser));

                this.configReady.next(null);
            }
        );
    }

    getDays() {
        const days = []
        for (let i = 1; i <= 31; i++) {
            days.push({id: i, name: ("0" + i).slice(-2)})
         }
        return days;
    }

    newOMC() {
        if (this.activeRoute?.snapshot?.queryParams?.addExisting) {
            console.log('Add Existing');
            return this.chooseExisting();
        }
        this.slugField.enableControl();
        this.slugField.sendServer = true;
        return of(new Omc());
    }

    chooseExisting() {
        const config = new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FormTextComponent.make('slug', 'id'),
                    FormTextComponent.make('Name', 'name'),
                    FormTextComponent.make('Legal Name', 'legalName'),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`OMCs Invitations to share`,
            mode: 'list',
        });

        const dialogRef = this.dialog.open(PickDialogComponent,
            {
                data: {
                    config: config,
                    service: {
                        get: this.dataSvc.getInvites.bind(this.dataSvc)
                    },
                }
        });
        return dialogRef.afterClosed().pipe( concatMap ( (omcTeam) => {
            if (omcTeam) {
                const title = $localize `Link OMC to your agency`;
                const msg = $localize `Do you want to accept invitation from ${omcTeam.name} and be able to share their data?`
                const choice = {
                    title,
                    msg,
                    options: [{ name: 'OK', returns: true }]
                }
                return this.cds.openChoice(choice).pipe( concatMap( confirmed => {
                    if (confirmed) {
                        return this.dataSvc.acceptInvites(omcTeam).pipe(map( omc => {
                            if (omc) {
                                this.router.navigate([OmcPageComponent.navRoute.getIdUrl(omc.id)]);
                            }
                        }));
                    } else {
                        this.router.navigate([OmcPageComponent.navRoute.url]);
                    }
                }));
            } else {
                this.router.navigate([OmcPageComponent.navRoute.url]);
            }
        }));
    }

    beforeEdit(omc: Omc) {

        if (omc) {
            const newOptions: NavOption[] = [
                { name: $localize`New Service Request`, basePath: 'crm/requests/NEW', params: { omcId: omc.id } },
                { name: $localize`New Templated Request`, basePath: 'crm/requests/NEW', params: { templated: '', omcId: omc.id } }
            ];

            this.nbs.setupButton($localize`Request For ${omc.name}`, '', true, newOptions);
        } else {
            omc = new Omc(); //avoid errors if no omc, e.g. not logged in...
        }
        if (omc.omcTeamId) {
            this.slugField.disableControl();
            this.slugField.sendServer = false;
        } else {
            this.slugField.enableControl();
            this.slugField.sendServer = true;
        }
        return omc;
    }
}
