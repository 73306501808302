/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { AbstractObject, uuid } from "./abstract-object";
import { RecurringJob } from "./recurring-job";
import { ServiceRequest } from "./serviceRequest";
import { OmcRole } from './OmcRole';
import { User } from "./user";

export class Omc extends AbstractObject {
    constructor(o: Partial<Omc> = {}) {
        super(o);
    }
    managerId: uuid;
    manager: User;
    adminId: uuid;
    financeId: uuid;
    caretakerId: uuid;

    omcTeamId: uuid;
    requests: ServiceRequest[] = [];
    recurringJobs: RecurringJob[] = [];

    legalName: string;
    typeId: uuid;
    type: AbstractObject;
    address: string;
    expectedUnits = 0;
    slug: string;
    yearEndMonth: number;
    yearEndDay: number;

    // Only appears on omc list, comes from agency...
    residentSignup: boolean;
    ownerSignup: boolean;
    otherSignup: boolean;

    /** Replicated Server Side in Omc.php Model Class  */
    static getUserId(omc: Omc, type: string) {
        if (type === OmcRole.manager.id) {
            return omc.managerId;
        } else if (type === OmcRole.admin.id) {
            return omc.adminId ? omc.adminId : omc.managerId;
        } else if (type === OmcRole.finance.id) {
            return omc.financeId ? omc.financeId : omc.managerId;
        } else if (type === OmcRole.caretaker.id) {
            return omc.caretakerId ? omc.caretakerId : omc.managerId;
        }
    }

    /*static userTypes: AbstractObject[] = [
        { id: 'manager', name: 'Property Manager' },
        { id: 'admin', name: 'Administrator' },
        { id: 'finance', name: 'Finance' },
        { id: 'caretaker', name: 'Caretaker' }
    ]*/
    static omcTypes: AbstractObject[] = [
        { id: 5, name: 'Residential' },
        { id: 10, name: 'Commercial & Residential' },
        { id: 15, name: 'Commercial' },
    ];
}
