/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { NavRoute } from '../../NavRoute';
import { Params, RouterLink } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-navigate-item',
  standalone: true,
  imports: [MatIconModule, RouterLink, MatButtonModule],
  templateUrl: './navigate-item.component.html',
  styleUrl: '../simple-fields.scss'
})
export class NavigateItemComponent {
    @Input() label: string
    @Input() value: number;
    @Input() navRoute: NavRoute;
    @Input() navParms: Params;
    @Output() editor = new EventEmitter<null>();
}
