/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";
import { AbstractObject } from "src/app/model/abstract-object";
import { Field } from "src/app/shared/field/Field";
import { GridControl } from "./grid-control";

export class GridRow extends UntypedFormGroup {
    focus: AbstractObject;
    fields: Field[];
    deleted = false;
    attachedData?: unknown;

    constructor(object, group, fields: Field[]) {
        super(group);
        this.focus = object;
        this.fields = fields;
    }

    getParent() {
        return this.parent as GridControl;
    }

    refreshFrom(o: AbstractObject) {
        //const chgs = [];
        this.focus = o;
        for (const f of this.fields) {
            f.setValue(o, f.readonly);
        }
    }

    getFormValue(getAll = false): AbstractObject {
        const newRow = {} as AbstractObject;
        this.fields.forEach(cell => {
            if (cell.sendServer || getAll) {
                newRow[cell.name] = cell.control.value;
            }
        });
        if (this.deleted) {
            newRow.deleted = true;
        }
        return newRow;
    }

    undelete() {
        if (this.deleted) {
            this.delete();
        }
    }

    delete() {
        if (!this.get('id') || this.get('id').value === null) {
            this.getParent().removeGridRow(this);
        } else {
            this.deleted = !this.deleted;
            this.markAsDirty();
            if (this.deleted) {
                this.addControl('deleted', new UntypedFormControl(true));
            } else {
                this.removeControl('deleted');
            }
        }
    }

    setPrimary() {
        const primaryIdName = this.getParent().primaryId;
        const primaryControl = this.parent.parent.controls[primaryIdName];
        const primaryIdCompareName = this.getParent().primaryCompareId;
        console.log('Setting primary', { primaryControl });
        primaryControl.setValue(this.controls[primaryIdCompareName].value);
        primaryControl.markAsDirty();
        this.getParent().onPrimarySet(this.parent.parent as UntypedFormGroup, this);
    }

    isPrimary(): boolean {
        const primaryIdName = this.getParent().primaryId;
        const mainForm = this.getParent().parent as UntypedFormGroup;
        if (primaryIdName) {
            if (!mainForm.controls[primaryIdName]) {
                console.warn('Mainform not initialised with primaryId ' + primaryIdName, mainForm);
            } else {
                const compareToName = this.getParent().primaryCompareId;
                if (!this.controls[compareToName]) {
                    console.warn('Grid Row not initialized with primary compare id [' + compareToName + ']', this);
                } else {
                    if (mainForm.controls[primaryIdName].value === this.controls[compareToName].value) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
