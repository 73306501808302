/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { catchError, first, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
    providedIn: 'root'
})
export class PayzService {

    readonly ajaxPath = AbstractHttpService.ajaxPath;

    httpOptions = {
        headers: new HttpHeaders().set('Content-Type', 'application/json')
    };

    constructor(protected http: HttpClient, protected messageService: MessageService) {
    }

    getSepaMethods() {
        return this.http.get(this.ajaxPath + 'payz/sepaMethods', this.httpOptions).pipe(first()).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Error retrieving sepaMethods', error);
                return of(null);
            })
        );
    }
    getInProgress(unitId: uuid) {
        return this.http.get(this.ajaxPath + 'payz/inProgress/' + unitId, this.httpOptions).pipe(first()).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Error retrieving in progress items', error);
                return of([]);
            })
        );
    }

    getSession(sessionId: uuid) {
        return this.http.get(this.ajaxPath + 'payz/session/' + sessionId, this.httpOptions).pipe(first()).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Error retrieving payment session', error);
                return of(null);
            })
        );
    }

    getMandate(sessionId: uuid) {
        return this.http.get(this.ajaxPath + 'payz/mandate/' + sessionId, this.httpOptions).pipe(first()).pipe(
            catchError((error: HttpErrorResponse) => {
                console.error('Error retrieving mandate session', error);
                return of(null);
            })
        );
    }
    getMandateForUnit(unitId: uuid) {
        return this.http.get(this.ajaxPath + 'payz/mandate/unit/' + unitId, this.httpOptions).pipe(first()).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.status === 404) {
                    /** Grand - No mandate */
                } else {
                    console.error('Error retrieving mandate session', error)
                }
                return of(null);
            })
        );
    }

    payNow(personId: uuid, unitId: uuid, invoiceId: uuid, amount: number) {
        console.log('Setting up stripe payment session');
        amount = Math.round(amount * 100);
        this.http.post(this.ajaxPath + 'payz/simplePay', { personId, unitId, invoiceId, amount, returnUrl: window.location.href }, {
            headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'text/html, text/plain'),
            responseType: 'text',
        }).pipe(
            map((data: string) => {
                console.log('mapping', data);
                window.location.href = data;
            }),
            catchError((error: HttpErrorResponse) => { console.error(error); return of(null) })
        ).subscribe();
    }

    setupMandate(personId: uuid, unitId: uuid, frequencyId: uuid) {
        console.log('Setting up stripe payment session');
        this.http.post(this.ajaxPath + 'payz/mandate', { personId, unitId, frequencyId, returnUrl: window.location.href }, {
            headers: new HttpHeaders().set('Content-Type', 'application/json').set('Accept', 'text/html, text/plain'),
            responseType: 'text',
        }).pipe(
            map((data: string) => {
                console.log('mapping', data);
                window.location.href = data;
            }),
            catchError((error: HttpErrorResponse) => { console.error(error); return of(null) })
        ).subscribe();
    }

}
