/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { FieldSet, FieldSetComponent } from '../../form/field-set/field-set.component';
import { MatButtonModule } from '@angular/material/button';
import { DialogBannerComponent } from '../dialog-banner-component';
import { ConfirmDialogService } from '../confirmDialog';
import { ErrorHelper } from '../../form/form-error/form-error.component';
import { UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { AbstractObject } from 'src/app/model/abstract-object';

export class FieldSetDialogOptions {
    fieldSet: FieldSet;
    saveText?: string;
    saveFn: (unknown) => Observable<AbstractObject>;
    title: string;
    minHeight: string;
}

@Component({
    selector: 'app-field-set-dialog',
    templateUrl: './field-set-dialog.component.html',
    styleUrls: ['./field-set-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatButtonModule, MatDialogClose, DialogBannerComponent, FieldSetComponent]
})
export class FieldSetDialogComponent {
    cds = inject(ConfirmDialogService);
    dialogRef = inject(MatDialogRef);
    dialogOptions = inject(MAT_DIALOG_DATA) as FieldSetDialogOptions;

    saveText = $localize `Save`

    constructor() {
        if (this.dialogOptions.saveText) {
            this.saveText = this.dialogOptions.saveText;
        }
        console.log('Created Dialog', this);
    }

    cancel() {
        this.dialogRef.close(null);
    }

    save() {
        if (this.dialogOptions.fieldSet.formGroup.valid) {
            this.dialogOptions.saveFn(this.dialogOptions.fieldSet.getFormValue()).subscribe ( x => {
                this.dialogRef.close(x);
            })
        } else {
            let errs = '';
            this.dialogOptions.fieldSet.fields.forEach ( f => {
                if (!f.control.valid) {
                    errs += f.name + ': [' + ErrorHelper.getAllMsgs(f.control as UntypedFormControl) + '] ';
                }
            })
            this.cds.alert('Correct Errors Before you can save', errs);
        }
    }
}
