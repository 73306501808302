/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { User } from "src/app/model/user";
import { Field } from "src/app/shared/field/Field";
import { PicklistField, PicklistOptions } from "src/app/shared/field/PicklistField";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { required } from "src/app/shared/validators";
import { ImportDoc } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ImportInput } from "../ImportInput";
import { ImportRow } from "../ImportRow";
import { ParseOptionBase, ParseOptionReady } from "./ParseOptionBase";

export class GeneralParser {

    rowsIn: unknown[][];
    importDoc: ImportDoc;

    currentUser: User;
    users: User[];

    readonly noImportField = 'No Import';

    availableImportItems: ParseOptionBase[];

    columnDefMap: Map<string, { items: ParseOptionReady[] }> = new Map();

    parseRows(rows: unknown[][], importDoc: ImportDoc) {
        this.importDoc = importDoc;
        this.rowsIn = rows;
        // Row 0 Is Blank line!
        rows.forEach((row, rowIdx) => {
            if (rowIdx === (rows.length - 1) && row.length === 1 && Field.isEmpty(row[0])) {
                // Then that is an empty trailing line in the file.
            } else {
                const importRow = new ImportRow();
                row.forEach((fld, fldIdx) => {
                    importRow.add(new ImportField('Field' + fldIdx).setValue(fld));
                })
                this.importDoc.add(importRow);
            }
        });

        this.getInputs();
    }

    getInputs() {
        this.getFirstLineInput();
    }

    getFirstLineInput() {
        const options = { validators: [required] } as Partial<Field>;
        const plOptions = { items: [] } as PicklistOptions;
        this.importDoc.getRows().map((row, idx) => plOptions.items.push({ id: idx, name: row.getTextSample() }))
        options.cellOpts = {style: 'minWidth: 200px; width: 200px'};

        options.valueChanges = ((newValue, field) => {
            this.importDoc.setFirstRow(newValue);
            this.importDoc.getRows().forEach((row, idx) => {
                if (idx >= newValue) {
                    row.skipRow = false;
                } else {
                    row.skipRow = true;
                }
            });
            this.importDoc.addNote('Import starts on row ' + newValue);
            this.importDoc.removeInputForField(field);
            const headerRow = newValue > 0 ? this.importDoc.getRows()[newValue -1] : null;
            this.getColumnDefs(headerRow);
        }).bind(this);
        const firstLineField = FormPicklistComponent.make('First Line To Import', 'firstLine', null, plOptions, options);

        this.importDoc.addInput(new ImportInput('firstLine', [firstLineField], 'Select first line with real data you want to be imported'));
        //firstLineField.control.setValue(15, {emitEvent: false});
    }
    /*
        getHasHeadersInput() {
            const options = { validators: [minValue(-1), maxValue(this.importDoc.getRows().length -2)]} as Partial<Field>;
            options.valueChanges = ((newValue, field) => {
                console.log(newValue, field);
                const headerLine = this.importDoc.getRows()[newValue];
                this.importDoc.getRows().forEach( (row) => {
                    const maxItems = Math.min(headerLine.getFields().length, row.getFields().length);
                    for (let i = 0; i < maxItems; i++) {
                        const fld = row.getFields()[i];
                        fld.setHeading(headerLine[i].getValue());
                    }
                });
                this.importDoc.addNote('Import starts on row ' + newValue);
                this.importDoc.removeInputForField(field);
            }).bind(this);
            const firstLineField = FormNumberComponent.make('First Line To Import', 'firstLine', {}, options);
            this.importDoc.addInput(new ImportInput('headerLine', [firstLineField], 'Does your data have headers. Leave as -1 for no, or enter line number'));
        }
    */
    getColumnDefs(headerRow: ImportRow) {
        let maxFields = 0;
        this.importDoc.getRows().map(row => maxFields = row.getFields().length > maxFields ? row.getFields().length : maxFields);
        const mappedFields: Map<string, ParseOptionReady> = new Map();
        for (let i = 0; i < maxFields; i++) {
            const fName = 'Field' + i;
            const label = 'Map ' + fName + ' to ';
            const items = this.getAvailableImportItems(fName);

            const fld = FormPicklistComponent.make(label, fName, null,
                { items, refreshes: [this.parseOptionChosen.bind(this)] },
                {
                    validators: [required],
                    valueChanges: this.bindColumnToField.bind(this)/*(newValue, field) => {
                        const input = this.importDoc.removeInputForField(field);
                        const currPO = (field as PicklistField).picklist.items.find(o => o.id === newValue) as ParseOptionReady;

                        this.importDoc.addNote(fName + ' is mapped to ' + items.find(o => o.id === newValue).name, () => {
                            field.control.setValue(null, { emitEvent: false });
                            this.parseOptionUnselected(currPO);
                            this.importDoc.addInputAtTop(input);
                        });
                    } */
                }
            );
            this.importDoc.addInput(new ImportInput(fName, [fld], 'What field if any to import <b>' + fName + '</b> into '));
            this.columnDefMap.set(fName, { items });
            if (headerRow) {
                const headerValue = (headerRow.getFields()[i].getValue() as string).toLowerCase();
                const headerPOB = fld.picklist.items.find( pob => pob.name.toLowerCase() === headerValue) as ParseOptionReady;
                if (headerPOB && !mappedFields.has(headerPOB.name)) {
                    mappedFields.set(headerPOB.name, headerPOB);
                    const idx = fld.picklist.items.indexOf(headerPOB as ParseOptionReady);
                    this.parseOptionChosen(headerPOB);
                    this.bindColumnToField(idx as uuid, fld);
                }
            }
        }
        mappedFields.forEach ( (por) => {
            this.removeChosenOptionFromPicklists(por);
        });
    }
    bindColumnToField(newValue, field: Field) {

        const fName = field.name;
        const items = this.getAvailableImportItems(fName);
        const input = this.importDoc.removeInputForField(field);
        const currPO = (field as PicklistField).picklist.items.find(o => o.id === newValue) as ParseOptionReady;

        this.importDoc.addNote(fName + ' is mapped to ' + items.find(o => o.id === newValue).name, () => {
            field.control.setValue(null, { emitEvent: false });
            this.parseOptionUnselected(currPO);
            this.importDoc.addInputAtTop(input);
        });
    }

    getAvailableImportItems(fieldName): ParseOptionReady[] {
        const availOptions: ParseOptionReady[] = [];
        this.addNoImportOption();
        this.availableImportItems.forEach((pob, idx) => {
            const por = this.prepareOption(pob, fieldName, idx);
            availOptions.push(por);
        })
        return availOptions;
    }

    addNoImportOption() {
        if (this.availableImportItems.find(o => o.name === this.noImportField)) {
            // Already added - coola boola
        } else {
            this.availableImportItems.unshift(this.noImportOption());
        }
    }

    prepareOption(pob: ParseOptionBase, fieldName: string, idx: uuid): ParseOptionReady {
        const por = {
            id: idx,
            name: pob.name,
            mappedFieldName: fieldName,
            repeatable: pob.repeatable,
            actionFn: pob.actionFn(fieldName),
            unSelectFn: pob.unSelectFn(fieldName),
            customUnSelectFn: pob.customUnSelectFn
        } as ParseOptionReady;

        pob.prepareOption(this.importDoc);
        return por;
    }

    parseOptionUnselected(por: ParseOptionReady) {
        if (!por.repeatable) {
            const poBase = this.availableImportItems[por.id];
            this.columnDefMap.forEach((cd, fieldName) => {
                const cdpo = cd.items.find(o => o.id === por.id);
                if (!cdpo) {
                    cd.items.push(this.prepareOption(poBase, fieldName, por.id));
                }
            });
        }

        const pob = this.availableImportItems[por.id];
        pob.setMappedTo(null);
        pob.prepareOption(this.importDoc);

        this.importDoc.getGeneralRows().forEach(row => {
            por.unSelectFn(row);
        });
        this.importDoc.finalise();
    }

    removeChosenOptionFromPicklists(por: ParseOptionReady) {
        if (!por.repeatable) {
            for (const cd of this.columnDefMap.values()) {
                const cdpo = cd.items.find(o => o.id === por.id);
                if (cdpo && cdpo !== por) {
                    cd.items.splice(cd.items.indexOf(cdpo), 1);
                }
            }
        }
    }

    parseOptionChosen(por: ParseOptionReady) {
        this.removeChosenOptionFromPicklists(por);
        //this.removeRequiredNote(por);
        const pob = this.availableImportItems[por.id];
        pob.setMappedTo(por.mappedFieldName);
        pob.optionSelected(this.importDoc);
        this.importDoc.getGeneralRows().forEach(row => {
            por.actionFn(row, por);
        });

        this.importDoc.finalise();
    }

    noImportOption(): ParseOptionBase {
        const noImportValidator = { validateRow: (cell: ImportField) => { cell.skipField = true; return null } };
        const noImportUnselect = (cell: ImportField) => cell.skipField = false;

        const pob = new ParseOptionBase(this.noImportField, null, [noImportValidator], noImportUnselect);
        pob.setRepeatable(true);

        return pob;
    }

    getFieldList() : string[] {
        const list = [];
        this.availableImportItems.forEach(pob => {
            if (pob.fieldName && pob.getMappedColumn()) {
                list.push(pob.fieldName);
            }
        })
        return list;
    }

    getItemsToPost(newPostItemFn: (row: ImportRow) => AbstractObject): AbstractObject[] {
        const newItems = [];

        this.importDoc.getGeneralRows().forEach(row => {
            const newItem = newPostItemFn(row);
            this.availableImportItems.forEach(pob => {
                if (pob.fieldName) {
                    pob.setItemValueFn(pob, row, newItem);
                }
            })
            newItems.push(newItem);
        })
        return newItems;
    }
}
