/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { MyInjector } from "src/app/app.module";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { CurrentUserService } from "../../user/current-user.service";
import { MatDialog } from "@angular/material/dialog";
import { BankAccount, BankItem } from "src/app/model/bankAccount";
import { Field } from "src/app/shared/field/Field";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { FormDateComponent } from "src/app/shared/form/form-date/form-date.component";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { of } from "rxjs";
import { Txn } from "src/app/model/txn";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { EditDialogComponent } from "src/app/shared/dialogs/edit-dialog/edit-dialog.component";
import { required } from "src/app/shared/validators";
import { FormTextComponent } from "src/app/shared/form/form-text/form-text.component";
import { FieldMaker } from "src/app/shared/field/FieldMaker";
import { BankAccountService } from "../bank-account.service";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { BankTransferService } from "./bank-transfer.service";

export class BankTransferAction implements IFormAction {
    name = $localize`Transfer Funds`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'move_down';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    tipText = $localize `Click to transfer funds from this account to another`;
    bankAccount: BankAccount;
    bankItem: BankItem;

    currentUser = MyInjector.instance.get(CurrentUserService).currentUser;
    baSvc = MyInjector.instance.get(BankAccountService);
    dialog = MyInjector.instance.get(MatDialog);

    toIBAN = FormTextComponent.make('IBAN', 'IBAN', { disable: true, sendServer: false });

    toAcct = FormPicklistComponent.make('Bank Account', 'toBankAccountId', null, {items: [], refreshes: [
        (o: BankAccount) => this.toIBAN.control.setValue(o.IBAN, {emitEvent: false})
    ]});

    dateField = FormDateComponent.make('Transaction Date', 'txnDate', { validators: [required] });
    amountField = FormNumberComponent.makeCurrency('Amount', 'amount', { validators: [required] });

    action() { // config: FormConfig
        return this.openDialog();
    }

    setup(item: BankAccount) {
        this.bankAccount = item;
        this.show = true;
        this.baSvc.get(true).subscribe(accts => {
            this.toAcct.setPicklistItems(accts.filter( ba => ba.id !== item.id));
        })
    }

    setBankItem(bi: BankItem) {
        this.bankItem = bi;
        this.dateField.disable = true;
        this.amountField.disable = true;
    }

    private getFields(): Field[] {
        return [
            FieldMaker.idHolder('bankAccountId'),
            FieldMaker.idHolder('bankItemId'),
            FormTextComponent.make('FROM IBAN', 'bankAccount.IBAN', { disable: true, sendServer: false }),
            FormTextComponent.make('FROM ACCOUNT', 'bankAccount.name', { disable: true, sendServer: false }),
            this.toAcct,
            this.toIBAN,
            this.dateField,
            this.amountField
        ];
    }

    newTransaction() {
        const t = new Txn();
        t.bankAccountId = this.bankAccount.id;
        t.bankAccount = this.bankAccount;
        if (this.bankItem) {
            t.bankItemId = this.bankItem.id;
            t['amount'] = 0 - this.bankItem.transactionAmount;
            t.txnDate = this.bankItem.bookingDate;
        }
        return of(t);
    }

    openDialog() {
        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Bank Transfer`,
                fieldSet: new FieldSet(
                    {
                        fields: this.getFields(),
                        formLayout: [{ cells: [{ width: '100%' }]}]
                    }
                ),
                mode: 'new',
                objectFactory: this.newTransaction.bind(this),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: MyInjector.instance.get(BankTransferService),
                    hideTabs: true,
                    width: 400,
                    height: 700,
                }
            }
        );

        return dialogRef.afterClosed();
    }
}
