/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { RouterLink } from '@angular/router';

type UserRole = 'Director'|'Owner'|'Agent'|'Resident'

@Component({
  selector: 'app-anonymous',
  standalone: true,
  imports: [MatCardModule, RouterLink],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './anonymous.component.html',
  styleUrl: './anonymous.component.scss'
})
export class AnonymousComponent {


    currentRole : UserRole;

    chooseRole(role: UserRole) {
        this.currentRole = role;
    }

}
