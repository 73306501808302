/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { NavRoute } from 'src/app/shared/NavRoute';
import { BCodeService } from '../../budget/bcode.service';
import { ApTxnService } from '../ap-txn.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
  selector: 'app-ap-page',
  templateUrl: './ap-page.component.html',
  styleUrls: ['./ap-page.component.scss'],
  standalone: true,
  imports: [FormPageComponent]
})
export class ApPageComponent extends AbstractPageComponent {

  static readonly navRoute = new NavRoute(Txn.LEDGER.AP.code, ApPageComponent, 'call_made');

  readonly path = Txn.LEDGER.AP.code;

  config = new FormConfig({
    navRoute: ApPageComponent.navRoute,
    title: Txn.LEDGER.AP.name,
    help: $localize`Accounts Payable Transactions`,
    fieldSet: new FieldSet({
      fields: this.dataSvc.getFields(),
      formLayout: [
        { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
        { cells: [{ colspan: 4, pageTab: 'yes' }] }
      ],
    }),
    service: this.dataSvc,
    mode: 'list',
    objectFactory: () => of(null), // this.newFactory.bind(this),
    newOptions: [
      { name: $localize`New ${Txn.TYPE.PURCHASE.name}`, basePath: `/${Txn.TYPE.PURCHASE.code}/NEW`, params: {} },
      { name: $localize`New ${Txn.TYPE.PURCHASE_CN.name}`, basePath: `/${Txn.TYPE.PURCHASE_CN.code}/NEW`, params: {} }
    ],
    pathEdit: (o: Txn) => Txn.getType(o.typeId).code + '/' + o.id
  });

  constructor(public dataSvc: ApTxnService, public bCodeSvc: BCodeService,
    protected activeRoute: ActivatedRoute) {
    super();
  }

  newFactory(): Observable<Txn> {
    const txn = new Txn();
    txn.typeId = +this.activeRoute.snapshot.queryParamMap.get('typeId');
    console.warn(txn, this.activeRoute.snapshot);
    return of(txn);
  }

}
