/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject, Input } from '@angular/core';
import { uuid } from 'src/app/model/abstract-object';
import { User } from 'src/app/model/user';
import { UserService } from '../user.service';

@Component({
  selector: 'app-user-name',
  standalone: true,
  imports: [],
  templateUrl: './user-name.component.html',
  styleUrl: './user-name.component.scss'
})
export class UserNameComponent {
    userService = inject(UserService);
    
    myUser: User = {id: 'rubbish', name: 'User?', avatar: null} as User;
    myId: uuid;
    @Input() set id(value: number) {
        this.myId = value;
        if (value) {
            this.userService.getUser(this.myId).subscribe(user => {
                this.myUser = user;
            });
        } else {
            this.myUser.name = '';
        }
    }
}
