/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridControl } from '../../grid/grid-control';
import { FormMode } from '../form.component';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

type Table = {
    changed: boolean;
};

@Component({
    selector: 'app-form-actions',
    templateUrl: './form-actions.component.html',
    styleUrls: ['./form-actions.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatButtonModule, MatIconModule, MatFormFieldModule, FormErrorComponent]
})

export class FormActionsComponent implements OnInit, OnChanges {

    @Input() pageForm: UntypedFormGroup;
    @Input() hideSaveAndNew = true;
    @Input() myMode: FormMode;
    @Input() readonly = true;
    @Input() allowDelete = true;

    @Output() saveAndNew = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() delete = new EventEmitter();

    table: Table;
    showSaveAndNew = false;
    showSave = false;
    showCancel = false;
    showDelete = false;

    showTheErrors = false;

    ngOnInit() {
        if (this.saveAndNew.observers.length && !this.hideSaveAndNew) {
            this.showSaveAndNew = true;
        }
        this.showSave = this.save.observers.length > 0;
        this.showCancel = this.cancel.observers.length > 0;
        this.showDelete = this.delete.observers.length > 0;

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.hideSaveAndNew) {
            this.hideSaveAndNew = changes.hideSaveAndNew.currentValue;
            if (this.saveAndNew.observers.length && !this.hideSaveAndNew) {
                this.showSaveAndNew = true;
            } else {
                this.showSaveAndNew = false;
            }
        }
    }

    checkFormGroup(group: UntypedFormGroup) {
        for (const controlName in group.controls) {
            const control = group.get(controlName);
            if (control.invalid) {
                control.markAsTouched();
                console.log(controlName + ' is invalid ', { controlName, control });
                if (control instanceof GridControl) {
                    const grid = control as GridControl;
                    for (const row of grid.gridRows()) {
                        this.checkFormGroup(row);
                    }
                }
            }
        }
    }

    showErrors() {
        /*
        for (const ctlName in this.pageForm.controls) {
          const ctl = this.pageForm.get(ctlName);
          if (.invalid) {
            console.log(ctlName + ' is invalid', this.pageForm.get(ctlName).errors);
            if ()
            this.pageForm.get(ctlName).markAllAsTouched();
          }
        }
        */
        this.showTheErrors = true;
        this.checkFormGroup(this.pageForm);
        if (this.pageForm.invalid) {
            this.pageForm.markAsTouched();
        }

        console.log(this.pageForm);
    }


    saveChangesAndNew() {
        this.saveAndNew.emit();
    }

    saveChanges() {
        this.save.emit();
    }

    cancelChanges() {
        this.cancel.emit();
    }

    deleteObject() {
        this.delete.emit();
    }
}
