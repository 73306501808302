/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from './abstract-object';
import { UnitCategory } from './unit-category';

export class UnitType extends AbstractObject {
  name: string;
  categories?: UnitCategory[] = [];
  constructor(o: Partial<UnitType> = {}) {
    super(o);
  }
}
