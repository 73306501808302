/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { formatCurrency, PercentPipe, CurrencyPipe, DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Cycle } from 'src/app/model/cycle';
import { Frequency } from 'src/app/model/frequency';
import { Unit } from 'src/app/model/unit';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { PayzService } from '../../payzService';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';

@Component({
    selector: 'app-pay-in-full',
    templateUrl: './pay-in-full.component.html',
    styleUrls: ['./pay-in-full.component.scss'],
    standalone: true,
    imports: [MatRadioModule, FormsModule, MatButtonModule, PercentPipe, CurrencyPipe, DatePipe]
})
export class PayInFullComponent {

    @Input() unit: Unit;
    @Input() cycle: Cycle;

    paymentOption: 'repeating' | 'oneOff' = 'repeating';

    constructor(private payzSvc: PayzService, private cds: ConfirmDialogService) { }

    payInFullOnly() {
        const amount = this.unit.outstanding;
        const amountDisp = formatCurrency(amount, 'EN-ie', 'EUR');

        this.cds.open($localize`Make Payment`, $localize`Do you wish to connect to stripe and make a payment of ${amountDisp}`, () => {
            this.payzSvc.payNow(this.unit.ownerId, this.unit.id, null, amount);
        });

    }

    setupMandateAndPay() {
        const amount = this.unit.outstanding;
        const amountDisp = formatCurrency(amount, 'EN-ie', 'EUR');
        this.cds.open($localize`Make Payment & Setup Direct Debit Mandate`,
            $localize`Do you wish to connect to stripe to authorise a payment of ${amountDisp}
                and authorise an annual direct debit mandate for future charges`, () => {
            this.payzSvc.setupMandate(this.unit.ownerId, this.unit.id, Frequency.YEARLY.id);
        });
    }

    setupMandateOnly() {
        this.cds.open($localize`Setup Direct Debit Mandate`,
            $localize`Do you wish to connect to stripe to authorise an annual
                direct debit mandate for future charges`, () => {
            this.payzSvc.setupMandate(this.unit.ownerId, this.unit.id, Frequency.YEARLY.id);
        });
    }

}
