/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogClose } from '@angular/material/dialog';
import { FormConfig } from "../../form/FormConfig";
import { AbstractHttpService } from '../../abstract-http.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Field } from '../../field/Field';
import { FieldSet, LAYOUT_OPTIONS } from '../../form/field-set/field-set.component';
import { FieldMaker } from '../../field/FieldMaker';
import { CtlHolderComponent } from '../../form/ctl-holder/ctl-holder.component';
import { MatButtonModule } from '@angular/material/button';
import { AppFormControl } from '../../form/app-form-control';
import { GridControl } from '../../grid/grid-control';
import { MessageService } from '../../message.service';
import { ErrorHelper } from '../../form/form-error/form-error.component';
import { UntypedFormControl } from '@angular/forms';

export class EditFieldDialogOptions {
    field: Field;
    service: AbstractHttpService;
    id: uuid;
    forceTeamId: uuid;
    constructor(defaults: Partial<EditFieldDialogOptions>) {
        Object.assign(this, defaults);
    }
}

@Component({
    selector: 'app-edit-field-dialog',
    templateUrl: './edit-field-dialog.component.html',
    styleUrls: ['./edit-field-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogContent, CtlHolderComponent, MatButtonModule, MatDialogClose]
})
export class EditFieldDialogComponent {
    dialogRef = inject(MatDialogRef);
    dialogOptions = inject(MAT_DIALOG_DATA) as EditFieldDialogOptions;
    msgSvc = inject(MessageService);

    control: AppFormControl | GridControl;
    item: AbstractObject;

    fieldSet = new FieldSet({
        fields: [
            FieldMaker.id(),
            FieldMaker.rev(),
            this.dialogOptions.field,
        ],
        formLayout: LAYOUT_OPTIONS.singleCol,
    });

    config = new FormConfig({
        fieldSet: this.fieldSet,
        service: this.dialogOptions.service,
        mode: 'edit',
    })

    constructor() {
        const dopts = this.dialogOptions;
        if (!dopts.field.control) {
            this.control = dopts.field.makeControl();
        } else {
            this.control = dopts.field.control;
        }
        dopts.service.getOne(dopts.id, null, dopts.forceTeamId).subscribe( item => {
            if (item) {
                this.item = item;
                dopts.field.setValue(item, false);
            }
        })
    }

    save() {
        if (!this.control.valid) {
            const msg = ErrorHelper.getAllMsgs(this.control as UntypedFormControl);
            this.msgSvc.showErrorMessage(msg);
        } else if ( !this.control.dirty) {
            this.dialogRef.close(null);
        } else {
            const update = {
                id: this.item.id,
                revision: this.item.revision,
            }
            update[this.dialogOptions.field.name] = this.dialogOptions.field.getFormValue();
            this.dialogOptions.service.put(update, this.dialogOptions.forceTeamId).subscribe(result => {
                this.dialogRef.close(result);
            })
        }
    }
}
