/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from 'src/app/model/person';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { catchError, map } from 'rxjs/operators';
import { MailItem } from 'src/app/model/mailMerge';
import { Observable, of } from 'rxjs';
import { uuid } from 'src/app/model/abstract-object';
import { Attachment } from 'src/app/model/attachment';

@Injectable({
  providedIn: 'root'
})
export class PersonService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'people';
  protected cache: Person[];
  protected typeString = 'Person';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }

  sendMail(p: Person, subject: string, content: string, attachments: Attachment[] = []) : Observable<MailItem>{
    const url = this.baseUrl + '/sendMail/' + p.id;
    return this.http.post<MailItem>(url, {subject, content, attachments}, this.getSimpleOptions(p.teamId)).pipe(
        map( x => {
            return x;
        }),
        catchError((error) => {
            this.messageService.showError(error, 'Sending Mail');
            return of(null);
        })
    )
  }

    performMerge(remove: Person, mergeIntoId: uuid): Observable<Person> {
        const url = this.baseUrl + '/merge/' + remove.id + '/' + mergeIntoId;
        return this.http.post<MailItem>(url, { }, this.getSimpleOptions(remove.teamId)).pipe(
            map(x => {
                return x;
            }),
            catchError((error) => {
                this.messageService.showError(error, 'Performing Merge');
                return of(null);
            })
        )
    }
}
