/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { ValidatorFn, AsyncValidatorFn, FormGroup, UntypedFormGroup } from '@angular/forms';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { AppFormControl, ControlOn } from '../app-form-control';
import { AttachmentField } from "../../field/AttachmentField";
import { CtlHolderComponent } from '../ctl-holder/ctl-holder.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgClass, NgTemplateOutlet } from '@angular/common';

export const LAYOUT_OPTIONS = {
    simpleDialog: [{ cells: [{}] }],
    singleCol: [{ cells: [{}] }, { cells: [{}] }],

    doubleCol: [{ cells: [{ width: '50%' }, { width: '50%' }] }],
    doubleColOver1: [{ cells: [{ width: '50%' }, { width: '50%' }] },
    { cells: [{ colspan: 2, pageTab: 'yes' }] }],

    threeCol: [{ cells: [{ width: '33%' }, { width: '33%' }, { width: '33%' }] }],
    threeColOver1: [{ cells: [{ width: '33%' }, { width: '33%' }, { width: '33%' }] },
    { cells: [{ colspan: 3, pageTab: 'yes' }] }],

    fourCol: [{ cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] }],
    fourColOver1: [{ cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
    { cells: [{ colspan: 4, pageTab: 'yes' }] }],

    fiveCol: [{ cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] }],
    fiveColOver1: [{ cells: [{ width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }, { width: '20%' }] },
    { cells: [{ colspan: 5, pageTab: 'yes' }] },
    ]
};

export interface CellDef {
    width?: string;
    colspan?: number;
    rowspan?: number;
    style?: string;
    pageTab?: string;
    showAfter?: boolean;
    class?: string;
}
export interface RowDef {
    showAfter?: boolean,
    cells: CellDef[];
}

export class FieldSet {
    fields: Field[] = [];
    formLayout: RowDef[] = LAYOUT_OPTIONS.threeColOver1;
    formValidator: ValidatorFn[];
    asyncFormValidator: AsyncValidatorFn[];
    valueChanges: (newValue, formGroup) => void;
    formGroup: FormGroup;

    constructor(defaults: Partial<FieldSet> = {}, initFormGroup = false) {
        Object.getOwnPropertyNames(defaults).forEach(prop => {
            this[prop] = defaults[prop];
        });
        if (initFormGroup) {
            this.setFormGroup();
        }
    }
    setFields(fields: Field[]) {
        this.fields = fields;
        this.setFormGroup();
    }

    setFormGroup() {
        this.formGroup = new UntypedFormGroup({}, this.formValidator, this.asyncFormValidator);

        this.fields.forEach(field => {
            this.formGroup.addControl(field.name, field.makeControl());
        });

        if (this.valueChanges) {
            this.formGroup.valueChanges.subscribe((newValue) => {
                this.valueChanges(newValue, this.formGroup);
            });
        }
    }

    getFormGroup() {
        if (!this.formGroup) {
            this.setFormGroup();
        }
        return this.formGroup;
    }

    setValue(item: AbstractObject, readonly = false) {
        this.getFormGroup(); // Make sure controls are initialized...
        this.fields.forEach(f => f.setValue(item, readonly));
    }

    getFormValue(getAll = false): AbstractObject {
        const formValue = {} as AbstractObject;
        this.fields.forEach(field => {
            if (field.sendServer || getAll) {

                if (field instanceof GridField) {
                    formValue[field.name] = (field.control as GridControl).getFormValue(getAll);
                } else {
                    //formValue[field.name] = field.getFormValue();
                    this.setFormValue(formValue, field);
                }


            }
        });
        return formValue as AbstractObject;
    }
    
    setFormValue(newValue: unknown, field: Field) {
        if (field.value.includes(".")) {
            const names = field.value.split(".");
            if (names.length > 2) {
                console.error('Cannot process field with multiple object levels', field);
            }
            if (newValue[names[0]] === undefined || newValue[names[0]] === null) {
                newValue[names[0]] = {};
            }
            newValue[names[0]][names[1]] = field.getFormValue();

        } else {
            newValue[field.name] = field.getFormValue();
        }
    }

    get(name: string) {
        return this.getFieldByName(name);
    }

    getFieldByName(name: string) {
        const f = this.fields.find(f => f.name === name);
        if (f === undefined) {
            console.warn('Unable to find field', {seeking: name, in: this.fields});
        }
        return f;
    }

    getFieldByValue(value: string) {
        for (const field of this.fields) {
            if (field.value === value) {
                return field;
            }
        }
        return null;
    }
}

@Component({
    selector: 'app-field-set',
    templateUrl: './field-set.component.html',
    styleUrls: ['./field-set.component.scss'],
    standalone: true,
    imports: [NgClass, MatTabsModule, CtlHolderComponent, NgTemplateOutlet]
})
export class FieldSetComponent {

    @Input() fieldSet: FieldSet;
    @Input() isPhone: boolean;
    @Input() initComplete: boolean;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';
    @Input() showAfter = false;
    @Input() isPrint = false;

    focusRow: RowDef;

    public static make(label: string, value: string, options: Partial<Field> = {}) {
        /* */
        const field: Field = new Field(
            {
                label, value, type: 'fieldSet',
                cellOpts: { style: 'justify-content: flex-end; text-align: right; padding-right: 5px' },
                footer: { style: 'justify-content: flex-end; text-align: right; padding-right: 5px' }
            },
            options
        );
        field.formControlFactory = FieldSetComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FieldSetComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    getFieldCount(field: Field) {
        if (field instanceof GridField || field instanceof AttachmentField) {
            if (Array.isArray(field.control?.value) && field.control.value.length > 0) {
                return field.control.value.length;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    maximiseRow(row: RowDef) {

        if (this.focusRow) {
            console.log('Up ');
            this.focusRow = null;
        } else {
            console.log('Show Sedcond');
            this.focusRow = row;
        }
    }

    showRow(row: RowDef) {
        if (this.focusRow) {

            if (this.focusRow === row) {
                return true;
            } else {
                return false;
            }
        } else if (this.showAfter) {
            return row.showAfter; // only show rows explicitly marked showAfter: true after the save control;
        } else {
            if (!row.showAfter) {
                return true; // Before save control Show all cells not explicitly marked showAfter: true
            }
        }
        return false;
    }

    showField(field: Field, inRow: number, inCol: number) {
        if (field) {
            if (this.isPhone && inCol === 1) {
                return true;
            } else if (field.inRowCol(inRow, inCol)) {
                return true;
            }
        }
        return false;
    }
}
