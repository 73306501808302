/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { RequestService } from '../../request.service';
import { MatButtonModule } from '@angular/material/button';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MessageService } from 'src/app/shared/message.service';
import { ControlVisibility, Field } from 'src/app/shared/field/Field';
import { HttpParams } from '@angular/common/http';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { CommsTemplateType } from 'src/app/model/CommsTemplate';

@Component({
  selector: 'app-resolve-request',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './resolve-request.component.html',
  styleUrl: './resolve-request.component.scss'
})
export class ResolveRequestComponent {
    dialogRef = inject(MatDialogRef<ResolveRequestComponent>);
    sr = inject(MAT_DIALOG_DATA);
    srSvc = inject(RequestService);
    msgSvc = inject(MessageService);

    defaultComment = $localize `If you include comments above about what was done,
        they will be added as a comment on the request and
        included in the notification to followers that request has been closed.`;
    comment = this.defaultComment;

    ready = false;

    fieldSet = new FieldSet();
    closeStatusField = FormPicklistComponent.make('Close Status', 'srCloseStatus', null,
        { items: ServiceRequest.srCloseStates, refreshes: [this.closeStatusRefresh.bind(this)] }
    )

    duplicateRequestField = FormComboBoxComponent.make('Duplicate Of Request', 'duplicateSrId', null, {
        items: [],
        optionDisplayValue: sr => sr ? sr.refNr + ': ' + sr.title : '',
        refreshes: [this.duplicateSrRefresh.bind(this)]
    }, {visible: Field.noShow});

    closeTextField = FormRichTextComponent.make('What was done?', 'closeText').setCommsTemplate(CommsTemplateType.REQUEST_CLOSING);

    fields = [
        FieldMaker.idHolder('id'),
        FieldMaker.rev(),
        this.closeStatusField,
        this.duplicateRequestField,
        this.closeTextField,
    ]

    constructor() {
        this.fieldSet.setFields(this.fields);
        this.closeStatusField.setValue({srCloseStatus: ServiceRequest.srComplete.id} as unknown as AbstractObject);
        this.ready = true;
    }

    closeStatusRefresh(closeStatus: AbstractObject) {
        let commentVisibility: ControlVisibility = Field.showAll;
        this.comment = this.defaultComment

        if (closeStatus.id === ServiceRequest.srDuplicate.id) {
            this.duplicateRequestField.visible = Field.showAll;
            const parms = new HttpParams().set('srStatus', ServiceRequest.srOpen.id);
            this.srSvc.get(false, parms, this.sr.teamId).subscribe( openSRs => {
                this.duplicateRequestField.setPicklistItems(openSRs.filter( sr => sr.id !== this.sr.id));
            })
        } else {
            this.duplicateRequestField.visible = Field.noShow;
            this.duplicateRequestField.clearComboValue();
            this.duplicateRequestField.control.setValue(null, {emitEvent: false});
            if (closeStatus.id === ServiceRequest.srCancelled.id) {
                const msg = $localize `<p>This request is being close because we understand it is no longer required,
                    or no further action is required</p>`;
                this.closeTextField.control.setValue(msg);
            } else if (closeStatus.id === ServiceRequest.srNoAction.id) {
                this.comment = $localize `This request is being close because no action is required.
                There will be NO COMMENT STORED, NO NOTIFICATION SENT TO Reporter. 
                Status will go directly to accepted. It will not be possible to re-open the request. 
                Only use this option if you are certain that there is no work required related to this request
                and that the person who created the request has no desire to receive further information about this request.`;
                commentVisibility = Field.noShow;
                this.closeTextField.control.setValue('');
            } else {
                this.closeTextField.control.setValue('');
            }
        }
        this.closeTextField.visible = commentVisibility
    }

    duplicateSrRefresh(sr: ServiceRequest){
        let msg = '';
        if (sr.id) {
            msg = $localize `<p>This request ${this.sr.refNr} is a duplicate of ${sr.refNr}</p>
            <p>We are marking ${this.sr.refNr} closed, but we will continue working on request ${sr.refNr}</p>
            <p>You will receive a mail confirming that you are now following ${sr.refNr} 
            so that you can receive updates on the issue</p>`
        }
        this.closeTextField.control.setValue(msg);
    }

    closeRequest() {
        const s = this.closeStatusField.control.value;
        const t = this.closeTextField.control.value;
        const d = this.duplicateRequestField.control.value;
        this.srSvc.closeRequest(this.sr, s, t, d).subscribe ( response => {
            if (response) {
                this.dialogRef.close(response);
            }
        }, error => this.msgSvc.showError(error, 'Resolving Request'));
    }
}
