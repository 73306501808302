/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogModule } from '@angular/material/dialog';
import { of } from 'rxjs';
import { MyInjector } from 'src/app/app.module';
import { uuid } from 'src/app/model/abstract-object';
import { FORUM_PRIVS, Forum } from 'src/app/model/forum';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { ForumService } from '../forum.service';
import { Person } from 'src/app/model/person';
import { FormsModule } from '@angular/forms';
import { Area } from 'src/app/model/area';
import { Core } from 'src/app/model/core';
import { AreaService } from 'src/app/modules/unit/area.service';

export class ForumPeopleAction implements IFormAction {
    name = $localize`Show People`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'groups';
    approvalNeeded = false;
    disabled = false;
    approvalText = "";
    tipText = 'Show the people associated to this channel';
    teamId: uuid;
    forum: Forum;

    action(forum: Forum) {
        console.log(forum);
        MyInjector.instance.get(MatDialog).open(ForumPeopleComponent, {data: this.forum});
        return of(this.forum);
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(forum: Forum) {
        this.forum = forum;
        this.show = true;
    }
}

@Component({
  selector: 'app-forum-people',
  standalone: true,
  imports: [MatDialogModule, FormsModule],
  templateUrl: './forum-people.component.html',
  styleUrl: './forum-people.component.scss'
})
export class ForumPeopleComponent {

    people: Person[] = [];
    areaSvc = inject(AreaService);

    areas: Area[] = [];
    cores: Core[] = [];

    forumPrivs = FORUM_PRIVS;
    selectedPriv = this.forumPrivs.find(o=>o.id==='canEdit');
    selectedArea : Area;
    selectedCore : Core;

    constructor(@Inject(MAT_DIALOG_DATA) public forum: Forum, private forumService: ForumService) {
        this.changePriv();
        this.areaSvc.get<Area>(true).subscribe ( areas => this.areas = areas);
    }

    fullName(person: Person) {
        return Person.fullName(person);
    }

    changeArea() {
        this.selectedCore = null;
        this.changePriv()
    }

    changeCore() {
        this.changePriv()
    }

    changePriv() {
        this.forumService.getPeople(this.forum, this.selectedPriv, this.selectedArea?.id, this.selectedCore?.id).subscribe( (peeps: Person[]) => {
            this.people.length = 0;
            peeps.forEach ( p => this.people.push(p));
        });
    }
}
