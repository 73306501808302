/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { RequestCategory, RequestQuestion } from 'src/app/model/RequestCategory';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { maxChars, required, uniqueGrid } from 'src/app/shared/validators';
import { RequestCategoriesService } from '../request-categories.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { RequestIconsService } from '../request-icons.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormImageComponent } from 'src/app/shared/form/form-image/form-image.component';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { GridField } from 'src/app/shared/grid/grid-field';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { RequestTemplatesService } from '../request-templates.service';
import { UserService } from '../../user/user.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
@Component({
    selector: 'app-request-categories-page',
    templateUrl: './request-categories-page.component.html',
    styleUrls: ['./request-categories-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RequestCategoriesPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/categories', RequestCategoriesPageComponent, 'category');

    iconPickField = FormPicklistComponent.make('Icon File', 'fileName', null, {
        refreshes: [this.changeImage.bind(this)]
    }, { formColumn: 4 });
    iconShowField = FormImageComponent.make('Image', 'fileUrl',
        { calculateValue: o => RequestCategory.getRequestIconUrl((o as RequestCategory)?.fileName), formColumn: 4, visible: Field.formOnly }
    );

    questionsField = new GridField({
        field: { value: 'questions', cellOpts: { heading: 'Questions' }, visible: Field.formOnly, formRow: 2 },
        rowFactory: () => [
            FieldMaker.id({ visible: Field.noShow }),
            FieldMaker.nameControl({ validators: [required, maxChars(30)], cellOpts: { heading: 'Question', width: '20%' } }),
            FormNumberComponent.make('Order', 'order', {}),
            FormPicklistComponent.make('Response Type', 'dataType', null, { items: RequestQuestion.QuestionTypes }),
            FormTextComponent.make('Options', 'options', { hint: 'For picklists enter available values seperated by semi colons;' }),
            FormTextComponent.make('User Notes / Help', 'userNotes'),
            //FieldMaker.notes(),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => of(new RequestQuestion()),
        newOptionText: $localize`Add Question`,
        gridValidator: [uniqueGrid()]
    })

    subcatField = new GridField({
        field: { value: 'subCategories', cellOpts: { heading: 'Child Categories' }, visible: Field.formOnly, formRow: 2 },
        rowFactory: (o: RequestCategory) => [
            FieldMaker.id({ visible: Field.noShow }),
            FormButtonComponent.makeLink('', 'detail', RequestCategoriesPageComponent.navRoute.getIdUrl(o?.id),
                { calculateValue: () => 'Detail' }
            ),
            FieldMaker.nameControl({ validators: [required, maxChars(30)], cellOpts: { heading: 'Categories', width: '20%' } }),
            FormNumberComponent.make('Order', 'order', {}),
            FieldMaker.notes(),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ],
        objFactory: () => of(new RequestCategory()),
        newOptionText: $localize`Add Child Category`,
        gridValidator: [uniqueGrid()]
    });

    parentField = FormPicklistComponent.make('Parent Category', 'parentId', 'parent', { service: this.dataSvc });

    defaultOwnerField = FormPicklistComponent.make('Default Owner', 'ownerId', 'owner', { items: [] }, { formColumn: 3 });

    config = new FormConfig({
        navRoute: RequestCategoriesPageComponent.navRoute,
        title: $localize`Request Category`,
        help: $localize`Categories for analysing requests.`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FieldMaker.nameControl({ validators: [required, maxChars(30)] }),
                FormNumberComponent.make('Order', 'order', {}, { hint: 'Sequence this item will appear in', formColumn: 1 }),

                //FormPicklistComponent.make('Template', 'templateId', 'template', { service: this.tmplService }, { formColumn: 2 }),
                FormCheckboxComponent.make('Offer Externally', 'offerExternal', {
                    formColumn: 2, hint: $localize `Allow owners or residents create requests for this category`
                }),
                this.defaultOwnerField,
                this.parentField,
                this.iconPickField,
                this.iconShowField,
                FormTextAreaComponent.make('User Title', 'notes', {
                    formColumn: 2,
                    hint: "The title when offering to allow users to create a request of this category"
                }),
                FormTextAreaComponent.make('Rejection text', 'userNotes', {
                    formColumn: 3,
                    hint: "Users will see this text displayed and not be able to create request online" }),
                this.subcatField,
                this.questionsField
            ],
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new RequestCategory()),
    });

    changeImage(o: AbstractObject) {
        this.iconShowField.control.setValue(RequestCategory.getRequestIconUrl(o.name));
    }

    constructor(public dataSvc: RequestCategoriesService, iconService: RequestIconsService,
        private tmplService: RequestTemplatesService, userSvc: UserService) {
        super();
        iconService.getIcons().subscribe(icons => {
            console.log(icons);
            icons.forEach(i => this.iconPickField.picklist.items.push(i));
            //this.iconField.picklist.items = icons;
        })
        userSvc.getUsers().subscribe( u => {
            this.defaultOwnerField.setPicklistItems(u);
        })

    }
}
