/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/shared/message.service';
import { PersonService } from '../person.service';
import { FieldSet, FieldSetComponent, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { Person } from 'src/app/model/person';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AbstractObject } from 'src/app/model/abstract-object';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
import { PersonPageComponent } from '../person-page.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MyInjector } from 'src/app/app.module';

@Component({
  selector: 'app-merge-person',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './merge-person.component.html',
  styleUrl: './merge-person.component.scss'
})
export class MergePersonComponent {
    dialogRef = inject(MatDialogRef<MergePersonComponent>);
    person = inject(MAT_DIALOG_DATA);
    mergeInto: Person;
    peopleSvc = inject(PersonService);
    msgSvc = inject(MessageService);
    router = inject(Router);
    cds = inject(ConfirmDialogService);

    ready = false;

    fieldSet = new FieldSet({formLayout: LAYOUT_OPTIONS.singleCol});
    mergeIntoIdField = FormComboBoxComponent.make('Merge Into Person', 'mergeIntoPersonId', null,
        { service: this.peopleSvc,
          refreshes: [this.personRefresh.bind(this)],
          optionDisplayValue: (p:Person) => p.fullName + ' (' + p.email + ')'
        }
    )

    fields = [
        FieldMaker.idHolder('removePersonId'),
        this.mergeIntoIdField,
    ]

    constructor() {
        this.fieldSet.setFields(this.fields);
        this.fieldSet.setValue({ removePersonId: this.person.id } as unknown as AbstractObject);
        this.ready = true;
    }

    personRefresh(person: Person) {
        if (person.id === this.person.id) {
            this.msgSvc.showErrorMessage($localize `Cannot merge ${this.person.fullName} on to themselves`);
        }
        console.log(person)
    }

    performMerge() {
        this.cds.open($localize `Are you 100% certain you want to merge `, $localize `This cannot be undone, are you you sure?`, () => {
            this.peopleSvc.performMerge(this.person, this.mergeIntoIdField.control.value).subscribe(r => {
                if (r) {
                    this.dialogRef.close();
                    this.router.navigate([PersonPageComponent.navRoute.getIdUrl(r.id)]);
                }
            })
        });
    }
}
