/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { minValue, required } from 'src/app/shared/validators';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FieldSetComponent } from '../../../shared/form/field-set/field-set.component';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-omc-sign-up',
    templateUrl: './omc-sign-up.component.html',
    styleUrls: ['./omc-sign-up.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatIconModule, FieldSetComponent, MatTooltipModule, MatButtonModule]
})
export class OmcSignUpComponent {
    static readonly navRoute = new NavRoute('omcSignUp', OmcSignUpComponent, '');

    roles = [{ id: 'member', name: 'Member' },
        { id: 'director', name: 'Director' },
        { id: 'agent', name: 'Agent' },
        { id: 'other', name: 'Other' }
    ]

    fs = new FieldSet({
        fields: [
            FormTextComponent.make('Your first name', 'firstName',{validators: [required]}),
            FormTextComponent.make('Your last name', 'lastName', { validators: [required] }),
            FormPhoneComponent.make('Your Phone Number', 'phoneNumber', { validators: [required] }),
            FormEmailComponent.make('Your Email Address', 'email', { validators: [required] }),
            FormTextComponent.make('Owner Management Company Name', 'omcName', { validators: [required] }),
            FormPicklistComponent.make('Your Role', 'roleId', '', { items: this.roles }, { validators: [required] }),
            FormNumberComponent.make('How many units in your OMC', 'expectedUnits').override({ validators: [required, minValue(2)] }),
        ],
        formLayout: LAYOUT_OPTIONS.singleCol},
        true
    );

    constructor( public screenSize: IsNarrowService) {
    }

}
