/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { first } from 'rxjs/operators';
import { Notice } from 'src/app/model/notice';
import { User } from 'src/app/model/user';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { NavigationService } from 'src/app/shared/navigation/navigation.service';
import { NoticeService } from '../notice.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { MatDialog } from '@angular/material/dialog';
import { MyRequestService } from 'src/app/modules/crm/my-request.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { MatButtonModule } from '@angular/material/button';
import { Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { TitleCasePipe } from '@angular/common';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { HomePageComponentComponent } from '../home-page-component.component';
import { MyInjector } from 'src/app/app.module';

@Component({
  selector: 'app-logged-in',
  standalone: true,
  imports: [MatCardModule, RouterLink, MatButtonModule, FromNowPipe, TitleCasePipe],
  templateUrl: './logged-in.component.html',
  styleUrl: './logged-in.component.scss'
})
export class LoggedInComponent  extends AbstractPageComponent {
    config = new FormConfig({
        navRoute: HomePageComponentComponent.navRoute,
        title: $localize`Notifications`,
        help: $localize`Notifications of events.`,
        allowNew: false,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FormDateTimeComponent.make("Created", "createdAt", { readonly: true }),
                FormTextComponent.make('Type', 'type', { calculateValue: Notice.getType }),
                FormTextComponent.make('Title', 'data.title', { sendServer: false, readonly: true }),
                FormDateTimeComponent.make("Read", "readAt", { readonly: true }),
                FormButtonComponent.make("Mark Read", "mark_email_read",
                    {
                        type: 'icon', toolTip: 'Click to mark as read', label: ' ', cellOpts: { heading: ' ' },
                        calculateValue: () => 'mark_email_read',
                        btnOpts: { clickMethod: this.markRead.bind(this), hide: (o) => (o as Notice).readAt !== null }
                    })
            ],
            formLayout: LAYOUT_OPTIONS.singleCol,
        }),
        service: this.noticeSvc,
        mode: 'list',
        pathEdit: this.pathEdit.bind(this),
        tableHighlighter: (o) => (o as Notice).readAt === null,
    });
    currentUser: User = null;
    loggedIn = false;
    hasTeam = false;
    currentTeam = '';
    myRequests: ServiceRequest[] = [];

    constructor(public currentUserSvc: CurrentUserService, private noticeSvc: NoticeService,
        private dialog: MatDialog, mySvcRequestSvc: MyRequestService,
        private navSvc: NavigationService, public screenSvc: IsNarrowService) {
        super();
        if (!this.currentUserSvc.currentTeam) {
            mySvcRequestSvc.getAccessRequests().subscribe((reqs: ServiceRequest[]) => {
                this.myRequests = reqs;
            })
        }

        this.subscriptions.push(this.currentUserSvc.getCurrentUser().subscribe(user => {
            this.currentUser = user;
            if (user !== null) {
                this.loggedIn = true;
                if (user.currentTeam) {
                    this.hasTeam = true;
                    this.currentTeam = user.currentTeam.name;
                } else {
                    this.hasTeam = false;
                    this.currentTeam = '';
                }
            } else {
                this.loggedIn = false;
                if (window.location.hostname !== 'localhost' && window.location.hostname !== 'devdo.ouromc.ie') {
                    //window.location.href = "http://home.ouromc.ie";
                }
            }
        }));
    }

    pathEdit(n: Notice) {
        this.markRead(n, null);
        return this.navSvc.getModelUrl(n.model, n.modelId);
    }

    markRead(n: Notice, $event) {

        if (n.readAt === null) {
            this.noticeSvc.put(n).pipe(first()).subscribe(returnN => n.readAt = returnN.readAt);
        }

        if ($event) {
            $event.stopPropagation();
        }
    }

    requestOmcAccess() {
        const r = MyInjector.instance.get(Router);
        r.navigate(['/unknownUser']);
    }

    switchRole(pur: PersonUnitRole) {
        this.currentUserSvc.switchTeamRole(pur);
    }

    showExtras() {
        if (!this.currentUser) {
            return false;
        }
        if (this.currentUser.activePositions.length > 1) {
            // I have more than one position to choose from
            return true;
        }
        if (this.currentUser.activePositions.length === 0) {
            return false;
        }
        if (this.currentUser.activePositions.find((o) => this.currentUser.currentRoleId === o.roleId)) {
            // I am in my one available position
            return false;
        } else {
            // I am in an OMC or otherwise mysteriously not in my one available position...
            return true
        }
    }
    /*
        ngOnInit(): void {
            this.currentUserSvc.getCurrentUser().subscribe(currentUser => {
                this.currentUser = currentUser;
                if (currentUser) {
                    this.loggedIn = true;
                }
            });
        }
    */

}
