/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { NavRoute } from 'src/app/shared/NavRoute';
import { UserProfileComponent } from '../user-profile/user-profile.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';

class Token {
    email: string;
    token: string;
    createdAt: number;
    expired: boolean;
    userName: string;
}

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule, MatIconModule]
})
export class RegisterUserComponent {

    static readonly navRoute = new NavRoute('register/:token', RegisterUserComponent, '');

    username = '';
    password = '';
    passwordConfirm = '';
    tokenString = '';
    token: Token;
    acceptablePassword = false;

    passwordStrengthPct = 0;
    passwordStrengthText = "";
    passwordStrengthColor = '#0F0';

    hide = true;

    constructor(activeRoute: ActivatedRoute, private http: HttpClient, private cds: ConfirmDialogService) {
        activeRoute.params.subscribe(parms => {
            this.tokenString = parms.token;
            this.http.get<Token>('api/api/reset/' + this.tokenString).pipe(first()).subscribe(token => {
                if (token.expired) {
                    cds.alert('Invalid Registration Token', 'The link you have used is expired. You could reset again', () => {
                        window.location.pathname = '/login';
                    })
                }
                this.token = token;
                if (token.userName) {
                    this.username = token.userName;
                }

            }, error => {
                console.log(error);
                if (error.status === 404) {
                    cds.alert('Invalid Registration Token', 'The link you have used is invalid. Please connect or register again', () => {
                        window.location.pathname = '/login';
                    })
                }
            })
        });
    }

    showHidePassword() {
        console.log('Toggle Hide');
        this.hide = !this.hide;
    }

    setupSanctumCookie() {
        const httpOptions = {
            headers: new HttpHeaders().set('Content-Type', 'application/json')
        };
        const sanctumUrl = '/api/sanctum/csrf-cookie';
        return this.http.get<Response>(sanctumUrl, httpOptions);
    }

    postPasswordReset() {
        return this.http.post<Token>('api/api/reset/' + this.tokenString, {
            username: this.username,
            password: this.password,
            passwordConfirm: this.passwordConfirm,
            email: this.token.email,
        }).pipe(first());
    }

    resetComplete() {
        this.cds.alert('Welcome to OurOMC', 'You have been successfully logged in. Please make sure you remember that password!', () => {
            window.location.pathname = '/'; // + UserProfileComponent.navRoute.listRoute.path;
        })
    }

    resetFails(error) {
        console.log(error);
        if (error.error && error.error.code === 'TOKEN_EXPIRED') {
            this.cds.alert('Token Expired', 'We are really sorry, but the token expired before registration could be completed. You need to request a fresh token', () => {
                window.location.pathname = '/login';
            })
        } else {
            this.cds.alert('Unknown Error',
                'Really sorry - but your registration could not be completed... If you used a weak password likely the server rejected it', () => {
                    window.location.pathname = '/login';
                })
        }
    }

    register() {
        const title = 'Reset Password';
        const msg = 'This will reset your password to the password entered and log you in. ' +
            'Are you sure you will be able to remember this password? ' +
            'If not, please cancel and save the password with your preferred password manager before proceeding!'
        return this.cds.open(title, msg, this.resetPassword.bind(this));
    }

    resetPassword() {
        this.setupSanctumCookie().subscribe(csrfResult => {
            console.log('I got the CSRF Token', csrfResult);
            this.postPasswordReset().subscribe({
                complete: this.resetComplete.bind(this),
                error: this.resetFails.bind(this)
            })
        });
    }

    calcPasswordStrength() {
        this.passwordStrengthPct = Math.min(this.measureStrength(this.password), 100);
        this.setColor(this.passwordStrengthPct);
    }

    private measureStrength(pass: string) {
        let score = 0;
        // award every unique letter until 5 repetitions
        const letters = {};
        for (let i = 0; i < pass.length; i++) {
            letters[pass[i]] = (letters[pass[i]] || 0) + 1;
            score += 5.0 / letters[pass[i]];
        }
        // bonus points for mixing it up
        const variations = {
            digits: /\d/.test(pass),
            lower: /[a-z]/.test(pass),
            upper: /[A-Z]/.test(pass),
            nonWords: /\W/.test(pass),
        };

        let variationCount = 0;
        for (const check in variations) {
            variationCount += (variations[check]) ? 1 : 0;
        }
        score += (variationCount - 1) * 10;

        // This is the actual server side check - The escape may or may not be useless there!
        // https://stackoverflow.com/questions/31539727/laravel-password-validation-rule
        // Note: \x matches unicode character, has been tralated to \\x
        // eslint-disable-next-line no-useless-escape
        const regex = /^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[\d\\x])(?=.*[!$#%£\^\&\*\()\{\}\[\]:@\~\<\>\.\/\=-_]).*$/;
        if (this.password.length < 7 || !this.password.match(regex)) {
            score = Math.min(29, score);
        } else if (pass.toUpperCase().includes('PASSWORD') || pass.toUpperCase().includes('QWERTY') || pass.includes('1234')) {
            score = score / 3;
        }
        if (score >= 30) {
            this.acceptablePassword = true;
        } else {
            this.acceptablePassword = false;
        }
        return Math.trunc(score);
    }
    private setColor(score: number) {
        if (score > 90) {
            this.passwordStrengthColor = 'green';
            this.passwordStrengthText = 'Excellent - Good Password';
        } else if (score > 70) {
            this.passwordStrengthColor = 'darkolivegreen';
            this.passwordStrengthText = 'Not bad';
        } else if (score >= 40) {
            this.passwordStrengthColor = 'darkorange';
            this.passwordStrengthText = 'Guessable';
        } else if (score >= 30) {
            this.passwordStrengthColor = 'indianred';
            this.passwordStrengthText = 'No Good';
        } else {
            this.passwordStrengthPct = 15;
            this.passwordStrengthColor = '#F00';
            this.passwordStrengthText = 'Bad';
        }

        return score;
    }

}
