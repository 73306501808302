/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject, Injectable } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';

interface CDSAction {
    name: string;
    needsReason?: boolean;
    action?: (reason: string) => void;
    returns?: unknown;
}
export interface CDSData {
    title: string;
    msg: string;
    warning?: boolean;
    needsReason?: boolean;
    asHtml?: boolean;
    options: CDSAction[];
}

/**
 * @title Dialog with header, scrollable content and actions
 */
@Injectable({
    providedIn: 'root'
})
export class ConfirmDialogService {
    cancelOption = { name: $localize`Cancel`, action: () => { console.log('Cancelled action'); }, returns: null }
    okOption = { name: $localize`OK`, action: () => { console.log('Cancelled action'); }, returns: 'OK' }

    constructor(protected dialog: MatDialog) { }

    openConfirm(title: string, msg: string) {
        const options = {
            title, msg, options: [this.okOption, this.cancelOption]
        }
        return this.dialog.open(ConfirmDialogComponent, { disableClose: true, data: options }).afterClosed();
    }

    openChoice(options: CDSData, withCancel = true) {
        if (withCancel) {
            options.options.push(this.cancelOption);
        }

        return this.dialog.open(ConfirmDialogComponent, { disableClose: true, data: options }).afterClosed();
    }

    open(title: string, msg: string, action: () => void, actionName: string = $localize`OK`, needsReason = false) {
        const dataOptions = {
            title, msg, needsReason,
            options: [
                { name: actionName, action, needsReason },
                this.cancelOption,
            ]
        };
        return this.dialog.open(ConfirmDialogComponent, { disableClose: true, data: dataOptions }).afterClosed();
    }

    alert(title: string, msg: string, action: () => void = null, asHtml = false) {
        const okOption = this.okOption;
        if (action) {
            okOption.action = action;
        }
        const dataOptions = {
            title, msg, asHtml,
            options: [
                okOption,
            ]
        };
        return this.dialog.open(ConfirmDialogComponent, { disableClose: true, data: dataOptions });
    }
}

@Component({
    selector: 'app-confirm-dialog',
    template: `<div class="confirmDialog" style="padding-bottom:10px"><div style="padding: 10px">
      <h4 mat-dialog-title>{{data.title}}</h4>
      <div mat-dialog-content>
        <div style="display:flex; align-items: center">
            @if(data.warning) {
                <mat-icon color="accent" style="font-size: 72px;width:72px;height:72px">warning</mat-icon>
            }
            @if (data.asHtml) {
                <div [innerHtml]="data.msg"></div>
            } @else {
                <p>{{data.msg}}</p>
            }
        </div>
        @if (data.needsReason) {
            <textarea [(ngModel)]="reason" placeholder="Reason" rows="4" style="width:100%"></textarea>
        }
      </div>
      <div mat-dialog-actions align="end">
        @for (option of data.options; track option) {
          <button mat-button [disabled]=optionDisabled(option) (click)="clickAction(option)">{{option.name}}</button>
        }
      </div></div></div>`,
    standalone: true,
    imports: [
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatButtonModule,
        FormsModule,
        MatIconModule,
    ],
})

export class ConfirmDialogComponent {
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent, unknown>,
        protected dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: CDSData) { }

    reason = '';

    optionDisabled(option: CDSAction) {
        if (option.needsReason && this.reason.length < 5) {
            return true;
        }
        return false;
    }

    closeMe(): void {
        this.dialogRef.close();
    }

    clickAction(option: CDSAction) {
        if (option.action) {
            option.action(this.reason);
        }
        this.dialogRef.close(option.returns);
    }
}
