/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Question } from 'src/app/model/ballot';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';


@Injectable({
  providedIn: 'root'
})
export class QuestionService extends AbstractHttpService {
  protected baseUrl = this.ajaxPath + 'social/questions';

  protected cache: Question[];
  protected typeString = 'Question';

  constructor(protected http: HttpClient,
    protected messageService: MessageService) {
    super();
  }

}
