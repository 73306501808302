/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Notice } from 'src/app/model/notice';

@Injectable({
    providedIn: 'root'
})
export class NoticeService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'notices';
    protected cache: Notice[];
    protected typeString = 'Notice';

    protected hasRevision = false;

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
        this.msgUpdated = null; //  Suppress notice when marking read...
    }
}
