/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Role } from 'src/app/model/role';

@Injectable({
    providedIn: 'root'
})
export class RoleService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'settings/roles';
    protected cache: Role[];
    protected typeString = 'Roles';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
