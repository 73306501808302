/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AgentSettingsService } from './agent-settings.service';
import { maxChars, required } from 'src/app/shared/validators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { MatCardModule } from '@angular/material/card';
import { TextEditComponent } from 'src/app/shared/simpleFields/text-edit/text-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { EditFieldDialogComponent, EditFieldDialogOptions } from 'src/app/shared/dialogs/edit-field-dialog/edit-field-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { AgentSettings } from 'src/app/model/AgentSettings';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';

@Component({
  selector: 'app-agent-settings',
  standalone: true,
  imports: [MatCardModule, TextEditComponent],
  templateUrl: './agent-settings.component.html',
  styleUrl: './agent-settings.component.scss'
})
export class AgentSettingsComponent {
    /** If you are editing here, you likely need to make same change in OmcSettingsComponent */
    static readonly navRoute = new NavRoute('agentSettings', AgentSettingsComponent, 'settings_applications');
    agentSettingsSvc = inject(AgentSettingsService);
    dialog = inject(MatDialog);
    cus = inject(CurrentUserService);
    cds = inject(ConfirmDialogService);


    team = new AgentSettings();

    constructor() {
        this.agentSettingsSvc.getOne('agentSettings', null).subscribe(team => {
            if (team) {
                this.team = team
            }
        });
    }

    deleteParameter(name: string, value: string) {
        if (!this.getParameter(name)) {
            this.cds.alert(
                $localize`Cannot remove parameter`,
                $localize`${value} is the default value for ${name}, you cannot remove the parameter, you can edit to override it`,
            );
        } else {
            this.cds.open(
                $localize`Remove Setting`,
                $localize`Do you wish to remove the '${name}' setting and revert to the default value`,
                () => this.agentSettingsSvc.deleteParameter(name, value, this.team).subscribe(x => this.team = x)
            );
        }
    }

    getParameter(name: string) {
        return this.team.parameters.find(p => p.name === name);
    }

    editName() {
        this.editField(FieldMaker.nameControl({ validators: [required] }));
    }

    editAddress() {
        this.editField(FormTextAreaComponent.make('Edit Business Address', 'defaultAddress', {
            validators: [required, maxChars(255)],
        }))
    }

    editMailSendingName() {
        const field = FormTextComponent.make('The name to send mails as', 'mailSendingName',
            {
                validators: [required],
                hint: 'Usually you can leave the default value here, and let the system use the name of the OMC, but enter a value here to override it'
            })
        this.editField(field);
    }

    editMailSendingAccount() {
        const field = FormTextComponent.make('The email account to send mail', 'mailSendingAccount',
            {
                validators: [required],
                hint: 'Usually you can leave the default value here, and let the system use the ID of the OMC, the account used here must exist in your sending domain'
            })
        this.editField(field);
    }

    editReplyToMail() {
        const field = FormEmailComponent.make('Email Address if recipient presses reply', 'replyToMail',
            {
                validators: [required],
                hint: `Note: It can reduce your mails deliverability if the replyTo does not match the sender email address,
                especially if the address you use is a free account, e.g. gmail etc. Unless you really know what you are doing
                it is usually best to leave the replyToMail alone. If reply comes back to OurOMC we will create a request for you.
                `
            })
        this.editField(field);
    }

    editBillingAddress() {
        this.editField(FormTextAreaComponent.make('Billing Address', 'billingAddress', {
            validators: [required, maxChars(255)],
            hint: 'This will be the default billing address for all of your OMCs unless you change the settings on the OMC itself'
        }))
    }


    editMailSendingDomain() {
        this.editField(FormTextComponent.make('Mail Sending Domain', 'mailSendingDomain', {
            hint: 'Must be configured by OurOMC',
            disable: !this.cus.isUber(),
            validators: [required, maxChars(255)],
        }))
    }

    editMailReplyToDomain() {
        this.editField(FormTextComponent.make('Mail Reply To Domain', 'mailReplyToDomain', {
            hint: 'Must be configured by OurOMC',
            disable: !this.cus.isUber(),
            validators: [required, maxChars(255)],
        }))
    }

    editLegalName() {
        const field = FormTextComponent.make('Legal Company Name', 'legalName', { validators: [required] })
        this.editField(field);
    }

    editPaymentTerms() {
        this.editField(FormTextAreaComponent.make('Default Payment Terms', 'paymentTerms',
            { validators: [required, maxChars(255)],
              hint: 'This will be the default billing address for all of your OMCs unless you change the settings on the OMC itself'
            }
        ));
    }

    editField(field: Field) {
        const data = new EditFieldDialogOptions({
            field,
            id: 'agentSettings',
            service: this.agentSettingsSvc,
        });
        this.dialog.open(EditFieldDialogComponent,{data}).afterClosed().subscribe((updated) => {
            if (updated) {
                this.team = updated;
            }
        })
    }
}
