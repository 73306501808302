/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Txn } from 'src/app/model/txn';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { BCodeService } from 'src/app/modules/budget/bcode.service';
import { InvoiceService } from '../invoice.service';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { PersonService } from 'src/app/pages/person-page/person.service';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { UnitService } from '../../unit/unit.service';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { nonZero, required, validateDate, validateTxnDate } from 'src/app/shared/validators';
import { CurrentUserService } from '../../user/current-user.service';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import moment from 'moment';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { Unit } from 'src/app/model/unit';
import { first } from 'rxjs/operators';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { HttpParams } from '@angular/common/http';
import { BCode } from 'src/app/model/bcode';
import { ScheduleService } from '../../budget/schedule.service';
import { Schedule } from 'src/app/model/schedule';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { User } from 'src/app/model/user';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { MailInvoicePDFAction } from './mail-invoice-pdf-action';
import { MailService } from 'src/app/shared/mail-service';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';

@Component({
    selector: 'app-invoice-page',
    templateUrl: './invoice-page.component.html',
    styleUrls: ['./invoice-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class InvoicePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(Txn.TYPE.INVOICE.code, InvoicePageComponent, 'sell').setViews((u: User) => {
        return [
            {
                id: 'currentYear',
                name: 'Current Year',
                filterFields: {
                    refNr: null,
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                    txnPeriodId: 'All',
                },
            }, {
                id: 'outstanding',
                name: 'Outstanding',
                filterFields: {
                    outstanding: '>=0.01',
                },
                sort: 'createdAt'
            }
        ]
    });

    readonly path = Txn.TYPE.INVOICE.code

    bCodes: BCode[] = [];

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { service: this.cycleSvc }, { formColumn: 2, visible: Field.noShow }
    );

    periodField: Field = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        {
            formColumn: 2, visible: Field.noShow,
            refresh: (o: Cycle, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.periods; control.setValue(null); } },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' }, formColumn: 3,
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    childTxn = this.getChildGrid();

    configReady = new ReplaySubject<null>(1);
    currentCycle: Cycle;
    schedules: Schedule[] = [];

    paymentGrid = new GridField({
        field:
            { label: 'Payments Received', value: 'relations', visible: Field.formOnly, formRow: 2, formColumn: 2, sendServer: false },
        rowFactory: (o: Txn) => [
            FormButtonComponent.makeLink('Ref', 'parent.refNr', o ? Txn.getTxnLink(o) : '', {
                readonly: true, sendServer: false,
                cellOpts: { width: "5em", maxWidth: "5em" },
            }),
            FormDateComponent.make('Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
            FormNumberComponent.make("Amount", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
            FieldMaker.notes(),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ]
    });

    debitField = FormNumberComponent.make("Total", "debit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, validators: [nonZero] }
    );

    outstandingField = FormNumberComponent.make("Outstanding", "outstanding",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, disable: true }
    );

    idField = FieldMaker.id();
    refField = FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true, formColumn: 3 });

    revField = FieldMaker.rev({ visible: Field.showAll, readonly: true, formColumn: 3 });

    unitEditField = FormComboBoxComponent.make('Unit', 'unitId', 'unit',
        {
            service: this.unitSvc, optionDisplayValue: (o: Unit) => Unit.getFullName(o),
            refreshes: ['personId', 'personFullName', 'Address']
        },
        { formColumn: 1, validators: [required], visible: Field.formOnly },
    );

    unitTableField = FormButtonComponent.makeNavDetailButton('Unit', 'unit.name', 'unit.id', UnitPageComponent.navRoute)
    .override({visible:  Field.noForm});

    mailItemGrid = this.mailSvc.getMailItemGrid(this.dataSvc, 'model');

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc, { readonly: true});

    config = new FormConfig({
        navRoute: InvoicePageComponent.navRoute,
        title: Txn.TYPE.INVOICE.name,
        help: $localize`Service Charge Billings`,
        readonly: true,
        fieldSet: new FieldSet({
            fields: [

                this.idField,
                this.refField,

                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
                FormTextComponent.make('bCodeId', 'bCodeId', { visible: Field.noShow }),
                FieldMaker.idHolder('billingId'),

                /* txtDateField Validator overwrites period info, need this order! */
                this.cycleField,
                this.periodField,
                this.txnDateField,
                this.revField,
                this.unitEditField,
                this.unitTableField,

                FormDateComponent.make('Services From', 'svcFrom', {
                    cellOpts: { width: '2%' }, formColumn: 1,
                    validators: [validateDate]
                }),
                FormDateComponent.make('Services To', 'svcTo', {
                    cellOpts: { width: '2%' }, formColumn: 1,
                    validators: [validateDate]
                }),

                FormTextComponent.make('Person', 'personId', {
                    visible: Field.noShow,
                    refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(o.owner?.id),
                    formColumn: 3
                }),

                FormButtonComponent.makeLink('Person', 'personFullName', '/people/${personId}', {
                    cellOpts: { width: '20%' }, disable: true,
                    calculateValue: (o: Txn) => Person.fullName(o.person),
                    refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(Person.fullName(o?.owner)),
                    formColumn: 2
                }),
                FormTextAreaComponent.make('Address', 'unit.address',
                    {
                        name: 'Address', formColumn: 2, readonly: true, disable: true, sendServer: false, visible: Field.formOnly,
                        refresh: (o: Unit, ctl: AppFormControl) => ctl.setValue(o?.address),
                    }),

                this.debitField,
                this.outstandingField,

                //FormNumberComponent.make("Paid To Date", "debit", { name: 'debit2', formRow: 1, formColumn: 4, readonly: true, numberOpts: { format: 'currency', width: 9, formatParms: '1.2-2' } }),

                FormNumberComponent.make("Pct Owed", "outstandingPct",
                    { format: 'percent', width: 9, formatParms: '1.2-2' },
                    {
                        formRow: 1, formColumn: 4, readonly: true, disable: true,
                        calculateValue: (o: Txn) => (o.debit ? o.outstanding / o.debit : 0),
                        sendServer: false,
                    }),
                this.childTxn,
                this.paymentGrid,
            ],
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 2, pageTab: 'yes' }, { colspan: 2, pageTab: 'yes' }] }
            ],
            formValidator: this.validateInvoice.bind(this),
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.enhanceObject.bind(this),
        actions: [new MailInvoicePDFAction()],
        newOptions: [
            { name: $localize`New ${Txn.TYPE.INVOICE.name}`, basePath: `${Txn.TYPE.INVOICE.code}/NEW` },
            { name: $localize`New ${Txn.TYPE.CREDIT_NOTE.name}`, basePath: `${Txn.TYPE.CREDIT_NOTE.code}/NEW` }
        ],
        tabFields: [this.attachmentGrid, this.mailItemGrid]
    });

    constructor(public dataSvc: InvoiceService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute,
        protected unitSvc: UnitService, public personSvc: PersonService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected scheduleSvc: ScheduleService,
        private currentUserSvc: CurrentUserService, private mailSvc: MailService,
        @Optional() public dialogRef: MatDialogRef<InvoicePageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        const bcParms = new HttpParams().set('typeId', BCode.TYPE.INCOME.id + ',' + BCode.TYPE.LIABILITY.id+ ',' + BCode.TYPE.CAPITAL.id)

        combineLatest([currentUserSvc.getCurrentUser(),
        this.scheduleSvc.get(true).pipe(first()),
        this.cycleSvc.get(true).pipe(first()),
        this.bCodeSvc.get(false, bcParms),
        this.currentUserSvc.getDefaultBCodes()
        ]).subscribe(
            ([currentUser, schedules, cycles, bCodes]) => {
                this.currentUser = currentUser;
                this.schedules = schedules as Schedule[];
                this.bCodes = bCodes as BCode[];
                this.currentCycle = this.cycleSvc.getCurrentCycle();
                this.cycleField.picklist.items = cycles;

                this.configReady.next(null);
            });
    }

    getChildRow() {
        const bCodeField = FormPicklistComponent.make('Account', 'bCodeId', 'bCode',
            { items: this.bCodes },
            { cellOpts: { width: '25%' } }
        );
        return [
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),

            FormPicklistComponent.make('Schedule', 'scheduleId', 'schedule',
                {
                    items: this.schedules, refreshes: [(schedule: Schedule) => {
                        console.log('Value ', schedule);
                        //bCodeField.setValue(schedule.incomeBCode);
                        bCodeField.control.setValue(schedule.incomeBCodeId);
                        console.log({ bCodeField, schedule, val: bCodeField.control.value })
                    }]
                },
            ),
            bCodeField,
            FormNumberComponent.make("Amount", "credit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.invoiceValueChanges.bind(this) }
            ),
            FieldMaker.notes({ cellOpts: { width: '50%' } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ];
    }

    getChildGrid() {

        return new GridField({
            field:
                { label: 'Charge Details', value: 'details', visible: Field.formOnly, formRow: 2 },
            rowFactory: this.getChildRow.bind(this),
            objFactory: this.newChildTxn.bind(this),
        });
    }

    validateInvoice() {

        if (this.outstandingField.control && this.outstandingField.control.value < 0) {
            return FormError.reportError('outstandingSubZero',
                $localize`You cannot change a service charge such that outstanding value is less than zero`);
        }
        return null;
    }
    /*
    * Readonly for displaying, allow entry only
    */
    enhanceObject(o: Txn) {
        //this.config.readonly = true;
        this.config.fieldSet.formLayout = [
            { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
            { cells: [{ colspan: 2, pageTab: 'yes' }, { colspan: 2, pageTab: 'yes' }] }
        ];
        this.paymentGrid.visible = Field.formOnly;

        this.revField.visible = Field.showAll;

        return o;
    }

    newFactory(): Observable<Txn> {
        this.config.fieldSet.formLayout = [
            { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
            { cells: [{ colspan: 4, pageTab: 'yes' }, { colspan: 2, pageTab: 'yes' }] }
        ];
        this.paymentGrid.visible = Field.noShow;

        this.revField.visible = Field.noShow;

        const txn = new Txn();
        this.config.readonly = false;

        txn.ledgerId = Txn.LEDGER.AR.id;
        txn.typeId = Txn.TYPE.INVOICE.id;

        txn.bCodeId = this.currentUserSvc.getDefaultBCode('Debtors').bCodeId;
        //txn.bCode = this.currentUser.currentTeam.debtorsBCode;

        txn.txnDate = moment().toISOString().substring(0, 10);
        txn.txnCycleId = this.cycleSvc.getCurrentCycle().id;
        txn.txnPeriodId = this.cycleSvc.getCurrentPeriod().id;

        txn.svcFrom = this.cycleSvc.getCurrentCycle().from;
        txn.svcTo = this.cycleSvc.getCurrentCycle().to;

        for (const s of this.schedules) {
            const c = this.createChildItem();
            c.txnDate = moment().toISOString().substring(0, 10);
            c.txnCycleId = this.cycleSvc.getCurrentCycle().id;
            c.txnPeriodId = this.cycleSvc.getCurrentPeriod().id;
            c.bCodeId = s.incomeBCodeId;
            c.scheduleId = s.id;
            c.schedule = s;
            txn.details.push(c);
        }
        // txn.led
        // txn.typeId = Txn.TYPE.INVOICE.id;
        // txn.bCodeId = Defaulted on the server?
        return of(txn);
    }

    createChildItem(): Txn {
        const txn = new Txn();
        txn.ledgerId = Txn.LEDGER.AR.id;
        txn.typeId = Txn.TYPE.INVOICE_ITEM.id;
        txn.txnDate = this.txnDateField.control.value;
        txn.txnPeriodId = this.periodField.control.value;
        txn.txnCycleId = this.cycleField.control.value;
        if (this.schedules.length === 1) {
            txn.scheduleId = this.schedules[0].id;
        }
        return txn;
    }

    newChildTxn(): Observable<Txn> {

        return of(this.createChildItem());
    }

    dateValueChanges(): void {
        const allocations = (this.childTxn.control as GridControl).controls;
        if (allocations) {
            for (const txnRow of allocations) {
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            }
        }
    }

    invoiceValueChanges(): void {
        const txns = (this.childTxn.control as GridControl).gridRows();
        let total = 0;

        if (txns) {
            for (const tRow of txns) {
                total += tRow.get('credit').value;
            }
        }
        const pays = this.paymentGrid.control.gridRows();
        let paid = 0;
        if (pays) {
            for (const pay of pays) {
                paid += pay.get('credit').value;
            }
        }
        this.debitField.control.setValue(total);
        this.outstandingField.control.setValue(total - paid);

    }

}
