/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { Team } from "./team";

export type uuid = string | number;

export class AbstractObjectList {
    itemList: AbstractObject[];
}
export abstract class AbstractObject {
    id: uuid = null; // New Items created in UI will have id = null until saved to server.
    revision?: number = 0;
    name? = '';
    teamId?: uuid;
    team?: Team;
    createdAt?: number; // date!
    updatedAt?: number; //date!
    deletedAt?: number; // date!
    active?: boolean;

    notes?: string = '';
    deleted?: boolean;

    constructor(data: Partial<AbstractObject> = {}) {
        this.overrideValues(data);
    }

    overrideValues?(data: Partial<AbstractObject> = {}) {
        Object.getOwnPropertyNames(data).forEach((prop) => { this[prop] = data[prop]; });
    }
}
