/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { MyInjector } from "src/app/app.module";
import { Cycle } from "src/app/model/cycle";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { CycleService } from "../cycle.service";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { CyclePageComponent } from "./cycle-page.component";

export class NextCycleAction implements IFormAction {
    name = $localize`Create Next`;
    color: ActionColor = 'primary';
    show = true;
    icon = 'skip_next';
    approvalNeeded = true;
    disabled = false;
    approvalText = "Create the next annual cycle using all of the data from this cycle as the default values";
    tipText: string = $localize`Creates next cycle / financial year`;

    action(cycle: Cycle) {
        console.log(cycle);
        const cs = MyInjector.instance.get(CycleService);

        return cs.createNext(cycle).pipe( tap((next:Cycle) => {
            const router = MyInjector.instance.get(Router);
            router.navigate([CyclePageComponent.navRoute.getIdUrl(next.id)]);
        }));
    }

    //  setup(serviceRequest: ServiceRequest) {
    setup(cycle: Cycle) {
        console.log(cycle);
        this.show = true;
    }
}
