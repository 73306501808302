/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { ButtonField } from '../../field/ButtonField';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { AbstractObject } from 'src/app/model/abstract-object';

@Component({
  selector: 'app-link-table-cell',
  standalone: true,
  imports: [ MatTooltipModule, RouterModule, MatButtonModule],
  templateUrl: './link-table-cell.component.html',
  styleUrl: './link-table-cell.component.scss'
})
export class LinkTableCellComponent {
    @Input({ required: true }) field: ButtonField;
    @Input({ required: true }) focusItem: AbstractObject;
}
