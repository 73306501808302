/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject, OnInit } from '@angular/core';
import { GridField } from '../../grid/grid-field';
import { IsNarrowService } from '../../is-narrow.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Observable } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { CtlHolderComponent } from '../../form/ctl-holder/ctl-holder.component';

class GridDialogParms {
    gridField: GridField;
    saveFn: () => Observable<AbstractObject>;
    value: AbstractObject[];
    width?: number;
    height?: number;
}

@Component({
    selector: 'app-grid-dialog',
    templateUrl: './grid-dialog.component.html',
    styleUrls: ['./grid-dialog.component.scss'],
    standalone: true,
    imports: [CtlHolderComponent, MatButtonModule]
})
export class GridDialogComponent implements OnInit {

    width = 400;
    height = 800;

    gridField: GridField;

    constructor(private isNarrowSvc: IsNarrowService,
    public dialogRef: MatDialogRef<GridDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private dialogParms: GridDialogParms ) {

        this.gridField = dialogParms.gridField;
        if (!this.gridField.control) {
            this.gridField.makeControl();
        }
    }

    ngOnInit(): void {
        this.width = this.dialogParms.width ? this.dialogParms.width : this.isNarrowSvc.screenWidth * .8;
        this.height = this.dialogParms.height ? this.dialogParms.height : this.isNarrowSvc.screenHeight * .8;
    }

    cancel() {
        this.dialogRef.close();
    }

    saveGrid() {
        if (this.dialogParms.saveFn) {
            this.dialogParms.saveFn().subscribe( x => {
                this.dialogRef.close(x);
            });
        } else {
            console.error('Need a save method that provides an observable...');
        }
    }

    isSaveable() {
        if (this.gridField.control.valid && this.gridField.control.dirty) {
            return false;
        } else {
            return true;
        }
    }
}
