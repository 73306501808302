/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { uuid } from 'src/app/model/abstract-object';
import { BCode } from 'src/app/model/bcode';
import { Period } from 'src/app/model/period';
import { Schedule } from 'src/app/model/schedule';
import { Txn } from 'src/app/model/txn';
import { DateHelper } from 'src/app/shared/dateHelper';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridRow } from 'src/app/shared/grid/grid-row';

export class PrepaymentLedgers {

    lineItemId: uuid;
    firstSetupDone = false;
    reverseExpense: GridRow;
    createAsset: GridRow;
    periods: Period[];
    txnPeriod: Period;
    expenseAccount: BCode;
    prepaidAccount: BCode;
    schedule: Schedule;
    amount: number;
    svcFrom: string; // moment.Moment;
    svcTo: string; // moment.Moment;
    grid: GridField;

    txnDate: string;

    writeDowns: Map<uuid, { chargeExpense: GridRow; reduceAsset: GridRow; }> = new Map();

    public setup(lineItemId: uuid, txnDate: string, expenseAccount: BCode, schedule: Schedule, prepaidAccount: BCode,
        amount: number, from: string, to: string, periods: Period[], grid: GridField) {
        this.lineItemId = lineItemId;
        this.periods = periods;
        this.txnDate = txnDate;
        this.txnPeriod = this.periods.find(p => p.from <= this.txnDate && p.to >= this.txnDate);
        if (!this.txnPeriod) {
            console.error('Cannot find transaction period ', { txnDate, periods });
        }
        this.expenseAccount = expenseAccount;
        this.prepaidAccount = prepaidAccount;
        this.amount = amount;
        this.svcFrom = from; //moment(from, DateHelper.FMT);
        this.svcTo = to; //moment(to, DateHelper.FMT);
        this.schedule = schedule;
        this.grid = grid;

        if (!this.firstSetupDone) {
            this.doFindEntries();
            this.firstSetupDone = true;
        }
        this.doReverseExpense();
        this.doCreateAsset();
        this.doWriteDowns();
        this.doAdjustToRoundedTotals();
    }

    doFindEntries() {
        for (const row of this.grid.control.gridRows()) {
            if (row.get('relatedId').value === this.lineItemId) {
                if (row.get('typeId').value === Txn.TYPE.PREPAY_ASSET.id) {
                    this.createAsset = row;
                    console.log('Found Asset');
                } else if (row.get('typeId').value === Txn.TYPE.PREPAY_REV_EXP.id) {
                    this.reverseExpense = row;
                    console.log('Found rev exp');
                } else {
                    const periodId = row.get('txnPeriodId').value;
                    let wd = this.writeDowns.get(periodId);
                    if (!wd) {
                        this.writeDowns.set(periodId, { chargeExpense: null, reduceAsset: null });
                        wd = this.writeDowns.get(periodId);
                    }
                    if (row.get('typeId').value === Txn.TYPE.PREPAY_CHARGE.id) {
                        wd.chargeExpense = row;
                        console.log('Found charge exp');
                    } else if (row.get('typeId').value === Txn.TYPE.PREPAY_WRITEDOWN.id) {
                        wd.reduceAsset = row;
                        console.log('Found writedown asset');
                    }
                }
            }
        }
    }

    public getFormValue(): Txn[] {
        const journals: Txn[] = [(this.createAsset.getFormValue() as Txn), (this.reverseExpense.getFormValue() as Txn)];
        this.writeDowns.forEach(value => {
            journals.push(value.chargeExpense.getFormValue() as Txn);
            journals.push(value.reduceAsset.getFormValue() as Txn);
        })
        return journals;
    }

    public getTxns(): Txn[] {
        return [];
    }

    private doAdjustToRoundedTotals() {
        let total = 0;
        this.writeDowns.forEach(value => {
            if (!value.chargeExpense.deleted) {
                total += value.chargeExpense.get('debit').value;
            }
        })
        this.reverseExpense.get('credit').setValue(total, { emitEvent: false });
        this.createAsset.get('debit').setValue(total, { emitEvent: false });
    }

    private doCreateAsset() {
        if (!this.createAsset) {
            const txn = new Txn();
            txn.ledgerId = Txn.LEDGER.AP.id;
            txn.typeId = Txn.TYPE.PREPAY_ASSET.id;
            this.createAsset = this.grid.control.addRow(txn, true, true);
        }
        this.createAsset.get('txnDate').setValue(this.txnDate, { emitEvent: false });
        this.createAsset.get('txnCycleId').setValue(this.txnPeriod.cycleId, { emitEvent: false });
        this.createAsset.get('txnPeriodId').setValue(this.txnPeriod.id, { emitEvent: false });
        this.createAsset.get('scheduleId').setValue(this.schedule.id, { emitEvent: false });
        this.createAsset.get('bCodeId').setValue(this.prepaidAccount.id, { emitEvent: false });
        this.createAsset.get('debit').setValue(this.amount, { emitEvent: false }); // Rounding!!!
        this.createAsset.get('credit').disable({ emitEvent: false });
    }

    private doReverseExpense() {
        if (!this.reverseExpense) {
            const txn = new Txn();
            txn.ledgerId = Txn.LEDGER.AP.id;
            txn.typeId = Txn.TYPE.PREPAY_REV_EXP.id;
            this.reverseExpense = this.grid.control.addRow(txn, true, true);
        }
        this.reverseExpense.get('txnDate').setValue(this.txnDate, { emitEvent: false });
        this.reverseExpense.get('txnCycleId').setValue(this.txnPeriod.cycleId, { emitEvent: false });
        this.reverseExpense.get('txnPeriodId').setValue(this.txnPeriod.id, { emitEvent: false });
        this.reverseExpense.get('scheduleId').setValue(this.schedule.id, { emitEvent: false });
        this.reverseExpense.get('bCodeId').setValue(this.expenseAccount.id, { emitEvent: false });
        this.reverseExpense.get('credit').setValue(this.amount, { emitEvent: false }); // Rounding!!!
        this.reverseExpense.get('debit').disable({ emitEvent: false })
    }

    private doWriteDowns() {
        for (const p of this.periods) {
            if (p.from <= this.svcTo && p.to >= this.svcFrom) {
                if (!this.writeDowns.get(p.id)) {
                    this.writeDowns.set(p.id, { chargeExpense: null, reduceAsset: null });
                }
                this.doChargeExpense(p);
                this.doReduceAsset(p);
            } else {
                const wd = this.writeDowns.get(p.id);
                if (wd) {
                    if (!wd.chargeExpense.deleted) {
                        wd.chargeExpense.delete();
                    }
                    if (!wd.reduceAsset.deleted) {
                        wd.reduceAsset.delete();
                    }

                    // Don't always delete it, may need to be sent to server to do the delete...
                    if (!wd.chargeExpense.get('id').value && !wd.reduceAsset.get('id').value) {
                        this.writeDowns.delete(p.id);
                    }
                }
            }
        }
    }

    private doChargeExpense(period: Period) {
        const pTxns = this.writeDowns.get(period.id);
        if (!pTxns.chargeExpense) {
            pTxns.chargeExpense = this.grid.control.addRow(
                new Txn({ ledgerId: Txn.LEDGER.AP.id, typeId: Txn.TYPE.PREPAY_CHARGE.id }), true, true
            );
        } else if (pTxns.chargeExpense && pTxns.chargeExpense.deleted) {
            pTxns.chargeExpense.undelete();
        }
        pTxns.chargeExpense.get('txnDate').setValue(period.from, { emitEvent: false });
        pTxns.chargeExpense.get('txnCycleId').setValue(period.cycleId, { emitEvent: false });
        pTxns.chargeExpense.get('txnPeriodId').setValue(period.id, { emitEvent: false });
        pTxns.chargeExpense.get('bCodeId').setValue(this.expenseAccount.id, { emitEvent: false });
        pTxns.chargeExpense.get('scheduleId').setValue(this.schedule.id, { emitEvent: false });
        pTxns.chargeExpense.get('debit').setValue(this.calcWriteDown(period), { emitEvent: false }); // Rounding!!!
        pTxns.chargeExpense.get('credit').disable({ emitEvent: false });
        const costPerDay = (pTxns.chargeExpense.get('credit') as AppFormControl).field.formatValue(this.calcCostPerDay());
        pTxns.chargeExpense.get('notes').setValue(
            $localize`${this.calcDaysPrepaid(period)} days @ ${costPerDay} per day`, { emitEvent: false });
    }

    private doReduceAsset(period: Period) {
        const pTxns = this.writeDowns.get(period.id);
        if (!pTxns.reduceAsset) {
            pTxns.reduceAsset = this.grid.control.addRow(
                new Txn({ ledgerId: Txn.LEDGER.AP.id, typeId: Txn.TYPE.PREPAY_WRITEDOWN.id }), true, true
            );
        } else if (pTxns.reduceAsset && pTxns.reduceAsset.deleted) {
            pTxns.reduceAsset.undelete();
        }
        pTxns.reduceAsset.get('txnDate').setValue(period.from, { emitEvent: false });
        pTxns.reduceAsset.get('txnCycleId').setValue(period.cycleId, { emitEvent: false });
        pTxns.reduceAsset.get('txnPeriodId').setValue(period.id, { emitEvent: false });
        pTxns.reduceAsset.get('bCodeId').setValue(this.prepaidAccount.id, { emitEvent: false });
        pTxns.reduceAsset.get('scheduleId').setValue(this.schedule.id, { emitEvent: false });
        pTxns.reduceAsset.get('credit').setValue(this.calcWriteDown(period), { emitEvent: false }); // Rounding!!!
        pTxns.reduceAsset.get('debit').disable({ emitEvent: false });
    }

    private calcWriteDown(period: Period) {
        return Math.floor(this.calcCostPerDay() * this.calcDaysPrepaid(period) * 100) / 100;
    }

    private calcDaysPrepaid(period: Period) {
        const start = period.from > this.svcFrom ? period.from : this.svcFrom;
        const end = period.to < this.svcTo ? period.to : this.svcTo;
        return DateHelper.isoDaysBetween(start, end) + 1;
    }

    private calcCostPerDay() {
        const days = DateHelper.isoDaysBetween(this.svcFrom, this.svcTo) + 1;
        return this.amount / days;
    }
}
