/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from './abstract-object';
import { Core } from './core';

export class Area extends AbstractObject {
    cores: Core[] = [];
  constructor(o: Partial<Area> = {}) {
    super(o);
  }
}
