/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Frequency } from 'src/app/model/frequency';
import { Unit } from 'src/app/model/unit';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { CurrencyPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormPicklistComponent as FormPicklistComponent_1 } from '../../../../shared/form/form-picklist/form-picklist.component';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-pay-plan',
    templateUrl: './pay-plan.component.html',
    styleUrls: ['./pay-plan.component.scss'],
    standalone: true,
    imports: [MatCardModule, FormPicklistComponent_1, MatButtonModule, CurrencyPipe]
})
export class PayPlanComponent {

    @Input() unit: Unit;
    @Input() cycle: Cycle;

    frequencies = Frequency.PAYMENT_OPTIONS;
    frequencyField = FormPicklistComponent.make('Frequency', 'frequencyId', 'frequency',
        { items: Frequency.PAYMENT_OPTIONS }, { valueChanges: this.frequencyUpdates.bind(this) });

    frequencyCtl = this.frequencyField.makeControl() as AppFormControl;

    frequencyUpdates(newFrequency: number, freqencyField: Field) {
        console.log({ newFrequency, freqencyField });
        this.unit.frequencyId = newFrequency;
        this.unit.frequency = Frequency.PAYMENT_OPTIONS.find(o => o.id === newFrequency);
        Unit.calculatePaymentSchedule(this.unit, this.cycle);
    }
}
