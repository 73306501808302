/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { AbuChart } from "./AbuChart";

export class AbuMyOmcChart extends AbuChart {
    title = "Action Creation for My OMCs";
    dataParams = new HttpParams().set('week', 0).set('show', 'myomcs');
}
