/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { Person } from 'src/app/model/person';
import { RoleService } from 'src/app/pages/role-page/role.service';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { required } from 'src/app/shared/validators';
import { TeamUserService } from './team-user.service';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { CurrentUserService } from '../current-user.service';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { TeamUser } from 'src/app/model/team-user';
import { Role } from 'src/app/model/role';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';

@Component({
    selector: 'app-team-user-page',
    templateUrl: './team-user-page.component.html',
    styleUrls: ['./team-user-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class TeamUserPageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('tusers', TeamUserPageComponent, 'people_alt')
        .setViews(() => [
            {
                id: 'nonUnit',
                name: 'Non Unit Related',
                filterFields: {
                    unitName: 'null',
                    roleId: 'All',
                    current: 1,
                },
            },
            {
                id: 'unitRelated',
                name: 'Unit Related Roles',
                filterFields: {
                    unitName: 'not null',
                    roleId: 'All',
                    current: 1,
                },
            },
            {
                id: 'noEmailAvailable',
                name: 'People with no email',
                filterFields: {
                    unitName: 'not null',
                    email: 'null',
                    roleId: 'All',
                    current: 1,
                },
            },
            {
                id: 'users',
                name: 'All Registered Users',
                filterFields: {
                    userName: 'not null',
                    roleId: 'All',
                    current: 1,
                },
            },
            {
                id: 'nonUsers',
                name: 'Profiles Never Registered',
                filterFields: {
                    userName: 'null',
                    current: 1,
                },
            },
        ]);

    roles: Role[] = [];

    auditGrid: GridField = new GridField({
        field: {
            value: 'auditEntries', cellOpts: { heading: 'Actions Taken' }, formColumn: 1, formRow: 2,
            visible: Field.formOnly, readonly: true, sendServer: false
        },
        rowFactory: () => [
            FormDateTimeComponent.make('Date', 'date', { readonly: true }),
            FormTextComponent.make('Object', 'object', { readonly: true }),
            FormTextComponent.make('Object Id', 'objectId', { readonly: true }),
            FormTextComponent.make('Model', 'model', { readonly: true }),
            FormTextComponent.make('Model Id', 'rowId', { readonly: true }),
            FormTextComponent.make('Revision', 'revision', { readonly: true }),
            FormTextComponent.make('Action', 'action', { readonly: true }),
        ]
    });

    rolePicklistField = FormPicklistComponent.make('Role', 'roleId', 'role',
        { items: [] },
        { cellOpts: { width: '20%' }, validators: [required], visible: Field.formOnly, formColumn: 2 }
    )


    config = new FormConfig({
        navRoute: TeamUserPageComponent.navRoute,
        title: $localize`Team Users`,
        help: $localize`The users authorized for the current team.`,
        allowNew: false,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormButtonComponent.makeNavDetailButton('Profile', 'fullName', 'personId', PersonPageComponent.navRoute).override({
                    calculateValue: (o: Person) => Person.fullName(o)
                }),
                FormTextComponent.make('Email Address', 'email', { readonly: true, sendServer: false }),
                FormPhoneComponent.make('Phone', 'phone', { readonly: true, sendServer: false }),
                FormTextComponent.make('User Name', 'userName', { readonly: true, sendServer: false, formColumn: 5 }),
                FormTextComponent.make('Role', 'roleName', {
                    readonly: true, sendServer: false, allowFiltering: false, visible: Field.noForm
                }),
                FormTextComponent.make('Unit', 'unitName',{visible: Field.noShow}),
                FormButtonComponent.makeNavDetailButton('Unit', 'unitNameLink', 'unitId', UnitPageComponent.navRoute).override({
                    calculateValue: (o) => o['unitName']
                }),
                FormPicklistComponent.make('Current', 'current', null, {items: [{id:0, name: 'No'}, {id:1, name: 'Yes'}]}, {
                    visible: Field.noShow, sendServer: false,
                }),
                /*FormButtonComponent.make('Current', 'currentIcon', {
                    type: 'icon', sendServer: false, cellOpts:{heading: ' '}, visible: Field.noForm,
                    toolTip: 'Is role currently active?',
                    tableIconStyler: () => 'color:red',
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    calculateValue: (o: PersonUnitRole) => PersonUnitRole.isCurrent(o) ? '' : 'close'
                }), */
                FormDateComponent.make('From', 'startDate', { cellOpts: { width: '2%' }, formColumn: 3 }),
                FormDateComponent.make('Until', 'endDate', { cellOpts: { width: '2%' }, formColumn: 3 }),
                this.rolePicklistField,
                //this.peopleGrid,
                FormDateTimeComponent.make('Last Login', 'lastLoginAt', { readonly: true, formColumn: 4 }),
                FormNumberComponent.make('Login Count', 'logins', {}, { readonly: true, formColumn: 4 }),
                this.auditGrid,
            ],
            formLayout: LAYOUT_OPTIONS.fiveColOver1
        }),
        service: this.dataSvc,
        mode: 'list',
        beforeEdit: this.beforeEdit.bind(this),
        readonly: true,
    });

    constructor(public dataSvc: TeamUserService, private roleSvc: RoleService, currentUserSvc: CurrentUserService) {
        super();
        currentUserSvc.getCurrentUser().subscribe(user => {
            console.log(user);
            //if (user.inAgency) {
            //    this.config.fieldSet.formLayout = LAYOUT_OPTIONS.threeColOver1;
            //} else {
            //    this.config.fieldSet.formLayout = LAYOUT_OPTIONS.fiveCol;
            //}
        })
        roleSvc.get(true).subscribe(roles => {
            this.roles = roles as Role[];
            this.rolePicklistField.setPicklistItems(roles);
        });
    }

    beforeEdit(o: TeamUser) {
        const role = this.roles.find(r => r.id === o.roleId);
        if (role) {
            if (role.unitLinked) {
                this.rolePicklistField.setPicklistItems(this.roles);
                this.rolePicklistField.setReadonly();
            } else {
                this.rolePicklistField.setPicklistItems(this.roles.filter(ur => !ur.unitLinked));
                this.rolePicklistField.setReadonly(false);
            }
        } else {
            console.error('Role not found ', { o, role });
        }
        console.log({ o, f: this.auditGrid });
        return o;
    }
}
