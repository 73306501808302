<div class="basePage">
    @if (isPhone) {
        <ng-container *ngTemplateOutlet="onPhone"></ng-container>
    } @else {
        <ng-container *ngTemplateOutlet="onLaptop"></ng-container>
    }
</div>

<ng-template #onLaptop>
    <div class="laptop-main">
        <h1 class="mat-h1">Requests for: {{currentUser?.name}}</h1>
        <mat-tab-group (selectedTabChange)="selectTab($event)" [(selectedIndex)]="tabIndex" mat-align-tabs="start"
            mat-stretch-tabs="false" style="max-height: calc(100vh - 160px);overflow: auto; ">
            <mat-tab label="Report New Issue">
                <div class="tab">
                    <app-new-user-request-detail [existingList]="openRequests"
                    (selectRequest)="selectRequest($event)"></app-new-user-request-detail>
                </div>
            </mat-tab>
            <mat-tab label="Open Requests ({{this.openRequests.length}})">
                <!-- Delay initialization, grids do not display hidden-->
                @if (tabIndex === 1) {
                    <app-my-requests (selectRequest)="selectRequest($event)" [requests]="openRequests"></app-my-requests>
                    <p class="help-info">This is the list of open requests you are following that the OMC is still working on</p>
                }
            </mat-tab>
            <mat-tab label="To be accepted ({{this.acceptRequests.length}})">
                @if (tabIndex === 2) {
                    <app-my-requests (selectRequest)="selectRequest($event)" [requests]="acceptRequests"></app-my-requests>
                    <p class="help-info">
                        This is the list of requests you are following that the OMC believes are completed.
                        You can click on these requests and choose to reopen them if you believe further work is required,
                        or choose to accept, if everything is complete. 
                        If not accepted within 7 days, the request is automatically closed and cannot be reopened.
                    </p>
                }
            </mat-tab>
            <mat-tab label="Closed Requests ({{closedRequests.length}})">
                @if (tabIndex === 3) {
                    <app-my-requests (selectRequest)="selectRequest($event)" [requests]="closedRequests"></app-my-requests>
                    <p class="help-info">
                        This is the list of requests you where following that the OMC has completed and have been accepted.
                    </p>                    
                }
            </mat-tab>
        </mat-tab-group>
    </div>
</ng-template>

<ng-template #onPhone>
    <div>
        <h1 class="mat-h1">Requests for: {{currentUser?.name}}</h1>
        <mat-card>
        <mat-accordion>
            <mat-expansion-panel (opened)="currentPanel = 'new'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon color="primary">add</mat-icon>
                        Report New Issue
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    @if (currentPanel === 'new') {
                    <app-new-user-request-detail [existingList]="openRequests"
                        (selectRequest)="selectRequest($event)"></app-new-user-request-detail>
                    }
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="currentPanel = 'open'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon color="primary">flutter_dash</mat-icon>
                        Open Requests ({{openRequests.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                @if (currentPanel === 'open') {
                    <ng-container [ngTemplateOutlet]="listRequests"
                        [ngTemplateOutletContext]="{reqs: openRequests}" ></ng-container>
                }

            </mat-expansion-panel>
            <mat-expansion-panel  (opened)="currentPanel = 'toaccept'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon color="primary">done_outline</mat-icon>
                        Completed to be accepted ({{acceptRequests.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    @if (currentPanel === 'toaccept') {
                        <ng-container [ngTemplateOutlet]="listRequests" [ngTemplateOutletContext]="{reqs: acceptRequests}"></ng-container>
                    }
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="currentPanel = 'closed'">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <mat-icon color="primary">done_all</mat-icon>
                        Completed to be accepted ({{closedRequests.length}})
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                    @if (currentPanel === 'closed') {
                    <ng-container [ngTemplateOutlet]="listRequests"
                        [ngTemplateOutletContext]="{reqs: closedRequests}"></ng-container>
                    }
                </div>
            </mat-expansion-panel>
        </mat-accordion>
        </mat-card>
    </div>
</ng-template>

<ng-template #listRequests let-reqs="reqs">
    @if (reqs.length > 0) {
        <ul>
            @for (r of reqs; track $index) {
            <li>
                <a [routerLink]="'/myRequests/' + r.id">{{r.refNr}}</a> {{r.title}}
            </li>
            }
        </ul>
    } @else {
        <p style="padding-left: 20px">There are no requests to show</p>
    }
</ng-template>
