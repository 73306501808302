/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet } from "src/app/shared/form/field-set/field-set.component";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { FormNumberComponent } from "src/app/shared/form/form-number/form-number.component";
import { Panel } from "./panel";
import { PreferredSupplier } from 'src/app/model/supplier';
import { MyInjector } from "src/app/app.module";
import { MatDialog } from "@angular/material/dialog";
import { PurchasePageComponent } from "../purchase-page/purchase-page.component";
import { BankOutPageComponent } from "../bank-out-page/bank-out-page.component";
import { ConfirmDialogService } from "src/app/shared/dialogs/confirmDialog";
import { PurchaseService } from "../purchase.service";
import { Txn } from "src/app/model/txn";
import { PreferredSupplierPageComponent } from "../../supply/preferred-supplier-page/preferred-supplier-page.component";

export const supplierPanel = new Panel({
    type: 'suppliers',
    icon: 'shopping_cart',
    setTitle() {
        this._title = this._items?.length + ' Suppliers this payment might be to'
    },
    setConfig() { return new FormConfig({
        fieldSet: new FieldSet({
            fields: [
                FormButtonComponent.makeDialogOpenButton('Supplier Name', 'name', 'id', PreferredSupplierPageComponent),
                FormNumberComponent.make('Items', 'items'),
                FormNumberComponent.makeCurrency('Outstanding', 'outstanding'),
                FormNumberComponent.makeCurrency('Difference', 'diffAmount'),
                FormButtonComponent.makeTableButton('New', 'newInvoice', this.takeAction2.bind(this)).override({
                    calculateValue: () => 'New Invoice'
                }),
                FormButtonComponent.makeTableButton('Match', 'match', this.takeAction.bind(this)).override({
                    calculateValue: () => 'Pay'
                }),
            ],
        }),
        mode: 'list'
    })},

    takeAction2(supplier: PreferredSupplier) {
        console.log({supplier, item: this.item});
        const dialog = MyInjector.instance.get(MatDialog);
        const data = { supplier, bankItem: this.item };
        dialog.open(PurchasePageComponent, { data }).afterClosed().subscribe( (result: Txn) => {
            console.warn(result);
            const cds = MyInjector.instance.get(ConfirmDialogService);
            const title = $localize `Approve for payment`;
            const msg = $localize `Approve invoice reference ${result.reference} for payment?`;
            cds.open(msg, title, () => {
                const ps = MyInjector.instance.get(PurchaseService);
                ps.approve(result).subscribe( response => {
                    console.log(response);
                    if (response) {
                        this.takeAction(supplier);
                    }
                })
            });
        })        
    },

    takeAction(supplier: PreferredSupplier) {
        console.warn(this);
        const dialog = MyInjector.instance.get(MatDialog);
        const data = { supplier, bankItem: this.item, bankAccount: this.bankAccount }
        dialog.open(BankOutPageComponent, { data }).afterClosed().subscribe(result => {
            console.warn(result);
            if (result) {
                this.recItemsComponent.removeItem(this.item);
                this.reconciliationComponent.showPanel = null;
            }
        });
    }
})
