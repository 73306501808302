/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpClient } from '@angular/common/http';
import { Component, inject, OnDestroy } from '@angular/core';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { BCode } from 'src/app/model/bcode';
import { Cycle } from 'src/app/model/cycle';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { TxnPageComponent } from '../../txn-page/txn-page.component';
import { BCodePageComponent } from 'src/app/modules/budget/b-code-page/b-code-page.component';
import { Schedule } from 'src/app/model/schedule';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { GridField } from 'src/app/shared/grid/grid-field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Params, Router, RouterLink } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { CtlHolderComponent } from '../../../../shared/form/ctl-holder/ctl-holder.component';
import { NgClass, NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { Subscription } from 'rxjs';

type Balance = { bCodeId: uuid, scheduleId: uuid, debits: number, credits: number, calcTotal: number, count: number }

type ApiResponse = { cycle: Cycle, cycles: Cycle[], schedules: Schedule[], balances: Balance[],
    previous: Balance[], bcodes: BCode[], types: AbstractObject[] }

    /*
class Stats {
    count = 0;
    current = 0;
    previous = 0;
    budget = 0;
    scheduleId?: uuid
    arrow() { return (this.current > this.previous) ? "arrow_upward" : "arrow_downward" }
    tip() {
        "Hello World";
    }
}
type BCItem = { bCodeId: uuid, name: string, total: Stats, schedule: Stats[] }
*/

@Component({
    selector: 'app-income-expenditure',
    templateUrl: './income-expenditure.component.html',
    styleUrls: ['../balances.scss'],
    standalone: true,
    imports: [NgClass, CtlHolderComponent, NgTemplateOutlet, MatCardModule, RouterLink, MatIconModule, CurrencyPipe]
})
export class IncomeExpenditureComponent implements OnDestroy {
    static readonly navRoute = new NavRoute('txn/incomeExpenditure', IncomeExpenditureComponent, 'finance');

    private http = inject(HttpClient);
    private currentUserSvc = inject(CurrentUserService);
    private activeRoute = inject(ActivatedRoute);
    private router = inject(Router);
    public screenSize = inject(IsNarrowService);

    subscriptions: Subscription[] = [];

    companyName: string
    cycle: Cycle = null;
    previousCycle: Cycle = null;

    bcodes: BCode[] = [];
    balances: Balance[];
    types: AbstractObject[] = BCode.INCOME_EXPENDITURE_TYPES;
    ready = false;
    hideZero = true;
    showTotals = true;
    showSchedules = true;
    schedulesToShow = true;
    myData: ApiResponse;

    profit = 0;
    prevProfit = 0;
    budget = 0;

    cycleField = this.getCycleField();
    fromField = this.getFromField();
    toField = this.getToField();
    zerosField = this.getZerosField();

    schedulesField = FormCheckboxComponent.make('Show Schedules', 'hideZeros', {
        formColumn: 3,
        valueChanges: (value) => {
            this.showSchedules = value;
            this.refreshData();
        }
    }).setupControl();

    totalsField = FormCheckboxComponent.make('Show Totals', 'hideZeros', {
        formColumn: 2,
        valueChanges: (value) => {
            this.showTotals = value;
            this.refreshData();
        }
    }).setupControl();


    constructor() {
        this.subscriptions.push(this.activeRoute.params.subscribe(params => this.setUp(params)));
        this.zerosField.control.setValue(true, { emitEvent: false });
        this.schedulesField.control.setValue(true, { emitEvent: false });
        this.totalsField.control.setValue(true, { emitEvent: false });
        this.subscriptions.push(this.currentUserSvc.getCurrentUser().subscribe(user => {
            this.companyName = user.currentTeam.legalName;
            if (this.activeRoute?.snapshot?.params.cycleId) {
                this.getData(this.activeRoute.snapshot.params.cycleId);
            } else {
                this.getData(user.currentTeam.currentPeriod.cycleId);
            }
        }));
    }

    private setUp(parms: Params) {
        if (parms.cycleId) {
            this.getData(parms.cycleId);
        }
    }
    private navigateTo(cycleId: uuid) {
        this.router.navigate([IncomeExpenditureComponent.navRoute.url, { cycleId }]);
    }

    canDeactivate() {
        return true;
    }

    private getCycleField() {
        return FormPicklistComponent.make('Cycle', 'cycleId', null, {
            items: [],
            refreshes: [(cycle: Cycle) => {
                this.navigateTo(cycle.id);
            }]
        }).setupControl();
    }

    private getZerosField() {
        const fld = FormCheckboxComponent.make('Hide Zeros', 'hideZeros', {
            formColumn: 2,
            valueChanges: (value) => {
                this.hideZero = value;
                this.refreshData();
            }
        });
        fld.setupControl();
        fld.control.setValue(true, {emitEvent: false});
        return fld;
    }

    getScheduleField() {
        return new GridField({
            field: { label: 'Schedule Allocation', value: 'schedule', formColumn: 3},
            rowFactory: () => [
                FieldMaker.id(),
                FormTextComponent.make("Name", "name", {readonly: true}),
                FormNumberComponent.make('Percentage', 'percent',
                    { format: 'percent', formatParms: '2.3-3', step: .01 }, { readonly: true }
                ),
                FormCheckboxComponent.make("Show", "showSchedule"),
            ],
        });
    }

    private getToField() {
        return FormDateComponent.make('To', 'to', { readonly: true, disable: true }).setupControl()
    }

    private getFromField() {
        return FormDateComponent.make('From', 'from', { readonly: true, disable: true }).setupControl();
    }

    private getData(cycleId: uuid) {
        const url = AbstractHttpService.ajaxPath + 'txn/incomeExpenditure/' + cycleId;

        this.http.get<ApiResponse>(url).subscribe(result => {
            this.myData = result;
            this.cycleField.setPicklistItems(this.myData.cycles);
            this.cycleField.control.setValue(this.myData.cycle.id, { emitEvent: false });
            this.fromField.control.setValue(this.myData.cycle.from, { emitEvent: false })
            this.toField.control.setValue(this.myData.cycle.to, { emitEvent: false })
            this.refreshData();
        })
    }
    ngOnDestroy(): void {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private refreshData() {
        this.cycle = this.myData.cycle
        this.previousCycle = this.myData.cycles.find( c => c.id === this.cycle.previousId);
        this.balances = this.myData.balances
        this.bcodes = [];

        this.profit = 0;
        this.prevProfit = 0;
        this.budget = 0;

        if (this.myData.schedules.length === 1) {
            this.showSchedules = false;
            this.schedulesToShow = false;
        }
        this.myData.schedules.forEach( s => this.zeroSchedule(s));

        this.myData.bcodes.forEach((bc: BCode) => {
            this.zeroBCode(bc);
            this.calcBCodeTotal(bc);
            this.calcBCodePrev(bc);
            this.calcBCodeBudget(bc);
            bc.type = this.types.find(o => o.id === bc.typeId);

            if (!this.hideZero || bc.total !== 0 || bc.prevTotal !== 0 || bc.budget !== 0) {
                this.bcodes.push(bc);
            }

        });

        this.types.forEach(type => {
            type['bcodes'] = this.bcodes.filter(o => o.typeId == type.id);
            type['total'] = 0;
            type['prevTotal'] = 0;
            type['bcodes'].forEach(bc => {
                type['total'] += bc.total;
                type['prevTotal'] += bc.prevTotal;
            })
        })

        console.warn(this.types);
    }

    private zeroSchedule(s: Schedule) {
        s.total = 0;
        s.prevTotal = 0;
        s.budget = 0;
        s.count = 0
        this.types.forEach ( t => {
            t[this.totalName(s.id)] = 0;
            t[this.prevName(s.id)] = 0;
            t[this.budgetName(s.id)] = 0;
        });
    }

    private zeroBCode(bc: BCode) {
        bc.total = 0;
        bc.prevTotal = 0;
        bc.budget = 0;
        bc.count = 0
        this.myData.schedules.forEach( s => {
            bc[this.totalName(s.id)] = 0;
            bc[this.prevName(s.id)] = 0;
            bc[this.budgetName(s.id)] = 0;
        })
    }

    private balanceTotal(bal: Balance, bc: BCode) {
        return (bc.typeId === BCode.TYPE.EXPENSE.id) ? (+bal.debits - +bal.credits) : (+bal.credits - +bal.debits);
    }

    private calcBCodeTotal(bc: BCode) {
        const bals = this.myData.balances.filter( b => b.bCodeId === bc.id);
        bals.forEach( bal => {
            const total = this.balanceTotal(bal, bc);
            this.profit += (+bal.credits - +bal.debits)
            bc.count += bal.count;
            bc.total += total;
            if (bal.scheduleId) {
                bc[this.totalName(bal.scheduleId)] += total;
                const s = this.myData.schedules.find( s => s.id === bal.scheduleId);
                if (s) {
                    s.total += (+bal.credits - +bal.debits);
                }
                this.types.find( o => o.id === bc.typeId)[this.totalName(bal.scheduleId)] += total;
            }
        })
    }

    private calcBCodePrev(bc: BCode) {
        const bals = this.myData.previous.filter(b => b.bCodeId === bc.id);
        bals.forEach(bal => {
            const total = this.balanceTotal(bal, bc);
            this.prevProfit += (+bal.credits - +bal.debits)
            bc.prevCount += bal.count;
            bc.prevTotal += total;
            if (bal.scheduleId) {
                bc[this.prevName(bal.scheduleId)] += total;
                const s = this.myData.schedules.find(s => s.id === bal.scheduleId);
                if (s) {
                    s.prevTotal += total;
                }
                this.types.find(o => o.id === bc.typeId)[this.prevName(bal.scheduleId)] += (+bal.credits - +bal.debits);
            }
        })
    }

    getArrow(c: number, p:number) : string {
        if (c > p) {
            return 'arrow_upward';
        } else if (c < p) {
            return 'arrow_downward'
        }
        return ''
    }

    private calcBCodeBudget(bc: BCode) {

        this.myData.cycle.schedule.filter( o => o.bCodeId === bc.id).forEach(bc_sched => {

            const amount = bc_sched.plannedSpend;

            this.budget += amount;
            bc.budget += amount;
            bc[this.budgetName(bc_sched.scheduleId)] += amount;

            const s = this.myData.schedules.find(s => s.id === bc_sched.scheduleId);
            if (s) {
                s.budget += amount;
            }
        });
    }

    totalName(s: uuid) {
        return 'total' + s;
    }

    prevName(s: uuid) {
        return 'prev' + s;
    }

    budgetName(s: uuid) {
        return 'budget' + s;
    }

    getAccountTxn(bcode: BCode) {
        return ['/' + TxnPageComponent.navRoute.url, { bCodeId: bcode?.id, txnCycleId: this.cycle?.id }]
    }

    getAccountLink(bcode: BCode) {
        return BCodePageComponent.navRoute.getIdUrl(bcode.id);
    }
}
