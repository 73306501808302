/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Person } from 'src/app/model/person';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';

@Injectable({
    providedIn: 'root'
})
export class RequestPersonService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/requests/people';
    protected cache: Person[];
    protected typeString = 'Person';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
