/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { RecurringJob } from 'src/app/model/recurring-job';

@Injectable({
  providedIn: 'root'
})
export class RecurringJobService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'crm/recurringJobs';
  protected cache: RecurringJob[];
  protected typeString = 'RecurringJob';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }
}
