/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { PicklistField } from '../../field/PicklistField';
import { AbstractObject } from 'src/app/model/abstract-object';

@Component({
  selector: 'app-picklist-table-cell',
  standalone: true,
  imports: [],
  templateUrl: './picklist-table-cell.component.html',
  styleUrl: './picklist-table-cell.component.scss'
})
export class PicklistTableCellComponent {
    @Input({required: true}) field: PicklistField;
    @Input({required: true}) focusItem: AbstractObject;
}
