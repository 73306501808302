/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { uuid } from "./abstract-object";
import { Agent } from "./Agent";
import { BankAccount } from "./bankAccount";
import { Team } from "./team";

export class OmcSettings extends Team {
    bank_account: BankAccount;
    paymentTerms: string;
    fromEmail: string;
    replyToMail: string;
    billingAddress: string;
    agents: Agent[] = [];
    mailSendingDomain: string;
    mailSendingAccount: string;
    mailSendingName: string;
    mailReplyToDomain: string;
    parameters: {id: uuid, name: string, value: string}[] = [];
}
