<div style="display: flex" id="welcome_to_our_omc">
    <div style="flex: 1"></div>
    <div style="flex: 7; max-width: 500px">
        <mat-card appearance="raised" style="padding: 30px; margin-top: 10%;">
            <h1 class="mat-headline-5">Welcome to OurOMC</h1>
            <p>OurOMC is the complete online system for Owner Management Companies. We provide tools to help</p>
            <ul>
                <li>Members</li>
                <li>Directors</li>
                <li>Managing Agents</li>
            </ul>
            <p>Collaborate in a clear and transparent way to run Multi Unit Developments more efficiently and build
                communities</p>
            @if (!loggedIn || !currentUser) {
            <p style="text-align: center;">
                <button mat-raised-button  data-login-link routerLink="login" color="primary">Login</button>
            </p>
            }
            @if (loggedIn && currentUser) {
            <p data-loggedin-user>
                <span i18n><b>{{currentUser.name | titlecase}}</b> is logged in</span>
                @if (loggedIn && hasTeam) {
                <span>
                    as <b>{{currentUser.currentRole.name}}</b> in <b>{{currentTeam}}</b>
                    @if(screenSvc.isPhone()) {
                        and can use the three bars in the top left corner to see a menu of options
                    } @else {
                        and can use the menu on the left to navigate
                    }
                </span>
                } @else {
                    &NonBreakingSpace;<span><b>without data access</b></span>
                }
            </p>
            }

            @if (showExtras()) {
            <p style="margin-bottom: -10px">{{currentUser.name  | titlecase}} may also switch to</p>
            <ul>
                @for (pos of currentUserSvc.getActivePositions(); track $index) {
                @if (pos.roleId !== currentUser.currentRoleId || pos.teamId !== currentUser.current_team_id) {
                <li class="switchLink primary-font-color" (click)="switchRole(pos)">{{pos.team?.name}} as {{pos.role?.name}}</li>
                }
                }
            </ul>
            }

            @if (loggedIn && !hasTeam && myRequests.length === 0) {
            <p>
                You are not currently associated to an OMC click here to request access.
            </p>
            }
            <!--  && myRequests.length === 0 -->
            @if (loggedIn && !hasTeam) {
            <button mat-raised-button color="primary" (click)="requestOmcAccess()">Request OMC Access</button>
            }
            <div style="max-height: 10em; overflow: auto">
                @if (myRequests.length > 0) {
                <table style="width: 100%; margin-bottom: 20px">
                    <tr>
                        <th colspan="10">
                            Your Open Requests
                        </th>
                    </tr>
                    <tr>
                        <th>Ref.</th>
                        <th>Title</th>
                        <th>Omc</th>
                        <th>Updated</th>
                    </tr>
                    @for (r of myRequests; track r) {
                    <tr>
                        <td>{{r.refNr}}</td>
                        <td>{{r.title}}</td>
                        <td>{{r.omc?.name}}</td>
                        <td>@if (r.updatedAt) {
                                {{(r.updatedAt) | fromNow}}
                            } @else {
                                {{(r.createdAt) | fromNow}}
                            }
                        </td>
                    </tr>
                    }
                </table>
                }
            </div>
            @if (loggedIn && !hasTeam && myRequests.length > 0) {
            <p>
                Your access request needs to be approved before you can access your OMC
            </p>
            }
        </mat-card>
    </div>
    <div style="flex: 1"></div>
</div>
