/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';

@Component({
  selector: 'app-install-me',
  standalone: true,
    imports: [MatButtonModule, MatTooltipModule],
  templateUrl: './install-me.component.html',
  styleUrl: './install-me.component.scss'
})
export class InstallMeComponent {
    static installPrompt: { prompt: () => void };
    screenSvc = inject(IsNarrowService)
    show = false;

    constructor(public cus : CurrentUserService) {
        window.addEventListener("beforeinstallprompt", (event) => {
            event.preventDefault();
            console.log({event, t: cus?.currentUser?.currentTeam});
            InstallMeComponent.installPrompt = event as unknown as { prompt: () => void };
            this.decide();
        });
        cus.getCurrentUser().subscribe( () => {
            this.decide();
        })
    }

    decide() {
        if (this.screenSvc.isPhone() && InstallMeComponent.installPrompt?.prompt && this.cus.currentUser?.currentTeam) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
    installMe() {
        InstallMeComponent.installPrompt.prompt();
    }

}
