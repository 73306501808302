/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Supplier } from 'src/app/model/supplier';

@Injectable({
  providedIn: 'root'
})
export class PreferredSupplierService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'suppliers/preferred';
  protected cache: Supplier[];
  protected typeString = 'Preferred Supplier';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }
}
@Injectable({
    providedIn: 'root'
})
export class OmcPreferredSupplierService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'suppliers/preferred/omc';
    protected cache: Supplier[];
    protected typeString = 'Preferred Supplier';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
