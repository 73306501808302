/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

export class ErrorHelper {
    static getMsg(formControl: UntypedFormControl, key: string) {
        const value = formControl.errors[key];
        if (key === 'email') {
            return $localize`Invalid email address`;
        } else if (typeof value === 'string') {
            return value;
        } else if (value instanceof FormError) {
            return value.getMsg();
        } else {
            console.warn('Unrecognised Error : ' + key, { key, value, errors: formControl.errors, formControl });
            return 'Error';
        }
    }

    static getAllMsgs(formControl: UntypedFormControl) {
        let msg = '';
        if (formControl.errors) {
            Object.getOwnPropertyNames(formControl.errors).forEach(key => {
                if (msg.length > 0) {
                    msg += ', '
                }
                msg += ErrorHelper.getMsg(formControl, key);
            })
        }
        return msg;
    }
}

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatTooltipModule],
})
export class FormErrorComponent {

    formControl: UntypedFormControl = new UntypedFormControl();

    @Input() set control(control: UntypedFormControl) {
        this.formControl = control;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    showErrorMsg(o: any): string {
        if (o.value && o.value.getMsg) {
            return o.value.getMsg();
        } else {
            //
            if ('key' in o && 'value' in o) {
                if (o.key === 'email' && o.value) {
                    return $localize`Invalid email address`;
                }
                return o.value;
            } else {
                console.warn('Unrecognised Error ', { error: o, control: this.formControl });
                return '';
            }
        }
    }

    shortMsg() {
        const s = ErrorHelper.getAllMsgs(this.formControl);
        if (s.length > 43) {
            return s.substring(0, 40) + '...';
        }
        return s;
    }

    tipMsg() {
        const s = ErrorHelper.getAllMsgs(this.formControl);
        if (s.length <= 43) {
            return '';
        }
        return s;
    }
}

interface ErrorParams {
    [key: string]: string;
}

export class FormError {
    code: string;
    defaultMsg: string;
    formattedMsg: string;
    parms: ErrorParams;

    constructor(errorCode: ErrorCode, p: ErrorParams = {}) {
        this.code = errorCode.code;
        this.defaultMsg = errorCode.defaultMsg;
        this.parms = p;

        this.formatMsg();
        this.validateParms();
    }

    getMsg(): string {
        return this.formattedMsg;
    }

    static reportError(eCode: string, eMsg: string): FormError[] {
        return [new FormError(new ErrorCode(eCode, eMsg))];
    }

    private formatMsg(): void {
        const parmNames = Object.keys(this.parms);
        this.formattedMsg = this.defaultMsg;
        for (const pName of parmNames) {
            this.formattedMsg = this.formattedMsg.replace((`\${${pName}}`), this.parms[pName]);
        }
    }

    private validateParms(): void {
        const match = this.formattedMsg.match(/\{([^}]+)\}/g);
        if (match) {
            for (const parm of match) {
                console.error(`Code ${this.code} created missing parameter '${parm}'`);
            }
        }
    }

}


export class ErrorCode {
    code: string;
    defaultMsg: string;
    constructor(code, defaultMsg) {
        this.code = code;
        this.defaultMsg = defaultMsg;
    }
    new(parms: ValidationErrors = {}) {
        const newObject = {} as ValidationErrors;
        newObject[this.code] = new FormError(this, parms);
        return newObject;
    }
}
/*
* How do I localize these? $localize `` will expect to substitute strings immediately, here we need them to be substituted later.
*
*/
export const FORM_ERRORS = {
    required: new ErrorCode('required', 'You must enter a value'),
    minChars: new ErrorCode('minChars', 'You must enter at least ${minChars} characters, \
                                          so far you have entered ${actualChars} characters'),
    maxChars: new ErrorCode('maxChars', 'You may only enter ${minChars} characters, \
                                          so far you have entered ${actualChars} characters'),
    minValue: new ErrorCode('minValue', 'Minimum value allowed is ${minValue} - ${actualValue} is not allowed'),
    maxValue: new ErrorCode('maxValue', 'Maximum value allowed is ${maxValue} - ${actualValue} is not allowed'),
    minWords: new ErrorCode('minWords', 'You must enter at least ${minWords} words, you only entered ${actualWords} so far'),
    numericRange: new ErrorCode('numericRange', 'You must enter a number between ${min} and ${max} inclusive'),
    unique: new ErrorCode('unique', 'You must enter a unique value'),
    percentZeroOrOne: new ErrorCode('percentZeroOrOne', 'Percentages must add to zero or 100%, currently add to ${currentTotal}%'),
    validateDate: new ErrorCode('validDate', 'You must enter a valid date'),
    outsideCycle: new ErrorCode('outsideCycle', 'The date ${txnDate} is outside any known budget cycle'),
    periodClosed: new ErrorCode('periodClosed', 'Period ${periodName} is closed, cannot post transactions to it'),
    outsidePeriod: new ErrorCode('outsidePeriod', 'The date ${txnDate} falls outside any periods in the ${cycle} cycle'),
    greaterThen: new ErrorCode('greaterThan', 'The value must be greater then ${relatedValue}'),
    lessThen: new ErrorCode('lessThan', 'The value must be less then ${relatedValue}'),
};
