
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { Observable, ReplaySubject } from 'rxjs';
import { uuid } from 'src/app/model/abstract-object';
import { NavOption } from 'src/app/model/sys/navOption';
import { FormConfig } from "../form/FormConfig";
export interface NewButtonState {
    actionName?: string;
    link?: string;
    showButton: boolean;
    actionParms?: Params;
    options?: NavOption[];
}
@Injectable({
    providedIn: 'root'
})
export class NewButtonService {

    state: NewButtonState;
    private subjectState = new ReplaySubject<NewButtonState>();

    constructor() {
        this.state = { actionName: '', link: '', showButton: false, options: [] };
    }

    public setupButtonFromConfig(config: FormConfig, forceTeam: uuid = null) {
        this.setupButton(config.title, config.pathNew(), config.allowNew, config.newOptions, forceTeam);
    }

    public setupButtonWithParms(action: string, link: string, parms: Params) {
        const actionName = $localize`New ${action}`;
        this.state = { actionName, link, showButton: true, options: [], actionParms: parms };
        this.subjectState.next(this.state);
    }

    public setupButton(action: string, link: string, showButton = true, options: NavOption[] = [], forceTeam: uuid = null) {
        const actionName = $localize`New ${action}`;
        for (const navo of options) {
            if (!navo.params) {
                navo.params = {};
            }
        }
        const actionParms = forceTeam ? { _forceTeam: forceTeam } : {};
        this.state = { actionName, link, showButton, options, actionParms };
        this.subjectState.next(this.state);
    }

    clear() {
        this.state = { showButton: false };
        this.subjectState.next(this.state);
    }

    buttonUpdates(): Observable<NewButtonState> {
        return this.subjectState;
    }
}
