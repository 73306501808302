

/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AfterViewChecked, Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { CanComponentDeactivate } from 'src/app/shared/guards/can-deactivate.guard';
import { AbstractObject } from 'src/app/model/abstract-object';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { ConfirmDialogService } from '../dialogs/confirmDialog';
import { STD_ANIMATION } from '../std-animation';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Location, NgClass, NgTemplateOutlet } from '@angular/common';
import { GridField } from 'src/app/shared/grid/grid-field';
import { Field } from 'src/app/shared/field/Field';
import { IsNarrowService } from '../is-narrow.service';
import { first } from 'rxjs/operators';
import { MatDialogRef } from '@angular/material/dialog';
import { PickDialogComponent } from '../dialogs/pick-dialog/pick-dialog.component';
import { ChangeDetectorRef } from '@angular/core';
import { AttachmentField } from "../field/AttachmentField";
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { FormConfig } from './FormConfig';
import { MatMenuModule } from '@angular/material/menu';
import { FormActionsComponent } from './form-actions/form-actions.component';
import { FieldSetComponent } from './field-set/field-set.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { CtlHolderComponent } from './ctl-holder/ctl-holder.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OurAppTrackerService } from '../our-app-tracker-service';

export type FormMode = 'edit' | 'new' | 'list';
/*
export interface AppFormControlParameters {
  validator?: ValidatorFn[];
  aSyncValidator?: AsyncValidatorFn[];
  tableCol: Field;
}
*/
export type ActionColor = 'primary' | 'accent' | 'warn' | '';
export interface IFormAction {
    name: string;
    color: ActionColor;
    show: boolean;
    icon: string;
    approvalNeeded: boolean;
    disabled?: boolean;
    approvalText: string;
    action: (AbstractObject, FormConfig) => Observable<AbstractObject>;
    setup: (AbstractObject) => void;
    tipText?: string;
}

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [
        NgClass,
        NgTemplateOutlet,
        MatTabsModule,
        MatTooltipModule,
        MatIconModule,
        MatButtonModule,
        CtlHolderComponent,
        MatCardModule,
        MatProgressSpinnerModule,
        FieldSetComponent,
        FormActionsComponent,
        MatMenuModule,
    ],
})
export class FormComponent
    implements CanComponentDeactivate, OnInit, OnDestroy, AfterViewChecked {
    //@Input() page: AbstractPageComponent;

    @Input() config: FormConfig;
    @Input() dialogRef: MatDialogRef<PickDialogComponent>;

    formGroup: UntypedFormGroup = new UntypedFormGroup({});
    //formControlArray: (AppFormControl|GridControl)[];
    // formService: AbstractHttpService;
    // formTitle: string;
    // formMode: FormMode;
    // objectFactory: () => AbstractObject;
    // formLayout: CellDef[][] = formLayoutOptions.threeColOver1;

    currentParams: Params;
    currentQueryParams: Params;

    loaded = false;
    initComplete = false;
    isPrint = false;

    focusItem: AbstractObject;
    console = console;

    isPhone = false;
    formHeight = 600;
    dialogHeight: number;
    dialogWidth: number;

    resetStart = 0;
    loadingData = false;

    subscriptions: Subscription[] = [];

    protected readonly defaultDeleteMsg = $localize`Are you sure you want to delete this item and send it to the recycle bin`;

    protected dataService: AbstractHttpService;

    public selectedTopTab = 0;


    constructor(protected activeRoute: ActivatedRoute, protected router: Router,
        private isNarrow: IsNarrowService,
        private cdr: ChangeDetectorRef, private location: Location, private ourAppTracker: OurAppTrackerService,
        private cds: ConfirmDialogService) {
        this.subscriptions.push(isNarrow.detectVeryNarrow().subscribe(result => { this.isPhone = result; }));
        this.subscriptions.push(isNarrow.resizeObservable$.subscribe(() => {
            this.setFormHeight();
        }));
    }

    @HostListener('window:beforeunload')
    beforeUnload() {
        return !this.focusItem || !this.hasChanges();
    }

    setFormHeight() {

        if (!this.dialogRef) {
            this.formHeight = window.innerHeight - 276;
        } else {
            if (this.dialogRef.componentInstance.dialogOptions?.height) {
                this.formHeight = this.dialogRef.componentInstance.dialogOptions.height - 140
            } else {
                this.formHeight = (this.isNarrow.screenHeight * .8) - 190;
            }

            if (!this.dialogRef?.componentInstance?.dialogOptions?.hideTabs) {
                /*
                If we have tabs, we need to make the dialog height consistent,
                without this it resizes and jumps around when changing tab
                But if no tabs, let it set height automatically for content
                */
                this.dialogHeight = this.formHeight + 140;
            }


            if (this.dialogRef.componentInstance.dialogOptions?.width) {
                this.dialogWidth = this.dialogRef.componentInstance.dialogOptions.width - 40
            } else {
                this.dialogWidth = (this.isNarrow.screenWidth * .8) - 40;
            }
        }
        if (this.isNarrow.screenHeight < 499) {
            this.formHeight = null;
        }
    }

    ngOnInit() {
        this.setFormHeight();
        this.subscriptions.push(combineLatest([this.config.isReady, this.activeRoute.params, this.activeRoute.queryParamMap]).subscribe(
            // We have to wait for ready to fire, but we do not need a value for it.
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            ([ready, params, queryParams]) => {
                if (!this.initComplete) {
                    this.initFormObject();
                }
                this.selectedTopTab = 0;
                this.setUp(params, queryParams);
            }
        ));
    }

    ngOnDestroy() {
        for (const s of this.subscriptions) {
            s.unsubscribe();
        }
    }

    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
        if (this.focusItem && this.hasChanges()) {
            return confirm($localize`Your data has not been saved. Are you sure you want to leave and lose these changes?`);
        }
        return true;
    }


    getFieldCount(field: Field) {
        if (field instanceof GridField || field instanceof AttachmentField) {
            if (Array.isArray(field.control?.value) && field.control.value.length > 0) {
                return field.control.value.length;
            } else {
                return '';
            }
        } else {
            return '';
        }
    }

    hasChanges(): boolean {
        let hasChanges = false;
        for (const controlName of Object.keys(this.formGroup.controls)) {
            if (this.formGroup.controls[controlName].dirty) {
                hasChanges = true;
            }
        }
        return hasChanges;
    }

    cancelChanges() {
        if (this.dialogRef) {
            this.dialogRef.close(null);
        } else {
            if (this.config.mode === 'new') {
                this.backOut();
            } else {
                this.resetForm();
            }

            //this.backOut();
        }
    }

    deleteObject(): void {
        this.cds.open($localize`Delete this ${this.config.title}`,
            this.config.deleteMsg ? this.config.deleteMsg : this.defaultDeleteMsg,
            (this.doDelete).bind(this),
            $localize`Delete`, this.config.deleteReasonNeeded);
    }

    doDelete(reason: string): void {
        this.config.service.delete<AbstractObject>(this.focusItem, null, reason).pipe(first()).subscribe(response => {
            if (response) {
                //this.focusItem = this.config.objectFactory(null); // Jsut create a new one to let me out...
                this.backOut();
            }
        });
    }

    saveChangesAndNew(): void {
        console.warn('save & new');
        this.saveChanges(true);
    }

    saveChanges(andNew = false) {
        if (!this.config.getConfirmSaveMessage) {
            this.doSaveChanges(andNew);
        } else {
            console.log({ config: this.config, csm: this.config.getConfirmSaveMessage });
            const confirmText = this.config.getConfirmSaveMessage(this.formGroup);
            this.cds.open($localize`Confirm Save`, confirmText, () => { this.doSaveChanges(andNew); });
        }
    }

    doSaveChanges(andNew = false): void {

        if (this.config.beforeSave) {
            this.config.beforeSave(this.formGroup);
        }

        const saveData = this.config.fieldSet.getFormValue();
        this.loadingData = true;
        console.log('Saving', saveData);

        if (this.config.mode === 'new') {
            this.ourAppTracker.startSave(this.config.getTitle());
            this.config.service.post<AbstractObject>(saveData, true, this.getForceTeamId()).pipe(first()).subscribe(response => {
                if (response && response.id) {
                    this.ourAppTracker.completeSave(this.config.getTitle(), true);
                    response = this.config.beforeEdit(response);
                    if (this.dialogRef) {
                        this.dialogRef.close(response);
                    } else if (andNew) {
                        this.config.objectFactory(null).pipe(first()).subscribe((o: AbstractObject) => {
                            this.focusItem = o;
                            this.resetForm();
                        });
                        //this.focusItem = this.config.objectFactory(null); // AndNew really use same params again, probably not...
                        //this.resetForm();
                    } else {
                        this.focusItem = response;
                        this.config.setItemTitle(this.focusItem);
                        this.resetForm();
                        const url = '/' + this.config.navRoute.getIdUrl(response.id);
                        const team = this.getForceTeamId();
                        const parms = team ? { _forceTeam: team } : {};
                        this.router.navigate([url, parms]);
                    }
                    if (this.config.afterSave) {
                        this.config.afterSave(this.formGroup);
                    }
                } else {
                    console.warn('Did not get valid response', response);
                    this.ourAppTracker.completeSave(this.config.getTitle(), false);
                    this.loadingData = false;
                }
            });
        } else {
            this.ourAppTracker.startSave(this.config.getTitle());
            this.config.service.put<AbstractObject>(saveData, this.getForceTeamId()).pipe(first())
            .subscribe(response => {
                this.ourAppTracker.completeSave(this.config.getTitle(), response ? true : false);
                if (this.dialogRef) {
                    this.dialogRef.close(response);
                } else {
                    this.reloadContent(response);
                }
            });
        }
    }

    reloadContent(response) {
        if (response && response.id) {
            this.focusItem = this.config.beforeEdit(response);
            this.config.setItemTitle(this.focusItem);
            this.resetForm();
            if (this.config.afterSave) {
                this.config.afterSave(this.formGroup);
            }
            //this.backOut();
        } else {
            this.console.warn('Did not get a valid response', response);
            this.loadingData = false;
        }
    }

    getActionCount() {
        return this.config.actions.filter(a => a.show).length;
    }

    getActionTip(a: IFormAction) {
        if (a.tipText && a.icon) {
            return a.name + '. ' + a.tipText;
        } else if (a.tipText) {
            return a.tipText;
        } else {
            return a.name;
        }
    }

    takeAction(a: IFormAction) {
        const currentValue = this.config.fieldSet.getFormValue();
        let action: Observable<AbstractObject>;
        if (a.approvalNeeded) {
            this.cds.open(a.name, a.approvalText, () => {
                this.ourAppTracker.logAction(`Taking Action: ${a?.constructor?.name} - ${a?.name} - ${a?.icon}`);
                action = a.action(currentValue, this.config);
                if (action) {
                    action.subscribe(o => {
                        if (o) {
                            this.focusItem = this.config.beforeEdit(o);
                            this.resetForm();
                        }
                        this.ourAppTracker.logAction("Action: " + a?.constructor?.name + " completed");
                    });
                }
            }, a.name);
        } else {
            action = a.action(currentValue, this.config);
            this.ourAppTracker.logAction(`Taking Action: ${a?.constructor?.name} - ${a?.name} - ${a?.icon}`);
            if (action) {
                action.subscribe(o => {
                    if (o) {
                        this.focusItem = this.config.beforeEdit(o);
                        this.resetForm();
                    }
                    this.ourAppTracker.logAction("Action: " + a?.constructor?.name + " completed");
                });
            }
        }
    }

    initFormObject(): void {
        //console.error('Initializing form', this.config);
        this.formGroup = this.config.fieldSet.getFormGroup();
        /*= new UntypedFormGroup({}, this.config.fieldSet.formValidator, this.config.fieldSet.asyncFormValidator);

        this.config.fieldSet.fields.forEach(field => {
            this.formGroup.addControl(field.name, field.makeControl());
        }); */

        this.config.tabFields.forEach(field => {
            field.relatedFormGroup = this.formGroup;
            field.makeControl();
        });

        if (this.config.fieldSet.fields.length > 0) {
            this.initComplete = true;
        }

        /* Handled in FieldSet.getFormGroup now...
        if (this.config.fieldSet.valueChanges) {
            this.formGroup.valueChanges.subscribe((newValue) => {
                this.config.fieldSet.valueChanges(newValue, this.formGroup);
            });
        } */
    }

    /* This should be the logic, but not yet used,
        have not figured how to send default values on new records, or new grid rows if dirty not set
    */
    private sendField(field: Field, getAll: boolean): boolean {
        if (getAll) {
            return true; // Passing getAll true to getFormValue gets Every Value
        } else if (field.control.dirty && field.sendServer) {
            return true; // Field changed, so send it to the server
        } else if (field.name === 'id' && field.sendServer) {
            return true; // Usually need to send id to server even though not changed, so we can match the record
        } else {
            return false;
        }
    }

    public reloadForm() {
        this.setUp(this.currentParams, this.currentQueryParams);
    }

    getForceTeamId() {
        if (this.dialogRef?.componentInstance) {
            return this.dialogRef.componentInstance.dialogOptions?.forceTeamId;
        } else {
            return this.currentParams._forceTeam
        }
    }

    private setUp(params: Params, qParams: Params) {

        this.currentQueryParams = qParams;
        this.currentParams = params;
        this.config.setTeam(this.getForceTeamId());

        if (this.config.mode === 'edit') {
            if (!this.config.allowEdit) {
                this.config.readonly = true;
            }
            let theId;
            if (this.dialogRef?.componentInstance) {
                theId = this.dialogRef.componentInstance.dialogOptions?.id;
            } else {
                theId = this.currentParams.itemId;
            }
            this.loadingData = true;
            if (!theId) {
                console.warn('Get What ID? ' + this.config?.title, {t: this, c: this.config});
            }
            this.config.service.getOne(theId, null, this.getForceTeamId()).subscribe(
                fullObject => {
                    // console.log('**** Setting up ****', parms);
                    this.focusItem = this.config.beforeEdit(fullObject);
                    this.config.populatePicklists([this.focusItem]);
                    this.config.setItemTitle(this.focusItem);
                    this.resetForm();
                    this.notifySetupComplete();
                    // console.log('*** SETUP Complete ***', this.formGroup);
                }
            );
            if (this.currentQueryParams.params.topTab && this.selectedTopTab === 0) {
                const tabIndex = this.config.tabFields.findIndex(f => f.name === this.currentQueryParams.params.topTab);
                if (tabIndex >= 0) {
                    window.setTimeout(() => {
                        /** Post Angular 18 upgrade needs to happen on next tick
                         * otherwise the content flashes and disappears in the tabs, sorry for lazy fix...
                          */
                        this.selectedTopTab = tabIndex + 1;
                    }, 100);
                    //this.selectedTopTab = tabIndex + 1;
                }
            }
        } else if (this.config.mode === 'new') {
            this.config.objectFactory(params).subscribe((o: AbstractObject) => {
                this.focusItem = o;
                this.config.populatePicklists([this.focusItem]);
                this.resetForm();
                if (this.dialogRef) {
                    // ideally should be able to check if was defaulted, trying to turn on save if popped up with defaults
                    this.formGroup.markAsDirty();
                }
                this.notifySetupComplete();
            })
        }
    }

    public topTabChanges(evt: MatTabChangeEvent) {
        // This just changes the URL, to allow refresh/bookmark but does not reload, routing would reload.
        if (this.dialogRef) {
            return; // No need to do this if in a popup dialog
        }
        const pathname = window.location.pathname;
        let params = ''

        if (evt.index > 0) {
            const tf = this.config.tabFields[evt.index - 1];
            const searchParams = (new URL(window.location.href)).searchParams;
            searchParams.set('topTab', tf.name);
            params = searchParams.toString();
        }
        this.location.replaceState(pathname, params);
    }

    ngAfterViewChecked(): void {
        if (this.resetStart > 0) {
            console.log('Load form took  ' + (new Date().getTime() - this.resetStart) + 'ms',
                { item: this.focusItem, config: this.config });
            this.resetStart = 0;
        }
    }

    private resetForm() {
        this.resetStart = new Date().getTime();
        this.config.actions.forEach((ifa: IFormAction) => ifa.setup(this.focusItem));
        this.config.fieldSet.setValue(this.focusItem, this.config.readonly);
        this.config.tabFields.forEach(f => f.setValue(this.focusItem, this.config.readonly));
        this.loadingData = false;
    }

    private notifySetupComplete() {
        if (this.config.initNotification) {
            this.config.initNotification.next(this.formGroup);
            this.config.initNotification.complete();
        }
    }

    private backOut() {
        this.resetForm();
        this.router.navigate(['..'], { relativeTo: this.activeRoute });
    }
}

