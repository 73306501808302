/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
    providedIn: 'root'
})
export class MyRequestService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'crm/myrequests';
    protected cache: ServiceRequest[];
    protected typeString = 'ServiceRequest';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    getAccessRequests() {
        const url = this.baseUrl + '/openAccess';
        return this.http.get<ServiceRequest[]>(url, this.httpOptions);
    }

    postAgentRequest(object: ServiceRequest, agentId: uuid): Observable<ServiceRequest> {
        return this.http.post<ServiceRequest>(this.baseUrl + '/' + agentId, object, this.httpOptions).pipe(
            map((data: ServiceRequest) => {
                return data;
            }),
            tap((data: ServiceRequest) => {
                this.messageService.show(this.msgAdded);
                return data;
            }),
            catchError(this.handleError<ServiceRequest>('postAgentRequest'))
        );
    }

    approveResidentAccess(o: ServiceRequest) {
        const url = `${this.baseUrl}/${o.id}/approveResident`
        return this.http.put<ServiceRequest>(url, o, this.httpOptions).pipe(
            map((data: ServiceRequest) => {
                return data;
            }),
            tap((data: ServiceRequest) => {
                this.messageService.show('Resident has been added to your unit and can now access the system');
                return data;
            }),
            catchError(this.handleError<ServiceRequest>('approving Resident'))
        );
    }
}
