
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { MatDialog } from "@angular/material/dialog";
import { MyInjector } from "src/app/app.module";
import { Period } from "src/app/model/period";
import { Txn } from "src/app/model/txn";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { BankInEditComponent } from "./bank-in-edit/bank-in-edit.component";

export class BankInEditAction implements IFormAction {
    name = $localize`Edit Date`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'edit';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText: string;
    dialog = MyInjector.instance.get(MatDialog);

    bankIn: Txn;

    action() {
        return this.dialog.open(BankInEditComponent, { data: this.bankIn }).afterClosed();
    }

    setup(bi: Txn) {
        this.bankIn = bi;
        this.disabled = true;
        if (bi.txnPeriod?.statusId !== Period.STATUS.OPEN.id) {
            this.tipText = 'Cannot edit transaction date, the period is closed so changes not allowed';
        } else if (bi.bankAccount?.lastRecDate >=  bi.txnDate || bi.bankItemId) {
            this.tipText = 'Cannot edit transaction, this item is already reconciled';
        } else {
            this.disabled = false;
            this.tipText = 'Edit transaction date';
        }
        this.show = true;
    }
}
