/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { DateHelper } from "src/app/shared/dateHelper";
import { ImportDoc, ImportDocNote } from "../ImportDoc";
import { ImportField } from "../ImportField";
import { ParseOptionBase, ParseValidator } from "./ParseOptionBase";
import { ParseOptionBaseMapped } from "./ParseOptionBaseMapped";

export class POBValidators {
    /**
     * requiredPOB messes up if there is more than one required column,
     * for now simple validator if selected, must have a value, but not forcing user to select...
     */
    static requiredPOB() {
        const pobValidator = new ParseValidator();

        pobValidator.validateRow = (field: ImportField) => {
            const val = field.getValue();
            if (val === undefined || val === null || val === '' || (typeof val === 'string' && val.trim() === '')) {
                return $localize` Value not provided for required field`
            } else {
                return null;
            }
        }

        return pobValidator;
    }

    static noNewLines() {
        const pobValidator = new ParseValidator();

        pobValidator.validateRow = (field: ImportField) => {
            const val = field.getValue();
            if (typeof val === 'string') {
                if (val.includes("\n") || val.includes("<br") || val.includes("<BR")) {
                    return $localize` Value should only contain one line of text`
                }
            }
            return null;
        }

        return pobValidator;
    }
}

export const requiredPOB = {
    prepareOption(pob: ParseOptionBase, importDoc: ImportDoc) {
        if (!pob.hasValidatorNote(this)) {
            const noteItem = new ImportDocNote({
                note: $localize`${pob.name} is required, you will need to select a column with values for it`,
                needsFix: true
            });
            pob.addValidatorNote(this, noteItem, importDoc)
        }
    }, validateRow: (field: ImportField) => {
        const val = field.getValue();
        if (val === undefined || val === null || val === '' || (typeof val === 'string' && val.trim() === '')) {
            return $localize` Value not provided for required field`
        } else {
            return null;
        }
    },
    optionSelected(pob: ParseOptionBase, importDoc: ImportDoc) {
        pob.removeValidatorNote(this, importDoc);
    }
} as ParseValidator;

export const requiredNumber = {
    validateRow: (field: ImportField) => {
        const val = field.getValue() as string;
        const THOUSANDS_SEP = ','; // Sorry Thomas - You will need to genericise for Europe...
        if (val.trim() === '0' || val.trim() === '0.00') {
            field.setValue(0);
            return null;
        }
        const val2 = val.replace(THOUSANDS_SEP, '');
        if (+val2 ) {
            field.setValue(+val2);
            return null;
        } else {
            return $localize` Value not provided for required number field`
        }
    }
} as ParseValidator

export const requiredOrDefaultMap = {
    prepareOption(pob: ParseOptionBase, importDoc: ImportDoc) {
        if (!pob.hasValidatorNote(this)) {
            const noteText = $localize`${pob.name} is required, either select a column with values for it OR delete this item and enter a default value for it`;

            const noteItem = {
                note: noteText, needsFix: true, deleteHandler: () => {
                    if (pob instanceof ParseOptionBaseMapped) {
                        pob.createOptionMapperInput('default_OPTION');
                        pob.setMappedTo('default_OPTION');
                    } else {
                        console.error('ParseOption does not support default mapping', { pob });
                    }
                }
            } as ImportDocNote
            pob.addValidatorNote(this, noteItem, importDoc)
        }
    }, validateRow: (field: ImportField) => {
        const val = field.getValue();
        if (val === undefined || val === null || val === '' || (typeof val === 'string' && val.trim() === '')) {
            return $localize` Value not provided for required field`
        } else {
            return null;
        }
    },
    optionSelected(pob: ParseOptionBase, importDoc: ImportDoc) {
        pob.removeValidatorNote(this, importDoc);
    }
} as ParseValidator;

export const validateEmailPOB = () => ({
    validateRow: (field: ImportField) => {
        const EMAIL_REGEXP = /^[a-z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
        const value = field.getValue() as string;
        if (value) {
            if (!EMAIL_REGEXP.test(value)) {
                return $localize`'${value}' is not a valid email address`;
            }
        }
        return null;
    }
} as ParseValidator);

export const validateDatePOB = (format = 'YYYY-MM-DD') => ({
    validateRow: (field: ImportField) => {
        const dateIn = field.getValue() as string;
        if (new DateValidator(format, dateIn).isValid()) {
            if (format !== 'YYYY-MM-DD') {
                field.setValue(DateHelper.isoFromDDMMYY(dateIn));
            }
            return null;
        } else {
            return $localize`${field.getValue()} is not a valid date, required format is ${format}`
        }
    }
} as ParseValidator);

class DateExaminer {
    constructor(private year: string, private month: string, private day: string) { }

    public isValid() {
        if (!isNaN(Date.parse(`${this.year}-${this.month}-${this.day} 00:00:00 GMT`))) {
            return true;
        }
        return false;
    }
}

class DateValidator {

    constructor(private format: string, private dateString: unknown) { }

    public isValid() {
        if (!this.dateString || typeof this.dateString !== 'string') {
            return false;
        }
        if (this.format === 'YYYY-MM-DD') {
            const parts = this.dateString.split('-');
            if (parts.length !== 3) {
                return false;
            } else {
                return new DateExaminer(parts[0], parts[1], parts[2]).isValid();
            }
        } else if (this.format === 'DD/MM/YYYY') {
            const parts = this.dateString.split('/');
            if (parts.length === 3 && (new DateExaminer(parts[2], parts[1], parts[0]).isValid())) {
                return true;
            }
            return false;
        } else {
            console.error('Not able to handle format ' + this.format);
            return false;
        }
    }
}
