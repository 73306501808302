/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Team } from 'src/app/model/team';
import { Txn } from 'src/app/model/txn';
import { User } from 'src/app/model/user';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { AttachmentControl } from 'src/app/shared/field/AttachmentField';

import { AttachmentListComponent } from 'src/app/shared/form/file/attachment-list/attachment-list.component';
import { UpFile } from 'src/app/shared/form/file/file-upload/UpFile';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ByteCountPipe } from 'src/app/shared/pipes/byte-count.pipe';
import { BCodeService } from '../../budget/bcode.service';
import { ScheduleService } from '../../budget/schedule.service';
import { PurchaseService } from '../purchase.service';
import { AttachmentListComponent as AttachmentListComponent_1 } from '../../../shared/form/file/attachment-list/attachment-list.component';
import { MatCardModule } from '@angular/material/card';
import { CurrentUserService } from '../../user/current-user.service';

@Component({
    selector: 'app-purchase-uploads-page',
    templateUrl: './purchase-uploads-page.component.html',
    styleUrls: ['./purchase-uploads-page.component.scss'],
    standalone: true,
    imports: [MatCardModule, AttachmentListComponent_1]
})
export class PurchaseUploadsPageComponent implements OnInit {

    static readonly navRoute = new NavRoute(Txn.TYPE.PURCHASE.code + '/uploads', PurchaseUploadsPageComponent, 'cloud_upload');

    width = 500;

    control = new AttachmentControl(AttachmentListComponent.make('File Uploads', 'fileUploads',
        { service: this.purchaseSvc, widthPx: this.width, allowNew: true, allowMove: true, allowInvoice: true, allowDelete: true }));
    /*  onDrop: this.onDrop.bind(this) */
    files: UpFile[] = [];
    currentUserTeams: Team[];
    currentUser: User;
    byteCount = new ByteCountPipe();
    /*
      uploadGrid = new GridField({
        field:
            { label: $localize `Template Transaction`, value: 'template', visible: Field.formOnly, formRow: 2 },
        rowFactory: ( o: AbstractObject) => [
            Field.make.id(),
            //Field.make.rev(),
            FormPicklistComponent.make($localize `OMC Name`, 'teamId', 'team',
                { items: this.currentUserTeams }, { cellOpts:{minWidth: '30px', maxWidth: '120px'}}
            ),
            FormTextComponent.make('Name', 'name', {cellOpts:{maxWidth: '40px'}}),
            FormTextComponent.make('Size', 'size', {readonly: true, cellOpts:{maxWidth: '20px'}}),
            Field.make.notes({ cellOpts:{width: '25%'} }),
            Field.make.deleteGridRow(),
        ]
    });
    */
    constructor(protected purchaseSvc: PurchaseService, protected cds: ConfirmDialogService,
        protected scheduleSvc: ScheduleService, protected isNarrow: IsNarrowService,
        protected bCodeSvc: BCodeService, private currentUserSvc: CurrentUserService) {

        this.width = isNarrow.screenWidth * .6;
        this.control.field.control = this.control; // nasty hacking... needed for remove... should have inited properly...
        this.control.field.fileOpts.widthPx = this.width;

        this.purchaseSvc.getAttachments().pipe(first()).subscribe(as => this.control.setValue(as));

        this.currentUserSvc.getCurrentUser().subscribe ( user => {
            this.currentUser = user;
            if (this.currentUser.inAgency) {
                this.control.field.fileOpts.allowMove = true;
            } else {
                this.control.field.fileOpts.allowMove = false;
            }
        })

    }
    canDeactivate() {
        return true;
    }

    ngOnInit(): void {
        console.log(this.control.field.fileOpts);
        //this.uploadGrid.makeControl();
        //this.uploadGrid.control.setValue([]);
    }
    /*
      onDrop( files: UpFile[]) {
        console.log(files);
        for (const upf of files) {
            this.files.push(upf);
            const item = ({ id: null, teamId: this.currentUser.current_team_id,
                            team: this.currentUser.currentTeam,
                            name: upf.name,
                            size: this.byteCount.transform(upf.size)} as AbstractObject
            );

            this.uploadGrid.control.addRow( item, true, false);
        }
      }
    */

}
