/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { inject, Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { AbstractObjectList } from "src/app/model/abstract-object";
import { User } from "src/app/model/user";
import { CurrentUserService } from "../../user/current-user.service";
import { ImportParser } from '../import-page/Import-parser-interface';
import { ImportDoc } from "../ImportDoc";
import { GeneralParser } from "./GeneralParserService";
import { ParseOptionBase } from "./ParseOptionBase";
import { ParseOptionBaseMapped } from "./ParseOptionBaseMapped";
import { POBValidators, validateEmailPOB } from "./POBValidators";
import { Unit } from "src/app/model/unit";
import { UnitService } from "../../unit/unit.service";
import { ImportRow } from "../ImportRow";   
import { RoleService } from "src/app/pages/role-page/role.service";
import { Role } from "src/app/model/role";
import { PersonService } from "src/app/pages/person-page/person.service";

@Injectable({
    providedIn: 'root'
})
export class PeopleParserService implements ImportParser {

    gps = new GeneralParser();
    unitSvc = inject(UnitService);
    currentUserSvc = inject(CurrentUserService);
    roleSvc = inject(RoleService);
    personSvc = inject(PersonService);

    units : Unit[] = [];

    importDoc: ImportDoc;
    currentUser: User;

    unitNamePOB = new ParseOptionBaseMapped('Unit', 'unitId', [POBValidators.requiredPOB()]).makeSilent();
    titlePOB = new ParseOptionBase('Title', 'title');
    firstNamePOB = new ParseOptionBase('First Name', 'firstName');
    lastNamePOB = new ParseOptionBase('Last Name', 'lastName', [POBValidators.requiredPOB()]);
    emailPOB = new ParseOptionBase('Email', 'email', [validateEmailPOB()]);
    phonePOB = new ParseOptionBase('Phone', 'phone');
    addressPOB = new ParseOptionBase('Address', 'address');
    notesPOB = new ParseOptionBase('Notes', 'notes');
    rolePOB = new ParseOptionBaseMapped('Role', 'roleId', [POBValidators.requiredPOB()]);

    availableImportItems = [
        this.unitNamePOB,
        this.titlePOB,
        this.firstNamePOB,
        this.lastNamePOB,
        this.emailPOB,
        this.phonePOB,
        this.addressPOB,
        this.notesPOB,
        this.rolePOB
    ];

    setUp() {
        this.importDoc = new ImportDoc();

        return forkJoin({
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            units: this.unitSvc.get(true),
            roles: this.roleSvc.get(true)
        }).pipe(map(result => {
            this.currentUser = result.currentUser;
            this.unitNamePOB.setOptions(result.units, this.importDoc);
            this.rolePOB.setOptions((result.roles as Role[]).filter( r => r.unitLinked), this.importDoc);
            return true;
        }));
    }

    parseRows(rows: unknown[][]): ImportDoc {
        this.gps.availableImportItems = this.availableImportItems;
        this.gps.parseRows(rows, this.importDoc);
        return this.importDoc;
    }

    postToServer(): Observable<AbstractObjectList> {
        const postItems = this.gps.getItemsToPost((row: ImportRow) => {
            const u = {id: null, revision: null};
            console.warn('Row Ignored', row);
            /*
            const exists = this.units.find( u => u.name === row.getValue(this.unitNamePOB.fieldName));
            if (exists) {
                u.id = exists.id;
                u.revision = exists.revision;
            }
            */
            return u;
        });
        console.log(postItems);
    
        return this.personSvc.postItems(postItems);
    }
}
