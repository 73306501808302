/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { of } from 'rxjs';
import { ActionTemplate } from 'src/app/model/ActionTemplate';
import { Field } from 'src/app/shared/field/Field';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { maxValue, minValue, required } from 'src/app/shared/validators';
import { RequestCategoriesService } from '../request-categories.service';
import { RequestPrioritiesService } from '../request-priorities.service';
import { RequestTemplatesService } from '../request-templates.service';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { FormDurationComponent } from 'src/app/shared/form/form-duration/form-duration.component';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { ChartParms } from 'src/app/model/sys/nav-item';
import { OmcRole } from 'src/app/model/OmcRole';
import { Frequency } from 'src/app/model/frequency';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { RecurringJobComponent } from '../recurring-job/recurring-job.component';
import { AddRecurringJobsAction } from '../recurring-job/add-recurring-jobs-action';
import { ScheduleJobsAction } from '../recurring-job/schedule-jobs-action';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';

@Component({
    selector: 'app-request-template',
    templateUrl: './request-template.component.html',
    styleUrls: ['./request-template.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RequestTemplateComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/requestTemplates', RequestTemplateComponent, 'pattern').setCharts([
        { type: 'bar', picklist: 'categoryId' } as ChartParms,
        { type: 'pie', picklist: 'priorityId' } as ChartParms,
        { type: 'pie', picklist: 'frequencyId' } as ChartParms,
    ]);

    jobsLink = FormButtonComponent.makeNavButton('Jobs',
        RecurringJobComponent.navRoute, o => ({ templateId: o.id }),
        { calculateValue: o => o['recurring_jobs_count'], formColumn: 4 }
    );

    requestsLink = FormButtonComponent.makeNavButton('Open Requests',
        ServiceRequestPageComponent.navRoute, o => ({ templateId: o.id, srStatus: ServiceRequest.srOpen.id }),
        { value: 'open_requests_count', formColumn: 4 }
    );

    config = new FormConfig({
        navRoute: RequestTemplateComponent.navRoute,
        title: $localize`Request Templates`,
        help: $localize`Templates for creating new requests`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                FormTextComponent.make('Name', 'name', { validators: [required], cellOpts: { width: '25%' } }),
                FormTextAreaComponent.make('Description', 'description', { cellOpts: { width: '50%' } }),
                FormPicklistComponent.make('Category', 'categoryId', 'category', { service: this.catSvc }, { formColumn: 2 }),
                FormPicklistComponent.make('Priority', 'priorityId', 'priority', { service: this.prioSvc }, { formColumn: 2 }),
                FormPicklistComponent.make('Frequency', 'frequencyId', null,
                    { items: Frequency.ALL_OPTIONS, allowSelectNone: true }, { formColumn: 2 }
                ),
                FormPicklistComponent.make('Default Request Owner', 'ownerOmcRole', null,
                    { items: OmcRole.values }, { formColumn: 3 }
                ),
                FormPicklistComponent.make('Default Follower', 'followerOmcRole', null,
                    { items: OmcRole.values, allowSelectNone: true }, { formColumn: 3, hint: $localize`Will receive notification when closed` }
                ),
                this.jobsLink,
                this.requestsLink,

                new GridField({
                    field: { value: 'actions', cellOpts: { heading: 'Actions' }, visible: Field.formOnly, formRow: 2 },
                    rowFactory: () => [
                        FieldMaker.id({ visible: Field.noShow }),
                        FieldMaker.nameControl({ validators: [required], cellOpts: { heading: 'Name', width: '20%' } }),
                        FormTextComponent.make('Description', 'description', {}),
                        FormNumberComponent.make('Days Offset', 'offset', {}, {
                            validators: [maxValue(30), minValue(-63)],
                            hint: 'Number of days before or after target date, enter -1 for before'
                        }),
                        FormPicklistComponent.make('Default Action Owner', 'ownerOmcRole', null, { items: OmcRole.values }),
                        FormDurationComponent.make('Default Time', 'timeSpent', { validators: [minValue(0), maxValue(960)] }),
                        FieldMaker.rev(),
                        FieldMaker.deleteGridRow(),
                    ],
                    objFactory: () => of(new ActionTemplate()),
                    newOptionText: $localize`Add Action Item`,
                })
            ],
            formLayout: LAYOUT_OPTIONS.fourColOver1,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: () => of(new RequestTemplate()),
        actions: [new ScheduleJobsAction(), new AddRecurringJobsAction('template')],
    });

    constructor(private dataSvc: RequestTemplatesService,
        private prioSvc: RequestPrioritiesService, private catSvc: RequestCategoriesService,) {
        super();
    }
}
