/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Field, FieldType } from '../field/Field';
import { FormGroup } from '@angular/forms';
import { GridField } from '../grid/grid-field';
import { GridControlParameters } from '../grid/grid-control-parameters';
import { FormTextComponent } from '../form/form-text/form-text.component';
import { FormDateTimeComponent } from '../form/form-date-time/form-date-time.component';
import { MatDialog } from '@angular/material/dialog';
import { NewMemoComponent } from './new-memo/new-memo.component';
import { MyInjector } from 'src/app/app.module';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { AbstractHttpService } from '../abstract-http.service';
import { FormRichTextComponent } from '../form/form-rich-text/form-rich-text.component';
import { MemosComponent } from './memos.component';
import { FormButtonComponent } from '../form/form-button/form-button.component';
import { Memo } from 'src/app/model/memo';
import { AppFormControl } from '../form/app-form-control';

export class MemoField extends GridField {
    name = 'memos';
    label = 'Notes';
    value = 'memos';
    type = 'memos' as FieldType;
    sendServer = false;
    visible = Field.noShow;
    tabIcon = 'news';
    relatedFormGroup: FormGroup;

    relatedId: uuid;
    relatedTeamId: uuid;

    formControlFactory = MemosComponent.createComponent;

    config: GridControlParameters = {
        field: this,
        rowFactory: this.rowFactory.bind(this),
        objFactory: this.createNewMemo.bind(this),
        newOptionText: 'Add Note',
    };

    constructor(public service: AbstractHttpService) {
        super({ field: {}, rowFactory: () => [] }); // Just Dummy Values...
    }

    setValue(item: AbstractObject, readonly: boolean) {
        super.setValue(item, readonly);
        this.relatedId = item.id;
        this.relatedTeamId = item.teamId;
    }

    rowFactory(oMemo : Memo) {
        const fields = [
            FormTextComponent.make('Author', 'authorName', {
                sendServer: false, readonly: true, cellOpts: { maxWidth: '6em' }, visible: { phone: false, computer: true, form: true }
            }),
            FormDateTimeComponent.make('Date', 'createdAt', {
                cellOpts: {minWidth: '9em'},
                sendServer: true, readonly: true, visible: { phone: false, computer: true, form: true }
            }),
            FormRichTextComponent.make('Notes', 'memo', { cellOpts: { width: '100%' } }).override({ readonly: true}),
            FormButtonComponent.makeIconButton('edit-' + oMemo?.id, 'edit_note', 'Click to edit this note', (gridCell: AppFormControl) => {
                this.editMemo(oMemo, gridCell);
            })
        ];
        return fields;
    }

    editMemo(oMemo: Memo, gridCell: AppFormControl) {
        const dialog = MyInjector.instance.get(MatDialog);
        const dialogRef = dialog.open(NewMemoComponent, { data: { field: this, memo: oMemo } });
        return dialogRef.afterClosed().subscribe( updatedMemo => {
            if (updatedMemo && gridCell && gridCell.getRow()) {
                gridCell.getRow().refreshFrom(updatedMemo);
            }
        });
    }

    createNewMemo() {
        const dialog = MyInjector.instance.get(MatDialog);
        const dialogRef = dialog.open(NewMemoComponent, { data: { field: this } });
        return dialogRef.afterClosed();
    }
    /*
        createAndAddNote() {
            this.createNewNote().subscribe(newNote => {
                if (newNote) {
                    this.control.addRow(newNote, true, true);
                }
            });
        }
    */

    getTitle(oMemo: Memo) {
        if (oMemo) {
            return 'Edit Note';
        } else {
            return 'Add Note';
        }
    }
}
