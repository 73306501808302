@if (on === 'form') { <!-- FORM BUTTONS AND LINKS -->
    @if (control.field.type === 'link' && !control.field.btnOpts.clickMethod) {
        <ng-container *ngTemplateOutlet="formNavigateLink"></ng-container>
    } @else if (control.field.type === 'link' && control.field.btnOpts.clickMethod) {
        <ng-container *ngTemplateOutlet="formCustomLinkButton"></ng-container>
    } @else if (control.field.type === 'icon' && !control.field.btnOpts.clickMethod) {
        <!-- Switching between icon and raised buttons just to suit icon on budgeting screen, nasty...
        The budgeting screen is using buttons to display indicator icons, even though there is no click event
        Guess trick could be used elsewhere... but should really be another control...
        -->
        <ng-container *ngTemplateOutlet="formDisplayIconOnly"></ng-container>
    } @else if (control.field.type === 'icon' && control.field.btnOpts.clickMethod) {
        <ng-container *ngTemplateOutlet="formIconButton"></ng-container>
    }
} @else {  <!-- GRID BUTTONS AND LINKS -->
    @if (control.field.type === 'link') {
        @if (control.field.btnOpts.navParmCalculator) {
            <ng-container *ngTemplateOutlet="gridNavigateLinkButton"></ng-container>
        } @else if (control.field.btnOpts.navigateRoute && !control.field.externalNav) {
            <ng-container *ngTemplateOutlet="gridNavigateLink"></ng-container>
        } @else if (control.field.btnOpts.navigateRoute && control.field.externalNav) {
            <ng-container *ngTemplateOutlet="gridExternalLink"></ng-container>
        } @else if (control.field.btnOpts.clickMethod) {
            <ng-container *ngTemplateOutlet="gridCustomLinkButton"></ng-container>
        }
    } @else if (control.field.type === 'icon') {
        <ng-container *ngTemplateOutlet="gridIconButton"></ng-container>
    }
}

<ng-template #formNavigateLink>
    <mat-form-field class="full-width" style="height: 74px;" floatLabel="always">
        <!-- margin-bottom: 5px removed to make line up with date/dateTime field-->
        <mat-label>{{control.field.label}}</mat-label>
        <div
            style="width: 100%; overflow: hidden; margin-top: -16px; margin-left: -10px; align-items: bottom; white-space: nowrap;">
            @if (control.field.btnOpts.navigateRoute && !control.field.btnOpts.clickMethod) {
            <a [disabled]="control.field.disable" [matTooltip]="control.value" mat-button
                [color]="control.field.btnOpts.color"
                [routerLink]="[control.field.btnOpts.navigateRoute, control.field.btnOpts.navParms]"
                style="line-height: 18px; font-size: 14px; padding-top: 16px">{{control.value}}
            </a>
            }
        </div>
        <input matInput [formControl]="control" style="display: none">
        <!-- needed for layout, mat form complains complains without-->
        <mat-hint>{{control.field.hint}}</mat-hint>
    </mat-form-field>
</ng-template>

<ng-template #gridNavigateLink> <!-- used on units, owner name... or simple navigates-->
    <a [style.text-align]="'left'"
    style="padding: 0px; line-height: 1.25"
        [routerLink]="[control.field.btnOpts.navigateRoute, control.field.btnOpts.navParms]"
        [style.pointer-events]="control.field.disable ? 'none' : 'auto'">
        {{control.value}}
    </a>
</ng-template>

<ng-template #gridNavigateLinkButton> <!-- Example on BankAccounts Cashflow Grid -->
    <a mat-button [style.text-align]="'left'" [color]="control.field.btnOpts.color"
    style="padding: 0px; line-height: 1.25"
    [routerLink]="['/' + control.field.btnOpts.navigateRoute, control.field.btnOpts.navParms]"
        [disabled]="control.field.disable">
        {{control.value}}
    </a>
</ng-template>
<ng-template #gridNavigateDetailLinkButton>
    <a mat-button [style.text-align]="'left'" [color]="control.field.btnOpts.color"
        style="padding: 0px; line-height: 1.25" (click)="control.field.btnOpts.clickMethod(null, null)"
        [disabled]="control.field.disable">
        {{control.value}}
    </a>
</ng-template>
<ng-template #gridExternalLink>
    <a mat-button [style.text-align]="'left'" [color]="control.field.btnOpts.color" style="padding: 0px"
        [href]="control.field.btnOpts.navigateRoute" [disabled]="control.field.disable">
        {{control.value}}
    </a>
</ng-template>

<ng-template #formCustomLinkButton>
    <div class="textLinkButton">
        <div style="flex: 1; margin-top: 10px">
            {{control.field.label}}:
        </div>
        <div>
            <button mat-button (click)="onClick($event)" [color]="control.field.btnOpts.color"
            [disabled]="control.field.disable || control.field.readonly"
                [matTooltip]="control.field.hint" style="width: 100%; justify-content: flex-end;"> <!--  margin-top: 8px -->
                {{control.value}}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #gridCustomLinkButton>
    @if (control.field.disable) {
        <span style="color: gainsboro; padding-left: 10px">
            {{control.value}}
        </span>
    } @else {
        <div [matTooltip]="control.field.toolTip">
            <!-- Sorry about color & shadow, but if we use mat-button it centres and looks-->
            <a style="padding: 0px; line-height: 1.2; cursor: pointer;" class="primary-font-color" (click)="onClick($event)">
                {{control.value}}
            </a>
        </div>
    }
</ng-template>

<ng-template #formDisplayIconOnly>
    <ng-container style="height: 74px; margin-bottom: 5px;">
        <button (click)="onClick($event)" [disabled]="control.field.disable || control.field.readonly" mat-icon-button
            [color]="control.field.btnOpts.color" [style]="control.field.btnOpts.style" class="normal"
            [matTooltip]="control.field.toolTip" [matTooltipDisabled]="!control.field.toolTip">
            <mat-icon [style]="control.field.btnOpts.iconStyle" class="formButtonIcon"
                color="accent">{{control.value}}</mat-icon>
            {{control.field.label}}
        </button>
    </ng-container>
</ng-template>

<ng-template #formIconButton>
    <div [style]="control.field.cellOpts.style" style="height: 74px; margin-bottom: 5px;">
        <button (click)="onClick($event)" [disabled]="control.field.disable || control.field.readonly" mat-raised-button
            [color]="control.field.btnOpts.color" [style]="control.field.btnOpts.style" class="normal"
            [matTooltip]="control.field.toolTip" [matTooltipDisabled]="!control.field.toolTip">
            <mat-icon [style]="control.field.btnOpts.iconStyle" class="formButtonIcon">{{control.value}}</mat-icon>
            {{control.field.label}}
        </button>
    </div>
</ng-template>

<ng-template #gridIconButton>
        <button (click)="onClick($event)" [disabled]="control.field.disable" mat-icon-button
            [color]="control.field.btnOpts.color" [style]="control.field.btnOpts.style" [matTooltip]="control.field.toolTip"
            [matTooltipDisabled]="!control.field.toolTip">
            @if (!image()) {
            <mat-icon [style]="control.field.btnOpts.iconStyle">{{control.value}}</mat-icon>
            }
            @if (image()) {
            <img src="./assets/{{image()}}" />
            }
        </button>
</ng-template>
