/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AbstractObject } from 'src/app/model/abstract-object';
import { DateTimeField } from 'src/app/shared/form/form-date-time/form-date-time.component';

@Component({
  selector: 'app-date-time-table-cell',
  standalone: true,
  imports: [ MatTooltipModule, DatePipe ],
  templateUrl: './date-time-table-cell.component.html',
  styleUrl: './date-time-table-cell.component.scss'
})
export class DateTimeTableCellComponent {
    @Input({required: true}) field: DateTimeField
    @Input({required: true}) focusItem: AbstractObject
}
