/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { formatCurrency } from "@angular/common";
import { Injectable } from "@angular/core";
import { forkJoin, Observable } from "rxjs";
import { first, map } from "rxjs/operators";
import { BCode } from "src/app/model/bcode";
import { Period } from "src/app/model/period";
import { PreferredSupplier, SupplierTemplate } from "src/app/model/supplier";
import { Txn } from "src/app/model/txn";
import { User } from "src/app/model/user";
import { MessageService } from "src/app/shared/message.service";
import { BCodeService } from "src/app/modules/budget/bcode.service";
import { PeriodService } from "src/app/modules/budget/period.service";
import { PreferredSupplierService } from "src/app/modules/supply/preferred-supplier.service";
import { SupplierTemplateService } from "src/app/modules/supply/supplier-template.service";
import { PurchaseService } from "src/app/modules/txn/purchase.service";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { ImportProcessor } from '../import-page/Import-processor-interface';
import { ImportDoc } from "src/app/modules/importer/ImportDoc";
import { ImportField } from "src/app/modules/importer/ImportField";
import { ImportInput } from "src/app/modules/importer/ImportInput";
import { ImportRow } from "src/app/modules/importer/ImportRow";

@Injectable({
    providedIn: 'root'
})
export class BMPurchaseInvoiceProcessorService implements ImportProcessor {

    prefSuppliers: PreferredSupplier[];
    newSuppliers: ImportRow[] = [];
    existingSuppliers = 0;

    bCodes: BCode[];
    newBCodes: ImportRow[] = [];

    supplierTemplates: SupplierTemplate[];
    newSupplierTemplates: ImportRow[];

    purchases: Txn[];
    newPurchaseTxn: Txn[];
    newPurchases: ImportRow[] = [];

    periods: Period[] = [];
    currentUser: User;

    importDoc: ImportDoc;
    originalRows: ImportRow[];

    constructor(private prefSupplierSvc: PreferredSupplierService,
        private bCodeSvc: BCodeService,
        private supplierTemplateSvc: SupplierTemplateService,
        private periodSvc: PeriodService,
        private currentUserSvc: CurrentUserService,
        private purSvc: PurchaseService,
        private msgSvc: MessageService) {
    }

    setUp(): Observable<boolean> {
        return forkJoin({
            suppliers: this.prefSupplierSvc.get(false).pipe(first()),
            bcodes: this.bCodeSvc.get(false).pipe(first()),
            supplierTemplates: this.supplierTemplateSvc.get(false).pipe(first()),
            periods: this.periodSvc.get(true).pipe(first()),
            currentUser: this.currentUserSvc.getCurrentUser().pipe(first()),
            purchases: this.purSvc.get(false).pipe(first()),
            defs: this.currentUserSvc.getDefaultBCodes()
        }).pipe(map(result => {
            this.prefSuppliers = result.suppliers as PreferredSupplier[];
            this.bCodes = result.bcodes as BCode[];
            this.supplierTemplates = result.supplierTemplates as SupplierTemplate[];
            this.periods = result.periods as Period[];
            this.currentUser = result.currentUser;
            this.purchases = result.purchases as Txn[];
            return true;
        }));
    }

    getData(importDoc: ImportDoc): void {
        this.importDoc = importDoc;
        this.newSuppliers = [];
        this.newBCodes = [];
        this.newSupplierTemplates = [];
        this.newPurchaseTxn = [];
        this.newPurchases = [];

        this.importDoc.clearInputs();
        this.originalRows = importDoc.getRows();
        if (this.checkSuppliersExist()) {
            if (this.checkBCodesExist()) {
                if (this.checkSupplierTemplateExists()) {
                    if (this.checkDatesOk()) {
                        this.checkPurchaseInvoicesExists();
                    }
                }
            }
        }
    }

    checkSuppliersExist() {
        const newSupplierMap: Map<string, ImportRow> = new Map();

        for (const row of this.importDoc.getRows()) {
            if (row.hasField('creditor_name')) {
                const supplierName = row.getStringValue('creditor_name');
                const supplier = this.prefSuppliers.find(o => o.name === supplierName);
                if (supplier) {
                    row.add(new ImportField('supplierId').setValue(supplier.id));
                } else if (!newSupplierMap.get(supplierName)) {
                    newSupplierMap.set(supplierName,
                        new ImportRow().add(new ImportField('name').setValue(supplierName))
                    );
                    console.log('pusting', { supplierName, o: newSupplierMap.get(supplierName) });
                    this.newSuppliers.push(
                        newSupplierMap.get(supplierName)
                    );
                }
            }
        }
        if (this.newSuppliers.length > 0) {
            this.importDoc.setRows(this.newSuppliers);
            this.importDoc.addInput(
                new ImportInput(
                    `File Contains ${this.newSuppliers.length}. unknown suppliers`, null,
                    'These need to be imported before you can continue'
                )
            )
            console.log(this.importDoc);
            return false;
        } else {
            this.importDoc.addInput(new ImportInput(`All Suppliers are valid`, null, ''));
            return true;
        }
    }

    checkBCodesExist() {
        const importMap: Map<number, ImportRow> = new Map();

        for (const row of this.importDoc.getRows()) {
            const acct_name = row.getStringValue('account_name');
            const sort = +acct_name.substring(0, 4);
            const name = acct_name.substring(5);
            const bcode = this.bCodes.find(o => o.sort === sort);
            if (bcode) {
                row.add(new ImportField('bCodeId').setValue(bcode.id));
                row.add(new ImportField('bCodeSort').setValue(bcode.sort)); // so i can filter out suspense...
            } else if (!importMap.get(sort)) {
                const newRow = new ImportRow()
                    .add(new ImportField('name').setValue(name))
                    .add(new ImportField('sort').setValue(sort))
                    .add(new ImportField('typeId').setValue(BCode.TYPE.EXPENSE.id))
                    .add(new ImportField('type').setValue(BCode.TYPE.EXPENSE.name))
                    ;
                const nameCheck = name.toLowerCase().replace(/\W/g, '');
                const existing = this.bCodes.find(o => o.name.toLowerCase().replace(/\W/g, '') === nameCheck)
                if (existing !== undefined) {
                    newRow.addError(`Budget code [${name}] exists,
                        but import uses sort [${sort}]
                        which must match existing sort [${existing.sort}]`);
                }

                importMap.set(sort, newRow);
                this.newBCodes.push(
                    importMap.get(sort)
                );
            }
        }
        if (this.newBCodes.length > 0) {
            this.importDoc.setRows(this.newBCodes);
            this.importDoc.addInput(
                new ImportInput(
                    `File Contains ${this.newBCodes.length}. unknown budget codes`, null,
                    'These need to be imported before you can continue'
                )
            )
            return false;
        } else {
            this.importDoc.addInput(new ImportInput(`All Budget Codes are valid`, null, ''));
            return true;
        }
    }

    checkDatesOk(): boolean {
        let datesOk = true;
        for (const row of this.importDoc.getRows()) {
            if (row.hasField('date')) {
                const txnDate = row.getValue('date');
                const period = Period.getPeriod(txnDate, this.periods);
                if (period) {
                    row.add(new ImportField('txnPeriodId').setValue(period.id))
                    row.add(new ImportField('txnCycleId').setValue(period.cycleId))
                } else {
                    datesOk = false
                    row.addError('Transaction date invalid');
                }
            }
        }
        if (!datesOk) {
            this.importDoc.finalise();
        }
        return datesOk;
    }

    checkSupplierTemplateExists() {
        const importMap: Map<string, ImportRow> = new Map();

        let supplierId = -1;
        let supplierName = 'unknown';

        for (const row of this.importDoc.getRows()) {
            if (row.hasField('supplierId')) {
                supplierId = row.getNrValue('supplierId');
                supplierName = row.getStringValue('creditor_name');
            }

            const bCodeId = row.getValue('bCodeId');
            const key = 'template' + supplierId + '-' + bCodeId;

            const supplierTemplate = this.supplierTemplates.find(
                o => o.supplierId === supplierId && o.bCodeId === bCodeId
            );

            if (supplierTemplate === undefined && !importMap.get(key)) {
                const newRow = new ImportRow()
                    .add(new ImportField('supplierId').setValue(supplierId))
                    .add(new ImportField('Supplier Name').setValue(supplierName))
                    .add(new ImportField('bCodeId').setValue(bCodeId))
                    .add(new ImportField('Budget Code').setValue(row.getValue('account_name')))
                    ;
                if (supplierId < 0) {
                    newRow.addError('Unknown supplier, cannot link');
                }

                importMap.set(key, newRow);
                if (row.getValue('bCodeSort') !== 5999) { // do not link to suspense...
                    this.newSupplierTemplates.push(
                        importMap.get(key)
                    );
                }
            }
        }
        if (this.newSupplierTemplates.length > 0) {
            this.importDoc.setRows(this.newSupplierTemplates);
            this.importDoc.addInput(
                new ImportInput(
                    `File Contains ${this.newSupplierTemplates.length}. unknown supplier budget codes links`, null,
                    'These need to be imported before you can continue'
                )
            )
            return false;
        } else {
            this.importDoc.addInput(new ImportInput(`All Suppliers are linked to Budget Codes`, null, ''));
            return true;
        }
    }

    addDetail(parentTxn: Txn, row: ImportRow) {
        const detailTxn = {
            id: null as number,
            ledgerId: Txn.LEDGER.AP.id,
            typeId: parentTxn.typeId === Txn.TYPE.PURCHASE.id ? Txn.TYPE.PURCHASE_ITEM.id : Txn.TYPE.PURCHASE_CN_ITEM.id,
            txnDate: parentTxn.txnDate,
            txnPeriodId: parentTxn.txnPeriodId,
            txnCycleId: parentTxn.txnCycleId,
            bCodeId: row.getNrValue('bCodeId'),
            bCode: new BCode({ name: row.getStringValue('account_name').substring(5) }),
            debit: parentTxn.typeId === Txn.TYPE.PURCHASE.id ? row.getNrValue('amount') : 0,
            credit: parentTxn.typeId === Txn.TYPE.PURCHASE.id ? 0 : 0 - row.getNrValue('amount'),
            notes: row.getStringValue('description'),
        }
        parentTxn.details.push(detailTxn);
    }

    checkPurchaseInvoicesExists() {
        //const importMap: Map<string, ImportRow> = new Map();

        let parentTxn: Txn;

        for (const row of this.importDoc.getRows()) {
            if (row.hasField('supplierId')) {
                const isInvoice = row.getValue('type') === 'Invoice';
                parentTxn = {
                    id: null,
                    ledgerId: Txn.LEDGER.AP.id,
                    typeId: isInvoice ? Txn.TYPE.PURCHASE.id : Txn.TYPE.PURCHASE_CN.id,
                    bCodeId: this.currentUserSvc.getDefaultBCode('Creditors').bCodeId,
                    supplierId: row.getNrValue('supplierId'),
                    reference: row.getStringValue('reference'),
                    credit: isInvoice ? row.getNrValue('total') : 0,
                    debit: isInvoice ? 0 : 0 - row.getNrValue('total'),
                    outstanding: row.getNrValue('total'),
                    notes: row.getStringValue('description'),
                    txnDate: row.getStringValue('date'),
                    txnPeriodId: row.getNrValue('txnPeriodId'),
                    txnCycleId: row.getNrValue('txnCycleId'),
                    details: []
                }
                this.newPurchaseTxn.push(parentTxn);
            }
            this.addDetail(parentTxn, row);
        }
        if (this.newPurchaseTxn.length > 0) {
            this.objectArrayToImportRows(this.newPurchaseTxn);
            this.setDetailDescription();
            this.importDoc.addInput(
                new ImportInput(
                    `File Contains ${this.newPurchaseTxn.length}. purchase`, null,
                    'Press Upload to send to server'
                )
            )
            return false;
        } else {
            this.importDoc.addInput(new ImportInput(`All Purchase Information already loaded`, null, ''));
            return true;
        }
    }

    setDetailDescription() {
        for (const row of this.importDoc.getRows()) {
            let desc = '';
            for (const d of (row.getValue('details') as Txn[])) {
                if (desc.length > 0) { desc += ', '; }
                desc += d.bCode?.name + ' (' + formatCurrency(d.debit, 'EN-ie', '€') + ')';
            }
            row.setValue('details', desc);
        }
    }

    objectArrayToImportRows(objects: unknown[]) {
        const rows: ImportRow[] = [];
        for (const obj of objects) {
            const row = new ImportRow();
            Object.getOwnPropertyNames(obj).forEach((propName) => {
                row.add(new ImportField(propName).setValue(obj[propName]));
            })
            rows.push(row);
        }
        this.importDoc.setRows(rows);
    }

    postSuppliers() {
        const suppliers = this.importDoc.getRowsAsData();
        this.prefSupplierSvc.postItems(suppliers).subscribe(
            () => {
                this.prefSupplierSvc.get(false).subscribe(result => {
                    this.prefSuppliers = result as PreferredSupplier[];
                    this.importDoc.setRows(this.originalRows);
                    this.getData(this.importDoc);
                });
            }, failure => {
                console.log('Failed', failure);
                alert('Import Suppliers Failed');
            }
        );
    }

    postBCodes() {
        const bcodes = this.importDoc.getRowsAsData();
        this.bCodeSvc.postItems(bcodes).subscribe(
            () => {
                this.bCodeSvc.get(false).subscribe(result => {
                    this.bCodes = result as BCode[];
                    this.importDoc.setRows(this.originalRows);
                    this.getData(this.importDoc);
                });
            }, failure => {
                console.log('Failed', failure);
                alert('Import Budget Codes Failed');
            }
        );
    }

    postSupplierTemplates() {
        const templates = this.importDoc.getRowsAsData();
        this.supplierTemplateSvc.postItems(templates).subscribe(
            () => {
                this.supplierTemplateSvc.get(false).subscribe(result => {
                    this.supplierTemplates = result as SupplierTemplate[];
                    this.importDoc.setRows(this.originalRows);
                    this.getData(this.importDoc);
                });
            }, failure => {
                console.log('Failed', failure);
                alert('Importing Supplier Templates Failed');
            }
        );
    }

    postData(): void {
        if (this.newSuppliers.length > 0) {
            this.postSuppliers();
        } else if (this.newBCodes.length > 0) {
            this.postBCodes();
        } else if (this.newSupplierTemplates.length > 0) {
            this.postSupplierTemplates();
        } else {
            const txns = this.newPurchaseTxn.filter(o => o.typeId === Txn.TYPE.PURCHASE.id);
            this.purSvc.postItems(txns).pipe(first()).subscribe(
                success => {
                    console.log(success)
                    this.msgSvc.show('All data successfully imported');
                    this.importDoc.setRows([]);
                    this.importDoc.addInput(new ImportInput('Import completed successfully', null, ''));
                },
                failure => {
                    console.log(failure);
                    alert('Import Failed');
                }
            )
        }
    }
}
