
/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import moment from 'moment';

export class DateHelper {
    static readonly FMT = 'YYYY-MM-DD';

    static unixDatePlusDays(dt, days) : Date { 
        if (dt && Math.abs(dt) > (24*60*60*1000)) { // Null or 1/1/1970 probably a mistake
            const newDt = new Date()
            newDt.setTime((dt * 1000) + (days * 24 * 60 * 60 * 1000));
            return newDt;
        } else {
            console.warn('datePlusDays called with invalid date', {dt, days});
            return new Date();
        }
    }

    static longDate(dateString: string = null): string {
        const dt = dateString ? new Date(dateString + 'T12:00:00') : new Date();
        return dt.toLocaleDateString('en-IE', { weekday: "long", month: "long", day: "numeric" });
    }
    static localDate(dateString: string): string {
        if (dateString) {
            const dt = new Date(dateString + 'T12:00:00');
            return dt.toLocaleDateString('en-IE', { month: "short", day: "numeric", year: 'numeric' });
        } else {
            return '';
        }
    }
    static localDateTime(value) {
        const dt = new Date(value * 1000);
        return dt.toLocaleDateString('en-IE', 
            { month: "short", day: "numeric", year: 'numeric', hour: '2-digit', minute: '2-digit' }
        );
    }
    
    static isoFromDDMMYY(ddMMyy: string) {
        const day = ddMMyy.substring(0,2);
        const month = ddMMyy.substring(3,5);
        const year = ddMMyy.substring(6);
        return `${year}-${month}-${day}`
    }

    static dateFromISO(yyyy_mm_hh: string): Date {
        return new Date(yyyy_mm_hh);
    }

    static isWeekend(yyyy_mm_hh: string): boolean {
        const dow = this.dateFromISO(yyyy_mm_hh).getDay();
        return (dow === 0 || dow === 6) ? true : false;
    }

    static today() {
        return DateHelper.isoDate();
    }

    static isoDate(dt: Date = new Date()) {
        return dt.toISOString().slice(0, 10);
    }

    /**
     * Test if a date is between two other dates, assuming all dates in ISO string format
     * @param theDate - The date to be tested
     * @param startDate - Start Date inclusive
     * @param endDate - End Date inclusive
     * @returns
     */

    static isoDateIn(theDate: string | Date, startDate: string, endDate: string) {
        let momDate: moment.Moment;
        if (typeof theDate === 'string' || theDate instanceof String) {
            momDate = moment(theDate, DateHelper.FMT);
        } else {
            momDate = moment(theDate);
        }

        if (momDate.isBetween(
            moment(startDate, DateHelper.FMT),
            moment(endDate, DateHelper.FMT), 'days', '[]')) {
            return true;
        } else {
            return false;
        }
    }
    static isoIncludes(theDate: string, startDate: string, endDate: string) {
        if (moment(theDate, DateHelper.FMT).isBetween(
            moment(startDate, DateHelper.FMT),
            moment(endDate, DateHelper.FMT), 'days', '[]')) {
            return true;
        } else {
            return false;
        }
    }

    static isoDaysSince(theDate: string): number {
        return moment().diff(moment(theDate, DateHelper.FMT), 'days');
        //return 0;
    }
    static isoDaysTo(theDate: string): number {
        return moment(theDate, DateHelper.FMT).diff(moment(), 'days');
        //return 0;
    }
    static isoDaysBetween(dateFrom: string, dateTo: string): number {
        return moment(dateTo, DateHelper.FMT).diff(moment(dateFrom, DateHelper.FMT), 'days');
    }

    static addDays(dateFrom: string, add: number): string { // little testing...
        //const out = DateHelper.dateFromISO(dateFrom);
        //out.setDate(out.getDate() + add);
        //const outISO = DateHelper.isoDate(out);

        const newDate = moment(dateFrom, 'YYYY-MM-DD');
        const offsetDate = newDate.add(add, 'days');
        return offsetDate.format('YYYY-MM-DD');
    }

    static adjustDate(from: string, days: number) {
        const start = DateHelper.dateFromISO(from);
        const end = new Date();
        end.setTime(start.getTime() + (days * 24 * 60 * 60));
        return DateHelper.isoDate(end);
    }

    static beforeToday(value: string) {
        if (moment(value, 'YYYY-MM-DD').isBefore(new Date().setDate(new Date().getDate() - 1))) {
            return true;
        } else {
            return false;
        }
    }

    static dayAfter(dateFrom: string): string {
        return DateHelper.addDays(dateFrom, 1);
    }

}
