/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { UpFile } from "../file-upload/UpFile";

@Component({
    selector: 'app-upload-progress',
    templateUrl: './upload-progress.component.html',
    styleUrls: ['./upload-progress.component.scss'],
    standalone: true
})
export class UploadProgressComponent {
    //@Input() progress = 0;
    //@Input() failed = false;
    @Input() upFile: UpFile;

}
