<app-form-page [config]="config" [dialogRef]="dialogRef">
    <div class="main">
        <div style="min-width: 350px;">
            <ng-container [ngTemplateOutlet]="paramTemplate"></ng-container>
        </div>
        <div style="padding-left: 20px; flex: 1">
            <ng-container [ngTemplateOutlet]="contentTemplate"></ng-container>
        </div>
    </div>
</app-form-page>

<ng-template #paramTemplate>
    <div>
        <div class="param">
            <app-ctl-holder [control]="targetField.control"></app-ctl-holder>
        </div>

        <div class="params">
            <div class="param"><app-ctl-holder [control]="areaField.control"></app-ctl-holder></div>
            <div class="param"><app-ctl-holder [control]="coreField.control"></app-ctl-holder></div>
        </div>
        <div class="params">
            <div class="param" style="display:flex">
                <app-ctl-holder [control]="minArrearsField.control"></app-ctl-holder>
                <div>
                    <div style="display:flex">
                        <app-ctl-holder [control]="filterArrearsField.control" on="grid"></app-ctl-holder> Filter
                        Arrears
                    </div>
                    <div style="display:flex">
                        <app-ctl-holder [control]="includeStatementField.control" on="grid"></app-ctl-holder> Include
                        Statement
                    </div>

                </div>

            </div>
        </div>
        <div class="params">
            <div class="param" style="flex:1">
                @if(destinationField.control) {
                    <mat-radio-group aria-label="Select an option" style="font-size: small; display:flex; flex-direction: column;"
                        color="primary" [formControl]="destinationField.control">
                        <mat-radio-button value="Email" [matTooltip]="ownerTip">Send Emails</mat-radio-button>
                        <mat-radio-button value="NoEmailPDF" [disabled]="true" [matTooltip]="noEmailToMeTip">PDF for no email</mat-radio-button>
                        <mat-radio-button value="PDF" [matTooltip]="userTip">All items in PDF</mat-radio-button>
                    </mat-radio-group>
                }
            </div>
            <ng-container [ngTemplateOutlet]="statusTemplate"></ng-container>
        </div>
        @if(mailMerge.id) {

            <ng-container [ngTemplateOutlet]="attachmentsTemplate"></ng-container>
            <ng-container [ngTemplateOutlet]="linksTemplate"></ng-container>
        }
        @if(mailMerge.status === 'New' && this.page?.form?.formGroup?.pristine && this.mailMerge.todo > this.mailMerge.done) {
            <div class="param" style="text-align: center; margin-top:10px;">
                <button mat-raised-button color="primary" (click)="submitMerge()">
                    @if (this.mailMerge.destination === 'PDF') {
                        Generate PDF
                    } @else {
                        Send Mails
                    }
                </button>
            </div>
        } @else {
            @if(mailMerge.status !== 'New' && mailMerge.status) {
                <app-ctl-holder [control]="attachmentGrid.control"></app-ctl-holder>
            }
        }
    </div>
</ng-template>

<ng-template #attachmentsTemplate>
    @if(mailMerge.status === 'New') {
        <div class="attachments">
            <app-ctl-holder [control]="mailAttachmentGrid.control"></app-ctl-holder>
        </div>
        
    }
</ng-template>

<ng-template #linksTemplate>
    <p class="requestLink">
        @if (this.mailMerge.mergeRequestId) {
            <button mat-button color="primary" (click)="navigateRequest()"
            matTooltip="Responses will be linked to this request, click to navigate to the request">{{this.mailMerge.request?.refNr}}:{{this.mailMerge.request?.title}}</button>
            <mat-icon color="warn" style="font-size: 20px; height: 20px; margin-bottom: -5px" 
            matTooltip="Stop linking responses to this request" (click)="unlinkRequest()">delete</mat-icon>
        } @else {
            You can link responses to<button mat-button color="primary" 
            matTooltip="Choose a request that any responses should be linked to"
            (click)="chooseRequest()">existing</button>or<button 
            mat-button color="primary" matTooltip="Create a new request reponses should be linked to" 
            (click)="createRequest()">new</button>request
        }
    </p>
</ng-template>
<ng-template #contentTemplate>
    <div class="param">
        <app-ctl-holder [control]="nameField.control"></app-ctl-holder>
    </div>

    <app-ctl-holder [control]="contentField.control"></app-ctl-holder>
</ng-template>

<ng-template #statusTemplate>
    <div class="param" style="display:flex; flex:1; border: 1px dotted lightgrey; padding: 10px">
        <div>
            <p>Status: <span [class]="mailMerge.status">{{mailMerge.status}}</span></p>
            <p class="number">
                Todo:
                @if (mailMerge.todo) {
                    {{mailMerge.todo}}+
                } @else if (mailMerge.status) {
                    <span style="color:red" matTooltip="There are no matching records">Nothing</span>
                }
            </p>
            <p class="number">
                Done:
                @if (mailMerge.done) {
                    {{mailMerge.done}}
                }
            </p>
        </div>
        <div>
            <p class="number">
                Units:
                @if (mailMerge.unitEmails) {
                    <a [routerLink]="getUnitsLink()">{{mailMerge.unitEmails}}</a>
                }
            </p>
            <p class="number">
                Emails:
                @if (mailMerge.emails) {
                    <a [routerLink]="getPeopleLink()">{{mailMerge.emails}}</a>
                }
            </p>
            <p class="number">
                In PDF:
                @if(mailMerge.unitPDFs) {
                    {{mailMerge.unitPDFs}}
                }
            </p>
        </div>
    </div>

</ng-template>
