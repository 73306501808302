/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { Field } from "src/app/shared/field/Field";
import { PicklistOptions } from "src/app/shared/field/PicklistField";
import { FormPicklistComponent } from "src/app/shared/form/form-picklist/form-picklist.component";
import { required } from "src/app/shared/validators";
import { ImportDoc, ImportDocNote } from "../ImportDoc";
import { ImportInput } from "../ImportInput";
import { ImportRow } from "../ImportRow";
import { ParseOptionBase, ParseValidator } from "./ParseOptionBase";
import { FormButtonComponent } from "src/app/shared/form/form-button/form-button.component";
import { ComponentType } from "@angular/cdk/overlay";
import { MyInjector } from "src/app/app.module";
import { MatDialog } from "@angular/material/dialog";


export class ParseOptionBaseMapped extends ParseOptionBase {

    private importDoc: ImportDoc;
    private options: AbstractObject[];
    private creatorComponent: ComponentType<unknown>;
    optionMap: Map<string, AbstractObject> = new Map();
    optionNotes: ImportDocNote[] = [];
    optionInputs: ImportInput[] = [];
    private _silent = false;

    allowNulls = false;

    constructor(public readonly name: string, public readonly fieldName, parseValidators: ParseValidator[] = []) {
        super(name, fieldName, parseValidators, () => {
            this.optionMap = new Map();
            if (this.optionInputs.length > 0 || this.optionNotes.length > 0) {
                this.optionNotes.forEach(note => this.importDoc.deleteNote(note));
                this.optionNotes = [];
                this.optionInputs.forEach(input => this.importDoc.removeInput(input));
                this.optionInputs = [];
            }
        });
        this.addValidator(this.optionValidator);
    }
    allowNull() {
        this.allowNulls = true;
        return this;
    }
    makeSilent() {
        this._silent = true;
        return this;
    }

    getRowValue(row: ImportRow) {
        let rowValue = 'default_OPTION';
        if (this.mappedToColumn !== 'default_OPTION') {
            rowValue = row.getStringValue(this.mappedToColumn);
        }

        if (this.optionMap.has(rowValue)) {
            return this.optionMap.get(rowValue).id;
        } else {
            console.error('Could not find value ', { rowValue, pobm: this, row });
            return null
        }
    }

    setItemDefaultValue(row: ImportRow, item: AbstractObject) {
        const defaultMapping = this.optionMap.get('default_OPTION');
        if (defaultMapping) {
            item[this.fieldName] = defaultMapping.id;
        } else {
            console.error('Do not know how to map default value', { pobm: this, row, item });
        }
    }

    setCreator(componentType: ComponentType<unknown>) {        
        this.creatorComponent = componentType;
    }

    getOption<t extends AbstractObject>(id: uuid) : t {
        return this.options.find( o => o.id === id) as t;
    }

    setOptions(options: AbstractObject[], importDoc: ImportDoc) {
        this.options = options;
        this.importDoc = importDoc;
    }

    createOptionMapperInput(optionValue: string) {
        const newInput = new ImportInput(this.name + '.' + optionValue);
        newInput.setHelp($localize`Which '${this.name}' do you wish to import the value '${optionValue}' as`);

        const plRefresher = (o: AbstractObject) => {
            this.optionMap.set(optionValue, o);
            this.noteMapping(optionValue, o, newInput);
        }
        const plOptions = { items: this.options, refreshes: [plRefresher] } as Partial<PicklistOptions>;
        if (this.allowNulls) {
            plOptions.allowSelectNone = true;
        }
        const fldOptions = {
            validators: [required], valueChanges: (newValue, fld) => {
                this.importDoc.removeInputForField(fld);
            }
        } as Partial<Field>

        const label = $localize`'${this.name}' to import '${optionValue}' as`;
        const field = FormPicklistComponent.make(label, 'value', null, plOptions, fldOptions);

        newInput.addField(field);
        this.createOptionAdderInput(optionValue, plRefresher, newInput);

        this.importDoc.addInput(newInput);
        this.optionInputs.push(newInput);

    }

    creatorFn(optionValue: string) {
        const dialog = MyInjector.instance.get(MatDialog);
        const dialogRef = dialog.open(this.creatorComponent,
            {
                data:
                {
                    name: optionValue,
                    hideTabs: true,
                    mode: 'new'
                }
            }
        );
        return dialogRef.afterClosed();        
    }

    createOptionAdderInput(optionValue: string, plRefresher, input: ImportInput) {
        if (this.creatorComponent) {
            const btn = FormButtonComponent.makeTextButton('Create ' + optionValue, 'optionValue', ( () => {
                    this.creatorFn(optionValue).subscribe( newItem => {
                        if (newItem) {
                            //this.options.push(newItem); refresher adds the new one it seems...
                            plRefresher(newItem);
                            this.importDoc.removeInput(input);
                        }
                    });
                }).bind(this)
            );
            btn.makeControl().setValue('Or Create New');
            input.addField(btn);
        }
    }

    optionValidator: ParseValidator = {
        validateRow: (cell) => {
            const cellValue = cell.getValue() as string;
            if (!this.optionMap.has(cellValue)) {
                if (!this.options) {
                    console.warn('Options have not been set, call setOptions on the POBMapped with available options', this);
                }
                const option = this.options.find(o => o.name.toUpperCase() === cellValue.toUpperCase());
                this.optionMap.set(cellValue, option);
                if (option === undefined) {
                    this.createOptionMapperInput(cellValue);
                } else {
                    if (!this._silent) {
                        this.noteMapping(cellValue, option);
                    }
                }
            }
            return null;
        }
    }

    private noteMapping(optionValue: string, option: AbstractObject, input: ImportInput = null) {
        const newNote = new ImportDocNote();
        newNote.note = $localize`${this.name} value [${optionValue}] matches to [${option.name}]'`;

        if (input) {
            const deleteHandler = () => {
                input.getFields()[0].control.setValue(null, { emitEvent: false });
                this.importDoc.addInputAtTop(input);
                this.optionMap.delete(option.name);
                const isThere = this.optionInputs.indexOf(input);
                if (isThere < 0) {
                    console.log('Where did it go!')
                    this.optionInputs.push(input);
                }
            }
            newNote.deleteHandler = deleteHandler;
        }
        this.importDoc.addNoteItem(newNote);
        this.optionNotes.push(newNote);
    }
}
