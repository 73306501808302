/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { Cycle } from './cycle';

export class Period extends AbstractObject {
    cycleId?: uuid;
    cycle?: Cycle;
    statusId?: number = Period.STATUS.OPEN.id;
    status?: AbstractObject = Period.STATUS.OPEN;
    from = '';
    to = '';

    constructor(o: Partial<Period> = {}) {
        super(o);
    }

    static readonly STATUS = {
        OPEN: { 'id': 1, 'name': 'Open' },
        CLOSED: { 'id': 5, 'name': 'Closed' },
        LOCKED: { 'id': 9, 'name': 'Locked' },
    }

    static readonly STATUSES = [Period.STATUS.OPEN, Period.STATUS.CLOSED, Period.STATUS.LOCKED];

    static sorter(a: Period, b: Period) {
        if (a.from < b.from) {
            return -1;
        } else if (a.from > b.from) {
            return 1;
        } else {
            return 0;
        }
    }

    static open(p: Period) {
        if (p.statusId === Period.STATUS.OPEN.id) {
            return true;
        } else {
            return false;
        }
    }

    static getPeriod(txnDate, periods): Period {
        const p = periods.find(p => p.from <= txnDate && p.to >= txnDate);
        if (p === null || p === undefined) {
            console.log(`Cannot find period for date [${txnDate}]`);
            return null;
        } else if (p.statusId !== Period.STATUS.OPEN.id) {
            console.log(`Period [${p.name}] for transaction date [${txnDate}] not open for transactions`);
            return null;
        } else {
            return p;
        }
    }
}
