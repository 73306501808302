/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AbstractHttpService } from '../../abstract-http.service';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { uuid } from 'src/app/model/abstract-object';
import { MatTooltipModule } from '@angular/material/tooltip';

export class editItem {
    service: AbstractHttpService;
    id: uuid;
    revision: number;
}
@Component({
  selector: 'app-text-edit',
  standalone: true,
  imports: [MatIconModule, MatButtonModule, MatTooltipModule],
  templateUrl: './text-edit.component.html',
  styleUrl: '../simple-fields.scss'
})
export class TextEditComponent implements OnChanges{

    @Input() label = '';
    @Input() textValue = '';
    @Input() isHtml = false;
    @Input() editTip = $localize `Edit Item`
    @Input() deleteTip = $localize `Delete Item`;
    @Input() highlight = false;
    @Output() editor = new EventEmitter<unknown>();
    @Output() deleter = new EventEmitter<null>;

    textValueToShow = '';

    ngOnChanges(simpleChanges: SimpleChanges) {
        if (simpleChanges.textValue) {
            this.textValueToShow = this.doNl2br(simpleChanges.textValue.currentValue);
        }
    }

    doNl2br(newValue: string) {
        if (newValue) {
            return this.textValue.replace(/\n/g, '<br/>');
        }
        return '';
    }
}
