/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { BankAccount } from 'src/app/model/bankAccount';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { BankOption, GoCardlessOpenBankingService } from '../gocardless-open-banking-service';

@Component({
  selector: 'app-link-bank-account',
  standalone: true,
  imports: [MatDialogModule, MatCardModule, MatButtonModule, MatIconModule],
  templateUrl: './link-bank-account.component.html',
  styleUrl: './link-bank-account.component.scss'
})
export class LinkBankAccountComponent {

    bankAccount: BankAccount;

    options: BankOption[] = [];

    constructor(public dialogRef: MatDialogRef<LinkBankAccountComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {bankAccount: BankAccount},
        private cds: ConfirmDialogService, private gocob: GoCardlessOpenBankingService,) {

        this.bankAccount = data.bankAccount;
        gocob.getBanks().subscribe( opts => this.options = opts);
    }

    linkAccount(bo: BankOption) {
        const title = $localize `Link To Bank`
        const msg = $localize `Connect to ${bo.name} to grant access to bank accounts now?`
        const doIt = () => {
            this.gocob.requestAccess(bo).subscribe( goca => {
                window.location.href = goca.gocLink;
            }, failure => console.warn('Failed With', failure));
            this.dialogRef.close();
        }
        this.cds.open(title, msg, doIt);
    }
}
