/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { BCodeSchedule } from './bcode-schedule';
import { BudgetSchedule } from './budget-schedule';

export type DefaultBCodeType = 'OverPaidCharge'|'Debtors'|'Creditors'|'Accruals'|'PrePayments'|'InvoiceDiscounts'
    |'TxnDisputeIncome'|'TxnDisputeExpense'|'DefaultExpense'|'IncomeSuspense' | 'RetainedProfit';
export class DefaultBCode extends AbstractObject {
    bCodeId: uuid;
    bCodeName: string;
    bCodeSort: number;
    type: DefaultBCodeType;
}
export class BCodeAnalysis extends AbstractObject { // Doesn't really extend, no id etc....
    bCodeId: uuid;
    periodName: string;
    txnPeriodId: uuid;
    debits: number;
    credits: number;
    balance: number;
    openBalance: number;
}
export class BCode extends AbstractObject {
    description: string;
    sort = 100000;
    schedule: BCodeSchedule[] = [];
    budget?: number; // not stored
    prevBudget?: number; // not stored
    system?: boolean = false;
    prepaid?: boolean = false;
    typeId: uuid = BCode.TYPE.EXPENSE.id;
    type: AbstractObject;
    budgets: BudgetSchedule[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    budgetSummary: any[] = [];
    txnAnalysis?: BCodeAnalysis[] = [];

    // Comes from balances not stored
    debits?: number;
    credits?: number;
    count?: number;
    prevCount?: number;
    total?: number;
    prevTotal?: number;


    constructor(o: Partial<BCode> = {}) {
        super(o);
    }

    /*
    * Remember types are duplicated in server/app/Models/
    */

    static readonly TYPE = {
        ASSET: { 'id': 1, 'name': 'Current Assets' },
        LIABILITY: { 'id': 2, 'name': 'Current Liabilities' },
        CAPITAL: { 'id': 3, 'name': 'Capital' },
        INCOME: { 'id': 4, 'name': 'Income' },
        EXPENSE: { 'id': 5, 'name': 'Expense' },
    }

    static readonly TYPES = [
        BCode.TYPE.ASSET,
        BCode.TYPE.LIABILITY,
        BCode.TYPE.CAPITAL,
        BCode.TYPE.INCOME,
        BCode.TYPE.EXPENSE
    ];

    static readonly BALANCE_SHEET_TYPES = [
        BCode.TYPE.ASSET,
        BCode.TYPE.LIABILITY,
        BCode.TYPE.CAPITAL,
    ];
    static readonly INCOME_EXPENDITURE_TYPES = [
        BCode.TYPE.INCOME,
        BCode.TYPE.EXPENSE,
    ];
}
