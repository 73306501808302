/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/

import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { MoaChart } from "./MoaChart";

export class MoaOmcChart extends MoaChart {
    title = "My OMC Actions";
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/moaOmc';

    getRouteParamsDone(i: { plannedDate: string }) {
        return { 'omc.managerId': this.currentUser.id, plannedDate: i.plannedDate, done: 1, _sort: 'plannedDate' };
    }

    getRouteParamsTodo(i: { plannedDate: string }) {
        return { 'omc.managerId': this.currentUser.id, plannedDate: i.plannedDate, done: 0, _sort: 'plannedDate' }
    }
}
