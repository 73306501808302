/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { of } from "rxjs";
import { MyInjector } from "src/app/app.module";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { MatDialog } from "@angular/material/dialog";
import { InvoicingActionComponent } from "./invoicing-action-component";
import { CurrentUserService } from "src/app/modules/user/current-user.service";
import { User } from "src/app/model/user";
import { Billing } from "src/app/model/billing";
import { Cycle } from "src/app/model/cycle";

export class InvoicingAction implements IFormAction {
    name = $localize`Mail Charges`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'mail';
    approvalNeeded = false;
    disabled = false;
    approvalText = 'Mail Charges to owners';
    tipText: string;
    billing: Billing;

    dialog = MyInjector.instance.get(MatDialog);
    cus = MyInjector.instance.get(CurrentUserService);

    action() {
        this.dialog.open(InvoicingActionComponent, {data:this.billing});
        return of(this.billing)
    }

    setup(billing: Billing) {
        this.billing = billing;
        const priv = User.privilege(this.cus.currentUser, 'Billing')
        if (priv?.post && billing.statusId === Cycle.statusIds.BILLED) {
            this.show = true;
        } else {
            this.show = false;
        }
    }
}
