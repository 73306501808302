/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { AbstractObject } from "src/app/model/abstract-object";
import { FormConfig } from "src/app/shared/form/FormConfig";
import { ReconciliationItemsComponent } from "./reconciliation-items/reconciliation-items.component";
import { BankAccount, BankItem } from "src/app/model/bankAccount";
import { BankReconciliationComponent } from "./bank-reconciliation.component";

export type PanelType = 'invoices'|'people'|'purchases'|'units'|'monthlyUnits'|'payments'|'receipts'|'suppliers'
export class Panel {
    type: PanelType;
    private _bankAccount: BankAccount;
    private _items:AbstractObject[] = [];
    get items() { return this._items}
    set items(items: AbstractObject[]) {
        this._items = items;
        this.setTitle();
    }
    set bankAccount(ba : BankAccount) {
        this._bankAccount = ba;
    }
    get bankAccount() {
        return this._bankAccount;
    }
    _title: string;
    get title() { return this._title }
    setTitle() {
        this._title =  this._items.length + ' Items';
    }
    icon: string;
    config: FormConfig;
    item?: BankItem;
    recItemsComponent: ReconciliationItemsComponent;
    reconciliationComponent: BankReconciliationComponent;
    takeAction: (AbstractObject) => unknown;
    takeAction2: (AbstractObject) => unknown;
    setConfig: () => FormConfig;

    constructor(defaults: Partial<Panel>) {
        Object.assign(this, defaults);
        this.config = this.setConfig();
    }
}
