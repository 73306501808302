/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';

export class UnitCategory extends AbstractObject {
  name: string;
  typeId: uuid;
  constructor(o: Partial<UnitCategory> = {}) {
    super(o);
  }
}
