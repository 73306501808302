/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { Attachment } from 'src/app/model/attachment';

import { FormControlInterface } from 'src/app/shared/form/form-control-interface';
import { CdkDragDrop, CdkDropList } from '@angular/cdk/drag-drop';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentService } from 'src/app/shared/form/file/attachment.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { Omc } from 'src/app/model/Omc';
import { User } from 'src/app/model/user';
import { Router } from '@angular/router';
import { Txn } from 'src/app/model/txn';
import moment from 'moment';
import { AttachmentControl, AttachmentField, FileOpts } from 'src/app/shared/field/AttachmentField';
import { ControlOn } from 'src/app/shared/form/app-form-control';
import { OmcService } from 'src/app/modules/agent/omc.service';
import { ByteCountPipe } from '../../../pipes/byte-count.pipe';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { FileUploadComponent } from '../file-upload/file-upload.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-attachment-list',
    templateUrl: './attachment-list.component.html',
    styleUrls: ['./attachment-list.component.scss'],
    standalone: true,
    imports: [CdkDropList, MatFormFieldModule, MatInputModule, FormsModule, ReactiveFormsModule,
        MatIconModule, MatTooltipModule, MatButtonModule, FileUploadComponent, FromNowPipe, ByteCountPipe, NgClass]
})
export class AttachmentListComponent implements FormControlInterface {

    @Input() label: string;
    @Input() control: AttachmentControl;
    @Input() on: ControlOn = 'form';
    @Input() attachToId = 0;

    moment = moment;

    currentUser: User;
    omcs: Omc[] = [];

    constructor(protected cds: ConfirmDialogService,
        protected dialog: MatDialog, protected attachSvc: AttachmentService,
        private router: Router,
        omcSvc: OmcService,
        currentUserSvc: CurrentUserService) {

        currentUserSvc.getCurrentUser().subscribe(user => {
            this.currentUser = user;
            if (this.currentUser.inAgency) {
                omcSvc.get(true).subscribe(omcs => {
                    this.omcs = (omcs as Omc[]).filter(o => o.omcTeamId);
                });
            }
        });

    }

    downloadFile($event, a: Attachment) {
        $event.preventDefault();

        const svc = this.control.field.fileOpts.service; // as PurchaseService);
        Attachment.download(a, svc);
    }

    getAttachTo() {
        if (this.attachToId) {
            return this.attachToId;
        }
    }

    deleteAttachment(attachment: Attachment) {
        Attachment.deleteAttachment(attachment, this.cds, this.control.field);
    }

    invoiceNow(o: Attachment) {
        this.router.navigate([Txn.TYPE.PURCHASE.code + '/NEW', { attach: o.id }]);
    }

    editAttachment(attachment: Attachment) {
        Attachment.editAttachment(attachment, this.dialog, this.control.field, this.attachSvc, this.omcs);
    }

    public static make(label: string, value: string, fileOpts: FileOpts, options: Partial<AttachmentField> = {}) {
        const field: AttachmentField = new AttachmentField({ label, value }, options);

        field.fileOpts = fileOpts;
        field.fileOpts.deferredFiles = [];
        field.formControlFactory = AttachmentListComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AttachmentControl, on: ControlOn) {
        const componentRef = vcr.createComponent(AttachmentListComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    drop(event: CdkDragDrop<string[]>) {

        if (event.previousContainer === event.container) {
            console.warn('DROPPED ON SAME CONTAINER');
            //moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            /*
            transferArrayItem(event.previousContainer.data,
                              event.container.data,
                              event.previousIndex,
                              event.currentIndex); */

            const prevCtl = event.item.data.ctl;
            const prevArray = prevCtl.value;
            const prevLength = prevCtl.value.length;

            const item = event.item.data.attachment;
            const prevIndex = prevArray.indexOf(item)
            if (prevIndex > 0) {
                prevArray.splice(prevIndex, 1);
            } else {
                console.error('Could not remove attachment form list', { item, prevIndex, arry: prevArray })
            }

            this.control.value.push(item);

            // Fire the changeDetection
            this.control.setValue(this.control.value);
            prevCtl.setValue(prevArray);
            if (prevLength - 1 !== prevCtl.value.length) {
                console.error(`How did we take more than one out of list!!! We had ${prevLength} now there is ${prevCtl.value.length}`);
            }

        }

        console.warn({ f: this.control.field.name, event, v: this.control.value });
    }
}
