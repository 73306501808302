/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CurrentUserService } from '../../user/current-user.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatChipsModule } from '@angular/material/chips';
import { uuid } from 'src/app/model/abstract-object';
import { ForumRole } from 'src/app/model/forum';

class VisibleChannel {
    name: string;
    id: uuid;
    from: string;
    roles: string[];
}

@Component({
  selector: 'app-channel-picker',
  standalone: true,
  imports: [MatTooltipModule, MatChipsModule],
  templateUrl: './channel-picker.component.html',
  styleUrl: './channel-picker.component.scss'
})
export class ChannelPickerComponent implements OnChanges {

    cus = inject(CurrentUserService);
    @Input() currentChannel: uuid;
    @Input() contentType: string;
    @Output() channelSelected = new EventEmitter<uuid>()

    channels: VisibleChannel[] = []
    selectedChannel: VisibleChannel;

    constructor() {
        this.cus.getCurrentUser().subscribe( u => {
            u.forumRoles.forEach( fr => {
                const cv = this.getChannel(fr.forumName);
                if (cv) {
                    cv.from += this.describePrivilege(fr);
                    cv.roles.push(fr.roleName);

                } else {
                    this.channels.push({ name: fr.forumName, id: fr.forumId, roles: [fr.roleName], from: this.describePrivilege(fr)});
                }
            })
        });
    }

    getChannel(name: string) {
        return this.channels.find( c => c.name === name);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentChannel) {
            if (changes.currentChannel.currentValue) {
                this.selectedChannel = this.channels.find( fr => fr.id === changes.currentChannel.currentValue);
            } else {
                this.selectedChannel = null;
            }
        }
    }

    selectChannel(c) {
        if (c.id === this.selectedChannel?.id) {
            this.channelSelected.emit(null);
        } else {
            this.channelSelected.emit(c?.id);
        }
    }

    describePrivilege(fr: ForumRole) {
        const privs = [];
        if (fr.canView) privs.push('view')
        if (fr.canCreate) privs.push('create')
        if (fr.canEdit) privs.push('edit')
        if (fr.canComment) privs.push('comment')
        if (fr.canVote) privs.push('vote')
        if (fr.canVote) privs.push('receive notifications');
        const last = privs.pop();
        if (privs.length === 1) {
            return 'You can ' + last + ' because you have ' + fr.roleName + ' role. ';
        } else {
            return 'You can ' + privs.join(', ') + ' and ' + last + ' because you have ' + fr.roleName + ' role. ';
        }
    }
}
