/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject, Inject, Optional } from '@angular/core';
import { of } from 'rxjs';
import { Area } from 'src/app/model/area';
import { STD_ANIMATION } from 'src/app/shared/std-animation';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { required } from 'src/app/shared/validators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogOptions, PickDialogComponent } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { MailMergeService } from '../mail-merge.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { OmcService } from '../omc.service';
import { Core } from 'src/app/model/core';
import { AreaService } from '../../unit/area.service';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormRichTextComponent } from 'src/app/shared/form/form-rich-text/form-rich-text.component';
import { CommsTemplateType } from 'src/app/model/CommsTemplate';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { MailMerge } from 'src/app/model/mailMerge';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { NgTemplateOutlet } from '@angular/common';
import { MatRadioButton, MatRadioGroup } from '@angular/material/radio';
import { MatTooltip } from '@angular/material/tooltip';
import { ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { UnitPageComponent } from '../../unit/unit-page/unit-page.component';
import { PersonPageComponent } from 'src/app/pages/person-page/person-page.component';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { HttpClient} from '@angular/common/http';
import { MessageService } from 'src/app/shared/message.service';
import { MatButtonModule } from '@angular/material/button';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { Field } from 'src/app/shared/field/Field';
import { AttachmentGridComponent } from 'src/app/shared/form/file/attachment-grid/attachment-grid.component';
import { RequestService } from '../../crm/request.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { SimpleRequestComponent } from '../../crm/simple-request/simple-request.component';
import { MatIconModule } from '@angular/material/icon';
import { ServiceRequestPageComponent } from '../../crm/service-request-page/service-request-page.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';

@Component({
    selector: 'app-mail-merge-page',
    templateUrl: './mail-merge-page.component.html',
    styleUrls: ['./mail-merge-page.component.scss'],
    animations: [STD_ANIMATION],
    standalone: true,
    imports: [FormPageComponent, CtlHolderComponent, NgTemplateOutlet, MatRadioButton, MatIconModule,
        MatRadioGroup, MatTooltip, ReactiveFormsModule, RouterLink, MatButtonModule],
})

export class MailMergePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('mailMerge', MailMergePageComponent, 'merge');
    omcService = inject(OmcService);
    areaService = inject(AreaService);
    requestService = inject(RequestService);
    router = inject(Router);
    http = inject(HttpClient);
    cds = inject(ConfirmDialogService);
    msgSvc = inject(MessageService);
    dialog = inject(MatDialog);

    areas: Area[] = [];
    cores: Core[] = [];

    ownerTip = $localize`Emails will be sent directly to owners where their email is on file`;
    userTip = $localize`PDF will be generated for you to print. The PDF will contain letters to be sent and statement if requested, a PDF will be requested for every 50 units`;
    noEmailToMeTip = $localize`Only invoices for units with no email address will be put in a PDF and mailed to you in groups of up to 50 at a time`;

    nameField = FieldMaker.nameControl({ validators: [required] }).override({
        label: 'Unique Name (subject for the mail)',
        formColumn: 2,
    });

    mailAttachmentGrid = AttachmentGridComponent.make(this.dataSvc)
        .override({showGrid: false, showAllFields: false, allowSimpleAdd: true, showSimple: true} as Partial<Field>).setupControl();

    contentField = FormRichTextComponent.make('Content', 'content', {
        formColumn: 2, validators: [required], cellOpts: {width: '80%', style: 'minWidth: 50%'}
    }).setCommsTemplate(CommsTemplateType.PERSON);

    coreField = FormPicklistComponent.make('Core', 'params.coreId', null, { items: [], allowSelectNone: true }, {
        formRow: 2, formColumn: 2
    });

    areaField = FormPicklistComponent.make('Area', 'params.areaId', 'area', {
        refreshes: [(area: Area) => {
            const currentCoreId = this.coreField.control.value;
            if (currentCoreId && !area.cores.find(c => c.id === currentCoreId)) {
                this.coreField.control.setValue(null);
            }
            this.coreField.setPicklistItems(area.cores);
        }], items: [], allowSelectNone: true }, {
            formRow: 2
        }
    );

    targetField = FormPicklistComponent.make('Target', 'params.targetId', null, {
        items: [ {id: 'owners', name: 'Owners (Resident or Not)'}, {id: 'residents', name: 'Residents (owner or not)'} ],
        refreshes: [(o) => {
            if (o.id === 'owners') {
                this.includeStatementField.enableControl();
                this.filterArrearsField.enableControl();
            } else {
                this.includeStatementField.control.setValue(false);
                this.includeStatementField.disableControl();
                this.filterArrearsField.disableControl();
                this.filterArrearsField.control.setValue(false);
            }
        }]
    }, {validators: [required]})


    filterArrearsField = FormCheckboxComponent.make('Filter with arrears', 'params.filterArrears', {
        valueChanges: (val) => {
            console.log(val);
            if (val) {
                this.minArrearsField.enableControl();
            } else {
                this.minArrearsField.control.setValue(0);
                this.minArrearsField.disableControl();
            }
        }, formRow: 3
    });

    includeStatementField = FormCheckboxComponent.make('Include Statement?', 'params.includeStatement', { formRow: 3, formColumn: 2 })

    minArrearsField = FormNumberComponent.makeCurrency('Minimum Arrears', 'params.minArrears', {
        disable: true, formColumn: 3, formRow: 3
    });

    statusField = FormTextComponent.make('Status', 'status', {visible: Field.noForm});

    attachmentGrid = AttachmentGridComponent.make(this.dataSvc).setupControl();

    destinationField = FormTextComponent.make('Destination', 'destination', {visible: Field.noForm, readonly: true});
    //fg = new FormGroup({destination: this.destinationField.makeControl()});

    mailMerge: MailMerge = new MailMerge();

    config = new FormConfig({
        navRoute: MailMergePageComponent.navRoute,
        title: $localize`Mail Merges`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.nameField,
                this.statusField,
                this.contentField,
                this.targetField,
                this.includeStatementField,
                this.areaField,
                this.coreField,
                this.filterArrearsField,
                this.minArrearsField,
                this.destinationField,
                FieldMaker.createdAt(),
                FieldMaker.updatedAt()
            ],
            formLayout: []
            /*formLayout: [
                { cells: [{ colspan: 3 }, { width: '66%', rowspan: 5 }] },
                { cells: [{ width: '16%', colspan: 2 }, { width: '16%' }]},
                { cells: [{ width: '8%' }, { width: '8%' }, { width: '16%' }] },
                { cells: [{ colspan: 3 }] },
            ]*/,
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newMerge.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
    });

    constructor(public dataSvc: MailMergeService,
        @Optional() public dialogRef: MatDialogRef<MailMergePageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {
        super();

        this.areaService.get<Area>().subscribe( areas => {
            this.areaField.setPicklistItems(areas);
            this.coreField.setPicklistItems(this.areaService.getCores(areas));
        })
    }

    newMerge() {
        this.mailMerge = new MailMerge();
        this.mailMerge.params = {}
        this.mailMerge.params.targetId = 'owners';
        this.mailMerge.params.filterArrears = false;
        this.mailMerge.params.includeStatement = false;
        this.mailMerge.params.minArrears = 0;
        this.mailMerge.destination = 'PDF';
        this.config.fieldSet.fields.forEach(f => f.setReadonly(false));
        this.attachmentGrid.setValue(this.mailMerge, true);
        return of(this.mailMerge)
    }

    unlinkRequest() {
        const dtl = $localize `Stop linking responses to this mailing to request 
        ${this.mailMerge.request?.teamId}-${this.mailMerge.request?.refNr} : ${this.mailMerge.request?.title}`;
        this.cds.open('Unlink Request', dtl, () => {
            this.dataSvc.linkRequest(this.mailMerge, null).subscribe( (m) => {
                if (m) {
                    this.mailMerge.mergeRequestId = null;
                    this.mailMerge.request = null;
                }
            });            
        })
    }

    createRequest() {
        this.dialog.open(SimpleRequestComponent, {data: {}}).afterClosed().subscribe( sr => {
            if (sr) {
                if (this.mailMerge.id) {
                    this.linkRequest(sr);
                }
            }
        })        
    }

    chooseRequest() {
        const chooseRequestConfig = new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FormTextComponent.make('Reference', 'refNr'),
                    FormTextComponent.make('Title', 'title'),
                ],
                formLayout: LAYOUT_OPTIONS.singleCol,
            }),
            title: $localize`Suppliers`,
            mode: 'list',
        });

        const dialogRef = this.dialog.open(PickDialogComponent,
            {
                data: {
                    config: chooseRequestConfig,
                    service: this.requestService,
                    showSearch: true,
                }
            });
        dialogRef.afterClosed().subscribe( sr => {
            if (sr) {
                const msg = $localize `Link incoming responses to request ${sr.refNr} : ${sr.title}`;
                this.cds.open($localize `Link Request`, msg, () => {
                    console.log('linker up there');
                   this.linkRequest(sr);
                })
            }
        });      
    }

    linkRequest(sr: ServiceRequest) {
        this.dataSvc.linkRequest(this.mailMerge, sr).subscribe( (m) => {
            if (m) {
                this.mailMerge = this.beforeEdit(m);
                this.page.form.reloadContent(this.mailMerge);
            }
        }); 
    }

    navigateRequest() {
        if (this.mailMerge.mergeRequestId) {
            this.router.navigate([ServiceRequestPageComponent.navRoute.getIdUrl(this.mailMerge.mergeRequestId)]);
        }
    }

    beforeEdit(o: MailMerge) {
        if (!o) {
            o = new MailMerge();
        }
        this.mailMerge = o
        this.attachmentGrid.override({ showSimple: true, readonly: true, showGrid: false } as Partial<Field>);
        if (o.status === 'New') {
            this.mailAttachmentGrid.setValue(o, false);
        } else {
            this.attachmentGrid.setValue(o, true);
        }

        if (o.status === 'New' || !o.status ) {
            this.config.fieldSet.fields.forEach(f => f.setReadonly(false));
            this.destinationField.control.enable()
        } else {
            this.config.fieldSet.fields.forEach ( f => f.setReadonly(true));
            this.destinationField.control.disable()
        }
        if (o.params?.filterArrears) {
            this.minArrearsField.enableControl();
        } else {
            this.minArrearsField.disableControl();
        }
        return o;
    }

    getUnitsLink() {
        return UnitPageComponent.navRoute.getListRouterLink({ mergeId: this.mailMerge.id });
    }

    getPeopleLink() {
        return PersonPageComponent.navRoute.getListRouterLink({ mergeId: this.mailMerge.id });
    }

    submitMerge() {
        const url = AbstractHttpService.ajaxPath + `mailmerge/${this.mailMerge.id}/${this.mailMerge.revision}/submit`;
        this.http.put<MailMerge>(url, null).subscribe(x => {
            if (x) {
                this.mailMerge = x;
            }
        }, error => {
            this.msgSvc.showError(error, 'Submitting MailMerge Job');
        })
    }
}
