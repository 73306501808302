/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-logo',
    templateUrl: './logo.component.html',
    styleUrls: ['./logo.component.scss'],
    standalone: true,
    imports: [RouterLink, MatIconModule, MatTooltip]
})
export class LogoComponent {

    /** ['localhost', './assets/clg-logo-230x63.png'], */
    /**         ['localhost', './assets/ouromc-v3.png'], */
    defaultLogo = '/assets/ouromc-v4.png';
    //defaultLogo = 'https://clgassets.fra1.cdn.digitaloceanspaces.com/logos/agt-casey-kennedy.png';
    //defaultLogo = 'https://clgassets.fra1.cdn.digitaloceanspaces.com/logos/agt-mums.png';

    customLogos: Map<string, string> = new Map([
        ['my.greendoor.ie', './assets/greendoor-logo.jpg'],
        ['mypetra.ie', './assets/petra.png']
    ]);
    customLogo: string;

    @Input() background = false;

    constructor() {
        if (this.customLogos.has(window.location.hostname)) {
            this.customLogo = this.customLogos.get(window.location.hostname);
        } else {
            this.customLogo = this.defaultLogo;
        }
    }
}
