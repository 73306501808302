<h2 class="dialog-banner">Merge Person {{person?.fullName}}</h2>
<mat-dialog-content class="mat-typography" style="max-width: 400px; min-width: 400px">
    <div style="width:100%">
        <app-field-set [fieldSet]="fieldSet" [isPhone]="false" [initComplete]="ready"></app-field-set>
    </div>

    <p style="margin-top: -20px; font-size:small; line-height: 1em; max-width: 300px; padding-left: 10px">
        All data related to {{person.fullName}} will be linked to the person you select.
        Any data fields on the person you select that do not have a value, will be populated from {{person.fullName}}.
    </p>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button (click)="performMerge()" [disabled]="!fieldSet.formGroup?.valid" color="primary"
        cdkFocusInitial>Merge</button>
</mat-dialog-actions>
