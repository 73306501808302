/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

class EmailField extends Field {
    public getFormValue() {
        return this.control.value ? this.control.value.toLowerCase() : null;
    }
}

@Component({
    selector: 'app-form-email',
    templateUrl: './form-email.component.html',
    styleUrls: ['./form-email.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatIconModule, MatInputModule, FormsModule, ReactiveFormsModule, MatTooltipModule, FormErrorComponent, NgClass]
})
export class FormEmailComponent implements FormControlInterface {

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static make(label: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new EmailField({ label, value, type: 'email' }, options);
        field.formControlFactory = FormEmailComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormEmailComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }
}
