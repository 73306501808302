<!--
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
-->

<mat-dialog-content [style.width.px]="width">
    <!-- div class="dialogTitleBar primary-background-color ">
        <span>{{getTitle()}}</span>
    </div -->

    <h1>
        {{getTitle()}}
    </h1>

    <!-- class="mat-form-field-wrapper" -->
    <div style="margin-top: 20px">
        <editor [init]="editorOptions" data-id="new-note-rich-text-entry" [formControl]="noteControl"
            placeholder="control.field.label" tinyMatFormControl>
        </editor>
    </div>

    <button mat-button mat-raised-button color="primary" style="margin:10px" data-save-comment
        [disabled]="noteControl.pristine" (click)="saveNote()">
        <mat-icon>save</mat-icon>Save
    </button>
</mat-dialog-content>