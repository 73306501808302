/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";

@Component({
    selector: 'app-dialog-banner',
    template: `
        <div style="display: flex; width: 100%" class="primary-background-color">
            <div style="flex: 50; padding-top:10px; padding-left:10px">
                <h1 class="mat-h1" style="margin-bottom: 4px">{{text}}</h1>
            </div>
            @if (dialogRef) {
                <div style="flex: 1;">
                    <div style="padding-top: 10px; padding-right: 8px" (click)="closeDialog()" matTooltip="Click to close">
                        <mat-icon style="border-radius: 4px; background-color: lightgrey; color: black">
                            close
                        </mat-icon>
                    </div>
                </div>
            }
        </div>
    `,
    standalone: true,
    imports: [MatIconModule, MatTooltipModule],
})

export class DialogBannerComponent {
    @Input() text = "Dialog Heading";
    @Input() dialogRef : MatDialogRef<unknown>;

    closeDialog() {
        this.dialogRef.close(null);
    }
}
