/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Schedule } from 'src/app/model/schedule';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService extends AbstractHttpService {

  protected baseUrl = this.ajaxPath + 'budgets/schedules';
  protected cache: Schedule[];
  protected typeString = 'Schedule';

  constructor(protected http: HttpClient, protected messageService: MessageService) {
    super();
  }
}
