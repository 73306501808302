/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, ElementRef, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ControlOn } from '../../app-form-control';
import { FormControlInterface } from '../../form-control-interface';
import { GridControlParameters } from "src/app/shared/grid/grid-control-parameters";
import { Field } from 'src/app/shared/field/Field';
import { Attachment } from 'src/app/model/attachment';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { UpFile } from '../file-upload/UpFile';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { fromEvent } from 'rxjs';
import { User } from 'src/app/model/user';
import { AttachmentGridField } from './attachment-grid-field';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { MatDialog } from '@angular/material/dialog';
import { AttachmentService } from '../attachment.service';
import { ByteCountPipe } from '../../../pipes/byte-count.pipe';
import { UploadProgressComponent } from '../upload-progress/upload-progress.component';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { GridComponent } from '../../../grid/grid.component';
import { MatIconModule } from '@angular/material/icon';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from '../../form-text/form-text.component';
import { FormTextAreaComponent } from '../../form-text-area/form-text-area.component';
import { FormConfig } from '../../FormConfig';
import { FieldSet } from '../../field-set/field-set.component';
import { EditDialogComponent } from 'src/app/shared/dialogs/edit-dialog/edit-dialog.component';
import { map } from 'rxjs/operators';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-attachment-grid',
    templateUrl: './attachment-grid.component.html',
    styleUrls: ['./attachment-grid.component.scss'],
    standalone: true,
    imports: [GridComponent, MatTooltipModule, MatButtonModule, FromNowPipe, ByteCountPipe,
        UploadProgressComponent, ByteCountPipe, MatIconModule, NgTemplateOutlet]
})
export class AttachmentGridComponent implements OnInit, FormControlInterface {

    @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;
    @Input() label: string;
    @Input() control: GridControl;
    @Input() on: ControlOn = 'form';

    files: UpFile[] = [];
    attachmentGridField: AttachmentGridField;

    currentUser: User;

    constructor(private cds: ConfirmDialogService, currentUserSvc: CurrentUserService,
        private dialog: MatDialog, private attachmentEditService: AttachmentService) {

        currentUserSvc.getCurrentUser().subscribe(user => {
            this.currentUser = user;
        });
    }

    public static make(service: AbstractHttpService, options: Partial<AttachmentGridField> = {}) {
        //const field: AttachmentGridField = new AttachmentGridField ( { label, value, type: 'email' }, options );
        //field.formControlFactory = AttachmentGridComponent.createComponent;

        const field = new Field({
            name: 'attachments',
            label: 'Attached Files',
            value: 'attachments',
            type: 'grid',
            sendServer: false,
            visible: Field.formOnly,
        }, options);

        const parms: GridControlParameters = {
            field,
            rowFactory: () => [],
            newOptionText: 'Attach File(s)',
        }

        const attachmentGridField: AttachmentGridField = new AttachmentGridField(parms);
        attachmentGridField.formControlFactory = AttachmentGridComponent.createComponent;
        attachmentGridField.fileAttachmentService = service;

        return attachmentGridField;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: GridControl, on: ControlOn) {
        const componentRef = vcr.createComponent(AttachmentGridComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
        componentRef.instance.attachmentGridField = ctl.field as AttachmentGridField;
    }

    ngOnInit(): void {
        this.attachmentGridField.attachmentEditService = this.attachmentEditService;
        this.attachmentGridField.dialog = this.dialog;
        this.attachmentGridField.cds = this.cds;
    }

    clickAddFiles() {
        this.fileDropEl.nativeElement.click();
    }
    /**
     * handle file user selected from file System browsing
     */
    fileBrowseHandler(files) {
        console.log('Uploading Files', files)
        this.uploadFiles(files);
    }

    /**
     * Convert Files list to normal array list
     * @param files (Files List)
     */
    uploadFiles(files: Array<UpFile>) {
        let refused = '';
        let refusedSize = 0;
        this.files = [];

        for (const item of files) {
            item.progress = 0;
            // PHP.ini max_file_size controls how much server willa accept...
            // On nginx it is nginx.conf, server, location or http setting client_max_body_size 20M;
            if ( (item.type === 'image/jpeg' || item.type === 'image/png') && item.size > (1024 * 1024)) {
                this.resizeAndUploadImage(item);
            } else if (item.size > (10 * 1024 * 1024)) {
                console.log(item, item.size, 10 * 1024 * 1024)
                if (refused.length > 0) {
                    refused += ', '
                }
                refused += item.name;
                refusedSize += item.size;
            } else {
                this.upLoadFile(item);
            }
        }
        if (refused.length > 0) {
            const rSize = Math.round(refusedSize / 1024 / 1024) + 'MB';
            this.cds.alert($localize`Files Refused`, $localize`Files of ${rSize} are too large to upload ${refused}`);
        }

        this.fileDropEl.nativeElement.value = "";
        //this.uploadFilesSimulator(0);
    }

    upLoadFile(item: UpFile) {
        this.files.push(item);
        this.uploadSimulator(item);
        const attachToId = this.attachmentGridField.attachToId;

        this.attachmentGridField.fileAttachmentService.attach(item, attachToId).subscribe((o: Attachment) => {
            if (o) {
                console.log('Successful Upload', o);
                item.progress = 100;
                item.completed = true;
                item.attachment = o;

                this.files.splice(this.files.indexOf(item), 1);

                if (!this.control.value) {
                    this.control.setValue([]);
                }
                this.control.addRow(o, true, false);
                this.attachmentGridField.attachmentList.push(o);

            } else {
                item.failed = true;
                console.log('Failed');
            }
        });
    }

    /*
    * Resizing Images
    * https://img.ly/blog/how-to-resize-an-image-with-javascript/?utm_source=imgly&utm_medium=blog&utm_campaign=howtos
    * https://img.ly/blog/how-to-compress-an-image-before-uploading-it-in-javascript/
    */
    resizeAndUploadImage(imgToResizeFile: File) {

        const imgToResize = document.createElement('img');
        const reader = new FileReader();

        fromEvent(reader, 'load').subscribe(() => {
            // Execute this when finished reading the file from disk (reader.readAsDataUrl below)
            fromEvent(imgToResize, 'load').subscribe(() => {
                // Execute this when img tag loaded and we have dimensions (after imgToResize.src = ...)
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                const originalWidth = imgToResize.width;
                const originalHeight = imgToResize.height;
                const resizingFactor = 1 / originalWidth * 1920;
                const canvasWidth = originalWidth * resizingFactor;
                const canvasHeight = originalHeight * resizingFactor;

                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                context.drawImage(
                    imgToResize,
                    0,
                    0,
                    originalWidth * resizingFactor,
                    originalHeight * resizingFactor
                );

                // Get an uploadable version of the scaled image
                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            // showing the compressed image
                            //this.imageFile = blob as File;
                            blob['name'] = imgToResizeFile.name;
                            this.upLoadFile(blob as UpFile);

                        } else {
                            console.warn('No Blob, what happened there?');
                        }
                    },
                    "image/jpeg",
                );
            });
            imgToResize.src = reader.result as string;
        })
        reader.readAsDataURL(imgToResizeFile);
    }

    /**
     * Simulate the upload process
     */
    uploadSimulator(upf: UpFile) {
        const averageUploadSpeed = 1 * 1024 * 1024; // 10MB/s
        const intervalMS = 200;
        const intervalUpload = (averageUploadSpeed / 1000) * intervalMS; // ((upf.size / ((averageUploadSpeed / 1000) * intervalMS) / 100));
        const intervalUploadPct = (1 / (upf.size / intervalUpload)) * 100;

        const progressInterval = setInterval(() => {

            if (upf.completed) {
                upf.progress = 100;
                clearInterval(progressInterval);
            } else {
                if ((upf.progress + intervalUploadPct) < 100) {
                    upf.progress = upf.progress + intervalUploadPct;
                } else {
                    upf.progress = 90;
                    clearInterval(progressInterval);
                }
            }
        }, intervalMS);
    }

    deleteAttachment(attachment: Attachment) {

        this.cds.open($localize`Delete ${attachment.name}`,
            $localize`Are you sure that you want to delete the attachment ${attachment.name}`,
            () => {
                this.attachmentGridField.fileAttachmentService.detach(attachment).subscribe((o: Attachment) => {
                    if (o) {
                        this.removeAttachment(attachment);
                    }
                });
            }, $localize`Delete`);
    }

    removeAttachment(attachment: Attachment) {
        const idx = this.attachmentGridField.attachmentList.indexOf(attachment)
        if (idx) {
            this.attachmentGridField.attachmentList.splice(idx, 1);
        }
    }

    editAttachment(attachment: Attachment) {
        const fields = [];
        fields.push(FieldMaker.id());
        fields.push(FieldMaker.rev());
        fields.push(FormTextComponent.make('Name', 'name'));
        fields.push(FormTextAreaComponent.make('Notes', 'notes'));

        const dialogFormConfig = new FormConfig(
            {
                title: $localize`Attachment`,
                fieldSet: new FieldSet(
                    {
                        fields,
                        formLayout: [{ cells: [{}] }]
                    }
                ),
            }
        );

        const dialogRef = this.dialog.open(EditDialogComponent,
            {
                data:
                {
                    config: dialogFormConfig,
                    service: this.attachmentGridField.attachmentEditService,
                    id: attachment.id,
                    width: 420,
                    hideTabs: true,
                }
            }
        );

        dialogRef.afterClosed().pipe<Attachment>(map(o => {
            if (o === 1) { // Gone to another OMC/Team
                this.removeAttachment(attachment);
            } else {
                if (o) {
                    Object.assign(attachment, o);
                }
            }
            return o;
        })).subscribe();
    }

}
