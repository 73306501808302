/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { Unit } from 'src/app/model/unit';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { formatCurrency } from '@angular/common';
import { Person } from 'src/app/model/person';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { uuid } from 'src/app/model/abstract-object';
@Injectable({
    providedIn: 'root'
})
export class UnitService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'units';
    protected cache: Unit[];
    protected typeString = 'Unit';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    suffixPerson(pur: PersonUnitRole): Person {
        const p = pur.person;
        p.lastName = p.lastName + ' (' + pur.role.name + ')';
        return p;
    }

    getOwners(unitId: uuid) {
        return this.http.get<PersonUnitRole[]>(`${this.baseUrl}/${unitId}/owners`);
    }

    refreshPeople(unit: Unit, personField: PicklistField, msgService: MessageService) {
        if (unit === null || unit.id === null) {
            personField.setPicklistItems([]);
            return;
        }

        this.getOne(unit.id, null, unit.teamId).subscribe((fullUnit) => {
            const relatedPeople = [];

            fullUnit.people.forEach((p: PersonUnitRole) => relatedPeople.push(this.suffixPerson(p)));

            personField.setPicklistItems(relatedPeople);

            let outstanding = 0;
            fullUnit.transactions.forEach(txn => outstanding += txn.outstanding)
            if (outstanding > 0) {
                const msg = msgService.show(
                    new MsgDef($localize`There is ${formatCurrency(outstanding, 'EN-ie', '€')} on unit ${fullUnit.name} - it is paid ${fullUnit.frequency.name}`,
                        'warn'), "Click to show unit");
                msg.afterDismissed().subscribe((info) => {
                    if (info.dismissedByAction) {
                        // causes circular dependency with UnitPageComponent
                        //const url = window.location.protocol + "//" + window.location.host + UnitPageComponent.navRoute.getIdUrl(unit.id)
                        const url = window.location.protocol + "//" + window.location.host + '/units/' + unit.id;
                        window.open(url, '_blank');
                    }
                });
            }
        })
    }

    getPDF(unit: Unit, mailIt = false, includeAll: boolean) {
        const url = `${this.baseUrl}/${unit.id}/statement/${includeAll}`;
        if (!mailIt) {
            this.downloadFile(url, unit.teamId, 'application/pdf');
        } else {
            this.http.post(url, {}, this.getSimpleOptions(unit.teamId)).subscribe(response => {
                let owners = 'Owners Email Address';
                if (response['mailedTo']) {
                    owners = '';
                    response['mailedTo'].forEach((to: { name: string, address: string }) => {
                        if (owners !== '') {
                            owners += ', ';
                        }
                        owners += to.address;
                    });
                }
                this.messageService.show('PDF Sent by mail to ' + owners);
            }, failure => this.messageService.showError(failure, "Producing Statement"));
        }
    }
    /*
    getMyUnits(): Observable<Unit[]> {

          return this.http.get<Unit[]>(this.baseUrl + '/myUnits')
              .pipe(
                  map((data: Unit[]) => {
                      return data;
                  }),
                  catchError(this.handleError<Unit[]>('get' + this.typeString, []))
              );
      }
      */
}
