/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractHttpService } from "src/app/shared/abstract-http.service";
import { ActionPageComponent } from "../../crm/action-page/action-page.component";
import { UnreadChart } from "./UnreadChart";

export class UnreadActionsChart extends UnreadChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/unreadActions';
    title = "My Unread Actions";

    getSubTitle() {
        return super.getSubTitle($localize`Actions assigned to me I have not read yet`);
    }

    clickEvent() {
        const url = ActionPageComponent.navRoute.url;
        const params = { ownedById: this.dataUserId, ownerReadAt: '' };

        this.router.navigate([url, params]);
    }
}
