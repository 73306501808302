/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Field } from "src/app/shared/field/Field";
import { AppFormControl } from "src/app/shared/form/app-form-control";

export class ImportInput {
    private name: string;
    private fields: Field[];
    private ctls: AppFormControl[] = null;
    private help: string;

    constructor(name: string, fields: Field[] = [], help = '') {
        this.name = name;
        this.fields = fields;
        this.help = help;
        if (this.fields) {
            for (const f of this.fields) {
                f.makeControl();
            }
        }
    }

    addField(field: Field) {
        this.fields.push(field);
        if (!field.control) {
            field.makeControl();
        }
    }

    setHelp(help: string) {
        this.help = help;
    }

    getHelp() {
        return this.help;
    }

    hasControl() {
        if (this.fields.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    /*
        getControl() {
            if (!this.field.control) {
                this.field.makeControl();
            }
            return this.field.control;
        }
    */
    getControls() {
        if (this.ctls === null) {
            const ctls: AppFormControl[] = [];
            if (this.fields !== null) {
                for (const f of this.fields) {
                    if (!f.control) {
                        console.warn('Should not be making them now...');
                        f.makeControl();
                    }
                    ctls.push(f.control as AppFormControl);
                }
            }
            this.ctls = ctls;
        }
        return this.ctls;
    }

    getFields() {
        return this.fields;
    }

    getName() {
        return this.name;
    }

}
