/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Area } from 'src/app/model/area';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';
import { MailMerge } from 'src/app/model/mailMerge';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MailMergeService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'mailmerge';
    protected cache: Area[];
    protected typeString = 'MailMerge';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }

    linkRequest(mailMerge: MailMerge, sr: ServiceRequest): Observable<MailMerge> {

        const url = this.baseUrl + '/' + mailMerge.id + '/linkRequest';
        const object = {id: mailMerge.id, revision: mailMerge.revision, requestId: sr?.id}
        return this.http.put<MailMerge>(url, object, this.getSimpleOptions(mailMerge.teamId))
            .pipe(
                catchError(this.handleOneError('put' + this.typeString, null))
            );
    }    
}
