/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RoleService } from '../../role-page/role.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FieldSet, FieldSetComponent } from 'src/app/shared/form/field-set/field-set.component';
import { MatButtonModule } from '@angular/material/button';
import { required } from 'src/app/shared/validators';
import { OmcSettingsService } from '../omc-settings.service';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Role } from 'src/app/model/role';

class Agency extends AbstractObject {
    agentRoles: Role[];
}

@Component({
  selector: 'app-omc-add-agent',
  standalone: true,
  imports: [MatDialogModule, FieldSetComponent, MatButtonModule],
  templateUrl: './omc-add-agent.component.html',
  styleUrl: './omc-add-agent.component.scss'
})
export class OmcAddAgentComponent {
    omcRoleSvc = inject(RoleService);
    omcSettingsSvc = inject(OmcSettingsService);
    dialogRef = inject(MatDialogRef<OmcAddAgentComponent>);
    omcTeam = inject(MAT_DIALOG_DATA);
    ready = true;

    fieldSet = new FieldSet();
    agentRolePicklist = FormPicklistComponent.make('Agent Role', 'agentRoleId', null, {
        items: [],
    }, { validators: [required] });

    agentPicklist = FormPicklistComponent.make('Agent', 'agentId', null, {
        items: [],
        optionValue: (a) => a.teamId,
        refreshes: [(agency: Agency) => this.agentRolePicklist.setPicklistItems(agency.agentRoles)]
    }, { validators: [required] });

    omcRolePicklist = FormPicklistComponent.make('OMC Role', 'omcRoleId', null, {
        service: this.omcRoleSvc,
    }, { validators: [required] });

    constructor() {
        this.omcSettingsSvc.getAgencies().subscribe( (agencies) => {
            this.agentPicklist.setPicklistItems(agencies);
        });
        this.fieldSet.setFields([
            this.agentPicklist, this.agentRolePicklist, this.omcRolePicklist
        ])
    }

    addAgent() {
        const agent = {
            teamId: this.omcTeam.id,
            agentTeamId: this.agentPicklist.control.value,
            agentRoleId: this.agentRolePicklist.control.value,
            omcRoleId: this.omcRolePicklist.control.value,
        }
        this.omcSettingsSvc.addAgent(agent).subscribe (updatedSettings => {
            if (updatedSettings) {
                Object.assign(this.omcTeam, updatedSettings);
                this.dialogRef.close();
            }
        });
    }

}
