/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { uuid } from 'src/app/model/abstract-object';
import { Team } from 'src/app/model/team';
import { Omc } from 'src/app/model/Omc';
import { User } from 'src/app/model/user';
import { OmcService } from 'src/app/modules/agent/omc.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { UserProfileComponent } from 'src/app/modules/user/user-profile/user-profile.component';
import { FormComboBoxComponent } from '../form/form-combo-box/form-combo-box.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { AvatarComponent } from '../../modules/user/avatar/avatar.component';
import { PersonUnitRole } from 'src/app/model/person-unit-role';
import { MyInjector } from 'src/app/app.module';
import { ConfirmDialogService } from '../dialogs/confirmDialog';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from '../abstract-http.service';
import { MessageService } from '../message.service';
@Component({
    selector: 'app-user-nav-button-component',
    templateUrl: './user-nav-button-component.component.html',
    styleUrls: ['./user-nav-button-component.component.scss'],
    standalone: true,
    imports: [AvatarComponent, MatMenuModule, MatButtonModule, MatIconModule, MatDividerModule, RouterLink]
})
export class UserNavButtonComponentComponent {

    currentUser: User;
    currentTeam: uuid;
    currentTeamName: string;
    /**
     * @deprecated Teams array replaced by active positions
     */
    teams: Team[];
    activePositions: PersonUnitRole[];

    omcs: Omc[] = []
    mgrList: { name: string, omcs: Omc[] }[];

    profilePath = '/' + UserProfileComponent.navRoute.listRoute.path;

    omcComboControl = FormComboBoxComponent.make('omc', 'omc', null, { items: this.omcs }).makeControl();

    currentPath = '';
    currentHost = window.location.hostname;

    constructor(public currentUserSvc: CurrentUserService, private omcService: OmcService, router: Router) {
        router.events.subscribe(o => {
            if (o instanceof NavigationEnd) {
                this.currentPath = o.urlAfterRedirects;
            }
        })
        this.currentUserSvc.getCurrentUser().subscribe(currentUser => {
            this.currentUser = currentUser;
            if (currentUser) {
                this.activePositions = currentUserSvc.getActivePositions();
                this.currentTeam = currentUser.current_team_id;
                this.currentTeamName = this.currentUser?.currentTeam?.name;
                if (currentUser.inAgency) {
                    this.getAvailableOmcs();
                }
            }
        });
    }
    getAvailableOmcs() {
        this.omcService.get<Omc>(true).subscribe(omcs => {
            this.currentUser.inAgency.availableOmcs = omcs;
            this.omcs = (omcs as Omc[]).sort((a, b) => a.name.localeCompare(b.name));
            const mgrMap: Map<string, Omc[]> = new Map();
            this.mgrList = [];
            this.omcs.map(omc => {
                if (omc.manager) {
                    if (!mgrMap.has(omc.manager.name)) {
                        mgrMap.set(omc.manager.name, []);
                    }
                    mgrMap.get(omc.manager.name).push(omc);
                }
            });
            mgrMap.forEach((omcs, name) => this.mgrList.push({ name, omcs }));
            this.mgrList = this.mgrList.sort((a, b) => a.name.localeCompare(b.name));
        });
    }

    getIssueUrl() {
        if (this.currentUser) {
            return `https://docs.google.com/forms/d/e/1FAIpQLSdS4q0H5UXhbQ1TKx6IMRVu-HfZRbjaq0bSDPnxDZmbeYyhyQ/viewform?usp=pp_url&entry.954273259=${this.currentUser.name}&entry.1107363702=${this.currentPath}&entry.233045424=${this.currentTeamName}&entry.2033988018=${this.currentHost}`
        } else {
            return '';
        }

    }
    getIssueLogUrl() {
        if (this.currentUser) {
            return `https://docs.google.com/spreadsheets/d/1WtoJ6IgeTP9xmKsBXJC0XkjEta7wLyM-TmgQmQMDrNw/edit#gid=198211854`
        } else {
            return '';
        }
    }

    impersonate() {
        const cds = MyInjector.instance.get(ConfirmDialogService);
        const msgSvc = MyInjector.instance.get(MessageService);
        const http = MyInjector.instance.get(HttpClient);
        if (this.currentUserSvc.isUber()) {
            const cd = {
                title: 'Impersonate Someone',
                msg: 'Who would you like to impersonate?',
                needsReason: true,
                options: [{name: 'Yes Please', needsReason: true, action: (who) => {
                    http.post(AbstractHttpService.ajaxPath + 'imperson8', {who}).subscribe( o => {
                        console.warn("Result was ", o);
                        msgSvc.show('Away you go as ' + who + " you will need to refresh browser");
                    }, f => msgSvc.showError(f, "impersonating"));
                }}]
            }
            cds.openChoice(cd, true);
        }
    }

    logoutCurrentUser() {
        this.currentUserSvc.logoutCurrentUser();
    }

    selectCurrentTeam(pur: PersonUnitRole) {
        this.currentUserSvc.switchTeamRole(pur);
    }

    selectCurrentOmc(omc: Omc) {
        this.currentUserSvc.switchTeamOmc(omc);
    }

}
