/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from "src/app/model/abstract-object";
import { ChatItem } from "src/app/model/chat-item";
import { Field } from "src/app/shared/field/Field";
import { Follower } from "src/app/model/user";
import { AbstractHttpService } from "../abstract-http.service";
import { ITabField } from "../field/TabFIeld";
import { FormGroup } from "@angular/forms";

export type ChatViewMode = { order: 'ascending' | 'descending', nested: boolean };
export class ChatField extends Field implements ITabField {
    relatedFormGroup: FormGroup;
    tabIcon = 'chat';

    chat: {
        service: AbstractHttpService;
        parentTitle: string;
        parentId: uuid;
        teamId: uuid;
        parentType?: 'How I know the type?';
        followers: Follower[];
        comments: ChatItem[];
        hierarchyItems: ChatItem[];
        mode: ChatViewMode;
        allowReplies: boolean;
        allowComments: boolean;
        hideFollowers?: boolean;
        commentsMayNeedResponse?: boolean;
    }

    refreshAfterCommentFn: () => void;

    constructor(service: AbstractHttpService, defaults: Partial<ChatField>, overrides: Partial<ChatField>) {
        super(defaults, overrides);
        if (!this.chat) {
            this.chat = {
                service, parentTitle: '', parentId: null, teamId: null, allowReplies: true, allowComments: false,
                followers: [], comments: [], hierarchyItems: [], mode: { order: 'ascending', nested: false }
            }
        }
    }

    refreshAfterComment( fn : () => void) {
        this.refreshAfterCommentFn = fn;
        return this;
    }

    allowComments(on = true) {
        this.chat.allowComments = on;
        this.chat.allowReplies = on;
        return this;
    }

    disableComments() {
        this.chat.allowComments = false;
        this.chat.allowReplies = false;
        return this;
    }

    commentsMayNeedResponse() {
        this.chat.commentsMayNeedResponse = true;
        return this;
    }

    hideFollowers() {
        this.chat.hideFollowers = true;
        return this;
    }

    getTabChip(): string {
        if (this.chat.comments.length > 0) {
            return '' + this.chat.comments.length;
        }
        return '';
    }

    setValue(item: AbstractObject, readonly: boolean) {
        if (!Field.isEmpty(item['title'])) {
            this.chat.parentTitle = item['title'];

        } else if (!Field.isEmpty(item['name'])) {
            this.chat.parentTitle = item['name'];

        } else {
            this.chat.parentTitle = 'Unknown Conversation Parent'
        }
        if (Array.isArray(item['followers'])) {
            this.chat.followers.length = 0;
            for (const f of item['followers']) {
                this.chat.followers.push(f);
            }
        }
        this.chat.parentId = item.id;
        this.chat.teamId = item.teamId;
        if (Array.isArray(item['comments'])) {
            this.chat.comments = item['comments'];
            this.sortChat();
        }

        return super.setValue(item, readonly);
    }

    sortChat(): void {

        const sorted = this.chat.comments.sort((comt1: ChatItem, comt2: ChatItem) => {
            if (comt1.id > comt2.id) {
                return this.chat.mode.order === 'ascending' ? 1 : -1;
            } else {
                return this.chat.mode.order === 'ascending' ? -1 : 1;
            }
        });


        this.chat.hierarchyItems.length = 0;
        for (const comt of sorted) {
            comt.showReplies = false;
            comt.replies = [];
        }

        for (const comt of sorted) {
            const parent = sorted.find((ci) => ci.id === comt.parentId);
            if (parent) {
                comt.parent = parent;
                if (!parent.replies) { parent.replies = []; }
                parent.replies.push(comt);
            }
            if (this.chat.mode.nested) {
                if (!parent) {
                    this.chat.hierarchyItems.push(comt);
                }
            } else {
                this.chat.hierarchyItems.push(comt);
            }
        }
    }
}
