/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
/**
 * Interface for post title, content and excerpt
 */

import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Forum } from './forum';
import { User } from './user';
import { Attachment } from './attachment';
import { Area } from './area';

/* eslint-disable @typescript-eslint/naming-convention,no-underscore-dangle,id-blacklist,id-match,  */

interface ContentObject {
    // This property is always present
    rendered: string;
    // This property is only present in some contexts
    raw?: string;
}

interface PostContent extends ContentObject {
    protected?: boolean;
}

interface Link {
    count?: number;
    embeddable?: boolean;
    href: string;
    id?: uuid;
    name?: string;
    taxonomy?: string;
    templated?: boolean;
}

interface PostLinks {
    self: Array<Link>;
    collection?: Array<Link>;
    curies?: Array<Link>;
    about?: Array<Link>;
    author?: Array<Link>;
    replies?: Array<Link>;
    'version-history'?: Array<Link>;
    'wp:attachment'?: Array<Link>;
    'wp:term'?: Array<Link>;
    'predecessor-version'?: Array<Link>;
    'wp:featuredmedia'?: Array<Link>;
}

export class Post extends AbstractObject {
    //date: string;
    //date_gmt: string;
    //guid: {
    //    rendered: string;
    //};
    //modified: string;
    //modified_gmt: string;
    //slug: string;
    //status: string;
    //type: string;
    link: string;
    //title: ContentObject = { rendered: '' };
    //content: PostContent = { rendered: ''};
    createdAt?: number; // date!
    updatedAt?: number; //date!
    forum: Forum;
    title: string;
    content: string;
    imageId: uuid;
    forumId: uuid;
    areaId: uuid;
    areaName: string;
    area: Area;
    coreId: uuid;
    coreName: string;
    excerpt: PostContent;
    authorId: uuid;
    author: User;
    featured_media = 0;
    comments: Comment[] = [];
    comment_status: string;
    ping_status: string;
    sticky: boolean;
    template: string;
    format: string;
    meta: Array<string>;
    categories: Array<number>;
    tags: Array<number>;
    _links: PostLinks;

    mailsSent?: number;
    notificationSentAt?: number;
    attachments?: Attachment[] = [];

    constructor(o: Partial<Post> = {}) {
        super(o);
    }

    static getExcerpt(post: Post) {
        const div = document.createElement('div');
        div.innerHTML = post.content;
        let exc = (div.textContent || div.innerText).substring(0, 255);
        if (exc.length === 255) {
            exc += '...'
        }
        return exc
    }
}

export class Comment {
    id: uuid;
    postId: uuid;
    post: Post;
    parentId: uuid;
    authorId: uuid;
    authorName: string;
    content: string;
    isIcon: boolean;
    /*
    author_name: string;
    author_email?: string;
    authorUrl: string;
    date: string;
    date_gmt: string;
    content: ContentObject  = { rendered: '', raw: '' };
    link: string;
    status: string;
    type: string;
    meta: Array<string>;
    _links: CommentLinks;
    */
    showReplyBox = false; // Cheap trick to show hide reply options
    reply: Comment; // Placeholder to allow a reply to be drafted
    children: Array<Comment>; // API Does not give this, we construct it
    userGaveIcon = false; // Ditto
}

/* eslint-enable @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match */

