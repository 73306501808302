/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { User } from 'src/app/model/user';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { required } from 'src/app/shared/validators';
import { CurrentUserService } from '../../user/current-user.service';
import { MatDialogRef, MatDialogContent } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { MatCardModule } from '@angular/material/card';
import { MessageService } from 'src/app/shared/message.service';

@Component({
    selector: 'app-new-agent',
    templateUrl: './new-agent.component.html',
    styleUrls: ['./new-agent.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatCardModule, CtlHolderComponent, MatButtonModule]
})
export class NewAgentComponent {

    isMobile = false;
    currentUser: User;

    msgSvc = inject(MessageService);

    newAgencyNameField = FormTextComponent.make('New Agency Name', 'agencyName', { validators: [required] });
    newAgencyNameCtl = this.newAgencyNameField.makeControl();

    newAgencyLegalField = FormTextComponent.make('Agency Legal Name', 'agencyLegalName', { validators: [required] });
    newAgencyLegalCtl = this.newAgencyLegalField.makeControl();

    slugField = FormTextComponent.make('Unique slug', 'slug', { validators: [required] });
    slugCtl = this.slugField.makeControl();    

    constructor(private currentUserSvc: CurrentUserService, private http: HttpClient,
        private mdRef: MatDialogRef<NewAgentComponent>,
        isNarrowSvc: IsNarrowService) {

        isNarrowSvc.detectVeryNarrow().subscribe(result => this.isMobile = result);
        this.currentUserSvc.getCurrentUser().subscribe(user => this.currentUser = user);
    }

    createAgency() {
        const name = this.newAgencyNameCtl.value;
        const legalName = this.newAgencyLegalCtl.value;
        const slug = this.slugCtl.value;

        const url = AbstractHttpService.ajaxPath + 'agency';
        this.http.post(url, { name, legalName, slug }).subscribe(success => {
            console.log(success);
            this.mdRef.close();
        }, failure => {
            this.msgSvc.showError(failure, 'Create Agency');
        })
    }
}
