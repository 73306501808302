/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/
import { Params } from '@angular/router';
import moment from 'moment';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { DashboardChart } from 'src/app/shared/chart/dashboard-chart/dashboard-chart';
import { ActionPageComponent } from '../../crm/action-page/action-page.component';

import * as Highcharts from 'highcharts';
import { HttpParams } from '@angular/common/http';

class moaData {
    startDate: string;
    endDate: string;
    rows: { plannedDate: string; actions: number; completed: string }[];
}

export class MoaChart extends DashboardChart {
    dataUrl = AbstractHttpService.ajaxPath + 'agent/dashboard/moa';
    dataParams = new HttpParams().set('week', 0);

    title = "My Actions";

    categories: string[] = [];

    //series: { name: string, y: number, route: NavRoute, routeParams: Params, comt: string }[] = [];
    toDoSeries = { name: 'To Do', data: [] };
    upcomingToDoSeries = { name: 'Upcoming To Do', data: [] };
    completeSeries = { name: 'Completed', data: [] }
    series = [this.toDoSeries, this.upcomingToDoSeries, this.completeSeries];

    startDate = '';
    endDate = '';
    options = [
        { name: 'Previous Week', action: this.previousWeek.bind(this) },
        { name: 'Following Week', action: this.nextWeek.bind(this) }
    ];

    previousWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) - 1);
        this.getData();
    }

    resetChart() {
        this.categories = [];
        this.toDoSeries = { name: 'To Do', data: [] };
        this.upcomingToDoSeries = { name: 'Upcoming To Do', data: [] };
        this.completeSeries = { name: 'Completed', data: [] };
        this.series = [this.toDoSeries, this.upcomingToDoSeries, this.completeSeries];
    }

    nextWeek() {
        const currWeek = this.dataParams.get('week');
        this.dataParams = this.dataParams.set('week', Number(currWeek) + 1);
        this.getData();
    }

    getSubTitle() {
        return super.getSubTitle('Week ' + this.startDate + ' to ' + this.endDate)
    }

    getRouteParamsDone(i: { plannedDate: string }): unknown {
        return { ownedById: this.currentUser.id, plannedDate: i.plannedDate, done: 1, _sort: 'plannedDate' };
    }

    getRouteParamsTodo(i: { plannedDate: string }): unknown {
        return { ownedById: this.currentUser.id, plannedDate: i.plannedDate, done: 0, _sort: 'plannedDate' }
    }

    protected setupData(data: moaData) {
        const route = ActionPageComponent.navRoute;
        this.startDate = data.startDate;
        this.endDate = data.endDate;

        for (const i of data.rows.sort((a, b) => a.plannedDate.localeCompare(b.plannedDate))) {
            this.categories.push(i.plannedDate);

            const routeParamsDone: Params = this.getRouteParamsDone(i);
            const routeParamsTodo: Params = this.getRouteParamsTodo(i);

            let comt = ';'
            if (i.plannedDate === 'Overdue') {
                comt = 'Actions that should have been completed before this week';
                routeParamsDone.plannedDate = '<=' + this.startDate;
                routeParamsTodo.plannedDate = routeParamsDone.plannedDate;
            } else if (i.plannedDate === 'Unplanned') {
                comt = 'Actions that do not have a planned date entered on them';
                routeParamsDone.plannedDate = '';
                routeParamsTodo.plannedDate = '';
            } else if (i.plannedDate === moment(new Date()).format('YYYY-MM-DD')) {
                comt = 'Actions that should be completed TODAY';
            } else {
                comt = 'Actions scheduled ' + moment(i.plannedDate, 'YYYY-MM-DD').format('dddd');
            }

            let todoActions = i.actions - Number(i.completed);
            let upcomingActions = 0;

            if (i.plannedDate !== 'Overdue' &&
                i.plannedDate !== 'Unplanned' &&
                i.plannedDate >= moment(new Date()).format('YYYY-MM-DD')) {
                upcomingActions = todoActions;
                todoActions = 0;
            }

            this.toDoSeries.data.push({ y: (todoActions), route, routeParams: routeParamsTodo, comt });
            this.upcomingToDoSeries.data.push({ y: (upcomingActions), route, routeParams: routeParamsTodo, comt });
            this.completeSeries.data.push({ y: (Number(i.completed)), route, routeParams: routeParamsDone, comt });
            //            this.series.push({ name: i.plannedDate, y: i.actions, route, routeParams, comt});

        }
    }

    clickEvent($event) {
        this.router.navigate([$event.point.route.url, $event.point.routeParams]);
    }

    protected createChartOptions(): Highcharts.Options {

        return {
            colors: ['red', 'orange', 'green'],
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'column',
                height: this.height,
                width: this.width
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: {
                pointFormat: '{series.name}: {point.y} <br/> {point.comt}'
            },
            xAxis: {
                categories: this.categories,
            },
            yAxis: {
                min: 0,
                title: { text: 'Count Actions' }
            },
            legend: { enabled: false },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: false
                    }
                },
                series: {
                    events: {
                        click: this.clickEvent.bind(this)
                    },
                }
            },
            series: this.series
        } as unknown as Highcharts.Options;
    }
}
