/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, OnInit, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AbstractObject } from 'src/app/model/abstract-object';
import { Field } from 'src/app/shared/field/Field';
import { PicklistField, PicklistOptions } from '../../field/PicklistField';
import { AppFormControl, ControlOn } from '../app-form-control';
import { AppPicklistControl } from '../form-picklist/form-picklist.component';
import { NgClass, AsyncPipe } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { FormErrorComponent } from '../form-error/form-error.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-form-combo-box',
    templateUrl: './form-combo-box.component.html',
    styleUrls: ['./form-combo-box.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatIconModule, MatTooltipModule, MatInputModule, FormsModule, MatAutocompleteModule, ReactiveFormsModule, FormErrorComponent, MatOptionModule, NgClass, AsyncPipe]
})
export class FormComboBoxComponent implements OnInit {
    @Input() control: AppPicklistControl;
    @Input() on: ControlOn = 'form';

    // Internal myControl holds either the filter string or the selected object...
    oldValue: unknown;
    isFocused = false;

    /** NEW VARS */

    listHidden = true;
    showError = false;

    selectedItem: AbstractObject = null;

    // the list to be shown after filtering (<LIST_ITEM> method only)
    filteredList: AbstractObject[] = [];
    /** to here */

    filteredOptions: Observable<AbstractObject[]>;

    firstTimeReturnAllItems() {
        return this.control.field.picklist.items.slice();
    }


    ngOnInit() {

        this.filteredOptions = this.control.field.filterControl.valueChanges.pipe(
            startWith(-1),
            map(value => {
                if (value === -1) {
                    return this.firstTimeReturnAllItems();
                }

                //                const name = typeof value === 'string' ? value : value?.name;
                if (typeof value === 'string') { // Value is a filter string the user typed in
                    if (!this.control.field.filterControl.pristine) {
                        if (value !== '' && !this.control.field.picklist.allowSelectNone) {
                            this.control.setErrors({ unselected: 'You must select a value from the list' });
                            this.control.markAsTouched();
                        } else if (this.control.field.picklist.allowSelectNone) {
                            this.control.setValue(null);
                            this.doRefreshes(null)
                        }
                    }
                    //return name ? this._filter(name as string) : this.control.field.picklist.items.slice();
                    return this._filter(value);

                } else { // Value is an Item user selected from picklist

                    if (!this.control.field.filterControl.pristine) {
                        const valueToStore = this.control.field.picklist.optionValue(value);
                        this.control.setValue(valueToStore);
                        this.control.markAsTouched();
                        this.control.markAsDirty();

                        this.doRefreshes(value);
                    }

                    // List contains everything again if one was selected - so user could reselect a different one
                    return this.control.field.picklist.items.slice();
                }
            }),
        );
    }

    private _filter(name: string): AbstractObject[] {
        const filterValue = name.toLowerCase();
        const pl = this.control.field.picklist;

        return pl.items.filter(option => {
            //console.log('Filtering', { filterValue, option, display: pl.optionDisplayValue(option), include: pl.optionDisplayValue(option).includes(filterValue) })

            if (pl.allowSelectNone && option.id === null && option.name === '') {
                return true;
            } else {
                return pl.optionDisplayValue(option).toLowerCase().includes(filterValue)
            }

        });
    }

    itemChanges($event, field: PicklistField) {
        console.warn('Am I ever actually called, do not think so...');
        const newValue = $event.target.value;
        const selected = field.picklist.items.find(item => {
            const optValue = field.picklist.optionDisplayValue(item);
            if (optValue) {
                // Multiple whitespaces in text get normalized to single whitespace when passed through DOM to display as option!
                const optyalue = optValue.replace(/\s+/g, ' ');
                const newyalue = newValue.replace(/ /g, '.');
                console.log({ optValue, newValue, optyalue, newyalue, match: optValue.trim() === newValue.trim() });
                return optyalue.trim() === newValue.trim();
            } else {
                return false;
            }
        });

        //console.log({ newValue, selected, items: field.picklist.items });

        const valueToStore = field.picklist.optionValue(selected);

        if (selected) {
            field.control.setValue(valueToStore);
        } else {
            field.control.setValue('');
            $event.target.value = field.control.value;
        }
        field.control.markAsTouched();
        field.control.markAsDirty();

        this.doRefreshes(selected);
    }

    public static make(label: string, valueId: string, valueObjectName: string,
        picklist: Partial<PicklistOptions>, options: Partial<Field> = {}): PicklistField {

        const field = new PicklistField({ label, value: valueId }, options);
        field.picklist.valueObjectName = valueObjectName;
        field.formControlFactory = FormComboBoxComponent.createComponent;
        field.picklist = { ...field.picklist, ...picklist };

        if (field.picklist.service) {
            field.picklist.service.get(true).subscribe(data => {
                field.setPicklistItems(data);
            });
        }
        if (field.disable) {
            field.filterControl.disable({ emitEvent: false });
        }

        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppPicklistControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormComboBoxComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    /** METHODS FOR DATALIST APPROACH - Only Used on GRID */
    itemFocused(field: Field) {
        this.oldValue = field.control.value
        this.isFocused = true;
        // Putty a dummy value in, to simulate focus on real control, i.e. move label
        if (!this.oldValue) {
            console.log('No old value - set -1');
            field.control.setValue("");
        }

    }

    getHiddenValue(comboControl) {
        if (this.isFocused && !comboControl.value) {
            return 1;
        } else {
            return comboControl.value;
        }
        /*
        if (comboControl.value && comboControl.value !== '') {
            return this.control.field.getDisplayValueFromId(comboControl.value);
        } else {
            return '';
        }
        */
    }
    getDisplayValue(comboControl) {
        if (comboControl.value && comboControl.value !== '') {
            return this.control.field.getDisplayValueFromId(comboControl.value);
        } else {
            return '';
        }
    }

    loseFocus(field: Field, $event) {
        // No new value selected, put the old value back...
        this.isFocused = false;
        if (field.control.value === null) {
            $event.target.value = '';
        }
        /*
        if (field.control.value === -1) {
            field.control.setValue(this.oldValue);
        }
        */
    }

    getComboIsEmpty() {
        return 'no';
    }

    doRefreshes(selected) {
        this.control.field.picklist.valueObject = selected;

        this.control.field.picklist.refreshes.forEach(fldName => {
            if (typeof fldName === 'string') {
                const ctl = (this.control.parent as UntypedFormGroup).get(fldName) as AppFormControl;
                if (ctl) {
                    ctl.field.refresh(selected as AbstractObject, ctl);
                } else {
                    console.warn('No control [' + fldName + '] to refresh', this.control);
                }
            } else if ((typeof fldName === 'function')) {
                fldName(selected);
            } else {
                console.error('Do not know how to refresh ', { fldName, control: this.control, comp: this })
            }
        });
    }

    public matchById(option, value) {
        return option === value;
    }

    /** Methods used by new item-list method */

    getFilteredList() {

        this.listHidden = false;
        // this.selectedIndex = 0;
        if (!this.listHidden && this.control.field.filterString !== undefined) {
            this.filteredList = this.control.field.picklist.items.filter((item) => {
                const dv = this.control.field.picklist.optionDisplayValue(item);
                return dv.toLowerCase().includes(this.control.field.filterString.toLowerCase());
            });
        } else {
            this.filteredList = this.control.field.picklist.items.slice();
        }
    }

    // select highlighted item when enter is pressed or any item that is clicked
    selectItem() {
        this.listHidden = true;
        this.control.field.filterString = this.control.field.picklist.optionDisplayValue(this.selectedItem);
        this.control.setValue(this.control.field.picklist.optionValue(this.selectedItem));
        this.control.markAsTouched();
        this.control.markAsDirty();
        this.doRefreshes(this.selectedItem);
    }

    pickItem(item) {
        console.warn('Selected ', item);
        this.selectedItem = item;
        this.selectItem();
    }

    makeItemVisible() {
        const elId = this.control.field.name + '-' + this.control.field.picklist.optionValue(this.selectedItem);
        const li = document.getElementById(elId);
        const div = document.getElementById(this.control.field.name + '-options');
        if (li && div) {
            if ((li.getBoundingClientRect().bottom > div.getBoundingClientRect().bottom)
                || li.getBoundingClientRect().top < div.getBoundingClientRect().top) {
                li.scrollIntoView();
            }
        }
    }

    preSelectNextItem() {
        if (!this.selectedItem) {
            this.selectedItem = this.filteredList[0];
        } else {
            const idx = this.filteredList.indexOf(this.selectedItem);
            if (idx < this.filteredList.length - 1) {
                this.selectedItem = this.filteredList[idx + 1];
            }
        }
        this.makeItemVisible();
    }

    preSelectPrevItem() {
        if (!this.selectedItem) {
            this.selectedItem = this.filteredList[this.filteredList.length - 1];
        } else {
            const idx = this.filteredList.indexOf(this.selectedItem);
            if (idx > 0) {
                this.selectedItem = this.filteredList[idx - 1];
            }
        }
        this.makeItemVisible();
    }
    // navigate through the list of items
    /*
    onKeyPress(event) {

        if (!this.listHidden) {
            if (event.key === 'Escape') {
                this.closeList();
            }

            if (event.key === 'Enter') {

                if (this.selectedItem) {
                    this.selectItem();
                }

            }
            if (event.key === 'ArrowDown') {

                this.preSelectNextItem();

            } else if (event.key === 'ArrowUp') {

                this.preSelectPrevItem();
            }
        }
    }
    */
    /*
        focusInput() {
            if (this.filterString === null || this.filterString === '') {
                this.showList();
            } else {
                console.log({f: this.filterString});
            }
        }
    */
    /*
     focusOut() {
         // If user clicked on item, focusOut fires before selectItem, setTimeout to let selectItem go first!
         setTimeout(this.closeList.bind(this), 500);
     }

     closeList() {
         if (this.control.value !== this.control.field.picklist.optionValue(this.selectedItem)) {
             console.log('Reverting Value ', { ctl: this.control.value, current: this.control.field.picklist.optionValue(this.selectedItem) });
             this.control.field.filterString = this.control.field.getDisplayValueFromId(this.control.value);
         }
         this.listHidden = true;

     }

     filterFieldFocused() {
         if (!this.listHidden && !this.control.value) {
             return 1;
         } else {
             return this.control.value;
         }
     }

     showList() {
         if (!this.control.field.readonly && !this.control.field.disable) {
             if (this.control.field.picklist.items.length === 0) {
                 console.warn('No items to choose...');
             }
             this.listHidden = false;
             this.control.field.filterString = '';
             this.getFilteredList();
             if (this.selectedItem) {
                 this.makeItemVisible();
             }
         }
     }
     */
}
