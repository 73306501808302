/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, OnInit, ViewContainerRef, ViewEncapsulation } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
@Component({
    selector: 'app-form-checkbox',
    templateUrl: './form-checkbox.component.html',
    styleUrls: ['./form-checkbox.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [MatIconModule, MatTooltipModule, NgClass,]
})
export class FormCheckboxComponent implements OnInit {

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    color = 'primary';
    opacity = 1;

    public static make(label: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new Field({ label, value, type: 'boolean' }, options);
        field.formControlFactory = FormCheckboxComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormCheckboxComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    ngOnInit(): void {
        if (this.control.field.disable) {
            this.color = 'normal';
            this.opacity = .25;
        } else {
            if (this.control.field.readonly) {
                this.color = 'normal';
            } else {
                this.color = 'primary';
            }
            this.opacity = 1;
        }
    }

    turnOn() {
        this.toggleValue(true);
    }

    turnOff() {
        this.toggleValue(false);
    }

    toggleValue(value: boolean) {
        if (!this.control.field.readonly && !this.control.field.disable) {
            this.control.setValue(value);
            this.control.markAsDirty();
            this.control.markAsTouched();
        }
    }

    clickIt($event) {
        console.log('clicking');
        if (this.control.field.readonly) {
            console.log('blocked');
            $event.preventDefault();
        } else {
            this.control.markAsDirty();
            this.control.markAsTouched();
        }
    }
}
