/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { NavRoute } from 'src/app/shared/NavRoute';
import { LoggedInComponent } from './logged-in/logged-in.component';
import { AnonymousComponent } from './anonymous/anonymous.component';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InstallMeComponent } from './install-me/install-me.component';
import { buildDate, buildVersion } from 'src/environments/version';

@Component({
    selector: 'app-home-page-component',
    templateUrl: './home-page-component.component.html',
    styleUrls: ['./home-page-component.component.scss'],
    standalone: true,
    imports: [LoggedInComponent, AnonymousComponent, MatProgressSpinnerModule, InstallMeComponent]
})
export class HomePageComponentComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('', HomePageComponentComponent, 'home');
    isReady = false;
    otherPage: HomePageComponentComponent;
    buildDate = buildDate;
    buildVersion = buildVersion;

    constructor(currentUserSvc: CurrentUserService) {
        super();
        currentUserSvc.getCurrentUser().subscribe( u => {
            this.currentUser = u;
            this.isReady = true;
        });
    }
}
