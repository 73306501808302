/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet, LAYOUT_OPTIONS } from 'src/app/shared/form/field-set/field-set.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { DebtorsListService } from '../debtors-list/debtors-list.service';
import { FormPageComponent } from 'src/app/shared/form/form-page/form-page.component';
import { Unit } from 'src/app/model/unit';
import { CurrencyPipe } from '@angular/common';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { ScheduleService } from '../../budget/schedule.service';
import { Schedule } from 'src/app/model/schedule';

@Component({
    selector: 'app-debtors-analysis',
    standalone: true,
    imports: [FormPageComponent, CurrencyPipe],
    templateUrl: './debtors-analysis.component.html',
    styleUrl: './debtors-analysis.component.scss'
})
export class DebtorsAnalysisComponent extends AbstractPageComponent {
    static readonly navRoute = new NavRoute('debtorsAnalysisList', DebtorsAnalysisComponent, 'sports').setViews(() => {
        return [
            {
                id: 'analysis',
                name: 'Analysis of outstanding monies',
                filterFields: {
                    analysisOnly: 'true'
                }
            }
        ]
    });

    scheduleSvc = inject(ScheduleService);
    schedules: Schedule[] = [];


    analysisField = FieldMaker.idHolder('analysisOnly');
    bCodeField = FormTextComponent.make('Budget Code', 'name');
    totalField = FormNumberComponent.makeCurrency('Total', 'total');

    fieldSet = new FieldSet({
        fields: [
            this.bCodeField,
            this.totalField,
            this.analysisField,
        ],
        formLayout: LAYOUT_OPTIONS.singleCol,
    });

    config = new FormConfig({
        navRoute: DebtorsAnalysisComponent.navRoute,
        title: $localize`Debtors Analysis`,
        help: $localize`Whatever`,
        fieldSet: this.fieldSet,
        service: this.debtorsListSvc,
        mode: 'list',
        beforeList: this.beforeList.bind(this),
        configReady: this.configReady,
    });

    constructor(public debtorsListSvc: DebtorsListService) {
        super();
        this.scheduleSvc.get(true).subscribe((data: Schedule[]) => {
            this.schedules = data;
            data.forEach(schedule => {
                this.fieldSet.fields.push(FormNumberComponent.makeCurrency(schedule.name + ' Debit', schedule.id + 'debit'));
                this.fieldSet.fields.push(FormNumberComponent.makeCurrency(schedule.name + ' Credit', schedule.id + 'credit'));
            });
            this.configReady.next(null);
        });
    }

    beforeList(items: Unit[]) {
        const bcodes = {
            Total: {name: "Total", total: 0}
        }
        this.schedules.forEach( s => {
            bcodes['Total'][s.id + 'debit'] = 0;
            bcodes['Total'][s.id + 'credit'] = 0;
        })

        items.forEach( item => {
            if (!bcodes[item['bCodeId']]) {
                bcodes[item['bCodeId']] = {name: item['bCodeName'], total: 0};
                this.schedules.forEach(s => {
                    bcodes[item['bCodeId']][s.id + 'debit'] = 0;
                    bcodes[item['bCodeId']][s.id + 'credit'] = 0;
                })
            }

            bcodes[item['bCodeId']][item['scheduleId'] + 'debit'] += +item['debit'];
            bcodes[item['bCodeId']][item['scheduleId'] + 'credit'] += +item['credit'];
            bcodes[item['bCodeId']]['total'] += +item['credit'] - +item['debit'];

            bcodes['Total'][item['scheduleId'] + 'debit'] += +item['debit'];
            bcodes['Total'][item['scheduleId'] + 'credit'] += +item['credit'];
            bcodes['Total']['total'] += +item['credit'] - +item['debit'];
        })
        console.warn(bcodes);
        const data = [];
        Object.values(bcodes).forEach( e => data.push(e));
        console.warn(data);
        return data;
    }

    canDeactivate() {
        return true;
    }
}
