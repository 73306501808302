/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { CurrencyPipe, formatPercent } from '@angular/common';
import { Component, Inject, Optional } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import moment from 'moment';
import { ReplaySubject, combineLatest, Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Attachment } from 'src/app/model/attachment';
import { BCode } from 'src/app/model/bcode';
import { Cycle } from 'src/app/model/cycle';
import { Field } from 'src/app/shared/field/Field';
import { Period } from 'src/app/model/period';
import { Schedule } from 'src/app/model/schedule';
import { PreferredSupplier } from 'src/app/model/supplier';
import { Txn } from 'src/app/model/txn';
import { DateHelper } from 'src/app/shared/dateHelper';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { AttachmentListComponent } from 'src/app/shared/form/file/attachment-list/attachment-list.component';
import { AttachmentService } from 'src/app/shared/form/file/attachment.service';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormError } from 'src/app/shared/form/form-error/form-error.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { GridField } from 'src/app/shared/grid/grid-field';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { validateTxnDate, validateDate, required, nonZero } from 'src/app/shared/validators';
import { BCodeService } from '../../budget/bcode.service';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { ScheduleService } from '../../budget/schedule.service';
import { CurrentUserService } from '../../user/current-user.service';
import { PurchaseService } from '../purchase.service';
import { PrepaymentLedgers } from './prepaymentLedgers';
import { FormDateTimeComponent } from 'src/app/shared/form/form-date-time/form-date-time.component';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { NavRoute } from 'src/app/shared/NavRoute';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { SupplierService } from '../../supply/supplier.service';
import { User } from 'src/app/model/user';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export class PurchaseDialogOptions {
    supplier: PreferredSupplier;
}

@Component({
    selector: 'app-purchase-page',
    templateUrl: './purchase-page.component.html',
    styleUrls: ['./purchase-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent, CurrencyPipe]
})
export class PurchasePageComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute(
        Txn.TYPE.PURCHASE.code, PurchasePageComponent, 'shopping_basket').setViews((u: User) => {
        return [
            {
                id: 'outstanding',
                name: 'All Outstanding',
                filterFields: {
                    outstanding: '>=0.01',
                },
                sort: 'createdAt'
            },
            {
                id: 'unpaidUnApproved',
                name: 'Invoices for approval',
                filterFields: {
                    outstanding: '>=0.01',
                    approvedAt: 'null'
                },
                sort: 'createdAt'
            },
            {
                id: 'unpaidApproved',
                name: 'Approved Invoices',
                filterFields: {
                    outstanding: '>=0.01',
                    approvedAt: 'not null'
                },
                sort: 'createdAt'
            },
            {
                id: 'currentYear',
                name: 'Current Year',
                filterFields: {
                    txnCycleId: u.currentTeam.currentPeriod?.cycleId,
                    txnPeriodId: 'All',
                },
            }
        ]
    });

    readonly path = Txn.TYPE.PURCHASE.code

    hasPrepays = false;
    configReady = new ReplaySubject<null>(1);
    currentCycle: Cycle;
    cycles: Cycle[] = [];
    periods: Period[] = [];
    schedules: Schedule[];
    bcodes: BCode[];
    creditors: BCode;
    accruals: BCode;
    accrualEntry: GridRow;
    prepays: BCode;
    expenseCodes: BCode[] = [];

    itemTotal: 0;
    itemOutstanding: 0;

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { items: this.cycles, refreshes: ['periodId'] }, { formColumn: 2, visible: Field.noShow }
    );

    periodField: PicklistField = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { items: this.periods },
        {
            formColumn: 2, visible: Field.noShow,
            refresh: (o: Cycle, control: AppPicklistControl) => { if (o) { control.field.picklist.items = o.periods; control.setValue(null); } },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' }, formColumn: 1,
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    accountField = FormPicklistComponent.make('Type', 'bCodeId', 'bCode', { items: [] }, { formColumn: 2, visible: Field.noShow });

    svcFromField = FormDateComponent.make('Services From', 'svcFrom', {
        cellOpts: { width: '2%' }, formColumn: 2,
        validators: [this.validateServiceDates.bind(this)],
        valueChanges: this.handlePrepays.bind(this),
    });

    svcToField = FormDateComponent.make('Services To', 'svcTo', {
        cellOpts: { width: '2%' }, formColumn: 2,
        validators: [this.validateServiceDates.bind(this)],
        valueChanges: this.handlePrepays.bind(this),
    });

    creditField = FormNumberComponent.make("Total", "credit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 2, readonly: true, validators: [nonZero] }
    );

    outstandingField = FormNumberComponent.make("Outstanding", "outstanding",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 3, readonly: true, disable: true }
    );

    attachedFiles = AttachmentListComponent.make('Attached Invoice', 'attachments',
        { service: this.dataSvc, allowNew: true, smallAddBox: true },
        { formColumn: 4, valueChanges: this.invoiceValueChanges.bind(this) }
    );

    supplierField = FormComboBoxComponent.make('Supplier', 'supplierId', 'supplier',
        { service: this.supplierSvc, refreshes: [this.supplierChanges.bind(this)] },
        { validators: [required] }
    );

    /** Hidden details to pass the actual transactions to the server */
    detailsField = FormTextComponent.make('details', 'details', { visible: Field.noShow });

    lineItemsField = new GridField({
        field:
        {
            label: $localize`Purchase Details`, value: 'lineItems', sendServer: false,
            visible: Field.formOnly, formRow: 2
        },
        rowFactory: this.childRowFactory.bind(this),
        objFactory: this.newChildTxn.bind(this),
    });

    journalGridField = new GridField({
        field:
        {
            label: $localize`Accruals & Prepayments`, value: 'journalItems',
            visible: Field.noShow, formRow: 2, readonly: true, sendServer: false
        },
        rowFactory: this.journalItemRowFactory.bind(this),
    });

    paymentGrid = new GridField({
        field:
            { label: 'Payments Made', value: 'relations', visible: Field.formOnly, formRow: 2, formColumn: 2, sendServer: false },
        rowFactory: (o: Txn) => [
            FormButtonComponent.makeLink('Ref', 'id', o ? Txn.getTxnLink(o) : '', {
                readonly: true, type: 'link', sendServer: false,
                cellOpts: { width: "3em", maxWidth: "3em" },
            }),
            FormDateComponent.make('Date', 'txnDate', { cellOpts: { width: '2%' }, formColumn: 2 }),
            FormNumberComponent.make("Amount", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
            FieldMaker.notes(),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow(),
        ]
    });

    approvedField = FormDateTimeComponent.make('Approved For Payment', 'approvedAt', { readonly: true, sendServer: false });
    approvedByField = FormTextComponent.make('Approved By', 'approverName', {disable: true, formColumn: 2, sendServer: false})

    refField = FormNumberComponent.make('Reference', 'refNr', {}, { cellOpts: { heading: 'Ref' }, readonly: true, formColumn: 3 });

    config = new FormConfig({
        navRoute: PurchasePageComponent.navRoute,
        title: Txn.TYPE.PURCHASE.name,
        help: $localize`Goods and Services Purchased`,
        readonly: false,
        fieldSet: new FieldSet({
            fields: [

                FieldMaker.id(), // this.idField,
                FieldMaker.rev(), //this.revField,
                this.refField,
                this.supplierField,

                FormTextComponent.make('globalSupplierId', 'globalSupplierId', {
                    visible: Field.noShow,
                    refresh: (o: PreferredSupplier, ctl: AppFormControl) => ctl.setValue(o.globalSupplierId)
                }),
                FormTextComponent.make('Supplier Reference', 'reference', { validators: [required] }),
                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),

                /* txtDateField Validator overwrites period info, need this order! */
                this.cycleField,
                this.periodField,
                this.txnDateField,
                this.approvedField,
                this.accountField,

                this.svcFromField,
                this.svcToField,

                this.creditField,
                this.approvedByField,
                FieldMaker.notes({ formColumn: 3 }),
                this.outstandingField,

                this.attachedFiles,

                this.lineItemsField,
                this.journalGridField,
                this.paymentGrid,
                this.detailsField,
            ],
            formValidator: this.noPrepaidAccruals.bind(this),
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.beforeEdit.bind(this),
        beforeSave: this.beforeSave.bind(this),
        getConfirmSaveMessage: this.getConfirmSaveMessage.bind(this),
        deleteMsg: Txn.DELETE_MSG,
        deleteReasonNeeded: true,
        afterSave: this.afterSave.bind(this),
        actions: this.getActions(),
        beforeList: this.beforeList.bind(this)
        /*
        newOptions: [
            { name: $localize`New ${Txn.TYPE.PURCHASE.name}`, basePath: `${Txn.TYPE.PURCHASE.code}/NEW`},
            { name: $localize`New ${Txn.TYPE.PURCHASE_CN.name}`, basePath: `${Txn.TYPE.PURCHASE_CN.code}/NEW` }
        ]*/
    });

    constructor(public dataSvc: PurchaseService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute, private router: Router,
        protected supplierSvc: SupplierService,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService, private attachmentSvc: AttachmentService,
        protected scheduleSvc: ScheduleService,
        protected cds: ConfirmDialogService, private currentUserSvc: CurrentUserService,
        @Optional() public dialogRef: MatDialogRef<PurchasePageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public purchaseDialogOptions: PurchaseDialogOptions)  {

        super();

        combineLatest([currentUserSvc.getCurrentUser(),
            this.scheduleSvc.get(true).pipe(first()),
            this.bCodeSvc.get(true).pipe(first()),
            this.periodSvc.get(true).pipe(first()),
            this.cycleSvc.get(true),
            this.currentUserSvc.getDefaultBCodes()
        ]).subscribe(
            ([currentUser, schedules, bcodes, periods, cycles]) => {
                this.currentUser = currentUser;
                this.cycles = (cycles as Cycle[]);
                this.cycleField.picklist.items = this.cycles;
                this.currentCycle = cycleSvc.getCurrentCycle();
                this.periodField.picklist.items = this.currentCycle.periods;
                this.currentPeriod = cycleSvc.getCurrentPeriod();
                this.schedules = schedules as Schedule[];
                this.bcodes = bcodes as BCode[];
                this.periods = periods.sort(Period.sorter) as Period[];
                const creditorsId: uuid = this.currentUserSvc.getDefaultBCode('Creditors').bCodeId;
                const accrualsId: uuid = this.currentUserSvc.getDefaultBCode('Accruals').bCodeId;
                const prepaysId: uuid = this.currentUserSvc.getDefaultBCode('PrePayments').bCodeId;

                this.creditors = this.bcodes.find(o => o.id === creditorsId);
                this.accruals = this.bcodes.find(o => o.id === accrualsId);
                this.expenseCodes.push(this.accruals);
                this.prepays = this.bcodes.find(o => o.id === prepaysId);
                //////////console.log({ creditors, accruals, bcodes, currentUser });
                this.accountField.picklist.items = [this.creditors, this.accruals];

                for (const bc of this.bcodes) {
                    if (bc.typeId === BCode.TYPE.EXPENSE.id
                    || bc.typeId === BCode.TYPE.ASSET.id
                    || bc.typeId === BCode.TYPE.CAPITAL.id) {
                        this.expenseCodes.push(bc);
                    }
                }

                this.configReady.next(null);
            }
        );
    }

    beforeList(items: Txn[]) {
        this.itemOutstanding = 0;
        this.itemTotal = 0;
        items.forEach(p => {
            this.itemOutstanding += p.outstanding;
            this.itemTotal += p.credit;
        });
        return items;
    }

    noPrepaidAccruals() {
/** For now, no accruals allowed at all, need to re-enable create accrual if no invoice, when can "unaccrue" */
        const value = this.attachedFiles.control?.value;
        const deferred = this.attachedFiles.fileOpts.deferredFiles;
        if (value && value.length === 0 && deferred && deferred.length === 0) {
            //console.warn('Error', {field: this.attachedFiles, value, deferred});
            return FormError.reportError('noPrepaidAccruals',
                $localize`You must attach a copy of the purchase invoice`);
        }
        return null;
    }

    supplierChanges(o: PreferredSupplier) {

        this.supplierSvc.getOne(o.id, null).subscribe( ps => {
            const txns = [... (this.lineItemsField.control as GridControl).gridRows()];

            if (txns) {
                for (const tRow of txns) {
                    tRow.delete();
                }
            }

            for (const st of ps.template) {
                const childTxn = this.createChildItem();
                childTxn.bCode = this.bcodes.find( o => o.id === st.bCodeId);
                childTxn.bCodeId = st.bCodeId;
                childTxn.schedule = this.schedules.find( o => o.id === st.scheduleId );
                childTxn.scheduleId = st.scheduleId;
                console.log({childTxn, ps});
                this.lineItemsField.control.addRow(childTxn, true, false);
            }
            this.invoiceValueChanges();
        });
    }

    beforeSave() {
        const details: AbstractObject[] = [];
        if (this.accrualEntry) {
            details.push(this.accrualEntry.getFormValue());
        }
        for (const ctl of this.lineItemsField.control.gridRows()) {
            const o = (ctl.getFormValue() as Txn);
            if (ctl.attachedData) {
                o.journalItems = (ctl.attachedData as PrepaymentLedgers).getFormValue();
            }
            if (o.debit || o.id || o.notes) {
                details.push(o);
            }

        }
        this.detailsField.control.setValue(details);
    }

    beforeEdit(o: Txn) {
        this.supplierField.control.disable();
        this.paymentGrid.visible = Field.formOnly;
        this.hasPrepays = false;
        if (o === null) {
            o = new Txn(); // Item was not found, will be reported - avoid any further errors
        }
        o.lineItems = [];
        o.journalItems = [];
        for (const d of o.details) {
            if (d.typeId === Txn.TYPE.PURCHASE_ITEM.id) {
                o.lineItems.push(d);
            } else {
                o.journalItems.push(d);
                if (d.typeId === Txn.TYPE.PREPAY_ASSET.id) {
                    this.hasPrepays = true;
                }
            }
        }
        if (o.journalItems.length > 0) {
            this.journalGridField.visible = Field.formOnly;
        }
        return o;
    }

    /*
    * After saving, if there was an attachment, and we are adding another, we probably want to go back to purchase inbox.
    */
    afterSave() {
        const cp = this.page.form.currentParams;
        if (this.config.mode === 'new' && cp && cp['attach']) {
            console.warn('Back to uploads');
            this.router.navigate([Txn.TYPE.PURCHASE.code + '/uploads']);
        }
    }

    journalItemRowFactory(): Field[] {
        return [
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.formOnly }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('relatedId', 'relatedId', { visible: Field.noShow }),

            FormPicklistComponent.make('Type', 'typeId', 'type',
                { items: Txn.TYPES },
                { cellOpts: { width: '20em' } }),

            FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
                { items: this.periods },
                { cellOpts: { width: '20em' } }),

            FormPicklistComponent.make('Account', 'bCodeId', 'bCode',
                {
                    items: this.expenseCodes,
                },
                { cellOpts: { width: '20em' } }),

            FormPicklistComponent.make('Schedule', 'scheduleId', 'bCode',
                { items: this.schedules },
                { cellOpts: { width: '20em' } }),

            FormNumberComponent.make("Debit", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
            FormNumberComponent.make("Credit", "credit", { format: 'currency', width: 9, formatParms: '1.2-2' }),
            FieldMaker.notes({ cellOpts: { width: '30em' } }),
            FieldMaker.rev(),
        ];
    }

    childRowFactory(): Field[] {
        return [
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),

            FormComboBoxComponent.make('Account', 'bCodeId', 'bCode',
                {
                    items: this.expenseCodes,
                    refreshes: [this.invoiceValueChanges.bind(this)]
                },
                { cellOpts: { width: '20em' } }),

            FormPicklistComponent.make('Schedule', 'scheduleId', 'bCode',
                { items: this.schedules },
                { cellOpts: { width: '20em' }, validators: [required] }),

            FormNumberComponent.make("Amount", "debit", { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.invoiceValueChanges.bind(this) }
            ),
            FormTextComponent.make('Description', 'notes', { cellOpts: { width: '30em' } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow({
                clickMethod: (ctl: AppFormControl) => {
                    ctl.getRow().delete();
                    this.invoiceValueChanges();
                }
            }),
        ]
    }


    newFactory(o: Params): Observable<Txn> {
        this.supplierField.control.enable();

        this.paymentGrid.visible = Field.noShow;
        this.hasPrepays = false;
        this.config.readonly = false;

        const txn = new Txn();

        txn.ledgerId = Txn.LEDGER.AP.id;
        txn.typeId = Txn.TYPE.PURCHASE.id;
        if (this.dialogRef && this.purchaseDialogOptions.supplier) {
            txn.supplierId = this.purchaseDialogOptions.supplier.id;
            this.supplierChanges(this.purchaseDialogOptions.supplier);
        }

        txn.bCodeId = this.currentUserSvc.getDefaultBCode('Creditors').bCodeId;
        //txn.bCode = this.currentUser.currentTeam.creditorsBCode;

        txn.txnDate = moment().toISOString().substring(0, 10);
        txn.txnCycleId = this.currentCycle.id;
        txn.txnPeriodId = this.currentPeriod.id;

        txn.svcFrom = '';
        txn.svcTo = '';

        if (o?.attach) {
            return this.attachmentSvc.getOne(o.attach, null).pipe(first()).pipe(
                map((a: Attachment) => {
                    if (a) {
                        if (!a.relatedId) {
                            txn.attachments.push(a);
                        } else {
                            console.warn('Already Attached', { a })
                            this.cds.alert($localize`Already Attached`,
                                $localize`Cannot attach to new invoice, already attached to another transaction`
                            );
                        }
                    }
                    return txn;
                })
            );
        } else {
            return of(txn);
        }
    }

    createChildItem(): Txn {
        const txn = new Txn();
        txn.ledgerId = Txn.LEDGER.AP.id;
        txn.typeId = Txn.TYPE.PURCHASE_ITEM.id;
        txn.txnDate = this.txnDateField.control.value;
        txn.txnPeriodId = this.periodField.control.value;
        txn.txnCycleId = this.cycleField.control.value;
        if (this.schedules.length === 1) {
            txn.scheduleId = this.schedules[0].id;
        }
        return txn;
    }

    newChildTxn(): Observable<Txn> {

        return of(this.createChildItem());
    }

    dateValueChanges(newValue: number): void {
        const allocations = (this.lineItemsField.control as GridControl).controls;
        console.log(newValue, this.txnDateField.control);
        if (allocations) {
            for (const txnRow of allocations) {
                console.log(txnRow);
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            }
        }
    }

    getConfirmSaveMessage(): string {
        if (!this.hasAttachments()) {
            // No pre attached files
            return $localize`
            You have not added an invoice. Save this as an accrual? Click cancel to go back and add the attachment`;
        }
        return null;
    }

    private hasAttachments() : boolean {
        if (this.attachedFiles?.control.value.length > 0 || this.attachedFiles.fileOpts.deferredFiles?.length > 0) {
            return true;
        }
        return false;
    }

    getActions(): IFormAction[] {
        return [new ApproveForPaymentAction(), new UnApproveForPaymentAction()];
    }

    private setAccrualEntry() {
        if (!this.accrualEntry) {
            for (const row of this.journalGridField.control.gridRows()) {
                if (row.get('typeId').value === Txn.TYPE.PURCHASE_ACCRUAL.id) {
                    this.accrualEntry = row;
                }
            }
            if (!this.accrualEntry) {
                const txn = new Txn();
                txn.ledgerId = Txn.LEDGER.AP.id;
                txn.typeId = Txn.TYPE.PURCHASE_ACCRUAL.id;
                this.accrualEntry = this.journalGridField.control.addRow(txn, true, true);
            }
        }
    }

    private doAccrueDebt(total: number, paid: number) {
        if (!this.hasAttachments()) {
            // No invoice - This is an accrual
            this.setAccrualEntry();
            this.accrualEntry.get('txnDate').setValue(this.txnDateField.control.value);
            this.accrualEntry.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
            this.accrualEntry.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            this.accrualEntry.get('bCodeId').setValue(this.accruals.id, { emitEvent: false });
            this.accrualEntry.get('scheduleId').setValue(null, { emitEvent: false });
            this.accrualEntry.get('credit').setValue(total, { emitEvent: false }); // Rounding!!!
            this.accrualEntry.get('debit').disable({ emitEvent: false });

            this.creditField.control.setValue(0);
            this.outstandingField.control.setValue(0);

        } else {
            this.creditField.control.setValue(total);
            this.outstandingField.control.setValue(total - paid);
            if (this.accrualEntry) {
                this.accrualEntry.delete();
                this.accrualEntry = null;
            }
        }

        const prepayOrAccrueTxn = this.journalGridField.control.gridRows();
        if (prepayOrAccrueTxn.length > 0) {
            this.journalGridField.visible = Field.formOnly;
        } else {
            this.journalGridField.visible = Field.noShow;
        }
    }

    invoiceValueChanges(): void {
        const start = new Date().getTime();
        if (!this.lineItemsField.control || !this.paymentGrid.control) {
            return null;
        }
        const txns = (this.lineItemsField.control as GridControl).gridRows();
        let total = 0;
        let prepays = 0

        if (txns) {
            for (const tRow of txns) {
                const acctId = ((tRow.get('bCodeId') as AppFormControl).value);
                const schedId = ((tRow.get('scheduleId') as AppFormControl).value);
                const sched = this.schedules.find(o => o.id === schedId);
                const acct = this.bcodes.find(o => o.id === acctId)
                if (acct?.prepaid) {
                    prepays++;
                }
                const amt = tRow.get('debit').value;
                total += amt;
                this.checkForSplitNeeded(tRow, acct, sched, amt);
            }
        }

        this.hasPrepays = (prepays > 0);
        this.svcFromField.control.updateValueAndValidity();
        this.svcToField.control.updateValueAndValidity();
        this.handlePrepays();

        const pays = this.paymentGrid.control.gridRows();
        let paid = 0;
        if (pays) {
            for (const pay of pays) {
                paid += pay.get('debit').value;
            }
        }

        this.doAccrueDebt(total, paid);


        const completed = new Date().getTime();
        console.log('Calculation Completed in ' + (completed - start) + 'ms');
    }

    checkForSplitNeeded(tRow: GridRow, acct: BCode, sched: Schedule, amt: number) {

        console.log({ acct, sched, amt });
        if (amt > 0 && (sched === undefined) && acct.schedule.length > 1) {
            let scheds = '';
            for (const bs of acct.schedule) {
                if (scheds.length > 0) {
                    scheds += ', ';
                }
                const sched = this.schedules.find(o => o.id === bs.scheduleId);
                const pct = formatPercent(bs.percent, Field.getLang());
                scheds += sched.name + ' (' + (pct) + ')';
            }
            const msg = $localize`Transactions for ${acct.name} are normally distributed as ${scheds}. Do you wish to do so`;
            this.cds.open($localize`Confirm Split`, msg, () => {
                const total = amt;
                for (const bs of acct.schedule) {
                    const txn = this.createChildItem();
                    txn.bCodeId = acct.id;
                    txn.bCode = acct;
                    txn.scheduleId = bs.scheduleId;
                    txn.schedule = this.schedules.find(o => o.id === bs.scheduleId);
                    txn.debit = total * bs.percent;
                    this.lineItemsField.control.addRow(txn, true, false);
                }
                tRow.delete();
            }, $localize`Split`);
        }
    }

    handlePrepays() {
        if (!this.hasPrepays) {
            return;
        }
        if (this.svcFromField.control.invalid || this.svcToField.control.invalid) {
            console.warn('Service dates invalid, cannot calculate yet');
            return;
        }

        const fromDate = this.svcFromField.control.value;
        const toDate = this.svcToField.control.value;

        const daysBetween = DateHelper.isoDaysBetween(fromDate, toDate);

        const txns = (this.lineItemsField.control as GridControl).gridRows();

        for (const tRow of txns) {
            const acctId = ((tRow.get('bCodeId') as AppFormControl).value);
            const schedId = ((tRow.get('scheduleId') as AppFormControl).value);
            const sched = this.schedules.find(o => o.id === schedId);
            const acct = this.bcodes.find(o => o.id === acctId);
            const amount = tRow.get('debit').value as number;
            if (acct.prepaid) {
                this.journalGridField.visible = Field.formOnly;
                if (!tRow.attachedData) {
                    tRow.attachedData = new PrepaymentLedgers()
                }
                (tRow.attachedData as PrepaymentLedgers).setup(
                    tRow.get('id').value, this.txnDateField.control.value, acct, sched,
                    this.prepays, amount, fromDate, toDate, this.periods, this.journalGridField
                );
            }
        }

        console.log({ daysBetween, fromDate, toDate });
    }

    validateServiceDates(o: AppFormControl) {
        let validationError = null;
        if (!this.svcFromField.control || !this.svcToField.control) {
            return null; // not fully initialized yet...
        }
        //console.log('Validating service dates...', {prepays: this.hasPrepays, ctl: o, value: o.value});
        if (this.hasPrepays) {
            validationError = validateDate()(o);
        } else {
            if (Field.isEmpty(o.value)) {
                validationError = null;
            } else {
                validationError = validateDate()(o);
            }
        }

        if (!Field.isEmpty(this.svcFromField.control?.value)
            && !Field.isEmpty(this.svcToField.control?.value)
            && !validationError) {
            if (this.svcToField.control.value <= this.svcFromField.control.value) {
                validationError = FormError.reportError('serviceDatesRangeWrong',
                    $localize`Service to date must be after service from date`);
            }
        }

        const fromDate = this.svcFromField.control.value;
        const toDate = this.svcToField.control.value;
        let hasStart = false;
        let hasEnd = false;

        if (!Field.isEmpty(this.svcFromField.control?.value)
            && !Field.isEmpty(this.svcToField.control?.value)
            && !validationError && this.hasPrepays) {
            for (const p of this.periods) {
                if (p.from <= toDate && p.to >= fromDate) {
                    if (!Period.open(p)) {
                        validationError = FormError.reportError('includesClosedPeriods',
                            $localize`Service dates include closed period ${p.name}`);
                        break;
                    }
                    if (p.from <= fromDate && p.to >= fromDate) {
                        hasStart = true;
                    }
                    if (p.from <= toDate && p.to >= toDate) {
                        hasEnd = true;
                    }
                }
            }
        }

        if (!hasStart && !validationError && this.hasPrepays) {
            validationError = FormError.reportError('beforeFinancialStart',
                $localize`Service starts before any financial periods are available to process it`);
        }
        if (!hasEnd && !validationError && this.hasPrepays) {
            validationError = FormError.reportError('afterFinancialEnd',
                $localize`Service ends after last financial periods available to process it`);
        }

        if (o.field.name === 'svcFrom' && !validationError && this.svcToField.control.invalid) {
            this.svcToField.control.updateValueAndValidity();
        } else if (o.field.name === 'svcTo' && !validationError && this.svcFromField.control.invalid) {
            this.svcFromField.control.updateValueAndValidity();
        }

        return validationError;
    }
}
class ApproveForPaymentAction implements IFormAction {
    name = $localize`Approve`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'thumb_up';
    approvalNeeded = true;
    approvalText = $localize`Approve this invoice to be paid to vendor`;
    txn: Txn;

    action(txn: Txn, config: FormConfig): Observable<Txn> {
        return (config.service as PurchaseService).approve(this.txn);
    }

    setup(txn: Txn) {
        this.txn = txn;
        this.show = txn.refNr > 0 && !txn.approvedAt;
    }
}
class UnApproveForPaymentAction implements IFormAction {
    name = $localize`Remove Approval`;
    color: ActionColor = 'accent';
    show = false;
    icon = 'thumb_down';
    approvalNeeded = true;
    approvalText = $localize`Remove the approval to pay this invoice`;
    txn: Txn;

    action(txn: Txn, config: FormConfig): Observable<Txn> {
        return (config.service as PurchaseService).unapprove(this.txn);
    }

    setup(txn: Txn) {
        this.txn = txn;
        this.show = (txn.refNr > 0 && txn.approvedAt > 0);
    }
}
