/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
* Describes a field to be displayed on a Form, Grid or Table
*/
import { ViewContainerRef } from '@angular/core';

import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { AbstractObject } from '../../model/abstract-object';
import { AsyncValidatorFn, ValidatorFn } from '@angular/forms';

export type ControlVisibility = {
    phone: boolean;
    computer: boolean;
    form: boolean;
};

export class Footer {
    text?: string;
    style?: string;
}

export class CellOpts {
    /** Heading to use on Tables & Grids, if not provided falls back to field.label if available, otherwise field.name */
    heading? = '';
    /** CSS Style information to be added to the <TD> style attribute of Grids & Tables */
    style?: string;
    /** width attribute for <TD> and <TH>  */
    width?: string = null;
    /** Minimum Width for <TD> and <TH> */
    minWidth?: string = null;
    /** Maximum Width for <TD> and <TH> */
    maxWidth?: string = null;
    /** Displays simple fixed text in a gridCell */
    displayText?: string;
    /** Styling for the header cell (<th />) */
    thStyle?: string
    thColspan?: number;
}

class Filter {
    active = false;
    value: string | number | boolean;
    priorPhone?: boolean;
    priorComputer?: boolean;
}

export type FieldType = 'limitTo' | 'icon' | 'picklist' | 'date' | 'dateTime' | 'link' | 'boolean' | 'number' | 'primary' | 'chart' | 'fieldSet'
    | 'text' | 'grid' | 'email' | 'url' | 'phone' | 'fileUpload' | 'richText' | 'radio' | 'trend' | 'image' | 'notes'
/**
 * Class definition - Everything we display on screen is a field!
 *
 */


export class Field {

    /*
    * Use static function getters so that same instance of ControlVisibiiity object not repeatedly reassigned
    */
    //static formOnly: ControlVisibility = { form: true, phone: false, computer: false };
    static get formOnly() {
        return { form: true, phone: false, computer: false };
    }
    //static noShow: ControlVisibility = { form: false, phone: false, computer: false };
    static get noShow() {
        return { form: false, phone: false, computer: false };
    }
    //static showAll: ControlVisibility = { form: true, phone: true, computer: true };
    static get showAll() {
        return { form: true, phone: true, computer: true };
    }

    //static noForm: ControlVisibility = { form: false, phone: true, computer: true };
    static get noForm() {
        return { form: false, phone: true, computer: true };
    }

    //static noPhone: ControlVisibility = { form: true, phone: false, computer: true };
    static get noPhone() {
        return { form: true, phone: false, computer: true };
    }

    static readonly locale = 'en-IE';
    static readonly currencySymbol = '€';
    static readonly currencyCode = 'EUR';

    /**  Name to be used to uniquely identify this control on a form or GridRow */
    name: string;
    type?: FieldType = 'text';

    /** The name of the value to take from object, load into the FormControl, and name to send it to the server as */
    value: string;

    /** Disable editing of this control - And with material controls fade the display */
    /*
    _disable = false;
    set disable(b: boolean) {
        this._disable = true;
    }
    get disable() {
        return this._disable;
    }
    */
    disableControl() {
        this.disable = true;
        this.control.disable({ emitEvent: false });
    }

    enableControl() {
        this.disable = false;
        this.control.enable({ emitEvent: false });
    }

    disable = false;

    /**
     * Make this field readonly (disabled controls are greyed out, readonly fields are not colored)
     * This field gets updated dynamically, if the form is set to readonly, this will be overridden to readonly
     * defaultReadonly retains the original setting and the one to use when the form is edittable
     * */
    readonly = false;
    defaultReadonly = false;

    /** Optionally we can pass a calculate value function, which if supplied will get the value instead of using value property */
    calculateValue: (item: AbstractObject, field: Field) => unknown;

    /** Label to use when displaying this field on forms */
    label: string;

    /**  An Icon to use if displaying this field as a tab*/
    tabIcon: string = null;

    /** The row and column of the form that we want this field to be displayed on
     * Remember, if the formRow and FormCol do not exist in your form layout, then field will not be displayed anywhere! */
    formRow = 1;
    formColumn = 1;

    /* Hint to display on forms */
    hint: string;

    /** Tooltip to display on Grids - sometimes, //TODO cleanup tooltip handling! */
    toolTip?: string;

    /** autoComplete turned off by default, pass 'on' to enable */
    autoComplete = 'nope';

    /** Is there an active filter on this field */
    filter: Filter = new Filter();
    allowFiltering = true;

    /** By default on save all fields will be sent to server, set to false not to send to server */
    sendServer = true;

    cellOpts = new CellOpts();

    validators: ValidatorFn[] = [];
    asyncValidators: AsyncValidatorFn[] = [];

    /** Gets Bound to: https://angular.io/api/forms/AbstractControl#valueChanges */
    valueChanges: (newValue, field: Field) => void;

    /** Gets Bound to: https://angular.io/api/forms/AbstractControl#statusChanges */
    statusChanges: (newStatus, field: Field) => void;

    control: AppFormControl | GridControl;

    footer: Footer = new Footer();

    visible: ControlVisibility = {
        phone: true,
        computer: true,
        form: true,
    };

    static isEmpty(val: unknown): boolean {
        if (val === null || val === undefined || val === '') {
            return true;
        } else {
            return false;
        }
    }

    /** The maximum length of text to be displayed in a table (Only Applies to 'LimitTo' fields) */
    maxLength?: number;

    formControlFactory: (vcr: ViewContainerRef, ctl: (AppFormControl | GridControl), mode: ControlOn) => void;

    constructor(defaultOptions: Partial<Field>, moreOptions: Partial<Field> = {}) {

        this.initFromPartial(defaultOptions, this);
        this.initFromPartial(moreOptions, this);

        if (this.control) {
            console.error('Fields should not be initialised with controls already set!', this);
        }

        if (!this.name && this.value) {
            /* Use value for the name of control, so that is what it is sent to server as and the name in tables */
            this.name = this.value;
        }

        this.defaultName();
        this.defaultLabels();

        if (this.readonly) {
            this.defaultReadonly = true;
        }
    }

    setReadonly(on = true) {
        this.defaultReadonly = on;
        this.readonly = on;
    }

    protected defaultName() {
        if (this.name) {
            while (this.name.indexOf('.') > 0) {
                this.name = this.name.replace('.', '_'); // FormGroup.get cannot get controls with '.' in the name
            }
        }
    }

    protected defaultLabels() {
        if (!this.label && this.label !== '') {
            this.label = (this.cellOpts.heading ? this.cellOpts.heading : this.name);
        }

        if (!this.cellOpts.heading) {
            this.cellOpts.heading = (this.label ? this.label : this.value);
        }
    }

    initFromPartial(data: unknown, onto: unknown = this) {
        Object.getOwnPropertyNames(data).forEach((prop) => {
            if (typeof data[prop] === 'object' && data[prop] !== null && !Array.isArray(data[prop])) {
                this.initFromPartial(data[prop], onto[prop]);
            } else {
                onto[prop] = data[prop];
            }
        });
        return onto;
    }

    override(data: Partial<Field>): this {
        // WARNING - OVERRIDING READONLY DOES NOT SEEM TO WORK, JUST DOES NOT GET SET!
        const ret = this.initFromPartial(data) as Field;
        if (data.readonly) {
            ret.defaultReadonly = true;
        }
        return ret as this;
    }

    cellStyle(style: string) {
        this.cellOpts.style = style;
        return this;
    }

    hide() {
        this.visible.phone = false;
        this.visible.computer = false;
        this.visible.form = false;
        return this;
    }

    show() {
        this.visible.phone = true;
        this.visible.computer = true;
        this.visible.form = true;
        return this;
    }

    static shallowCopy(data: AbstractObject, onto: AbstractObject) {
        Object.getOwnPropertyNames(data).forEach((prop) => {
            onto[prop] = data[prop];
        });
    }

    /**
     * Retrieve the initial value of this field (e.g. to put it into the FormControl)
     *
     **** If field has a calculated value, then the function will be called.
     *
     **** Simple "value" will return the value of that property from the item passed,
     * e.g. value = property, item.property is returned
     *
     **** Embedded properties, like "myPropertyObject.myChildObject.property" can be resolved
     *
     **** Array values with the syntax "myPropertyArrayOfObjects[objectProperty=value]myProperty"
     * Can be resolved, scanning the 'myPropertyArrayOfObjects', to find the matching object
     * where 'objectProperty' matches a specified 'value'. 'myProperty' is then selected from the object
     *
     * @param item - The data item from which to calculate the value of this field
     * @returns - The value for this field
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getValue(item: AbstractObject): any {
        //console.log(item, parentItem, this);
        if (this.calculateValue) {
            return this.calculateValue(item, this);
        } else {
            return this.resolveValue(this.value, item);
        }
    }

    resolveValue(value, item) {
        return value.split(/\.|\[|\]/).reduce((o, i) => {
            if (o) {
                // console.log('Resolving ' + i + ' of ' + this.value + ' as ', this.getObjParm(o, i), item);
                return this.getObjParm(o, i);
            } else {
                // console.warn('Could not resolve ' + this.value, item);
                return '';
            }
        }, item);
    }

    getObjParm(item, fld) {
        fld = fld.trim();
        if (Array.isArray(item)) {
            const flds: string[] = fld.split('=');
            if (flds.length !== 2) {
                console.error('Do not know how to resolve ' + fld + ' of ' + this.value, this);
            }
            return item.find(i => i[flds[0].trim()] === +flds[1]);

        } else {
            return item[fld];
        }
    }

    //getVal(o, i) { return o[i]; }

    getFormattedValue(item: AbstractObject) {
        return this.formatValue(this.getValue(item));
    }

    getCurrentFormattedValue() {
        return this.formatValue(this.control.value);
    }

    /** Format a raw value into a string value for display purposes */

    formatValue(val) {
        return val;
    }

    static getLang() {
        if (navigator.languages !== undefined) {
            return navigator.languages[0];
        }
        return navigator.language;
    }

    inRowCol(iRow: number, iCol: number) {
        if (this.visible.form && this.formRow === iRow && this.formColumn === iCol) {
            return true;
        } else {
            return false;
        }
    }

    getTabLabel(): string {
        return this.label;
    }

    /** getTabChip of GridField need value passed into it */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getTabChip(value: unknown): string {
        return '';
    }

    refresh(o: AbstractObject, control: AppFormControl) {
        console.warn('Refreshing - But no refresh method provided in field definition ', control, o);
    }

    getToolTip() {
        if (this.toolTip) {
            return this.toolTip;
        } else {
            return '';
        }
    }

    setValue(item: AbstractObject, readonly: boolean) {
        if (readonly) {
            this.readonly = true;
        } else {
            this.readonly = this.defaultReadonly;
        }
        if (!this.control) {
            console.error('Need to make a control before you can set a value on the field', this);
        }
        //this.readonly = readonly;
        this.control.setValue(this.getValue(item), { emitEvent: false });
        this.control.markAsPristine();
        this.control.markAsUntouched();
    }

    /** Allow chaining of controls with setup done */
    public setupControl() {
        this.makeControl();
        return this;
    }

    // Should this be overridden to create distinct controls? Does not really matter...
    public makeControl(): AppFormControl | GridControl {
        const newCtl: AppFormControl = new AppFormControl(this);
        this.control = newCtl;
        return newCtl;
    }

    public getFormValue() {
        return this.control.value;
    }

}

