/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { ChartParms } from "src/app/model/sys/nav-item";
import { User } from "src/app/model/user";

export const charts : ChartParms[] = [
    { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
    { type: 'bar', picklist: 'omcId', trend: 'weekCompleted'} as ChartParms,
    { type: 'bar', picklist: 'templateId' } as ChartParms,
    { type: 'pie', picklist: 'done', metric: 'timeSpent'} as ChartParms
];

export function views(u: User) { return [
    {
        id: 'myopen',
        name: 'My Open Actions',
        filterFields: {
            ownedById: u.id, done: 0, omcId: 'All'
        },
        sort: 'plannedDate',
        charts: [
            { type: 'bar', picklist: 'templateId'} as ChartParms,
            { type: 'pie', picklist: 'omc_managerId', trend: 'weekCompleted'} as ChartParms,
            { type: 'bar', picklist: 'omcId' } as ChartParms,
        ]
    },
    {
        id: 'myomcopen',
        name: 'My OMCs Open Actions',
        filterFields: {omc_managerId: u.id, done: 0},
        sort: 'plannedDate',
        charts: [
            { type: 'pie', picklist: 'ownedById'} as ChartParms,
            { type: 'bar', picklist: 'omcId', trend: 'weekCompleted'} as ChartParms,
            { type: 'bar', picklist: 'templateId'} as ChartParms,
            { type: 'pie', picklist: 'planned', metric: 'timeSpent'} as ChartParms
        ]
    },
    {
        id: 'myomc_unplanned',
        name: 'My OMCs Unplanned',
        filterFields: {omc_managerId: u.id, planned: 0},
        sort: 'id',
        charts : [
            { type: 'bar', picklist: 'omcId', trend: 'weekCompleted' } as ChartParms,
            { type: 'bar', picklist: 'templateId' } as ChartParms,
            { type: 'pie', picklist: 'done', metric: 'timeSpent'} as ChartParms
        ]
    },
    {
        id: 'all_open_actions',
        name: 'All Open Items',
        filterFields: { done: 0 },
        sort: 'id',
        charts: [
            { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
            { type: 'bar', picklist: 'omcId' } as ChartParms,
            { type: 'pie', picklist: 'ownedById' } as ChartParms
        ]
    },
    {
        id: 'all_unplanned',
        name: 'All Unplanned',
        filterFields: {planned: 0},
        sort: 'id',
        charts: [
            { type: 'pie', picklist: 'omc_managerId'} as ChartParms,
            { type: 'bar', picklist: 'omcId', trend: 'weekCompleted' } as ChartParms,
            { type: 'bar', picklist: 'templateId' } as ChartParms,
            { type: 'pie', picklist: 'done', metric: 'timeSpent' } as ChartParms
        ]
    },
    {
        id: 'all',
        name: 'All Actions',
        charts: [
            { type: 'pie', picklist: 'omc_managerId' } as ChartParms,
            { type: 'bar', picklist: 'omcId', trend: 'weekCompleted' } as ChartParms,
            { type: 'trend', picklist: 'ownedById', trend: 'weekCompleted' } as ChartParms,
            { type: 'pie', picklist: 'done', metric: 'timeSpent' } as ChartParms
        ]
    }
]}
