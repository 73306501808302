/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage, RoleHomePageComponent } from '../role-home-page/role-home-page.component';

@Component({
  selector: 'app-residents-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './residents.component.html',
})
export class ResidentsHomePageComponent {
    role: RoleHomePage = {
        title: 'Residents are part of OurOMC',
        mainText: `<p>
            Residents are the lifeblood of the community, but often managing agents are too busy
            and rightly don't believe interacting with residents is part of their job.
            OurOMC allows non resident owners to specify the contact details for the occupiers of their property.
            This makes it as easy for the agent to communicate with residents as members.
        </p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/residents.json',
        beforeText: `<p>
            In the old world, directors asked agents, to ask letting agents,
            to ask owners to let their tenants know something was happening.
        </p>`,
        afterText: `<p> <b>
            With OurOMC - Directors and their agents can communicate directly with all residents.
            </b>
        </p> `,
        benefitTitle: 'Benefits for Residents & Members',
        prevPageTitle: 'Home',
        prevPageRoute: '/',
        nextPageTitle: 'Owners',
        nextPageRoute: '/owners',
        benefits: [{
            icon: 'forum',
            head: 'Communications',
            text: `Receive notifications and posts - Know what is happening in your community.
                    OurOMC makes it easy to post messages to residents, owners or directors as appropriate.
                    It can also allow users to comment and have a discussion around a topic.`,
        },{
            icon: 'ballot',
            head: 'Voting - Have our say in the community',
            text: `OurOMC allows the OMC to ballot residents, members or directors as appropriate to ensure that
                    the appropriate voices are heard on every decision`,
        },{
            icon: 'library_books',
            head: 'Shared Documentation',
            text: `Whether it is the latest copy of the house rules or information about the local bus schedule.
                    Appropriate documentation can be easily shared with all residents`,
        },{
            icon: 'live_help',
            head: 'Request Management',
            text: `See something needs doing?
                Create a request as quickly as a snap of your camera and let the OMC know what needs doing`,
        },{
            icon: 'visibility_lock',
            head: 'Right people see the right data.',
            text: `OurOMC allows the system to be configured
                    so that residents, owners, directors and agents all see the data appropriate to them.
                    Directors can decide which audience any post, document or vote should be shared with.
                    Who can vote, who can comment, who can post, who can create requests can all be controlled.`,
        }
    ]
    }
}
