/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { FieldSet, LAYOUT_OPTIONS } from '../shared/form/field-set/field-set.component';
import { FormTextComponent } from '../shared/form/form-text/form-text.component';
import { of } from 'rxjs';
import { FormConfig } from "../shared/form/FormConfig";
import { AbstractObject, uuid } from './abstract-object';
import { PersonUnitRole } from './person-unit-role';
import { FieldMaker } from '../shared/field/FieldMaker';
import { FormPicklistComponent } from '../shared/form/form-picklist/form-picklist.component';
import { Action } from './action';
import { Memo } from './memo';
import { MailItem, MailMerge } from './mailMerge';
import { FormTextAreaComponent } from '../shared/form/form-text-area/form-text-area.component';
import { Attachment } from './attachment';

export class Person extends AbstractObject {

    title: string;
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
    address?: string;
    userId?: uuid;
    fullName?: string;
    //units: PersonUnitRole[] = [];
    personUnits: PersonUnitRole[] = [];
    units?: PersonUnitRole[] = []; // calc from personUnits
    roles?: PersonUnitRole[] = []; // calc from personUnits
    actions?: Action[] = [];
    memos?: Memo[] = [];
    mailItems?: MailItem[] = [];
    mailMerges?: MailMerge[] = [];
    attachments?: Attachment[] = [];
    // Not Really on person table...
    invoices?: number;
    outstanding?: number;
    unitName?: string;
    // Only for insert PersonUnitRole at same time
    unit?: {unitId: uuid, resident: boolean, owner: boolean}
    unitId?: uuid;
    roleId?: uuid;

    constructor(o: Partial<Person> = {}) {
        super(o);
    }
    static fullName(item: Person) {
        if (item && (item.id || item.lastName)) {
            return (item.title ? item.title + ' ' : '')
                + (item.firstName ? item.firstName + ' ' : '')
                + (item.lastName ? item.lastName : '');
        } else {
            return '';
        }
    }
    static titles: AbstractObject[] = [
        { id: '', name: '' },
        { id: 'Mr', name: 'Mr' },
        { id: 'Ms', name: 'Ms' },
        { id: 'Mrs', name: 'Mrs' },
        { id: 'Miss', name: 'Miss' },
        { id: 'Dr', name: 'Dr' },
        { id: 'Prof', name: 'Prof' },
        { id: 'Rt Hon.', name: 'Rt Hon.' },
    ]
    static getTitleField() {
        return FormPicklistComponent.make('Title', 'title', null, { items: Person.titles });
    }
    static pickDialogFormConfig(): FormConfig {
        return new FormConfig({
            fieldSet: new FieldSet({
                fields: [
                    FieldMaker.id(),
                    FieldMaker.rev(),
                    Person.getTitleField(),
                    FormTextComponent.make('First Name', 'firstName'),
                    FormTextComponent.make('Last Name', 'lastName'),
                    FormTextComponent.make('Telephone', 'phone', {formColumn: 2}),
                    FormTextComponent.make('Email', 'email', {formColumn: 2}),
                    FormTextAreaComponent.make('Address', 'address', {formColumn: 3}),
                    FieldMaker.notes().override({formColumn: 3}),
                ],
                formLayout: LAYOUT_OPTIONS.threeCol,
            }),
            title: $localize`People`,
            mode: 'new',
            objectFactory: () => of(new Person()),
        });
    }
}
