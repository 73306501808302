/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from './abstract-object';

export class RequestPriority extends AbstractObject {
    name: string;
    defaultPrio: boolean;
    constructor(o: Partial<RequestPriority> = {}) {
        super(o);
    }
}
