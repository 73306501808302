@if (on ==='form' && attachmentGridField.visible.form) {
    @if(attachmentGridField.showGrid) {
        <app-grid [control]="control" [on]="on"></app-grid>
    }
    @if(attachmentGridField.showSimple) {
        <ng-container [ngTemplateOutlet]="showSimple"></ng-container>
    }
    @if(attachmentGridField.allowSimpleAdd || attachmentGridField.showSimple) {
        <ng-container [ngTemplateOutlet]="showSimpleAdd"></ng-container>
    }    
    <div style="height:21px">
        @if (!control.field.readonly && (attachmentGridField.showGrid || attachmentGridField.allowSimpleAdd)) {
            <input type="file"  style="visibility:hidden"
            #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event.target.files)" />
        }
    </div>
    @if (!control.field.readonly && attachmentGridField.showGrid) {
        <ng-container [ngTemplateOutlet]="gridAddBox"></ng-container>
    }
}

<ng-template #gridAddBox>
    <table>
        <tr>
          <td matTooltip="Click here to add a file or image">
            <button mat-raised-button color="primary" style="font-size: small;" (click)="clickAddFiles()">
              Attach File(s)
            </button>
          </td>
          <td>
            <table width="100%">
              @for (file of files; track file; let i = $index) {
                <tr>
                  <td>
                    @if (file.progress < 100) {
                      <h4 class="name">
                        {{ file?.name }}
                      </h4>
                    }
                    @if (file.progress >= 100) {
                      <h4 class="name" >
                        {{ file?.name }} - Uploaded
                      </h4>
                    }
                  </td>
                  <td>
                    <p class="size">
                      {{ file?.size | byteCount }} @if (file?.failed) {
                      <span i18n >Upload has failed...</span>
                    }
                  </p>
                </td>
                <td width="50%">
                  <app-upload-progress [upFile]="file"></app-upload-progress>
                </td>
              </tr>
            }
          </table>
        </td>
      </tr>
    </table>
</ng-template>

<ng-template #showSimple>
    <div>
        <p style="opacity: .75; font-size: small;">Attachments</p>
        @for (a of attachmentGridField.attachmentList; track $index) {
            <div style="display:flex; margin-left: 20px">
                @if (attachmentGridField.getFileIcon(a) === 'attachment') {
                    <mat-icon class="image" color="primary">attachment</mat-icon>
                } @else {
                    <img style="height: 20px; width: 20px; vertical-align: middle;" src="./assets/{{attachmentGridField.getFileIcon(a)}}">
                }
                <div style="margin-left: 10px;">
                    <a style="line-height: 1.2; cursor: pointer;" class="primary-font-color"
                        matTooltip="Created : {{ a.createdAt | fromNow}} Size: {{a.size | byteCount}}" #tooltip="matTooltip"
                        href="/api/api/social/posts/attach/{{a.id}}">
                        {{a.name}}
                    </a>
                    <mat-icon style="margin-left: 10px; opacity: 50%" (click)="tooltip.show()">info</mat-icon>
                    @if (!attachmentGridField.readonly) {
                        @if (attachmentGridField.allowSimpleEdit) {
                            <mat-icon class="edits" color="primary" (click)="editAttachment(a)">edit</mat-icon>
                        }
                        @if (attachmentGridField.allowSimpleDelete) {
                            <mat-icon class="edits" color="warn" (click)="deleteAttachment(a)">delete</mat-icon>
                        }
                    }
                    <span class="edits" style="font-size: small; color: grey">{{a.notes}}</span>
                </div>
            </div>
        }
    </div>
</ng-template>
<ng-template #showSimpleAdd>
    <div style="text-align: center">
        @if (!control.field.readonly) {
            <button mat-icon-button adder class="add-button" (click)="clickAddFiles()"
            matTooltip="Click to add a new attachment">
                <mat-icon color="primary">add_circle</mat-icon>
            </button>
        }
    </div>
</ng-template>