/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
export const environment = {
    production: true,
    baseHref: '/', // Duplicate of value in angular.json deployUrl / baseHref... must match
    appBaseHref: '/',
    defaultUser: '', //'greg@me.com',
    defaultPass: '', //'pass'
};
