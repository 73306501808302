/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpClient } from '@angular/common/http';
import { Component, inject, Optional } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { Person } from 'src/app/model/person';
import { OmcRole } from 'src/app/model/OmcRole';
import { Omc } from 'src/app/model/Omc';
import { Unit } from 'src/app/model/unit';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { FormCheckboxComponent } from 'src/app/shared/form/form-checkbox/form-checkbox.component';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FORM_ERRORS } from 'src/app/shared/form/form-error/form-error.component';
import { FormPhoneComponent } from 'src/app/shared/form/form-phone/form-phone.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { required } from 'src/app/shared/validators';
import { CurrentUserService } from '../current-user.service';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { MessageService } from 'src/app/shared/message.service';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { CtlHolderComponent } from '../../../shared/form/ctl-holder/ctl-holder.component';
import { MatStepperModule } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgTemplateOutlet } from '@angular/common';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';

@Component({
    selector: 'app-join-an-omc',
    templateUrl: './join-an-omc.component.html',
    styleUrls: ['./join-an-omc.component.scss'],
    standalone: true,
    imports: [MatStepperModule, CtlHolderComponent,
        MatButtonModule, MatTooltipModule, NgTemplateOutlet]
})
export class JoinAnOmcComponent {

    router = inject(Router)
    cds = inject(ConfirmDialogService);

    personAddressRequired = false;
    unitAddressRequired = false;

    omcForm = new FormGroup({
        omcId: this.getOmcField(),
        omcAddress: this.getOmcAddressField(),
        omcUnknown: this.getOmcUnknown(),
    });

    currentForm: AbstractControl = this.omcForm;

    unitForm = new FormGroup({
        unitId: this.getUnitField(),
        unitAddress: this.getUnitAddressField(),
        owner: this.getOwnerField(),
        resident: this.getResideField(),
        involvement: this.getInvolveField(),
    }, this.unitValidator.bind(this));
    unitNameSelected = 'Unit';

    personAddressField = this.getPersonAddressField();
    nonMemberField =  this.getNonMemberField();

    personForm = new FormGroup({
        /** Editable  */
        title: this.getTitleField(),
        firstName: this.getFirstNameField(),
        lastName: this.getLastNameField(),
        phone: this.getPhoneField(),
        email: new FormControl(),
        gdprConsent: this.getGdprField(),
        unit: this.unitForm,
        nonMemberAcknowledged: this.nonMemberField,
    })

    //requestCategoryField = this.getRequestCategoryField();
    requestForm = new FormGroup({
        teamId: new FormControl(),
        omcId: this.omcForm.controls.omcId,
        unitId: this.unitForm.controls.unitId,
        title: this.getRequestTitleField(),
        description: this.getRequestDescField(),
        ownedById: new FormControl(),
        agentTeamId: new FormControl(),
    });

    data = new FormGroup({
        request: this.requestForm,
        omc: this.omcForm,
        unit: this.unitForm,
        nonMemberAcknowledged: this.nonMemberField,
        person: this.personForm,
        personalAddress: this.personAddressField.control,
    })

    constructor( public screenSvc: IsNarrowService, currentUserSvc: CurrentUserService,
        private msgSvc: MessageService, cds: ConfirmDialogService,
        @Optional() private dialogRef: MatDialogRef<JoinAnOmcComponent>,
        private http: HttpClient) {

        const url = AbstractHttpService.ajaxPath + 'omcs'
        http.get<Omc[]>(url).subscribe( (omcs: Omc[]) => {
            omcs.forEach( o => {
                if (o.legalName) {
                    o.name = o.name + ' (' + o.legalName + ')';
                }
            });
            //const filter = omcs.filter(o => o.teamId !== o.omcTeamId);
            //const sorted = filter.sort((a, b) => a.name.localeCompare(b.name));
            this.omcPicklist().setPicklistItems(omcs);
        }, failure => {
            if (failure.status === 401) {
                console.log('Not logged in');
            }
            this.router.navigate(['newUserRequest']);
        });
        currentUserSvc.getCurrentUser().subscribe( user => {
            if (user) {
                this.personForm.controls.email.setValue(user.email);
                this.requestForm.controls.description.setValue('Provide Access For ' + user.email);
                if (user.currentTeam?.name) {
                    cds.openChoice({
                        title: $localize`Already Linked to organisation`,
                        msg: $localize`You are already linked to ${user.currentTeam.name}. Do you want to request to join another OMC`,
                        options: [
                            {
                                name: $localize`Yes`, action: () => {
                                    console.log('Wants to continue');
                                }
                            },
                            {
                                name: $localize`No take me to ${user.currentTeam.name}`, action: () => {
                                    this.router.navigate(['/']);
                                }
                            }
                        ]
                    }, false);
                }
            }
        });

    }
    omcPicklist() {
        return this.omcForm.controls.omcId.field as PicklistField;
    }

    submitRequest() {
        console.warn({form: this.data, value: this.data.value});
        const url = AbstractHttpService.ajaxPath + 'omcs';
        this.http.post<ServiceRequest>(url, this.data.value).subscribe(() => {
            this.msgSvc.show('Your request to be added to the OMC has been sent. You should receive a response in the coming days');
            this.router.navigate(['/']);
        }, (failure) => {
            this.msgSvc.showError(failure, 'submitting access request');
        });
    }

    stepChanged(evt: StepperSelectionEvent) {
        this.currentForm = evt.selectedStep.stepControl as AbstractControl;
        //console.log({valid: this.data.valid, value: this.data.value, form: this.data, cf: this.currentForm, cv: this.currentForm.value});
    }

    getUnitName() {
        const ua = this.unitForm.controls.unitAddress.value;
        if (ua) {
            return ua.split('\n')[0];
        }
        return 'the unit';
    }

    private getOmcUnknown() {
        return FormCheckboxComponent.make('Cannot Find OMC', 'unknownOmc').override({
            hint: 'Check this box if you cannot find your Owner Management Company in the list above.',
            valueChanges: (o) => {
                if (o) {
                    this.cds.open('Register New OMC', 'Would you like to contact us to setup your OMC?', () => {
                        this.router.navigate(['/contact-us']);
                    });
                } else {
                    this.unitForm.controls.unitId.enable();
                    this.unitAddressRequired = false;
                    this.unitForm.controls.unitAddress.disable();
                }
                this.omcForm.controls.omcId.updateValueAndValidity();
            }

        }).makeControl();
    }

    private setOwner(omc: Omc) {
        this.requestForm.controls.ownedById.setValue(Omc.getUserId(omc, OmcRole.admin.id));
        this.requestForm.controls.agentTeamId.setValue(omc.teamId);
        this.requestForm.controls.teamId.setValue(omc.omcTeamId);
    }

    public refreshOmcChoice(omc: Omc) {
        if (omc.residentSignup || omc.ownerSignup || omc.otherSignup) {
            this.omcForm.controls.omcAddress.field.setValue(omc, true);
            this.refreshUnits(omc);
            if (omc) {
                this.omcForm.controls.omcUnknown.setValue(false);
                this.setOwner(omc);
            }
        } else {
            const msg = $localize `Sorry, the agent responsible for ${omc.name} does not currently accept requests.
                    Would you like to send us a note so that we can encourage them to sign up?`
            this.cds.open('Request Not Accepted', msg, () => {
                this.router.navigate(['/contact-us', {wantSignup: omc.name}]);
            })
        }
    }

    private getOmcField() {
        return FormComboBoxComponent.make('Owner Management Company', 'omcId', null, {
            items: [],
            refreshes: [this.refreshOmcChoice.bind(this)]
        }, {
            validators: [this.omcValidator.bind(this)],
            calculateValue: (omc) => omc.id,
            hint: 'Please select the name of the OMC that you are part of. Once you start typing the list will be populated with matching OMCs registered on the system.'
        }).makeControl();
    }

    private getOmcAddressField() {
        return FormTextAreaComponent.make('Address', 'address', {
            formColumn: 2, disable: true,
            label: 'Partial Address of OMC'
        }).makeControl();
    }

    private updateRequestTitle() {
        let role = "Person";
        if (this.unitForm.controls.owner.value) {
            role = 'owner';
        } else if (this.unitForm.controls.resident.value) {
            role = 'resident'
        }
        const title = $localize `Add ${role} to ${this.unitNameSelected}`;
        this.requestForm.controls.title.setValue(title, { emitEvent: false });
    }

    private getUnitField() {
        return FormComboBoxComponent.make('Unit', 'roleId', 'role',
            {
                items: [], refreshes: [(unit: Unit) => {
                    this.unitForm.controls.unitAddress.setValue(unit.address);
                    this.unitForm.controls.involvement.updateValueAndValidity();
                    this.updateRequestTitle();
                    this.unitForm.updateValueAndValidity();
                }]
            }, {
                validators: [this.unitRequiredValidator.bind(this)],
            }
        ).makeControl();
    }

    private getUnitAddressField() {
        return FormTextAreaComponent.make('Address', 'address', {
            formColumn: 2, disable: true,
            label: 'Address of Unit',
            validators: [this.unitAddressValidator.bind(this)],
        }).makeControl();
    }

    private getOwnerField() {
         /*return FormCheckboxComponent.make('Own this unit', 'ownerOmc').override({
             hint: 'Check this box if you own or co-own the unit',
             valueChanges: () => {
                this.setNonMemberField();
                this.setInvolveField();
                this.setPersonAddressField();
                this.updateRequestTitle();
                this.unitForm.updateValueAndValidity();
             },
         }).makeControl(); */
        return FormPicklistComponent.make('Do you own this unit', 'ownerOmc', null, {
            items: [{ id: 0, name: "No - I do not own this unit" }, { id: 1, name: "Yes - I own or co-own this unit" }]
        }).override({
            valueChanges: () => {
                this.setNonMemberField();
                this.setInvolveField();
                this.setPersonAddressField();
                this.updateRequestTitle();
                this.unitForm.updateValueAndValidity();
            }
        }).makeControl();
    }


    private getGdprField() {
        return FormCheckboxComponent.make('Consent to store and process your data', 'gdprConsent').override({
            hint: 'Please check this box to confirm you are giving your consent for your information to be stored and processed by the OMC for the purposes of this request and the ongoing management of the OMC',
            validators: [ required ]
        }).makeControl();
    }

    private getNonMemberField() {
        return FormCheckboxComponent.make('Proceed as non owner', 'nonMemberAcknowledged').override({
            validators: [(ctl) => {
                if (!ctl.value) {
                    if (!this.unitForm?.controls.owner.value) {
                        return {required: 'Acknowledgement potential slow response'}
                    }
                }
                return null;
            }],
        }).makeControl();
    }

    private getResideField() {
        /*return FormCheckboxComponent.make('Live in this unit', 'resideOmc').override({
            hint: 'Check this box if you live in the OMC',
            valueChanges: () => {
                this.setPersonAddressField();
                this.setInvolveField();
                this.updateRequestTitle();
                this.unitForm.updateValueAndValidity();
            }
        }).makeControl(); */
        return FormPicklistComponent.make('Do you live in this unit', 'resideOmc', null, {
            items: [{id: 0, name: "No - I do not live in this unit"}, {id: 1, name: "Yes - I do live here"}]
        }).override({
            valueChanges: () => {
                this.setPersonAddressField();
                this.setInvolveField();
                this.updateRequestTitle();
                this.unitForm.updateValueAndValidity();
            }
        }).makeControl();
    }

    setPersonAddressField() {
        if (this.unitForm.controls.resident.value || !this.unitForm.controls.owner.value) {
            this.personAddressRequired = false;
            this.personAddressField.disable = true
            this.personAddressField.control.setValue('');
            this.personAddressField.control.disable();
        } else {
            this.personAddressRequired = true;
            this.personAddressField.disable = false
            this.personAddressField.control.enable();
        }
        this.personAddressField.control.updateValueAndValidity();
    }
    setNonMemberField() {
        const ctl = this.data.controls.nonMemberAcknowledged;
        ctl.updateValueAndValidity();
        if (this.unitForm.controls.owner.value) {
            ctl.field.disableControl();
        } else {
            ctl.field.enableControl();
        }
    }

    setInvolveField() {
        const involve = this.unitForm.controls.involvement;
        if (this.unitForm.controls.owner.value || this.unitForm.controls.resident.value) {
            involve.setValue('', { emitEvent: false });
            involve.field.disableControl();
        } else {
            involve.field.enableControl();
        }
        this.unitForm.controls.involvement.updateValueAndValidity();
    }

    private getInvolveField() {
        return FormTextComponent.make('Other Involvement with unit/omc', 'involved').override({
            hint: 'If you are not a resident or owner, please tell us how you are involved with the OMC',
            valueChanges: () => {
                this.unitForm.controls.unitId.updateValueAndValidity();
                this.unitForm.controls.unitAddress.updateValueAndValidity();
                this.unitForm.updateValueAndValidity();
            },
            validators: [this.involveValidator.bind(this)],
        }).makeControl();
    }

    private getTitleField() {
        return Person.getTitleField().override({
            hint: 'Enter the title to be used in formal correspondence'
        }).makeControl();
    }

    private getFirstNameField() {
        return FormTextComponent.make('First Name', 'firstName', {
            hint: 'Please enter your legal first name(s), as it appears on your passport and/or driving license',
            validators: [required],
        }).makeControl();
    }

    private getLastNameField() {
        return FormTextComponent.make('Last Name', 'lastName', {
            validators: [required],
            hint: 'Please enter your legal last name, as it appears on your passport and/or driving license'
        }).makeControl();
    }

    private getPhoneField() {
        return FormPhoneComponent.make('Telephone', 'phone', {
            hint: 'Please enter the best phone number for you to be contacted on',
            validators: [required]
        }).makeControl();
    }

    private getPersonAddressField() {
        return FormTextAreaComponent.make('Address', 'address', {
            formColumn: 2, validators: [this.personAddressValidator.bind(this)],
            label: 'Address if not resident within OMC'
        }).setupControl();
    }

    private getRequestTitleField() {
        const fld = FormTextComponent.make('Request Title', 'requestTitle', {
            validators: [ required ], hint: 'A short name for this request'
        });
        fld.makeControl();
        fld.control.setValue('Add Person To OMC');
        return fld.control;
    }

    private getRequestDescField() {
        const fld = FormTextAreaComponent.make('Request Description', 'requestDescription', {
            validators: [required], hint: 'Full details of this request'
        });
        fld.makeControl();
        return fld.control;
    }

    refreshUnits(omc: Omc) {
        const unitPl = this.unitForm.controls.unitId.field as PicklistField;
        if (omc) {
            const url = AbstractHttpService.ajaxPath + 'omcs/' + omc.omcTeamId + '/units';
            this.http.get<Unit[]>(url).subscribe(units => {
                units.forEach(u => {
                    if (u.address) {
                        u.name = u.address.split('\n')[0];
                    }
                });
                unitPl.setPicklistItems(units);
            })
        } else {
            unitPl.setPicklistItems([]);
        }
    }

    involveValidator(ctl: AbstractControl) {
        if (!ctl.value || !ctl.value.trim()) {
            if (!this.unitForm?.controls.unitAddress.value
            || (!this.unitForm?.controls.resident.value && !this.unitForm.controls.owner.value)) {
                return { involvement: 'You need to state your involvement with the OMC' }
            }
        }
        return null;
    }
    personAddressValidator(ctl: AbstractControl) {
        if (this.personAddressRequired) {
            return this.validAddress(ctl.value);
        } else {
            return null;
        }
    }

    unitAddressValidator(ctl: AbstractControl) {
        if (this.unitAddressRequired) {
            if (!this.unitForm.controls.involvement.value ) {
                return this.validAddress(ctl.value);
            }
        }
        return null;
    }

    unitRequiredValidator(ctl: AbstractControl) {
        if (!ctl?.value && !this.unitAddressRequired && !this.unitForm?.controls.involvement.value) {
            return {unitRequired: 'Please Select Unit'};
        }
    }

    validAddress(value: string) {
        const parts = value.split('\n');
        if (parts.length > 1 && parts[0].length > 3 && parts[1].length > 3) {
            return null;
        }
        return {invalidAddress: 'Invalid Address Entered'};
    }

    omcValidator(ctl: AbstractControl) {
        if (this.omcForm?.controls.omcUnknown.value) {
            return null;
        } else if (!ctl.value){
            return FORM_ERRORS.required.new();
        }
    }

    unitValidator() {
        if (!this.unitForm?.controls.resident.value
        && !this.unitForm?.controls.owner.value
        && !this.unitForm?.controls.involvement.value) {
            return {unknownRole: 'Must enter live in, reside in or have some involvement with unit'}
        }
        return null;
    }

    logMe() {
        console.log(this.data);
    }
}
