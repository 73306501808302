/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorHandler } from './HttpErrorHandler';
import { OurAppTrackerService } from './our-app-tracker-service';

@Injectable({
    providedIn: 'root'
})

export class MessageService {
    oats = inject(OurAppTrackerService);
    constructor(private snackBar: MatSnackBar) { }
    show(msgOrString: MsgDef | string, closer = 'X') {
        let msg: MsgDef;
        if (msgOrString instanceof MsgDef) {
            msg = msgOrString as MsgDef;
        } else {
            msg = new MsgDef(msgOrString);
        }

        if (msg.type === 'success') {
            this.oats.logAction(msg.text);
            return this.snackBar.open(msg.text, closer, { duration: 3000, verticalPosition: 'top', panelClass: 'message-success' });
        } else if (msg.type === 'warn') {
            this.oats.logAction('WARNING: ' + msg.text);
            return this.snackBar.open(msg.text, closer, { duration: 10000, verticalPosition: 'top', panelClass: 'message-warn' });
        } else {
            this.oats.logAction('ERROR: ' + msg.text);
            return this.snackBar.open(msg.text, closer, { duration: 30000, verticalPosition: 'top', panelClass: 'message-fail' });
        }
    }

    showErrorMessage(msg: string) {
        const md = new MsgDef(msg, 'fail');
        this.show(md);
    }

    warn(msg: string) {
        const md = new MsgDef(msg, 'warn');
        this.show(md);
    }

    showError(failure: HttpErrorResponse, operation: string) {
        const h = new HttpErrorHandler();
        console.error(failure);
        const msg = h.getErrorMessage(failure, operation);
        return this.show(msg);
    }
}

type MsgType = 'success' | 'fail' | 'warn';

export class MsgDef {
    text: string;
    type: MsgType;
    constructor(text: string, type: MsgType = 'success') {
        this.text = text;
        this.type = type;
    }
    toString(): string {
        return this.text;
    }
}

const WARN = 'warn';

export const OLD_MESSAGES = {

    area: new MsgDef('Area'),
    areaCreated: new MsgDef('New area has been saved'),
    areaMaintenance: new MsgDef('Areas Maintenance'),
    areaTitleHeader: new MsgDef('Title'),
    areaTitleLabel: new MsgDef('Name for this area'),
    areaUpdated: new MsgDef('New area has been saved'),

    cancel: new MsgDef('Cancel'),

    carParkSpace: new MsgDef('Car Park Space'),
    carParkSpaceCreated: new MsgDef('New car park space has been saved'),
    carParkSpaceTitleHeader: new MsgDef('Title'),
    carParkSpaceTitleLabel: new MsgDef('Name for this car park space (i.e. Space Number)'),
    carParkSpaceOwningUnitLabel: new MsgDef('Unit which owns this car park space'),

    carParkSpaceUpdated: new MsgDef('New car park space has been saved'),

    commentLeaveLabel: new MsgDef($localize`Leave a comment`),
    commentReplyLabel: (to: string) => new MsgDef(`Reply to ${to}'s comment`),

    delete: new MsgDef('Delete'),
    deleteConfirm: new MsgDef('Are you sure you want to delete this object?'),
    deleteConfirmed: new MsgDef('Object has been marked deleted in database'),

    edit: new MsgDef('Edit'),

    freeFormComment: new MsgDef('Comments'),
    freeFormCommentLabel: new MsgDef('Any comment for this item'),


    mediaSaved: new MsgDef('Media file has been uploaded successfully to the server'),

    openRequest: new MsgDef('Open Request'),


    pageMenuOrderTitle: new MsgDef('Menu Position this item will appear in'),
    pageParentLabel: new MsgDef('Parent (Under which this page will display in menu)'),
    pageTitleLabel: new MsgDef('Title for this page'),
    pageTitleHint: new MsgDef('You can use " - " to seperate menu title, e.g. Menu title - remainder of title'),
    pageUpdated: new MsgDef('Page has been saved'),

    postTitleLabel: new MsgDef('Post Title'),

    save: new MsgDef('Save'),
    startDiscussion: new MsgDef('Start Discussion'),

    updatedBy: new MsgDef('Updated By'),
    updated: new MsgDef('Updated'),

    unit: new MsgDef('Unit'),
    unitAreaLabel: new MsgDef('Area that this unit is part of'),
    unitCreated: new MsgDef('New unit has been saved'),
    unitOwnerLabel: new MsgDef('User who owns and votes for this unit'),
    unitUpdated: new MsgDef('Unit has been updated successfully'),
    unitTitleHeader: new MsgDef('Title'),
    unitTitleLabel: new MsgDef('Name for this unit'),

    unauthorized: new MsgDef('', WARN),
};
