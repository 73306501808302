/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpClient } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavRoute } from 'src/app/shared/NavRoute';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { TrialBalanceApiResponse } from '../trial-balance/trial-balance.component';
import { uuid } from 'src/app/model/abstract-object';
import { CSVImporter } from 'src/app/modules/importer/import-page/csv-importer';
import { CurrencyPipe, NgTemplateOutlet } from '@angular/common';
import { BCode } from 'src/app/model/bcode';
import { MatIconModule } from '@angular/material/icon';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { BCodeService } from 'src/app/modules/budget/bcode.service';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { BCodePageComponent } from 'src/app/modules/budget/b-code-page/b-code-page.component';

class ImportLine {
    sort: number;
    description: string;
    debit: number;
    credit: number;
    bCode?: BCode;
    field?: PicklistField;
    needsDebit: number;
    needsCredit: number;
}

@Component({
  selector: 'app-trial-balance-import',
  standalone: true,
  imports: [NgTemplateOutlet, CurrencyPipe, MatIconModule, CtlHolderComponent, MatButtonModule],
  templateUrl: './trial-balance-import.component.html',
  styleUrls: ['../balances.scss'],
})
export class TrialBalanceImportComponent {

    static readonly navRoute = new NavRoute('txn/trialBalanceImport', TrialBalanceImportComponent, 'balance');
    router = inject(Router);
    private http = inject(HttpClient);
    activatedRoute = inject(ActivatedRoute);
    bCodeSvc = inject(BCodeService);
    cds = inject(ConfirmDialogService);
    dialog = inject(MatDialog);
    csvImporter = new CSVImporter();
    importLines: ImportLine[] = [];
    availBCodes: BCode[] = [];
    warnings: string[] = [];
    totalDebit = 0;
    totalCredit = 0;

    companyName: string

    myData: TrialBalanceApiResponse;
    gotImportFile = false;

    constructor() {
        this.activatedRoute.paramMap.subscribe( parms => {
            this.getData(parms.get('itemId'));
        })
    }

    getData(cycleId: uuid) {
        // URL reused from trialBalance
        const url = AbstractHttpService.ajaxPath + 'txn/trialBalance/' + cycleId;

        this.http.get<TrialBalanceApiResponse>(url).subscribe(result => {
            if (result) {
                console.log(result);
                result.bcodes.forEach( bc => {
                    bc.debits = 0.00;
                    bc.credits = 0.00;
                })
                result.balances.forEach( bal => {
                    const bc = result.bcodes.find( b => b.id === bal.bCodeId);
                    if (bc) {
                        bc.debits = +bal.debits;
                        bc.credits = +bal.credits;
                    } else {
                        console.log('Where is bcode', bal);
                    }
                    
                })
                this.myData = result;
            }
        })
    }

    importFile($event: FileList) {
        console.log($event);
        this.csvImporter.readFile($event, this.processImport.bind(this));
    }

    processImport(rows: unknown[][]) {
        this.importLines = [];
        this.availBCodes = this.myData.bcodes.slice();
        this.warnings = [];
        this.totalDebit = 0;
        this.totalCredit = 0;
        this.gotImportFile = true;

        const isNumber = (val: unknown) => typeof val === "number" && val === val
        let lineNr = 0;
        rows.forEach( row => {
            const line = {
                sort: row[0],
                description: row[1],
                debit: +row[2],
                credit: +row[3],
                needsDebit: 0.00,
                needsCredit: 0.00
            } as ImportLine;
            if (line.sort && isNumber(line.debit) && isNumber(line.credit)) {
                this.checkLine(line);
                this.importLines.push(line);
                this.totalDebit +=  line.debit;
                this.totalCredit += line.credit;
            } else {
                this.warnings.push('Skipping Line: ' + lineNr + ':' + row.join(','));
            }
            lineNr++;
        })
    }

    checkLine(line: ImportLine) {
        const bc = this.myData.bcodes.find( o => o.name.toUpperCase() === line.description.toUpperCase());
        if (bc) {
            this.assignBCode(bc, line);
        } else {
            line.field = FormComboBoxComponent.make('Budget Code', 'bCodeId', null, {
                items: this.availBCodes,
                refreshes: [ (o: BCode) => this.updateBCode(o, line)]
            }).setupControl();  
        }
        if (line.description.length > 30) {
            this.warnings.push($localize `The description '${line.description}' is too long to be stored, you will need to shorten it in your input file`)
        }
    }

    removeBCode(bc: BCode) {
        const idx = this.availBCodes.indexOf(bc);
        this.availBCodes.splice(idx, 1);
    }

    updateBCode(bc: BCode, line: ImportLine) {
        console.log({bc, line});
        const title = $localize `Update BCode`;
        const msg = $localize `Change description of Budget Code '${bc.name}' to be ${line.description}`;
        this.cds.open(title, msg, () => {
            bc.name = line.description;
            this.bCodeSvc.put(bc).subscribe( (bc) => {
                this.assignBCode(bc, line);
            })
        })
    }
    assignBCode(bc: BCode, line: ImportLine) {
        this.removeBCode(bc);
        const bal = this.myData.balances.find( bal => bal.bCodeId === bc.id);
        if (bal) {
            bc.debits = +bal.debits;
            bc.credits = +bal.credits;
        } else {
            bc.debits = 0.00;
            bc.credits = 0.00;
        }
        const journalNeeded = line.debit - bc.debits - line.credit + bc.credits;
        if (journalNeeded < 0)  {
            line.needsCredit = 0 - journalNeeded;
        } else {
            line.needsDebit = journalNeeded;
        }        
        line.bCode = bc;
        line.field = null;
    }

    createBcode4Line(line: ImportLine) {
        this.dialog.open(BCodePageComponent, 
            { data: { name: line.description, sort: line.sort, mode: 'new', hideTabs: true } }).afterClosed().subscribe( result => {
                this.assignBCode(result, line);
            }
        );
    }
}
