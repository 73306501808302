/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Period } from 'src/app/model/period';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';

import { Cycle } from 'src/app/model/cycle';
import { catchError, map, tap } from 'rxjs/operators';
import { ActionColor, IFormAction } from '../../shared/form/form.component';
import { FormConfig } from "../../shared/form/FormConfig";
import { DateHelper } from 'src/app/shared/dateHelper';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { Router } from '@angular/router';
import { MyInjector } from 'src/app/app.module';
import { CurrentUserService } from '../user/current-user.service';
import { User } from 'src/app/model/user';

class ProposeBudgetAction implements IFormAction {
    name = $localize`Propose`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'how_to_vote';
    approvalNeeded = true;
    approvalText = $localize`Lock this budget whilst it is proposed to the members for approval`;
    cycleSvc: CycleService;

    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).propose(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.EDITTING;
    }
}
class CopyPreviousAction implements IFormAction {
    name = $localize`Copy Previous`;
    color: ActionColor = 'accent';
    show = false;
    icon = 'content_copy';
    approvalNeeded = true;
    approvalText = $localize`Copy budget figures from last year to this year?`;

    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).copyPrevious(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.EDITTING;
    }
}

class CopyPreviousActualsAction implements IFormAction {
    name = $localize`Copy Actuals`;
    color: ActionColor = 'accent';
    show = false;
    icon = 'file_copy';
    approvalNeeded = true;
    approvalText = $localize`Copy last years actuals to this year? (make sure last years records complete if you do this!)`;

    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).copyPreviousActual(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.EDITTING;
    }
}

class ApproveBudgetAction implements IFormAction {
    name = $localize`Approve`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'approval';
    approvalNeeded = true;
    approvalText = $localize`Approve this budget, Ready to be billed`;
    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).approve(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.PROPOSED;
    }
}
class RejectBudgetAction implements IFormAction {
    name = $localize`Reject`;
    color: ActionColor = 'warn';
    show = false;
    icon = 'settings_backup_restore';
    approvalNeeded = true;
    approvalText = $localize`Reject this budget and allow it to be editted again?`;
    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).reject(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.PROPOSED;
    }
}

class ReviseBudgetAction implements IFormAction {
    name = $localize`Revise`;
    color: ActionColor = 'accent';
    show = false;
    icon = 'settings_backup_restore';
    approvalNeeded = true;
    approvalText = $localize`Revise this budget so that you can issue a supplementary charge?`;
    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).revise(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.BILLED;
    }
}
class RestartBudgetAction implements IFormAction {
    name = $localize`Restart`;
    color: ActionColor = 'accent';
    show = false;
    icon = 'settings_backup_restore';
    approvalNeeded = true;
    approvalText = $localize`Put this budget back into editting again?`;
    action(cycle: Cycle, config: FormConfig) {
        return (config.service as CycleService).restart(cycle);
    }
    setup(cycle: Cycle) {
        this.show = cycle.statusId === Cycle.statusIds.ACCEPTED;
    }
}
@Injectable({
    providedIn: 'root'
})
export class CycleService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'budgets/cycles';
    protected cache: Period[];
    protected typeString = 'Cycles';
    protected currentCycle: Cycle;
    protected currentPeriod: Period;

    constructor(protected http: HttpClient, protected messageService: MessageService,
        protected cds: ConfirmDialogService, protected router: Router) {
        super();
    }

    enhanceList(cycles: Cycle[]) {
        for (const c of cycles) {
            if (DateHelper.isoDateIn(new Date(), c.from, c.to)) {
                this.currentCycle = c;
                for (const p of c.periods) {
                    if (DateHelper.isoDateIn(new Date(), p.from, p.to)) {
                        this.currentPeriod = p;
                        break;
                    }
                }
                break;
            }
        }
        if (!this.currentPeriod || !this.currentCycle) {
            this.cds.open($localize`Period Missing`,
                $localize`There is no current period or cycle defined, system may behave unpredictably. Do you want to fix now?`,
                () => { this.router.navigate(['budgets/cycles']) });
        }
        return cycles;
    }

    getCurrentPeriod(): Period {
        if (!this.hasData) {
            throw ('Cannot getCurrentPeriod before cycle service retrieves data');
        }
        return this.currentPeriod;
    }

    getCurrentCycle(): Cycle {
        if (!this.hasData) {
            throw ('Cannot getCurrentCycle before cycle service retrieves data');
        }
        return this.currentCycle;
    }

    copyPrevious(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'copyPrevious');
    }

    copyPreviousActual(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'copyPreviousActual');
    }

    propose(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'propose');
    }

    approve(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'approve');
    }

    reject(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'reject');
    }

    billout(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'billout');
    }

    restart(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'restart');
    }

    revise(item: Cycle): Observable<Cycle> {
        return this.statusChange(item, 'revise');
    }

    getActions(): IFormAction[] {
        const cus = MyInjector.instance.get(CurrentUserService);
        const priv = User.privilege(cus.currentUser, 'Cycle');
        if (priv?.put) {
            return [
                new CopyPreviousAction(),
                new CopyPreviousActualsAction(),
                new ProposeBudgetAction(),
                new RejectBudgetAction(),
                new ApproveBudgetAction(),
                new RestartBudgetAction(),
                new ReviseBudgetAction()
            ];
        }
        return [];
    }

    statusChange(item: Cycle, change: string): Observable<Cycle> {
        const url = `${this.baseUrl}/${item.id}/${item.revision}/${change}`;
        return this.http.put<Cycle>(url, null, this.httpOptions)
            .pipe(
                map((data: Cycle) => data),
                tap(() => {
                    this.messageService.show(this.msgUpdated);
                }),
                catchError(this.handleError<Cycle>(change + this.typeString))
            );
    }

    createNext(item: Cycle): Observable<Cycle> {
        const url = `${this.baseUrl}/${item.id}/${item.revision}/createNext`;
        return this.http.post<Cycle>(url, null, this.httpOptions)
            .pipe(
                map((data: Cycle) => data),
                tap(() => {
                    this.messageService.show('Next cycle created');
                }),
                catchError(this.handleError<Cycle>(this.typeString))
            );
    }

    yearEnd(item: Cycle): Observable<Cycle> {
        const url = `${this.baseUrl}/${item.id}/${item.revision}/yearEnd`;
        return this.http.post<Cycle>(url, null, this.httpOptions)
            .pipe(
                map((data: Cycle) => data),
                tap(() => {
                    this.messageService.show('Year End Done...');
                }),
                catchError(this.handleError<Cycle>(this.typeString))
            );
    }


}
