/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { Component } from '@angular/core';
import { RoleHomePage } from '../role-home-page/role-home-page.component';
import { RoleHomePageComponent } from "../role-home-page/role-home-page.component";

@Component({
  selector: 'app-contractors-component',
  standalone: true,
  imports: [RoleHomePageComponent],
  templateUrl: './contractors.component.html',
})
export class ContractorsHomePageComponent {
    role: RoleHomePage = {
        title: `Contractors & Caretakers`,
        mainText: `<p>
            Contractors, Caretakers, Gardeners.
            There are a myriad of professionals who need to work together to make your
            Multi Unit Development a happy place to live.
        </p>`,
        lottieUrl: 'https://clgassets.fra1.cdn.digitaloceanspaces.com/lottie-files/contractors.json',
        prevPageTitle: 'Accounting',
        prevPageRoute: '/accountants',
        nextPageTitle: 'Home',
        nextPageRoute: '/',
        beforeText: `<p>
            It used to be that when something needed doing, someone would call the agent.
            The agent would call the gardener to ask them to do it.
            Then the agent needs to remember to check back to see if things got done
            and let the requestor know. It's no wonder things fall through the cracks.
        </p>`,
        afterText: `<p>
            OurOMC allows everyone to collaborate online.
            Everyone knows what needs doing and what has been done.
        </p>`,
        benefitTitle: `Contract / Caretaker Benefits`,
        benefits: [{
            icon: 'assignment_returned',
            head: 'Agents or directors can assign work directly ',
            text: `
                as the work comes in. No phone calls, emails or messing is needed,
                work can be directly passed to any contractors who are registered to use the system.
                No arguments about what was or was not asked for.
            `,
        },{
            icon: 'visibility',
            head: 'Contractors can see what needs doing right there on their phone. ',
            text: `
             a complete list of all of the actions and requests that are expected to be completed
            while you are on site. Contractors can simply check them off as they go.
        `,
        }, {
            icon: 'image',
            head: 'A picture is worth a thousand words',
            text: ` Contractors can get pictures of what work needs to be done, so there are no mistakes
                    `,
        },{
            icon: 'camera',
            head: 'Proof things were done',
            text: `
                Contractors can snap a picture on their camera and attach it to the request as proof the work has been done.
            `,
        },{
            icon: 'live_help',
            head: 'Be Proactive',
            text: `
                Contractors or agents, during site visits,
                can create new requests on their phone to get agreement on any extra work that may need doing.
                Generating requests is as simple as snapping a picture.
            `,
        },{
            icon: 'groups',
            head: 'Collaborative working ',
            text: `
                Contractors, Gardeners, Caretakers, Directors and Agents can all collaborate together to get issues resolved.
            `,
        }
    ]
    }
}
