/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { AbstractObject } from 'src/app/model/abstract-object';
import { Field, FieldType } from 'src/app/shared/field/Field';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service'
import { HttpParams } from '@angular/common/http';
import { FormControl, UntypedFormGroup } from '@angular/forms';
import { AppFormControl } from '../form/app-form-control';

export class PicklistOptions {
    refreshes?: (string | ((item: Partial<AbstractObject>) => void))[];
    items?: AbstractObject[];
    service?: AbstractHttpService;
    serviceFilter?: HttpParams;
    valueObject?: AbstractObject;
    valueObjectName?: string;
    asRadio?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    optionValue?: (item: any) => (any);
    allowSelectNone?: boolean;
    blockFilterNone?: boolean;
    detailView?: string;
    optionDisplayValue?: (AbstractObject) => string;
    selfPopulate?: boolean
}

export class PicklistField extends Field {
    type: FieldType = 'picklist';
    filterString = ''; // The text shown in ComboBoxes, to display value or filter list contents...
    filterControl = new FormControl<string | AbstractObject>(''); // v 2.0 filterString

    picklist: PicklistOptions = {
        items: [],
        refreshes: [],
        optionDisplayValue: (o) => o && o.name ? o.name : '',
        optionValue: (o) => o ? o.id : null,
    };

    constructor(defaultOptions: Partial<PicklistField>, moreOptions: Partial<Field> = {}) {
        super(defaultOptions, moreOptions);
    }

    setPicklistItems(items: AbstractObject[]) {
        this.picklist.items.length = 0;
        items.forEach(i => this.picklist.items.push(i));
        if (this.picklist.allowSelectNone) {
            this.allowEmpty();
        }
        // Force the combobox to list the values
        if (this.filterControl.value === undefined && this.control.value) {
            const selectedItem = this.picklist.items.find(item => item.id === this.control.value);
            this.filterControl.setValue(selectedItem);
        } else {
            this.filterControl.setValue(this.filterControl.value);
        }
    }

    /** Format a raw value into a string value for display purposes */
    formatValue(val) {
        const o: AbstractObject = this.picklist.items.find(o => o.id === val);
        if (o) {
            return this.picklist.optionDisplayValue(o);
        } else {
            return val;
        }
    }

    setComboDisplayValue(item: AbstractObject) {
        this.filterString = this.getDisplayValueFromId(this.getValue(item));

        if (this.picklist.valueObject) {
            this.filterControl.setValue(this.picklist.valueObject, { emitEvent: false });
        } else {
            const selectedItem = this.picklist.items.find(item => item.id === this.control.value);
            this.filterControl.setValue(selectedItem, { emitEvent: false });
        }

        this.filterControl.markAsPristine();
        this.filterControl.markAsUntouched();
    }

    clearComboValue() {
        this.filterString = '';
        this.filterControl.setValue(null);
        this.filterControl.markAsPristine();
        this.filterControl.markAsUntouched();
    }

    setValue(item: AbstractObject, readonly = false) {
        //this.setValue(this.field.getValue(item), {onlySelf: true, emitEvent:false, emitModelToViewChange:true, emitViewToModelChange:false});
        if (this.picklist.valueObjectName) {
            this.picklist.valueObject = this.resolveValue(this.picklist.valueObjectName, item); //item[this.picklist.valueObjectName];
            if (this.picklist.valueObject && this.picklist.refreshes) {
                this.picklist.refreshes.forEach(fldName => {
                    if (typeof fldName === 'string') {
                        const ctl = (this.control.parent as UntypedFormGroup).get(fldName) as AppFormControl;
                        if (ctl) {
                            ctl.field.refresh(this.picklist.valueObject, ctl);
                        } else {
                            console.warn('No control [' + fldName + '] to refresh', this.control);
                        }
                    }
                });
            }
        }
        super.setValue(item, readonly);
        this.setComboDisplayValue(item);
    }

    getDisplayValue(itemWithId: AbstractObject): string {
        // Resolve value if needs be, in case value comes from related object
        const itemId = this.resolveValue(this.value, itemWithId);
        let selectedItem = null; //itemWithId[this.value];
        if (this.picklist.selfPopulate) {
            return this.resolveValue(this.picklist.valueObjectName, itemWithId);
        } else if (this.picklist.valueObjectName && itemWithId[this.picklist.valueObjectName]) {
            selectedItem = this.resolveValue(this.picklist.valueObjectName, itemWithId);
        } else {
            selectedItem = this.picklist.items.find(item => item.id === itemId);
        }

        if (selectedItem) {
            return this.picklist.optionDisplayValue(selectedItem);
        } else {
            return itemId;
        }
    }

    getDisplayValueFromId(id) {
        //console.log('Seeking pl id', id, this.picklist.items);
        /*
        * If there is an ID, we want to get the value to display, if there is no ID then do not try get value
        * An id of 0 is valid, but falsey, hence || id === 0
        * Yes It stinks a bit... I know...
        */

        if ((id || id === 0)) {
            if (!this.picklist.items) {
                console.warn('No Items Set', {pl: this});
            }
            const selectedItem = this.picklist.items.find(item => item.id === id);
            if (selectedItem) {
                return this.picklist.optionDisplayValue(selectedItem);
            } else {
                if (id !== ' ') { // id of ' ' is set by combo box, but will be reverted to null, treat as falsey
                    // Fires if items not arrived yet...
                    //console.warn('Could not find id', { id, selectedItem, itemLength: this.picklist.items.length }, this.picklist);
                }

                return id;
            }
        } else {
            return '';
        }
    }

    allowEmpty() {
        if (this.picklist.items.find(o => o.id === null)) {
            // Already enabled, nothing to do...
        } else {
            this.picklist.allowSelectNone = true;
            const emptyItem = ({ id: null, name: '' } as AbstractObject);
            this.picklist.items.unshift(emptyItem);
        }
        return this;
    }
}
