/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { TeamUser } from 'src/app/model/team-user';

@Injectable({
    providedIn: 'root'
})
export class TeamUserService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'tusers';
    protected cache: TeamUser[];
    protected typeString = 'TeamUser';

    constructor(protected http: HttpClient, protected messageService: MessageService) {
        super();
    }
}
