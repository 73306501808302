/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
*/

import { HttpParams } from "@angular/common/http";
import { SraChart } from "./SraChart";

export class SraOmcChart extends SraChart {
    title = "My OMC Requests";
    dataParams = new HttpParams().set('week', 0).set('show', 'myomcs');

}
