import { MatDialog } from "@angular/material/dialog";
import { MyInjector } from "src/app/app.module";
import { BankAccount } from "src/app/model/bankAccount";
import { ActionColor, IFormAction } from "src/app/shared/form/form.component";
import { LinkBankAccountComponent } from "./link-bank-account/link-bank-account.component";

/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
export class LinkBankAccountAction implements IFormAction {
    name = $localize`Link Account`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'link';
    approvalNeeded = false;
    tipText: string;
    approvalText = $localize`Link this bank account to your real bank account to collect transaction information`;
    bankAccount: BankAccount;

    dialog = MyInjector.instance.get(MatDialog);

    action() {
        return this.dialog.open(LinkBankAccountComponent, {
            data: {bankAccount: this.bankAccount}
        }).afterClosed()
    }

    setup(bankAccount: BankAccount) {
        this.tipText = this.approvalText;
        this.bankAccount = bankAccount;
        this.show = true;
    }
}