/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injector, NgModule } from '@angular/core';

/*
* Angular Material
*/
import { MatIconRegistry } from '@angular/material/icon';

@NgModule()

export class AppModule {
  constructor(injector: Injector, iconRegistry: MatIconRegistry) {
    console.warn('Initializing Module - THIS NO LONGER HAPPENS OR USED - HAPPENS IN APPCOMPONENT');
    MyInjector.instance = injector;
    iconRegistry.setDefaultFontSetClass('material-symbols-outlined');
  }
}

export class MyInjector {
  static instance: Injector;
}
