/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChatItem } from 'src/app/model/chat-item';
import { IsNarrowService } from '../../is-narrow.service';
import { FromNowPipe } from 'src/app/shared/pipes/from-now.pipe';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgTemplateOutlet, DatePipe } from '@angular/common';
import { ChatReplyComponent } from '../chat-reply/chat-reply.component';
import { MatIconModule } from '@angular/material/icon';
import { AvatarComponent } from '../../../modules/user/avatar/avatar.component';
import { UserNameComponent } from 'src/app/modules/user/user-name/user-name.component';

@Component({
    selector: '[app-chat-entry]',
    templateUrl: './chat-entry.component.html',
    styleUrls: ['./chat-entry.component.scss'],
    standalone: true,
    imports: [AvatarComponent, MatIconModule, ChatReplyComponent, NgTemplateOutlet, 
        MatTooltipModule, MatButtonModule, DatePipe, FromNowPipe, UserNameComponent]
})
export class ChatEntryComponent implements OnInit {

    @Input()
    chatItem: ChatItem;
    StupidName: string;

    @Input()
    depth = 0;

    @Input()
    isFirst = false;

    @Input()
    isLast = false;

    @Input()
    allowReply = true;

    @Output() replyButton = new EventEmitter();

    indent = 0;

    isPhone = false;

    constructor(isNarrowSvc: IsNarrowService) {
        isNarrowSvc.detectVeryNarrow().subscribe(result => { this.isPhone = result });
    }

    ngOnInit(): void {
        this.indent = this.depth * 40;
        //    console.log({chatItem: this.chatItem, depth: this.depth, i: this.indent,  f: this.isFirst, l: this.isLast});
    }

    getIcons() {
        if (Array.isArray(this.chatItem.replies)) {
            return this.chatItem.replies.filter(ci => ci.isIcon);
        }
    }
    /*
    userGaveOpinion(c: any) {

    }

    showApproval(f,g){

    }
    */
}
