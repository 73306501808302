<div class="postsHolder carded left-sidebar inner-scroll" id='div-post-list'>

  <div class="postsContent blank p-24">
    <div class="pickerDiv">
        <app-channel-picker (channelSelected)="filterByChannel($event)"
                    [currentChannel]="currentChannelFilter" contentType="Posts"></app-channel-picker>
    </div>
    @if (posts.length === 0) {
      <mat-card appearance="raised" class="post-item">
        <mat-card-header>
          <mat-card-title>No Posts Yet!</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div style="padding-left: 70px;">
            <span i18n>There are no posts yet, go on start the conversation by creating a post!</span>
          </div>
        </mat-card-content>
      </mat-card>
    }
    @for (post of posts; track $index) {
      <div>
        <mat-card appearance="raised" [routerLink]="['/social/posts/'+post.id, currentChannelFilter ? {channelId: currentChannelFilter} : {}]" class="post-item">
          <mat-card-header>
          <!-- mat-card-title>{{post.title}}</mat-card-title>
          <mat-card-subtitle>
            <app-user [id]="post.authorId"></app-user> {{post.createdAt | fromNow }}
            </mat-card-subtitle -->
            <table width="100%"><tr><td width="100%">
              <mat-card-title data-post-title>{{post.title}}</mat-card-title>
              <mat-card-subtitle>
                Posted in
                @if (post.areaName) { {{post.areaName}} }
                @if (post.coreName) { ({{post.coreName}}) }
                {{post.forum.name}} <br/>
                {{post.createdAt | fromNow }}
                @if (post.updatedAt) {
                    <span>(Updated {{post.updatedAt | fromNow}})</span>
                }
            </mat-card-subtitle>
            </td><td width="40px"><app-avatar [id]="post.authorId"></app-avatar></td></tr></table>
          </mat-card-header>
          <mat-card-content>
            <div class="post-excerpt">
              <table>
                <tr>
                  <td>
                    <div style="display:inline">{{getExcerpt(post)}}</div>
                  </td>
                </tr>
              </table>
            </div>
          </mat-card-content>
        </mat-card>
        <p></p>
      </div>
    }
    <div style="float:right; padding-right:20px" #bottom_marker id='bottom_marker'><span class="small">Copyright OurOMC 2020-2025 - All rights
    reserved</span></div>
  </div>
</div>
