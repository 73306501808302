/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Inject, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { RecurringJob } from 'src/app/model/recurring-job';
import { ServiceRequest } from 'src/app/model/serviceRequest';

import { ChartParms } from 'src/app/model/sys/nav-item';
import { Omc } from 'src/app/model/Omc';
import { User } from 'src/app/model/user';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormConfig } from "src/app/shared/form/FormConfig";
import { NavRoute } from 'src/app/shared/NavRoute';
import { removeError, required } from 'src/app/shared/validators';
import { OmcService } from '../../agent/omc.service';
import { UserService } from '../../user/user.service';
import { RequestTemplatesService } from '../request-templates.service';
import { RecurringJobService } from './recurring-job.service';
import { DialogOptions } from 'src/app/shared/dialogs/pick-dialog/pick-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { AbstractObject } from 'src/app/model/abstract-object';
import { HttpParams } from '@angular/common/http';
import { FormButtonComponent } from 'src/app/shared/form/form-button/form-button.component';
import { RecurringJobEditor } from './recurring-job-editor';
import { ServiceRequestPageComponent } from '../service-request-page/service-request-page.component';
import { RequestService } from '../request.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { NewServiceRequestComponent } from '../new-service-request/new-service-request.component';
import { ValidationErrors } from '@angular/forms';
import { map } from 'rxjs/operators';
import { ErrorCode } from 'src/app/shared/form/form-error/form-error.component';
import { RequestTemplate } from 'src/app/model/RequestTemplate';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { SupplierPageComponent } from '../../supply/supplier-page/supplier-page.component';

@Component({
    selector: 'app-recurring-job',
    templateUrl: './recurring-job.component.html',
    styleUrls: ['./recurring-job.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class RecurringJobComponent extends AbstractPageComponent {

    static readonly navRoute = new NavRoute('crm/recurringJobs', RecurringJobComponent, 'repeat').setViews( (user: User ) => {
        return [
            {
                id: 'mine',
                name: 'My OMCs Recurring Jobs',
                filterFields: {
                    omc_managerId: user.id,
                    templateId: 'All',
                    frequencyId: '!=0',
                    omcId: 'All',
                },
                sort: 'nextOccurence'
            },
            {
                id: 'myadmin',
                name: 'My Admin OMCs Recurring Jobs',
                filterFields: {
                    omc_adminId: user.id,
                    templateId: 'All',
                    frequencyId: '!=0',
                    omcId: 'All',
                },
                sort: 'nextOccurence'
            },
            {
                id: 'myfinance',
                name: 'My Finance OMCs Recurring Jobs',
                filterFields: {
                    omc_financeId: user.id,
                    templateId: 'All',
                    frequencyId: '!=0',
                    omcId: 'All',
                },
                sort: 'nextOccurence'
            },
            {
                id: 'all',
                name: 'All Recurring Jobs',
                sort: 'nextOccurence',
                filterFields: {
                    templateId: 'All',
                    frequencyId: '!=0',
                    omcId: 'All',
                },
            }
        ]
    }).setCharts( [
            { type: 'bar', picklist: 'omcId' } as ChartParms,
            { type: 'bar', picklist: 'templateId'} as ChartParms,
            { type: 'pie', picklist: 'frequencyId'} as ChartParms,
            { type: 'pie', picklist: 'lastRequest_srStatus'} as ChartParms
    ])

    users: User[] = [];

    omcField = FormPicklistComponent.make('Owner Management Company', 'omcId', 'omc',
        { service: this.omcSvc, refreshes: [this.setTeamId.bind(this)] }, {
            validators: [required],  asyncValidators: [this.validateOMC.bind(this)]
    });

    teamField: Field = FieldMaker.idHolder('teamId');
    templateField = FormPicklistComponent.make('Template', 'templateId', 'template', { service: this.tmplSvc },
        { validators: [required], asyncValidators: [this.validateTemplate.bind(this)]
    });

    /* Last Status Unknown without completex queries
    lastSubStatusField = ServiceRequest.getSubStatusField().override({value: 'lastRequest.subStatus'});
    */
    lastStatusField = FormPicklistComponent.make('Last Status', 'lastRequest.srStatus', null,
        { items: ServiceRequest.srStates, },
        { readonly: true, visible: Field.noForm, sendServer: false }
    );

    lastPlannedField = FormDateComponent.make('Last Planned', 'lastRequest.plannedDate',
        {readonly: true, sendServer: false, formColumn: 3, visible: Field.noForm}
    );

    lastOwnerField = FormPicklistComponent.make('Last Owner', 'lastRequest.ownedById', null,
        { items: this.users },
        {
            formColumn: 1, readonly: true, sendServer: false, visible: Field.noForm,
        }
    );

    /* requestGrid: GridField = new GridField(
        {
            field: { value: 'occurences', readonly: true, cellOpts: { heading: 'Requests' }, formRow: 2, sendServer: false, visible: Field.formOnly, },
            rowFactory: () => [
                FieldMaker.id(),
                ServiceRequest.getRefField(),
                ServiceRequest.getTitleLinkField(),
                ServiceRequest.getDescriptionField(),
                ServiceRequest.getStateField(),
                FormDateComponent.make('Planned Date', 'plannedDate', {readonly: true}),
                ServiceRequest.getCreatedField(),
                ServiceRequest.getLastUpdatedField(),
                ServiceRequest.getOwnedByROField()
            ],
        }
    );
    */

    lastRequestLink = FormButtonComponent.makeTableButton('Last Request', 'refNr', (item) => {

        const job = (item as RecurringJob);
        if (job.lastRequest?.id) {
            this.dialog.open(ServiceRequestPageComponent,
                {
                    data: {
                        id: job.lastRequest.id,
                    }
                }
            ).afterClosed().subscribe(() => {
                this.requestSvc.getOne(job.lastRequest.id, null).subscribe((request) => {
                    job.lastRequest = request;
                });
            });
        }
    }, {
        calculateValue: (o: RecurringJob) => o?.lastRequest?.refNr,
        visible: Field.noForm
    });

    supplierField = FormButtonComponent.makeNavDetailButton('Supplier', 'supplier.name', 'supplierId', SupplierPageComponent.navRoute);
    supplierNewRequestField = FormButtonComponent.makeTableButton('+', 'newSupplierRequest',
        (item) => this.createNewSupplierRequest(item as RecurringJob), {
        calculateValue: (rj: RecurringJob) => rj.supplierId ? 'add' : '',
        visible: Field.noForm, type: 'icon',
        toolTip: $localize`Create a new request for this supplier, for something other than to perform this regular job`
    });

    occurencesLink = FormButtonComponent.makeNavButton('Occurences',
        ServiceRequestPageComponent.navRoute, o => ({ jobId: o.id, _sort: 'refNr' }),
        { visible:Field.noForm, value: 'occurences_count', cellOpts: { style: 'text-align: center' } }
    );

    configReady = new ReplaySubject<null>(1);

    /* timesDoneField = FormNumberComponent.make('Times Done', 'timesDone', {}, {
        label: 'Times Done', cellOpts: {heading: 'Times Done'}, disable: true, visible: Field.formOnly,
    }); */

    propertyManagerField = FormPicklistComponent.make('Property Manager', 'omc.managerId', null,
        { items: this.users },
        { formColumn: 1, readonly: true, sendServer: false, visible: Field.noForm,
            calculateValue: o => (o as RecurringJob).omc?.managerId
        }
    );

    propertyAdminField = FormPicklistComponent.make('Admin', 'omc.adminId', null,
        { items: this.users },
        { formColumn: 1, readonly: true, sendServer: false, visible: Field.noForm,
            calculateValue: o => (o as RecurringJob).omc?.adminId
        }
    );

    propertyFinanceField = FormPicklistComponent.make('Finance', 'omc.financeId', null,
        { items: this.users },
        {
            formColumn: 1, readonly: true, sendServer: false, visible: Field.noForm,
            calculateValue: o => (o as RecurringJob).omc?.financeId
        }
    );

    frequencyPicklist = RecurringJob.getFrequencyField().override({visible: Field.formOnly});

    editLink = FormButtonComponent.makeTableButton('Edit', 'Edit', (item) => {
        new RecurringJobEditor(item as RecurringJob).editJob();
    }, {
        visible: Field.noForm, calculateValue: () => 'edit',
        type: 'icon'
    });

    frequencyField = FormTextComponent.make('Frequency', 'frequency.name', {sendServer: false, readonly: true, visible:Field.noForm});

    nextOccurenceField = FormDateComponent.make('Next Occurence', 'nextOccurence', {
        formColumn: 2, hint: "Date this next needs to be planned",
    });

    nextFieldTable = FormButtonComponent.makeTableButton('Create Next Request', 'whatever',
        (item) => this.createNext(item as RecurringJob), {
            calculateValue: (o: RecurringJob) => o.lastRequest?.srStatus === 'open' ? '' : 'add_circle' ,
            visible: Field.noForm, type: 'icon',
            toolTip: $localize `Create the next request for this recurring job`
    });

    config = new FormConfig({
        navRoute: RecurringJobComponent.navRoute,
        title: $localize`Recurring Job`,
        help: $localize`Recurring Jobs`,
        fieldSet: new FieldSet({
            fields: [
                FieldMaker.id(),
                FieldMaker.rev(),
                this.teamField,
                this.omcField,
                this.templateField,
                this.supplierField,
                this.supplierNewRequestField,
                this.frequencyPicklist,
                this.frequencyField,
                this.nextOccurenceField,
                this.editLink,
                this.lastRequestLink,
                this.lastStatusField,
                this.nextFieldTable,
                this.lastPlannedField,
                this.lastOwnerField,
                this.occurencesLink,

                this.propertyManagerField,
                this.propertyAdminField,
                this.propertyFinanceField,
                //ServiceRequest.getTitleLinkField().override({value: 'last.title'}),
                FieldMaker.notes({formColumn: 3, cellOpts: {width: '50%'}}),
                // this.requestGrid,

            ],
            formLayout: [{ cells: [{ width: '25%' }, { width: '25%' }, { width: '50%' }] },
                         { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.beforeNew.bind(this),
        beforeEdit: this.beforeEdit.bind(this),
        beforeList: this.beforeList.bind(this),
        configReady:  this.configReady,
        //actions: [new RequestJobAction(this.router) ],
        afterSave: () => {
            history.back();
        }
    });

    beforeNew() : Observable<RecurringJob>{
        this.frequencyPicklist.visible = Field.formOnly;
        return of(new RecurringJob());
    }

    setTeamId(omc: Omc) {
        this.teamField.control.setValue(omc.omcTeamId, {emitEvent: false});
    }

    beforeEdit(rj: RecurringJob) {
        if (rj && rj.occurences) {
            rj.timesDone = rj.occurences.length;
        } else {
            rj.timesDone = 0;
        }
        if (!rj.occurences) {
            rj.occurences = [];
        }
        return rj;
    }

    constructor(private dataSvc: RecurringJobService,  private omcSvc: OmcService, private router: Router,
        teamUserSvc : UserService, private dialog: MatDialog, private requestSvc: RequestService,
        private tmplSvc: RequestTemplatesService, private msgSvc: MessageService,
        @Optional() public dialogRef: MatDialogRef<RecurringJobComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: DialogOptions) {

        super();

        combineLatest([teamUserSvc.getUsers()]).subscribe(
            ([users]) => {

                for (const u of users as User[]) {
                    this.users.push(u);
                }

                this.configReady.next(null)
            }
        )
    }

    validateOMC() : Observable<ValidationErrors | null> {

        const omcId = this.omcField.control.value;
        if (!omcId) {
            return of(null);
        }
        const omc = this.omcField.picklist.items.find( o => o.id === omcId) as Omc;
        if (!omc) {
            return of(new ErrorCode('InvalidOMC', 'Invalid OMC').new());
        }

        const template = (this.templateField.picklist.valueObject as RequestTemplate);
        if (!template) {
            return of(null);
        }

        const parms = new HttpParams().set('templateId', template.id);
        const errCode = 'templateExists';

        return this.dataSvc.get(false, parms, omc.omcTeamId).pipe( map( (result) => {
            if (result && result.length > 0) {
                return new ErrorCode(errCode, 'Already has job for ' + template.name).new()
            } else {
                removeError(this.templateField.control, errCode);
                removeError(this.omcField.control, errCode);
            }
            return null;
        }));
    }

    validateTemplate() : Observable<ValidationErrors | null> {

        const omc = (this.omcField.picklist.valueObject as Omc);
        if (!omc) {
            return of(null);
        }
        const templateId = this.templateField.control.value;
        if (!templateId) {
            return of(null);
        }

        const parms = new HttpParams().set('templateId', templateId);
        const errCode = 'templateExists';

        return this.dataSvc.get(false, parms, omc.omcTeamId).pipe( map( (result) => {
            if (result && result.length > 0) {
                return new ErrorCode(errCode, 'Already Exists for ' + omc.name).new()
            } else {
                removeError(this.templateField.control, errCode);
                removeError(this.omcField.control, errCode);
            }
            return null;
        }));
    }

    createNewSupplierRequest(rj: RecurringJob) {
        if (rj.supplierId) {
            this.dialog.open(NewServiceRequestComponent,
                {
                    data: {
                        omcId: rj.omcId,
                        supplierId: rj.supplierId,
                    }
                }
            );
        } else {
            const msg = $localize`Can only add requests here if the supplier is entered for the job`;
            const msgDef = new MsgDef(msg, 'warn');

            this.msgSvc.show(msgDef);
        }
    }

    createNext(rj: RecurringJob) {
        if (rj.lastRequest?.srStatus === 'open') {
            const msg = $localize`Please complete Service Request ${rj.lastRequest.refNr} before creating next`;
            const msgDef = new MsgDef(msg, 'warn');

            this.msgSvc.show(msgDef);
            return;
        }

        const popDialogRef = this.dialog.open(NewServiceRequestComponent,
            {
                data: {
                    //teamId: rj.teamId,
                    omcId: rj.omcId,
                    templateId: rj.templateId,
                    supplierId: rj.supplierId,
                    jobId: rj.id,
                    plannedDate: rj.nextOccurence,
                }
            }
        );
        popDialogRef.afterClosed().subscribe(() => {
            this.dataSvc.getOne(rj.id, null).subscribe(updatedRj => {
                if (updatedRj) {
                    Field.shallowCopy(updatedRj, rj);
                }
            });
        })
    }

    beforeList(data: AbstractObject[], params: HttpParams) {

        if (params.has('omcId')) {
            if (!this.propertyManagerField.filter.active) {
                this.propertyManagerField.visible.computer = false;
                this.propertyManagerField.visible.phone = false;
                this.propertyAdminField.visible.computer = false;
                this.propertyAdminField.visible.phone = false;
                this.propertyFinanceField.visible.computer = false;
                this.propertyFinanceField.visible.phone = false;
            }
        } else {
            if (!this.propertyManagerField.filter.active) {
                this.propertyManagerField.visible.computer = true;
                this.propertyManagerField.visible.phone = true;
                this.propertyAdminField.visible.computer = true;
                this.propertyAdminField.visible.phone = true;
                this.propertyFinanceField.visible.computer = true;
                this.propertyFinanceField.visible.phone = true;
            }
        }
        return data;
    }

}

/** No Longer Used ?
class RequestJobAction implements IFormAction {
    name = $localize`Create Request`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'add';
    approvalNeeded = false;
    disabled = false;
    approvalText = '';
    router: Router;

    constructor(router: Router) {
        this.router = router;
    }

    action(job: RecurringJob) { // config: FormConfig not needed

        this.router.navigate(['/crm/requests/NEW'], {queryParams: {templated: job.templateId, omcId: job.omcId}});
        return of(null);
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(job: RecurringJob) {
        if (job.id) {
            this.name = this.name + ' for ' + job.template.name
            this.show = true;
        } else {
            this.show = false;
        }
    }
}
 */
