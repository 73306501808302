/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { FORM_ERRORS } from 'src/app/shared/form/form-error/form-error.component';
import { ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { GridRow } from 'src/app/shared/grid/grid-row';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import moment from 'moment';
import { Cycle } from '../model/cycle';
import { Field } from './field/Field';
import { DateHelper } from './dateHelper';
import { Period } from '../model/period';
import { PicklistField } from './field/PicklistField';

export const minWords = (minAllowed: number): ValidatorFn => (control: AbstractControl): ValidationErrors => {
    const wordCount: number = (control.value ? control.value.trim().split(' ').length : 0);

    if (wordCount < minAllowed) {
        return FORM_ERRORS.minWords.new({ minWords: minAllowed, actualWords: wordCount });
    } else {
        return null;
    }
};

export const minChars = (minAllowed: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        const charCount: number = (control.value ? control.value.trim().length : 0);
        if (charCount < minAllowed && control.value) {
            return FORM_ERRORS.minChars.new({ minChars: minAllowed, actualChars: charCount });
        } else {
            return null;
        }
    };

export const minValue = (minAllowed: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {

        if (control.value < minAllowed) {
            return FORM_ERRORS.minValue.new({ minValue: minAllowed, actualValue: control.value });
        } else {
            return null;
        }
    };

export const maxValue = (maxAllowed: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {

        if (control.value > maxAllowed) {
            return FORM_ERRORS.maxValue.new({ maxValue: maxAllowed, actualValue: control.value });
        } else {
            return null;
        }
    };

export const maxChars = (maxAllowed: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        const charCount: number = (control.value ? control.value.trim().length : 0);
        if (charCount > maxAllowed && control.value) {
            return FORM_ERRORS.maxChars.new({ minChars: maxAllowed, actualChars: charCount });
        } else {
            return null;
        }
    };

export const required = (control: AbstractControl): ValidationErrors => {
    if ((!control.value && control.value !== 0) || (typeof control.value === 'string' && control.value.trim().length === 0)) {
        return FORM_ERRORS.required.new();
    } else {
        return null;
    }
};

export const nonZero = (control: AbstractControl): ValidationErrors => {
    if ((control?.value === 0)) {
        return FORM_ERRORS.required.new();
    } else {
        return null;
    }
};

export const greaterThen = (relativeField: Field): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        if (control?.value && relativeField?.control?.value) {
            if (relativeField.control.value > control.value) {
                return FORM_ERRORS.greaterThen.new({ relatedValue: relativeField.label });
            }
        }
        if (relativeField?.control && !relativeField.control.valid) {
            relativeField.control.updateValueAndValidity();
        }
        return null;
    };
export const lessThen = (relativeField: Field): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        if (control?.value && relativeField?.control?.value) {
            if (relativeField.control.value < control.value) {
                return FORM_ERRORS.lessThen.new({ relatedValue: relativeField.label });
            }
        }
        if (relativeField?.control && !relativeField.control.valid) {
            relativeField.control.updateValueAndValidity();
        }
        return null;
    };

export const validateDate = (): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        if (moment(control.value, 'YYYY-MM-DD').isValid()) {
            //console.log('Is valid', control.value, moment(control.value, 'YYYY-MM-DD').isValid());
            return null;
        } else {
            //console.log('Return Error');
            return FORM_ERRORS.validateDate.new();
        }
    };

export const validateTxnDate = (cycleFld: PicklistField, periodFld: Field): ValidatorFn =>
    (control: AppFormControl): ValidationErrors => {
        const txnDate = moment(control.value);
        // Watch what happens around midnight...console.error(txnDate);
        if (!txnDate.isValid() || Field.isEmpty(control.value)) {
            return FORM_ERRORS.validateDate.new();
        } else {
            for (const cycleO of cycleFld.picklist.items) {
                const cycle = cycleO as Cycle;
                if (DateHelper.isoDateIn(control.value, cycle.from, cycle.to)) {
                    for (const period of cycle.periods) {
                        if (DateHelper.isoDateIn(control.value, period.from, period.to)) {
                            if (Period.open(period)) {
                                cycleFld.control.setValue(cycle.id);
                                periodFld.control.setValue(period.id);
                                /*
                                ** This is the only successful exit from the validation!
                                */
                                return null;
                            } else {
                                return FORM_ERRORS.periodClosed.new({ periodName: period.name });
                            }
                        }
                    }
                    return FORM_ERRORS.outsidePeriod.new({ txnDate: control.value, cycle: cycle.name });
                }
            }
            console.warn(cycleFld);
            return FORM_ERRORS.outsideCycle.new({ txnDate: control.value });

        }
    }

export const numericRange = (min: number, max: number): ValidatorFn =>
    (control: AbstractControl): ValidationErrors => {
        if (control.value < min || control.value > max) {
            console.log('Return Error');
            return FORM_ERRORS.numericRange.new({ min, max });
        } else {
            return null;
        }
    };

export const removeError = (ctl: AbstractControl, err: string) => {
    if (ctl) {
        if (ctl.hasError(err)) {
            delete ctl.errors[err];
            //ctl.updateValueAndValidity(); ctl.errors does the trick, but do not put it inside hasError!
        }
        if (ctl.errors) {
            if (Object.keys(ctl.errors).length === 0) {
                ctl.setErrors(null);
            }
        }
    } else {
        console.log(' no ctl');
    }
}
//export const maxValue   = (maxAld: number         ): ValidatorFn => (control: AbstractControl): ValidationErrors => {
export const uniqueGrid = (uField = 'name'): ValidatorFn => (ctl: AbstractControl): ValidationErrors => {
    {
        const gridControl = (ctl as GridControl);
        const unique = {};
        let duplicates = false;
        gridControl.controls.forEach((gridRow: GridRow) => {
            if (gridRow.get(uField)) {
                removeError(gridRow.get(uField), 'unique');
                if (!gridRow.deleted) {
                    if (gridRow.get(uField).valid) {
                        const val = gridRow.get(uField).value;
                        if (unique[val]) {
                            unique[val].push(gridRow.get(uField));
                            duplicates = true;
                        } else {
                            unique[val] = [gridRow.get(uField)];
                        }
                    }
                }
            }
        });
        if (duplicates) {
            Object.keys(unique).forEach((key) => {
                if (unique[key].length > 1) {
                    unique[key].forEach((gridCell: AppFormControl) => {
                        gridCell.setErrors(FORM_ERRORS.unique.new());
                        gridCell.markAsTouched();
                    });
                }
            });
            console.log(gridControl);
            return FORM_ERRORS.unique.new();
        }
        return null;
    }
};

export const percentZeroOrOne = (gridControl: GridControl, pField = 'percent'): ValidationErrors => {
    {
        let total = 0;
        gridControl.controls.forEach((gridRow: GridRow) => {
            const pctCtl = gridRow.get(pField) as AppFormControl;
            if (pctCtl) {
                removeError(pctCtl, 'percentZeroOrOne');
                if (!gridRow.deleted) {
                    total += pctCtl.value;
                }
            }
        });
        removeError(gridControl, 'percentZeroOrOne');
        if (total !== 0 && total !== 1) {
            const err = FORM_ERRORS.percentZeroOrOne.new({ currentTotal: total * 100 });
            gridControl.controls.forEach((gridRow) => {
                const pctField = gridRow.get(pField);
                if (pctField) {
                    pctField.setErrors(err);
                    pctField.markAsTouched();
                }
            });
            return err;
        }
        return null;
    }
};
