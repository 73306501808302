/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Ballot, Vote } from 'src/app/model/ballot';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';

import { Observable } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
    providedIn: 'root'
})
export class VoteService extends AbstractHttpService {
    protected baseUrl = this.ajaxPath + 'social/votes';

    protected cache: Ballot[];
    protected typeString = 'Votes';


    constructor(protected http: HttpClient,
        protected messageService: MessageService) {
        super();
    }

    /*
      return this.http.post<Vote[]>(this.baseUrl, object, this.httpOptions)
          .pipe(
              map((data: T) => {
                  return data;
              }),
              tap((data: T) => {
                  console.log('Added a new ' + this.typeString);
                  if (showMsg) {
                      this.messageService.show(this.msgAdded);
                  }
              }),
              catchError(this.handleError<Vote[]>('post' + this.typeString))
          );
    */

    getItems(data: { items: Vote[] }): Vote[] {
        return data.items as Vote[];
    }

    postVote(object: Vote, forceTeamId: uuid = null): Observable<Vote[]> {
        return this.http.post<{ items: Vote[] }>(this.baseUrl, object, this.getSimpleOptions(forceTeamId))
            .pipe(
                map((retValue) => {
                    return (this.getItems(retValue) as Vote[]);
                }),
                tap((data) => {
                    console.log('Added a new ' + this.typeString, data);
                    this.messageService.show('Your vote has been cast, thank you for participating');
                }),
                catchError(this.handleError<Vote[]>('post' + this.typeString))
            );
    }

}
