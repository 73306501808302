/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Unit } from 'src/app/model/unit';
import { MyUnitService } from 'src/app/modules/unit/my-unit.service';
import { PayzService } from '../../payzService';
import { Frequency } from 'src/app/model/frequency';
import { TxnService } from '../../txn.service';
import { Txn } from 'src/app/model/txn';
import { CycleService } from 'src/app/modules/budget/cycle.service';
import { first } from 'rxjs/operators';
import { Cycle } from 'src/app/model/cycle';
import { forkJoin } from 'rxjs';
import { Payz } from 'src/app/model/Payz';
import moment from 'moment';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { uuid } from 'src/app/model/abstract-object';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgTemplateOutlet, CurrencyPipe } from '@angular/common';
import { PartPayComponent } from '../part-pay/part-pay.component';
import { PayPlanComponent } from '../pay-plan/pay-plan.component';
import { UnitMandateComponent } from '../unit-mandate/unit-mandate.component';
import { PayInFullComponent } from '../pay-in-full/pay-in-full.component';
import { MatIconModule } from '@angular/material/icon';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

@Component({
    selector: 'app-payment-options',
    templateUrl: './payment-options.component.html',
    styleUrls: ['./payment-options.component.scss'],
    standalone: true,
    imports: [MatCardModule, MatTabsModule, MatExpansionModule, MatIconModule, PayInFullComponent, UnitMandateComponent, PayPlanComponent, PartPayComponent, NgTemplateOutlet, MatSelectModule, MatOptionModule, MatButtonModule, CurrencyPipe]
})
export class PaymentOptionsComponent implements OnInit {

    static readonly navRoute = new NavRoute('payments', PaymentOptionsComponent, '');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    sepaMethods: any = null;
    units: Unit[] = [];
    unitName: string;
    txns: Txn[] = [];

    payInFullOpen = true;
    paySomethingOpen = false;
    setupPlanOpen = false;
    mandateOpen = false;
    transactionsOpen = false;
    sepaMethodsOpen = false;

    frequencies = Frequency.PAYMENT_OPTIONS;
    txnTypes = Txn.TYPES;
    currentCycle: Cycle;

    constructor(private payzSvc: PayzService,
        private myUnitSvc: MyUnitService,
        private txnSvc: TxnService,
        private cycleSvc: CycleService,
        private currentUserSvc: CurrentUserService) {
    }

    ngOnInit(): void {

        forkJoin({
            sepaMethods: this.payzSvc.getSepaMethods().pipe(first()),
            units: this.myUnitSvc.get(),
            current: this.cycleSvc.get(true).pipe(first())
        }).subscribe(results => {
            console.log(results);
            this.sepaMethods = results.sepaMethods;
            this.currentCycle = this.cycleSvc.getCurrentCycle();

            for (const unitO of results.units) {
                const unit = unitO as Unit;
                unit.inProgressPayz = [];
                this.txnSvc.get<Txn>(false, new HttpParams().set('unitId', unit.id)).subscribe(data => {
                    this.unitName = unit.name;
                    unit.transactions = data;
                    Unit.calculatePaymentSchedule(unit, this.currentCycle);

                });
                this.payzSvc.getInProgress(unit.id).pipe(first()).subscribe(data => {
                    unit.inProgressPayz = data as Payz[];
                })
                this.payzSvc.getMandateForUnit(unit.id).pipe(first()).subscribe(data => {
                    unit.payzMandate = data;
                })
            }
            this.units = (results.units as Unit[]);
        })
    }

    getTxnType(typeId: uuid) {
        const txnType = Txn.TYPES.find(type => type.id === typeId);
        if (txnType) {
            return txnType.name
        } else {
            return $localize` Unknown `;
        }
    }
    getTxnRef(txn: Txn) {
        if (txn.typeId === Txn.TYPE.BANK_IN_ALLOC.id || txn.typeId === Txn.TYPE.BANK_IN_ON_ACCT.id) {
            return txn.parentId;

        } else {
            return txn.id;
        }
    }

    getTxnLink(txn: Txn) {
        return Txn.getTxnLink(txn);
    }

    getMoment(isoDate) {
        return moment(isoDate).fromNow();
    }

}
