<div>
    @if (!field.btnOpts.hide || !field.btnOpts.hide(focusItem)) {
        <button class="row-button" (click)="clickIcon($event,field,focusItem)" mat-icon-button
            [disabled]="!field.btnOpts.tableClickMethod"
            [ngStyle]="field.tableIconStyler(focusItem)">
            @if(!field.tableIconStyler) {
                <mat-icon color="primary">{{field.getValue(focusItem)}}</mat-icon>
            } @else {
                <mat-icon>{{field.getValue(focusItem)}}</mat-icon>
            }
        </button>
    }
</div>
