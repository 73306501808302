/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { Billing } from 'src/app/model/billing';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { Params, Router } from '@angular/router';
import { SchedulePageComponent } from './schedule-page/schedule-page.component';
import { uuid } from 'src/app/model/abstract-object';

@Injectable({
    providedIn: 'root'
})
export class BillingService extends AbstractHttpService {

    protected baseUrl = this.ajaxPath + 'budgets/billing';
    protected cache: Billing[];
    protected typeString = 'Billing';

    private msgBilled: MsgDef = new MsgDef(
        'Billing submitted successfully. Status is now showing billing. Please check back soon to confirm it has been completed'
    );

    constructor(protected http: HttpClient, protected messageService: MessageService,
        private cds: ConfirmDialogService, private router: Router) {
        super();
    }

    billout(item: Billing): Observable<Billing> {
        const url = `${this.baseUrl}/${item.id}/${item.revision}/billout`;
        return this.http.put<Billing>(url, null, this.httpOptions)
            .pipe(
                map((data: Billing) => data),
                tap((data: Billing) => {
                    this.messageService.show(this.msgBilled);
                    return data;
                }),
                catchError((error: HttpErrorResponse) => {
                    console.warn(error);
                    if (error.error?.code === 'NO_PORTIONS_FOR_SCHEDULE') {
                        return this.cds.open($localize `Setup Schedule`,
                        $localize `You cannot billout yet as portions have not been allocated to units. Setup schedule now?`,
                        () => {
                            return this.router.navigate([SchedulePageComponent.navRoute.url]);
                        })
                    } else {
                        return this.handleError('Billing Out', item)(error);
                    }
                })
            );
    }
/** Is just for testing, will be emailed... */
    getPDF(billing: Billing, parms: unknown) {
        const url = `${this.baseUrl}/invoices/${billing.id}/${billing.revision}/send`;
        this.downloadPostFile(url, parms, billing.teamId, 'application/pdf');
    }

    postFileDownload(path, data: unknown, forceTeam: uuid = null, accepts = 'application/octet-stream'): Observable<HttpResponse<ArrayBuffer>> {
        let params: Params = {};
        if (forceTeam) {
            params = { _forceTeam: forceTeam }
        }
        return this.http.post(path, data,
            { params, headers: { 'Accept': accepts }, observe: 'response', responseType: 'arraybuffer' }
        );
    }

    downloadPostFile(path: string, data: unknown, forceTeam: uuid = null, accepts = 'application/octet-stream') {
        this.postFileDownload(path, data, forceTeam, accepts).subscribe(fileResponse => {
            const contentType = fileResponse.headers.get('our-mime-type');
            const blob = new Blob([fileResponse.body], { type: contentType });
            const fileName = fileResponse.headers.get('our-file-name');
            const file = new File([blob], fileName, { type: contentType });
            console.log({ contentType, fileName, file });

            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style.display = "none";

            const fileURL = URL.createObjectURL(file);

            a.href = fileURL;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(fileURL);
        }, badResponse => {
            this.handleOneError('Downloading File',null)(badResponse);
        });
    }    
}
