/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { AbstractObject, uuid } from 'src/app/model/abstract-object';
import { Attachment } from 'src/app/model/attachment';
import { Memo } from 'src/app/model/memo';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { Action } from 'src/app/model/action';

export class FixedAsset extends AbstractObject {
    agentTeamId: uuid;
    attachments: Attachment[] = [];
    memos: Memo[] = [];
    requests: ServiceRequest[] = [];
    actions: Action[] = [];
}

@Injectable({
  providedIn: 'root'
})
export class FixedAssetService extends AbstractHttpService {
  protected baseUrl = this.ajaxPath + 'fassets';
  protected http = inject(HttpClient);
  protected messageService = inject(MessageService);
  protected cache: FixedAsset[];
  protected typeString = 'Fixed Asset';

}
