/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';

@Component({
    selector: 'app-ctl-holder',
    template: ``,
    styleUrls: ['./ctl-holder.component.scss'],
    standalone: true
})
export class CtlHolderComponent implements OnChanges {

    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';
    @Input() name: string;

    initialized = false;

    constructor(public viewContainerRef: ViewContainerRef) { }

    /*
    * Switched to OnChanges because angular content project will always initialize content immediately
    * So even if content is inside an if statement, it gets initialized
    * So projected formContent initializes before the initComplete has been fired
    * This change allows the components to be rendered as the content gets controls get created.
    */
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.control?.currentValue) {
            if (changes.control.previousValue) {
                console.warn('Control has changed', { holder: this, changes });
            }
            this.control.field.formControlFactory(this.viewContainerRef, this.control, this.on);
        }
    }
}
