/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { MyInjector } from '../app.module';
import { MessageService, MsgDef } from '../shared/message.service';
import { AbstractObject, uuid } from './abstract-object';
import { BCode } from './bcode';
import { Billing } from './billing';
import { BudgetSchedule } from './budget-schedule';
import { Period } from './period';
import { Schedule } from './schedule';

type Balance = { bCodeId: uuid, scheduleId: uuid, debits: number, credits: number, count: number }

export class Cycle extends AbstractObject {
    static readonly statusIds = {
        EDITTING: 0,
        PROPOSED: 3,
        ACCEPTED: 6,
        BILLING: 8,
        BILLED: 9,
    };

    static readonly statuses: AbstractObject[] = [
        { id: 0, name: $localize`Editing` },
        { id: 3, name: $localize`Proposed` },
        { id: 6, name: $localize`Accepted` },
        { id: 8, name: $localize`Billing` },
        { id: 9, name: $localize`Billed` },
    ];

    from = '';
    to = '';
    previousId: uuid;
    previous: Cycle;
    plannedSpend = 0;
    periods: Period[] = [];
    schedule: BudgetSchedule[] = [];
    budgetBCodes: BCode[] = [];
    schedules: Schedule[];
    previousBalances: Balance[];
    statusId: uuid;
    firstPayments: string; // date
    mandateDiscount: number; // percent
    lastPayments: string; // date
    fullPayDiscount: number;  // percent
    fullPayBy: string; // date

    budgetTotal?: number; // not stored
    prevTotal?: number; // not stored
    sortedDiffs: BudgetSchedule[] // not stored;
    billings: Billing[] = [];
    billedTotal = 0;
    yearEnds? = [];


    constructor(o: Partial<Cycle> = {}) {
        super(o);
    }

    static warnOpenPeriods(cycle: Cycle) {

        const msgSvc = MyInjector.instance.get(MessageService);

        if (cycle.previous?.periods.find(o => o.statusId === Period.STATUS.OPEN.id)) {
            const msg = $localize `WARNING: ${cycle.previous.name} has open periods, there maybe balances not brought forward`;
            msgSvc.show(new MsgDef(msg, 'fail'))
        } else if (cycle.previous?.periods.find(o => o.statusId === Period.STATUS.CLOSED.id)) {
            const msg = $localize `WARNING: ${cycle.previous.name} has unlocked periods, they could be reopened and balances could change`;
            msgSvc.show(new MsgDef(msg, 'warn'))
        }
    }    
}
