/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';

export class Notice extends AbstractObject {
  type: string;
  model: string;
  modelId: uuid;
  readAt: number; // Date....

  constructor(o: Partial<Notice> = {}) {
    super(o);
  }

  static getType(n: Notice) {
    if (n.type) {
      return n.type.substring(n.type.lastIndexOf("\\") + 1)
    } else {
      return 'Unknown';
    }
  }
}
