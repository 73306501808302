/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { ShowDemosComponent } from './show-demos/show-demos.component';
import { GetDemoAccessComponent } from './get-demo-access/get-demo-access.component';
import { LeadProfileService } from '../lead-profile.service';
import { CurrentUserService } from '../../user/current-user.service';


export type ApprovalStatus = 'yes' | 'no' | 'request' | 'hasCode'
@Component({
  selector: 'app-demos',
  standalone: true,
  imports: [ShowDemosComponent, GetDemoAccessComponent],
  templateUrl: './demos.component.html',
  styleUrl: './demos.component.scss'
})
export class DemosComponent {

    entryApproved: ApprovalStatus = 'no';
    lps = inject(LeadProfileService);

    constructor( cus : CurrentUserService) {
        if (this.lps.profile.validated) {
            this.entryApproved = 'yes';
        }
        cus.getCurrentUser().subscribe( u => {
            if (u) {
                this.entryApproved = 'yes';
            }
        })
    }
    
    giveUp() {
        this.entryApproved = 'no';
    }

    allowEntry() {
        this.entryApproved = 'yes';
    }

    requestAccessEvent(hasCode) {
        console.log({hasCode})
        if (hasCode) {
            this.entryApproved = 'hasCode';
        } else {
            this.entryApproved = 'request';
        }

    }
}
