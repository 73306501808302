/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*
* Create a bar chart to display above a table
* Yes - OK - It is a column chart really, but never heard anyone in real world call it a column chart...
*/
import { AbstractChart } from "./abstract-chart";

export class BarChart extends AbstractChart {

    height = 200;

    getTooltip() {
        return {
            pointFormat: '{series.name}: {point.y} <br/><b>{point.pct:.1f}%</b>'
        };
    }

    protected createChartOptions() {

        return {
            chart: {
                backgroundColor: this.chartBackgroundColor,
                borderRadius: this.chartBorderRadius,
                plotBorderWidth: null,
                type: 'column',
                height: this.height,
            },
            title: this.getChartTitle(),
            subtitle: this.getSubTitle(),
            credits: { enabled: false },
            tooltip: this.getTooltip(),
            xAxis: {
                type: 'category'
            },
            yAxis: {
                title: null
            },
            legend: { enabled: false },
            plotOptions: {
                /*series: {
                    borderWidth: 0,
                    dataLabels: {
                        enabled: true,
                        format: '{point.y}',
                        distance: 50
                    }
                }*/
                series: {
                    events: {
                        click: this.filterData.bind(this)
                    },
                }

            },
            series: [{
                name: this.picklist.label,
                colorByPoint: true,
                data: this.series
            }]
        }
    }
}
