/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { Ballot, Question } from 'src/app/model/ballot';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService, MsgDef } from 'src/app/shared/message.service';
import { catchError, first, map } from 'rxjs/operators';
import { uuid } from 'src/app/model/abstract-object';


@Injectable({
  providedIn: 'root'
})
export class BallotService extends AbstractHttpService {
  protected baseUrl = this.ajaxPath + 'social/ballots';

  protected cache: Ballot[];
  protected typeString = 'Ballot';


  constructor(protected http: HttpClient,
    protected messageService: MessageService) {
    super();
  }

  setSortOrder(ballot: Ballot, questions: Question[], forceTeamId: uuid = null) {
    const send = new Ballot();
    send.id = ballot.id;
    send.questions = [];
    questions.forEach(q => {
      send.questions.push({ id: q.id, question: q.question } as Question)
    })
    console.log({ ballot, send, questions });

    this.http.post(this.baseUrl + '/sort/' + ballot.id, send, this.getSimpleOptions(forceTeamId)).subscribe(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      success => {
        this.messageService.show('Saved...');
        ballot.questions = questions;
      },
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      failure => {
        this.messageService.show(new MsgDef('Not Saved', 'fail'));
      }
    )
  }

  notify(object: Ballot, forceTeam: uuid = null) {
    const url = `${this.baseUrl}/notify/${object.id}/${object.revision}`;

    this.http.post(url, object, this.getSimpleOptions(forceTeam)).pipe(
      map((data) => {
        if (data) {
          this.messageService.show($localize`OurOMC will send notification mails and mail you to confirm when they have been sent`);
          object.mailsSent = (data as Ballot).mailsSent;
          object.notificationSentAt = (data as Ballot).notificationSentAt;
        }
        return data;
      }),
      catchError(this.handleError('put' + this.typeString))
    ).pipe(first()).subscribe();
  }

}
