/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { MyInjector } from 'src/app/app.module';
import { Accepted, User } from 'src/app/model/user';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { AbstractHttpService } from '../abstract-http.service';
import { MessageService, MsgDef } from '../message.service';
import { DatePipe } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'app-terms-of-service',
    templateUrl: './terms-of-service.component.html',
    styleUrls: ['./terms-of-service.component.scss'],
    standalone: true,
    imports: [MatDialogContent, MatDialogActions, MatButtonModule, MatDialogClose, MatTooltipModule, DatePipe]
})
export class TermsOfServiceComponent {

    // THIS DATE NEEDS TO BE yyyy-mm-dd
    static lastUpdate = new Date(Date.parse("2024-10-25"));

    @ViewChild('lastUpdateElement', { static: true }) lastUpdateElement: ElementRef<HTMLParagraphElement>

    allowAccept = false;
    simpleClose = false;

    constructor(private currentUserSvc: CurrentUserService, private http: HttpClient,
    private msgSvc: MessageService,
    @Optional() public dialogRef: MatDialogRef<TermsOfServiceComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogOptions: {simpleClose: boolean}) {
        if (dialogOptions?.simpleClose) {
            this.simpleClose = true;
        }
    }

    onScroll($event) {
        const rect = this.lastUpdateElement?.nativeElement.getBoundingClientRect();
        console.log({$event, target: $event.target, lastUpdated: this.lastUpdateElement, rect, lue: this.lastUpdateElement});
        /** This is a belts and braces approach... */
        if ( rect?.top <= window.innerHeight ) {
            console.log('Using lastUpate to decide to enable accept');
            this.allowAccept = true;
        }
        /** Several Users reported not being able to accept this one,
         *  usually F11 solved issue for them, but not all
         *
        */
        if ($event.target.offsetHeight + $event.target.scrollTop >= $event.target.scrollHeight) {
            console.log("End");
            this.allowAccept = true;
        }
    }

    logout() {
        this.currentUserSvc.logoutCurrentUser();
    }

    acceptTerms() {
        const accepts = User.TermsOfService;
        this.http.post<Accepted>(AbstractHttpService.ajaxPath + 'currentUser/accepts/' + accepts, {}).subscribe(
            (accepted) => {
                this.currentUserSvc.currentUser.accepts.push(accepted);
            },
            error => {
                console.error('Failed to record acceptance of ' + accepts, error);
                const msg = $localize `Failed record acceptance of ${accepts}`;
                this.msgSvc.show(new MsgDef(msg, 'fail'));
            }
        );

    }

    lastUpdatedDate() {
        return TermsOfServiceComponent.lastUpdate;
    }

    static acceptAfter() {
        return TermsOfServiceComponent.lastUpdate.getTime() / 1000;
    }

    static checkTermsOfService(cu: User) {
        const accepted = cu.accepts.find(a => a.accepts === User.TermsOfService);
        if (!accepted || (accepted.acceptedAt) < TermsOfServiceComponent.acceptAfter()) {
            const dialog = MyInjector.instance.get(MatDialog)
            dialog.open(TermsOfServiceComponent, { autoFocus: false, disableClose: true, });
        }
    }

}
