/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { Field } from 'src/app/shared/field/Field';
import { AppFormControl, ControlOn } from '../app-form-control';
import { FormControlInterface } from '../form-control-interface';
import { NgClass } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';

@Component({
    selector: 'app-form-phone',
    templateUrl: './form-phone.component.html',
    styleUrls: ['./form-phone.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatIconModule, MatTooltipModule, MatInputModule, FormsModule, ReactiveFormsModule, NgClass]
})
export class FormPhoneComponent implements FormControlInterface {

    @Input() label: string;
    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static make(label: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new Field({ label, value, type: 'phone' }, options);
        field.formControlFactory = FormPhoneComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormPhoneComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }
}
