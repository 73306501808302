/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AbstractHttpService } from 'src/app/shared/abstract-http.service';
import { MessageService } from 'src/app/shared/message.service';
import { Unit } from 'src/app/model/unit';
import { Omc } from 'src/app/model/Omc';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { HttpErrorHandler } from 'src/app/shared/HttpErrorHandler';
import { Person } from 'src/app/model/person';
import { uuid } from 'src/app/model/abstract-object';
import { BCode } from 'src/app/model/bcode';
import { Schedule } from 'src/app/model/schedule';

@Injectable({
    providedIn: 'root'
})
export class AgentDataService {

    protected omcUnitCacheMap: Map<uuid, Unit[]> = new Map();
    protected omcPersonCacheMap: Map<uuid, Person[]> = new Map();
    protected omcExpenseBCodeCacheMap: Map<uuid, BCode[]> = new Map();
    protected omcScheduleCacheMap: Map<uuid, Schedule[]> = new Map();

    private errorHandler = new HttpErrorHandler();

    constructor(protected http: HttpClient, protected messageService: MessageService) {
    }
    /*
        getOmcUnit(omc: Omc, unitId: uuid) : Observable<Unit>{
            if (this.omcUnitCacheMap.has(omc.omcTeamId)) {
                return of(this.omcUnitCacheMap.get(omc.omcTeamId).find( o => o.id === unitId));
            } else {
                return this.getOmcUnits(omc).pipe( map( (data: Unit[] ) => {
                    return data.find( o => o.id === unitId);
                }));
            }
        }
    */
    getOmcUnits(omc: Omc): Observable<Unit[]> {
        const url = AbstractHttpService.ajaxPath + 'units';

        if (this.omcUnitCacheMap.has(omc.omcTeamId)) {
            return of(this.omcUnitCacheMap.get(omc.omcTeamId));
        } else {
            return this.http.get(url, { params: { _forceTeam: omc.omcTeamId } }).pipe(
                map((data: Unit[]) => {
                    this.omcUnitCacheMap.set(omc.omcTeamId, data);
                    return data;
                }),
                catchError((error) => {
                    this.messageService.show(this.errorHandler.getErrorMessage(error, 'OMC Units for ' + omc.name));
                    return of([]);
                })
            );
        }
    }

    getOmcExpenseBCodes(omcTeamId): Observable<BCode[]> {
        const url = AbstractHttpService.ajaxPath + 'budgets/bcodes';

        if (this.omcExpenseBCodeCacheMap.has(omcTeamId)) {
            return of(this.omcExpenseBCodeCacheMap.get(omcTeamId));
        } else {
            return this.http.get(url, { params: { typeId: BCode.TYPE.EXPENSE.id,_forceTeam: omcTeamId } }).pipe(
                map((data: BCode[]) => {
                    this.omcExpenseBCodeCacheMap.set(omcTeamId, data);
                    return data;
                }),
                catchError((error) => {
                    this.messageService.show(this.errorHandler.getErrorMessage(error, 'OMC Expense BCodes for ' + omcTeamId));
                    return of([]);
                })
            );
        }
    }

    getOmcSchedules(omcTeamId: uuid): Observable<Schedule[]> {
        const url = AbstractHttpService.ajaxPath + 'budgets/schedules';

        if (this.omcScheduleCacheMap.has(omcTeamId)) {
            return of(this.omcScheduleCacheMap.get(omcTeamId));
        } else {
            return this.http.get(url, { params: { _forceTeam: omcTeamId } }).pipe(
                map((data: Schedule[]) => {
                    this.omcScheduleCacheMap.set(omcTeamId, data);
                    return data;
                }),
                catchError((error) => {
                    this.messageService.show(this.errorHandler.getErrorMessage(error, 'OMC Schedules for ' + omcTeamId));
                    return of([]);
                })
            );
        }
    }


    updatePerson(person: Person) {
        const people = this.omcPersonCacheMap.get(person.teamId)
        if (people) {
            const idx = people.findIndex(o => o.id === person.id);
            if (idx >= 0) {
                people.splice(idx, 1);
            }
            people.unshift(person);
        }
    }

    getOmcPeople(omc: Omc): Observable<Person[]> {
        const url = AbstractHttpService.ajaxPath + 'people';

        if (this.omcPersonCacheMap.has(omc.omcTeamId)) {
            return of(this.omcPersonCacheMap.get(omc.omcTeamId));
        } else {
            return this.http.get(url, { params: { _forceTeam: omc.omcTeamId } }).pipe(
                map((data: Person[]) => {
                    this.omcPersonCacheMap.set(omc.omcTeamId, data);
                    return data;
                }),
                catchError((error) => {
                    this.messageService.show(this.errorHandler.getErrorMessage(error, 'OMC People for ' + omc.name));
                    return of([]);
                })
            );
        }
    }
}
