/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject } from './abstract-object';

export class ActionTemplate extends AbstractObject {
    name: string;
    description: string;
    offset = 0;
    timeSpent = 15;
    ownerOmcRole: string; // OmcRole[key]

    constructor(o: Partial<ActionTemplate> = {}) {
        super(o);
    }
}
