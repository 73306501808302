/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { CommsTemplate, CommsTemplateType } from 'src/app/model/CommsTemplate';
import { CtlHolderComponent } from 'src/app/shared/form/ctl-holder/ctl-holder.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { minChars, required } from 'src/app/shared/validators';
import { CommsTemplateService } from '../comms-templates.service';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { uuid } from 'src/app/model/abstract-object';

@Component({
  selector: 'app-comms-template-updater',
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, CtlHolderComponent],
  templateUrl: './comms-template-updater.component.html',
  styleUrl: './comms-template-updater.component.scss'
})
export class CommsTemplateUpdaterComponent {
    data : {type: CommsTemplateType, template: CommsTemplate, content: string} = inject(MAT_DIALOG_DATA);
    dialogRef = inject(MatDialogRef<CommsTemplateUpdaterComponent>);
    commsTemplateSvc = inject(CommsTemplateService);
    cus = inject(CurrentUserService);
    agentTeamId: uuid;
    ready = false;

    title = FormTextComponent.make('Enter title for new template', 'title', {validators: [required, minChars(10)]}).setupControl();

    constructor() {
        this.cus.getCurrentUser().subscribe( () => {
            this.agentTeamId = this.cus.currentTeam.primaryAgentId;
            this.ready = true
        });
    }
    createNew() {
        const tmpl = new CommsTemplate();
        tmpl.type = this.data.type.id;
        tmpl.title = this.title.control.value;
        tmpl.content = this.data.content;

        this.commsTemplateSvc.post(tmpl, true, this.agentTeamId).subscribe ( r => {
            if (r) {
                this.dialogRef.close(r);
            }
        });
    }

    updateExisting() {
        // Yes, deliberately updating the in memory object, to reflect in cache...
        this.data.template.content = this.data.content;
        this.commsTemplateSvc.put(this.data.template, this.data.template.teamId).subscribe ( r => {
            if (r) {
                this.dialogRef.close(r);
            }
        });

    }

}
