/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, Input, ViewContainerRef } from '@angular/core';
import { ControlOn, AppFormControl } from 'src/app/shared/form/app-form-control';
import { Field } from 'src/app/shared/field/Field';
import { FormControlInterface } from '../form-control-interface';
import { NgClass } from '@angular/common';
import { FormErrorComponent } from '../form-error/form-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { DateHelper } from '../../dateHelper';

export class DateField extends Field {
    formatValue(val: string) {
        return DateHelper.localDate(val);
    }
}
@Component({
    selector: 'app-form-date',
    templateUrl: './form-date.component.html',
    styleUrls: ['./form-date.component.scss'],
    standalone: true,
    imports: [MatFormFieldModule, MatInputModule, FormsModule,
        ReactiveFormsModule, FormErrorComponent, NgClass, MatTooltipModule, MatIconModule]
})
export class FormDateComponent implements FormControlInterface {

    @Input() control: AppFormControl;
    @Input() on: ControlOn = 'form';

    public static make(heading: string, value: string, options: Partial<Field> = {}) {
        const field: Field = new DateField({ cellOpts: { heading }, value, type: 'date' }, options);
        field.formControlFactory = FormDateComponent.createComponent;
        return field;
    }

    public static createComponent(vcr: ViewContainerRef, ctl: AppFormControl, on: ControlOn) {
        const componentRef = vcr.createComponent(FormDateComponent);
        componentRef.instance.control = ctl;
        componentRef.instance.on = on;
    }

    /* Parochial but efficient */
    public formatToLocal(yyyyMMdd: string) {
        if (yyyyMMdd) {
            return yyyyMMdd.substring(8, 10) + '/' + yyyyMMdd.substring(5, 7) + '/' + yyyyMMdd.substring(0, 4);
        }
        return '';
    }

}
