/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AfterViewInit, Component, Inject, Input, OnInit, Optional, ViewChild, inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MyInjector } from 'src/app/app.module';
import { ServiceRequest } from 'src/app/model/serviceRequest';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { ActionColor, IFormAction } from 'src/app/shared/form/form.component';
import { IsNarrowService } from 'src/app/shared/is-narrow.service';
import { RequestService } from '../../request.service';
import { MessageService } from 'src/app/shared/message.service';
import { fromEvent } from 'rxjs';
import { ByteCountPipe } from '../../../../shared/pipes/byte-count.pipe';
import { CtlHolderComponent } from '../../../../shared/form/ctl-holder/ctl-holder.component';
import { MatButtonModule } from '@angular/material/button';
import { Action } from 'src/app/model/action';
import { AbstractObject } from 'src/app/model/abstract-object';
import { maxChars, minChars, minWords, required } from 'src/app/shared/validators';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { Field } from 'src/app/shared/field/Field';
import { Omc } from 'src/app/model/Omc';
import { OmcService } from 'src/app/modules/agent/omc.service';
import { OmcAgent } from 'src/app/model/OmcAgent';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { OmcAgentService } from 'src/app/modules/user/omc-agent-service';
import { NavRoute } from 'src/app/shared/NavRoute';
import { MyRequestService } from '../../my-request.service';
import { RouterLink } from '@angular/router';

export class ImageUploadRequestAction implements IFormAction {
    name = $localize`Report Image`;
    color: ActionColor = 'primary';
    show = false;
    icon = 'image';
    approvalNeeded = false;
    disabled = false;
    approvalText: string;
    tipText: string = $localize `Click to add image to this request or child request`;
    allowCreateChild = true;
    parentObject: AbstractObject;

    sr: ServiceRequest;

    constructor(allowCreateChild = true) {
        this.allowCreateChild = allowCreateChild
    }

    action() {

        const dialog = MyInjector.instance.get(MatDialog);

        const dialogRef = dialog.open(ImageUploadRequestComponent,
            {
                data:
                {
                    sr: this.sr,
                    allowCreateChild: this.allowCreateChild,
                    parentObject: this.parentObject
                }
            }
        );

        return dialogRef.afterClosed();
        /*
        dialogRef.afterClosed().pipe(first()).pipe<Action>(map(o => {
            return o;
        }));
        */
    }
    //  setup(serviceRequest: ServiceRequest) {
    setup(o: ServiceRequest | Action) {
        let sr: ServiceRequest = o as ServiceRequest;
        if (o['request']) { // This is an action or other object linked to a request
            sr = o['request'];
            this.parentObject = o;
        }
        if (sr.srStatus === ServiceRequest.srOpen.id) {
            this.sr = sr;
            this.show = true;
        } else {
            this.show = false;
        }
    }
}

class ImageReportParams {
    sr: ServiceRequest;
    allowCreateChild: boolean;
    parentObject: AbstractObject;
}

@Component({
    selector: 'app-image-upload-request',
    templateUrl: './image-upload-request.component.html',
    styleUrls: ['./image-upload-request.component.scss'],
    standalone: true,
    imports: [MatButtonModule, CtlHolderComponent, ByteCountPipe, RouterLink]
})
export class ImageUploadRequestComponent implements OnInit, AfterViewInit{

    @ViewChild('previewImgTag') previewImageTag;
    @ViewChild('fileUploadTag') fileUploadTag;
    @Input() noHeader = false;

    static readonly navRoute = new NavRoute('crm/quickRequest', ImageUploadRequestComponent, 'live_help');

    private isNarrowSvc = inject(IsNarrowService);
    private srSvc = inject(RequestService);
    private cds = inject(ConfirmDialogService);
    private msgSvc = inject(MessageService)
    public cUserSvc = inject(CurrentUserService);

    private omcSvc = inject(OmcService);
    private agentSvc = inject(OmcAgentService);
    private myReqSvc = inject(MyRequestService);

    omc: Omc;
    agent: OmcAgent;

    title = $localize `Upload Images To Request`
    titleToShow = this.title;
    titleField = FormTextComponent.make('Title', 'title',
        {validators: [minChars(5), maxChars(100), required]}
    ).setupControl();

    descField = FormTextAreaComponent.make('Decription', 'description',
        { validators: [minWords(3), maxChars(255), required] }
    ).setupControl();

    omcField = FormPicklistComponent.make('Owner Management Company', 'omcId', 'omc',
        { items: [], refreshes: [ o => this.omc = o as Omc] }, {validators: [required]}
    ).setupControl();

    agentField: PicklistField = FormPicklistComponent.make('Agent', 'agentTeamId', 'agentTeam',
        { items: [], refreshes: [o => this.agent = o as OmcAgent] }, {validators: [required] }
    ).setupControl();
    allowCreateChild = true;

    width = 330;
    height = 800;

    srTitle = '';
    imageChosen = false;
    imageFile: File;
    imageName: string;
    originalSize = 0;
    uploadSize = 0;

    uploadRunning = false;

    lastSR: ServiceRequest;

    constructor(@Optional() public dialogRef: MatDialogRef<ImageUploadRequestComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) public dialogParms: ImageReportParams) {
    }

    ngOnInit(): void {
        this.width = Math.min(this.isNarrowSvc.screenWidth * .8, this.width);
        this.height = Math.min(this.isNarrowSvc.screenHeight * .8, this.height);

        if (this.dialogParms) {
            this.lastSR = this.dialogParms.sr;
            this.allowCreateChild = this.dialogParms.allowCreateChild;
        }
        if (this.cUserSvc.currentUser.inAgency) {
            this.omcSvc.get(true).subscribe( omcs => {
                this.omcField.setPicklistItems(omcs);
                console.log('Set Picklist Items', {omcs, f: this.omcField});
            });
        } else {
            this.agentSvc.get(true).subscribe( agts => {
                this.agentField.setPicklistItems(agts);
                if (agts.length === 1) {
                    this.agentField.control.setValue((agts[0] as OmcAgent).agentTeamId, {emitEvent: false});
                    this.agent = agts[0] as OmcAgent;
                }
            })
        }
    }

    ngAfterViewInit(): void {
        if (this.dialogParms || this.noHeader) {
            window.setTimeout(() => this.changeImage(), 10);
        }
    }

    cancel() {
        if (this.dialogParms.parentObject) {
            this.dialogRef.close(this.dialogParms.parentObject);
        } else {
            this.srSvc.getOne(this.dialogParms.sr.id, null, this.dialogParms.sr.teamId).subscribe(sr => {
                this.dialogRef.close(sr);
            })
        }
    }

    clearFile() {
        this.previewImageTag.nativeElement.style.display = "none";
        this.previewImageTag.nativeElement.removeAttribute('src');
        this.imageChosen = false;
        this.imageFile = null;
        this.uploadRunning = false;
    }

    cleanControl(fld: Field) {
        fld.control.setValue('', { emitEvent: false });
        fld.control.markAsUntouched();
        fld.control.markAsPristine();
    }

    addImage() {
        this.srSvc.attach(this.imageFile, this.lastSR.id, this.lastSR.teamId, this.imageName).subscribe((r) => {
            if (r) {
                this.msgSvc.show($localize`${this.imageFile.name} has been added to request ${this.lastSR.refNr}`);
                this.clearFile();
                this.cleanControl(this.titleField);
                this.cleanControl(this.descField);
                if (!this.allowCreateChild && this.dialogParms.parentObject) {
                    this.dialogRef.close(this.dialogParms.parentObject);
                }
            }
        });
    }

    createAsChild() {
        const sr = new ServiceRequest();
        const parent = this.dialogParms.sr;
        sr.parentId = parent.id;
        sr.parent = parent;
        sr.omcId = parent.omcId;
        sr.omc = parent.omc;
        sr.teamId = parent.teamId;
        sr.agentTeamId = parent.agentTeamId;
        return sr;
    }

    createFresh() {
        const sr = new ServiceRequest();
        console.log({agent: this.agent, omc: this.omc});
        if (this.cUserSvc.currentUser.inAgency) {
            sr.omcId = this.omc.id;
            sr.omc = this.omc;
            sr.teamId = this.omc.omcTeamId;
            sr.agentTeamId = this.cUserSvc.currentUser.current_team_id;
        } else {
            sr.omcId = this.agent.omc.id;
            sr.omc = this.agent.omc;
            sr.teamId = this.cUserSvc.currentUser.current_team_id;
            sr.agentTeamId = this.agent.id;
        }
        return sr;
    }

    saveRequest() {
        //this.msgSvc.show('New Request Was Saved');
        let sr: ServiceRequest;
        if (this.dialogParms) {
            sr = this.createAsChild();
        } else {
            sr = this.createFresh();
        }
        sr.title = this.titleField.control.value;
        sr.description = this.descField.control.value;

        this.srSvc.post(sr, true, sr.teamId).subscribe( srResponse => {
            this.lastSR = srResponse;
            this.myReqSvc.updateCachedItem(sr);
            this.addImage();
        });
    }

    isSaveable() {
        if (this.uploadRunning) {
            return false;
        }
        if (this.imageChosen && this.titleField.control.value && this.descField.control.value) {
            return true;
        }
        return false;
    }

    changeImage() {
        this.fileUploadTag.nativeElement.click();
    }

    hideImage() {
        this.previewImageTag.nativeElement.src = null;
        this.previewImageTag.nativeElement.style.display = "none";
    }

    previewImage($event: Event) {
        const imageFiles = ($event.target as HTMLInputElement).files;

        if (!imageFiles || !imageFiles.length) {
            console.error('Does not seem to be a change event or no file selected', $event);
            this.clearFile();
            return false;
        }

        this.imageChosen = true;
        this.imageFile = imageFiles[0];
        this.imageName = this.imageFile.name;
        const fileSize = this.imageFile.size;
        this.originalSize = fileSize;

        if (fileSize > 1024 * 1024 && this.imageFile.type === 'image/jpeg') {
            this.resizeImage(this.imageFile);
        } else {
            this.uploadSize = this.originalSize;
            this.setupPreview(this.imageFile);
        }

    }

    setupPreview(file: File | Blob) {
        const imageSrc = URL.createObjectURL(file);
        this.previewImageTag.nativeElement.src = imageSrc;
        this.previewImageTag.nativeElement.style.display = "block";
    }

/*
* Resizing Images
* https://img.ly/blog/how-to-resize-an-image-with-javascript/?utm_source=imgly&utm_medium=blog&utm_campaign=howtos
* https://img.ly/blog/how-to-compress-an-image-before-uploading-it-in-javascript/
*/
    resizeImage(imgToResizeFile: File) {

        const imgToResize = document.createElement('img');
        const reader = new FileReader();

        fromEvent(reader, 'load').subscribe( () => {
            // Execute this when finished reading the file from disk (reader.readAsDataUrl below)
            fromEvent(imgToResize, 'load').subscribe(() => {
                // Execute this when img tag loaded and we have dimensions (after imgToResize.src = ...)
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d");

                const originalWidth = imgToResize.width;
                const originalHeight = imgToResize.height;
                const resizingFactor = 1 / originalWidth * 1920;
                const canvasWidth = originalWidth * resizingFactor;
                const canvasHeight = originalHeight * resizingFactor;

                canvas.width = canvasWidth;
                canvas.height = canvasHeight;

                context.drawImage(
                    imgToResize,
                    0,
                    0,
                    originalWidth * resizingFactor,
                    originalHeight * resizingFactor
                );

                // Get an uploadable version of the scaled image
                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            // showing the compressed image
                            this.uploadSize = blob.size;
                            this.imageFile = blob as File;
                            this.setupPreview(blob);
                        } else {
                            console.warn('No Blob, what happened there?');
                            this.clearFile();
                        }
                    },
                    "image/jpeg",
                );
            });
            imgToResize.src = reader.result as string;
        })
        reader.readAsDataURL(imgToResizeFile);
    }
}
