/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component, inject } from '@angular/core';
import { OmcSettings } from 'src/app/model/OmcSettings';
import { NavRoute } from 'src/app/shared/NavRoute';
import { OmcSettingsService } from './omc-settings.service';
import { maxChars, required } from 'src/app/shared/validators';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { GridField } from 'src/app/shared/grid/grid-field';
import { RoleService } from '../role-page/role.service';
import { Role } from 'src/app/model/role';
import { MatCardModule } from '@angular/material/card';
import { TextEditComponent } from 'src/app/shared/simpleFields/text-edit/text-edit.component';
import { MatDialog } from '@angular/material/dialog';
import { EditFieldDialogComponent, EditFieldDialogOptions } from 'src/app/shared/dialogs/edit-field-dialog/edit-field-dialog.component';
import { Field } from 'src/app/shared/field/Field';
import { FormEmailComponent } from 'src/app/shared/form/form-email/form-email.component';
import { FormTextAreaComponent } from 'src/app/shared/form/form-text-area/form-text-area.component';
import { ConfirmDialogService } from 'src/app/shared/dialogs/confirmDialog';
import { CurrentUserService } from 'src/app/modules/user/current-user.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { OmcAddAgentComponent } from './omc-add-agent/omc-add-agent.component';

@Component({
  selector: 'app-omc-settings',
  standalone: true,
  imports: [MatCardModule, TextEditComponent, MatButtonModule, MatIconModule],
  templateUrl: './omc-settings.component.html',
  styleUrl: './omc-settings.component.scss'
})
export class OmcSettingsComponent {
    /** If you are editing here, you likely need to make same change in AgentSettingsService */
    static readonly navRoute = new NavRoute('omcSettings', OmcSettingsComponent, 'settings_applications');
    omcSettingsSvc = inject(OmcSettingsService)
    roleSvc = inject(RoleService);
    dialog = inject(MatDialog);
    cds = inject(ConfirmDialogService);
    cus = inject(CurrentUserService);

    roles: Role[] = [];
    // Do not disable until picklist set correctly
    team = new OmcSettings();

    agentGrid = new GridField({
        field: { value: 'agents', cellOpts: { heading: 'Agents' }},
        rowFactory: this.agentFactory.bind(this),
        hasFooter: true
    });

      constructor() {
          this.omcSettingsSvc.getOne('omcSettings', null).subscribe(team => this.team = team)
        // This should be done pre-edit and only allow agencies with permissions...
        this.roleSvc.get(true).subscribe( roles => {
            roles.forEach( r => this.roles.push(r as Role));
        })
    }

    editName() {
        this.editField(FieldMaker.nameControl({ validators: [required] }));
    }

    editAddress() {
        this.editField(FormTextAreaComponent.make('Partial Unit Address', 'defaultAddress', {
            validators: [required, maxChars(255)],
        }))
    }

    editLegalName() {
        const field = FormTextComponent.make('Legal Company Name', 'legalName', { validators: [required] })
        this.editField(field);
    }

    editMailSendingName() {
       const field = FormTextComponent.make('The name to send mails as', 'mailSendingName',
            { validators: [required],
                hint: 'Usually you can leave the default value here, and let the system use the name of your OMC, but enter a value here to override it' })
        this.editField(field);
    }

    editMailSendingAccount() {
        const field = FormTextComponent.make('The email account to send mail', 'mailSendingAccount',
            {
                validators: [required],
                hint: 'Usually you can leave the default value here, and let the system use the ID of your OMC, the account used here must exist in your sending domain'
            })
        this.editField(field);
    }

    editPaymentTerms() {
        this.editField(FormTextAreaComponent.make('Payment Terms', 'paymentTerms',
            { validators: [required, maxChars(255)] }))
    }

    editBillingAddress() {
        this.editField(FormTextAreaComponent.make('Billing Address', 'billingAddress', {
            validators: [required, maxChars(255)],
         }))
    }

    editMailSendingDomain() {
        this.editField(FormTextComponent.make('Mail Sending Domain', 'mailSendingDomain', {
            hint: 'Must be configured by OurOMC',
            disable: !this.cus.isUber(),
            validators: [required, maxChars(255)],
        }))
    }

    editMailReplyToDomain() {
        this.editField(FormTextComponent.make('Mail Reply To Domain', 'mailReplyToDomain', {
            hint: 'Must be configured by OurOMC',
            disable: !this.cus.isUber(),
            validators: [required, maxChars(255)],
        }))
    }

    editField(field: Field) {
        const data = new EditFieldDialogOptions({
            field,
            id: 'omcSettings',
            service: this.omcSettingsSvc,
        });
        this.dialog.open(EditFieldDialogComponent,{data}).afterClosed().subscribe((updated) => {
            if (updated) {
                this.team = updated;
            }
        })
    }

    editReplyToMail() {
        const field = FormEmailComponent.make('Email Address if recipient presses reply', 'replyToMail',
            {
                validators: [required],
                hint: `Note: It can reduce your mails deliverability if the replyTo does not match the sender email address,
                especially if the address you use is a free account, e.g. gmail etc. Unless you really know what you are doing
                it is usually best to leave the replyToMail alone. If reply comes back to OurOMC we will create a request for you.
                `
            })
        this.editField(field);
    }

    deleteParameter(name: string, value: string) {

        if (!this.getParameter(name)) {
            this.cds.alert(
                $localize`Cannot remove parameter`,
                $localize`${value} is the default value for ${name}, you cannot remove the parameter, you can edit to override it`,
            );
        } else {
            this.cds.open(
                $localize`Remove Setting`,
                $localize`Do you wish to remove the '${name}' setting and revert to the default value`,
                () => this.omcSettingsSvc.deleteParameter(name, value, this.team).subscribe(x => this.team = x)
            );
        }
    }

    getParameter(name: string) {
        return this.team.parameters.find( p => p.name === name);
    }

    sendTestMail() {
        this.cds.open('Send Test', 'Send test mail from this OMC?', () => {
            this.omcSettingsSvc.sendTestMail(this.team);
        })

    }

    addAgent() {
        const dialogRef = this.dialog.open(OmcAddAgentComponent, {data: this.team});
        return dialogRef.afterClosed();
    }


    private agentFactory() {
        return [
            FormTextComponent.make('Agency Name', 'agentName', {disable: true}),
            FormTextComponent.make('Agency Personnel With Role', 'agentRoleName', {disable: true}),
            FormPicklistComponent.make('Are Granted', 'omcRoleId', null, {items: this.roles}, {disable: true}),
            //FieldMaker.spacer()
        ];
    }
}
