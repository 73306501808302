/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { Component } from '@angular/core';
import { Observable, combineLatest, of } from 'rxjs';
import { BCode } from 'src/app/model/bcode';
import { Cycle } from 'src/app/model/cycle';
import { Schedule } from 'src/app/model/schedule';
import { Txn } from 'src/app/model/txn';
import { NavRoute } from 'src/app/shared/NavRoute';
import { Field } from 'src/app/shared/field/Field';
import { FieldMaker } from 'src/app/shared/field/FieldMaker';
import { PicklistField } from 'src/app/shared/field/PicklistField';
import { FormConfig } from 'src/app/shared/form/FormConfig';
import { AbstractPageComponent } from 'src/app/shared/form/abstract-page.component';
import { FormDateComponent } from 'src/app/shared/form/form-date/form-date.component';
import { FormNumberComponent } from 'src/app/shared/form/form-number/form-number.component';
import { AppPicklistControl, FormPicklistComponent } from 'src/app/shared/form/form-picklist/form-picklist.component';
import { FormTextComponent } from 'src/app/shared/form/form-text/form-text.component';
import { GridControl } from 'src/app/shared/grid/grid-control';
import { GridField } from 'src/app/shared/grid/grid-field';
import { maxChars, removeError, required, validateTxnDate } from 'src/app/shared/validators';
import { FieldSet } from 'src/app/shared/form/field-set/field-set.component';
import { JournalService } from '../journal.service';
import { BCodeService } from '../../budget/bcode.service';
import { ActivatedRoute } from '@angular/router';
import { CycleService } from '../../budget/cycle.service';
import { PeriodService } from '../../budget/period.service';
import { ScheduleService } from '../../budget/schedule.service';
import { CurrentUserService } from '../../user/current-user.service';
import { first } from 'rxjs/operators';
import { DateHelper } from 'src/app/shared/dateHelper';
import { AppFormControl } from 'src/app/shared/form/app-form-control';
import { FormComboBoxComponent } from 'src/app/shared/form/form-combo-box/form-combo-box.component';
import { FormGroup } from '@angular/forms';
import { FormPageComponent } from '../../../shared/form/form-page/form-page.component';
import { BankAccountService } from '../bank-account.service';
import { BankAccount } from 'src/app/model/bankAccount';

@Component({
    selector: 'app-journal-page',
    templateUrl: './journal-page.component.html',
    styleUrls: ['./journal-page.component.scss'],
    standalone: true,
    imports: [FormPageComponent]
})
export class JournalPageComponent extends AbstractPageComponent  {

    static readonly navRoute = new NavRoute(Txn.TYPE.JRNL.code, JournalPageComponent, 'instant_mix');

    readonly path = Txn.TYPE.JRNL.code

    bCodes: BCode[] = [];
    bankAccounts: BankAccount[] = [];

    idField = FieldMaker.id();
    refField = FormNumberComponent.make('Reference', 'refNr', {}, {
        cellOpts: { heading: 'Ref' }, readonly: true
    });

    revField = FieldMaker.rev().override({ visible: Field.showAll, readonly: true, });

    cycleField: PicklistField = FormPicklistComponent.make('Cycle', 'txnCycleId', 'txnCycle',
        { service: this.cycleSvc, refreshes: ['periodId'] }, { formColumn: 2, readonly: true }
    );

    periodField: PicklistField = FormPicklistComponent.make('Period', 'txnPeriodId', 'txnPeriod',
        { service: this.periodSvc },
        {
            formColumn: 2, readonly: true,
            refresh: (o: Cycle, control: AppPicklistControl) => {
                if (o) {
                    control.field.picklist.items = o.periods;
                    control.setValue(null);
                }
            },
        }
    );

    txnDateField: Field = FormDateComponent.make('Transaction Date', 'txnDate', {
        cellOpts: { width: '2%' }, formColumn: 2,
        validators: [validateTxnDate(this.cycleField, this.periodField)],
        valueChanges: this.dateValueChanges.bind(this)
    });

    childTxn = this.getChildGrid();

    referenceField = FormTextComponent.make('Name', 'reference').override( {
        validators: [maxChars(30), required], formColumn: 3, hint: 'Enter a short name for this transaction up to 30 characters'
    })

    notesField = FieldMaker.notes().override({
        validators: [required], formColumn: 3, hint: 'Enter a detailed description to explain why this transaction was necessary'
    }).override({visible: Field.showAll});

    schedules: Schedule[];

    debitField = FormNumberComponent.make("Total Debits", "totalDebit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, visible: Field.formOnly }
    );

    creditField = FormNumberComponent.make("Total Credits", "totalCredit",
        { format: 'currency', width: 9, formatParms: '1.2-2' },
        { formRow: 1, formColumn: 4, readonly: true, visible: Field.formOnly }
    );

    config = new FormConfig({
        navRoute: JournalPageComponent.navRoute,
        title: Txn.TYPE.JRNL.name,
        help: $localize`Journal Entries`,
        readonly: true,
        fieldSet: new FieldSet({
            fields: [

                this.idField,
                this.refField,
                this.revField,

                FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
                FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),

                /* txtDateField Validator overwrites period info, need this order! */
                this.txnDateField,
                this.cycleField,
                this.periodField,

                this.referenceField,
                this.notesField,

                this.debitField,
                this.creditField,

                this.childTxn,
            ],
            formLayout: [
                { cells: [{ width: '25%' }, { width: '25%' }, { width: '25%' }, { width: '25%' }] },
                { cells: [{ colspan: 4, pageTab: 'yes' }] }
            ],
            formValidator: [ (o: FormGroup) => {
                if (this.debitField.control?.value === this.creditField.control?.value) {
                    removeError(o, 'unmatched');
                    return null;
                }
                const diff = this.debitField.control?.value - this.creditField.control?.value;
                return { unmatched: 'Debits do not match credits - difference of ' + diff };

            }]
        }),
        service: this.dataSvc,
        mode: 'list',
        objectFactory: this.newFactory.bind(this),
        configReady: this.configReady,
        beforeEdit: this.enhanceObject.bind(this),
    });

    constructor(public dataSvc: JournalService, public bCodeSvc: BCodeService,
        protected activeRoute: ActivatedRoute,
        protected cycleSvc: CycleService, protected periodSvc: PeriodService,
        protected scheduleSvc: ScheduleService, protected bankAccountSvc: BankAccountService,
        currentUserSvc: CurrentUserService) {
        super();

        combineLatest([currentUserSvc.getCurrentUser(),
        this.scheduleSvc.get(true).pipe(first()),
        this.cycleSvc.get(true),
        this.bCodeSvc.get(true),
        this.bankAccountSvc.get(true),
        ]).subscribe(
            ([currentUser, schedules, cycles, bCodes, bankAccounts]) => {
                this.currentUser = currentUser;
                this.schedules = schedules as Schedule[];
                this.bCodes = bCodes as BCode[];
                this.configReady.next(null);
                this.currentCycle = this.cycleSvc.getCurrentCycle();
                this.cycleField.picklist.items = cycles;
                this.bankAccounts = bankAccounts as BankAccount[];
            });
    }


    enhanceObject(o: Txn) {
        this.revField.visible = Field.showAll;
        this.refField.visible = Field.showAll;

        o.totalCredit = 0;
        o.totalDebit = 0;
        o.details.forEach ( d => {
            o.totalCredit += d.credit;
            o.totalDebit += d.debit;
        })
        console.log(o);
        return o;
    }

    newFactory(): Observable<Txn> {
        this.revField.visible = Field.noShow;
        this.refField.visible = Field.noShow;

        const txn = new Txn();
        this.config.readonly = false;

        txn.ledgerId = Txn.LEDGER.GL.id;
        txn.typeId = Txn.TYPE.JRNL.id;

        txn.txnDate = DateHelper.isoDate();
        txn.txnCycleId = this.cycleSvc.getCurrentCycle().id;
        txn.txnPeriodId = this.cycleSvc.getCurrentPeriod().id;

        txn.totalDebit = 0;
        txn.totalCredit = 0;

        txn.details.push(this.createChildItem(txn))


        // txn.led
        // txn.typeId = Txn.TYPE.INVOICE.id;
        // txn.bCodeId = Defaulted on the server?
        return of(txn);
    }

    createChildItem(parent: Txn = null): Txn {
        const txn = new Txn();
        txn.ledgerId = Txn.LEDGER.GL.id;
        txn.typeId = Txn.TYPE.JRNL_DETAIL.id;
        txn.txnDate = parent ? parent.txnDate : this.txnDateField.control.value;
        txn.txnPeriodId = parent ? parent.txnPeriodId : this.periodField.control.value;
        txn.txnCycleId = parent ? parent.txnCycleId : this.cycleField.control.value;
        return txn;
    }

    newChildTxn(): Observable<Txn> {

        return of(this.createChildItem());
    }

    dateValueChanges(): void {
        const allocations = (this.childTxn.control as GridControl).controls;
        if (allocations) {
            for (const txnRow of allocations) {
                txnRow.get('txnDate').setValue(this.txnDateField.control.value, { emitEvent: false });
                txnRow.get('txnCycleId').setValue(this.cycleField.control.value, { emitEvent: false });
                txnRow.get('txnPeriodId').setValue(this.periodField.control.value, { emitEvent: false });
            }
        }
    }

    editDebit(newValue: number, f: Field) {
        this.toggleDrCr(newValue, f, 'credit');
    }

    editCredit(newValue: number, f: Field) {
        this.toggleDrCr(newValue, f, 'debit');
    }

    toggleDrCr(newValue: number, f: Field, altSide) {
        const alt = (f.control as AppFormControl).getRow().get(altSide);
        alt.setValue(0, {emitEvent: false});
        if (Math.abs(newValue) > 0) {
            alt.disable({emitEvent: false });
        } else {
            alt.enable({emitEvent: false });
        }
        this.txnValueChanges();
    }

    txnValueChanges(): void {
        const txns = (this.childTxn.control as GridControl).controls;
        let totalDebits = 0;
        let totalCredits = 0;

        if (txns) {
            for (const tRow of txns) {
                totalDebits += tRow.get('debit').value;
                totalCredits += tRow.get('credit').value;
            }
        }
        // Somehow, without rounding we can get .0000000xx differences in the values...
        this.debitField.control.setValue(Math.round(totalDebits * 100) / 100, { emitEvent: false });
        this.creditField.control.setValue(Math.round(totalCredits * 100) / 100, { emitEvent: false });
    }

    childRow(o: Txn) {
        const bankAccountField = FormPicklistComponent.make('Bank Account', 'bankAccountId', null,
            { items: [], allowSelectNone: true },
            { cellOpts: { width: '30%' } });

        if (o) {
            bankAccountField.setPicklistItems(this.bankAccounts.filter(ba => ba.bCodeId === o.bCodeId));
        }

        return [
            FieldMaker.id(),
            FormTextComponent.make('ledgerId', 'ledgerId', { visible: Field.noShow }),
            FormTextComponent.make('typeId', 'typeId', { visible: Field.noShow }),
            FormTextComponent.make('txnDate', 'txnDate', { visible: Field.noShow }),
            FormTextComponent.make('txnCycleId', 'txnCycleId', { visible: Field.noShow }),
            FormTextComponent.make('txnPeriodId', 'txnPeriodId', { visible: Field.noShow }),

            FormComboBoxComponent.make('Account', 'bCodeId', 'bCode',
                {
                    items: this.bCodes,
                    refreshes: [ (bc) => {
                        const filterList = this.bankAccounts.filter(ba => ba.bCodeId === bc.id);
                        bankAccountField.setPicklistItems(filterList);
                        if (filterList.length > 0) {
                            bankAccountField.control.setValue(filterList[0].id, {emitEvent: false});
                        } else {
                            bankAccountField.control.setValue(null, { emitEvent: false });
                        }
                    }]
                },
                { cellOpts: { width: '30%' }, validators: [required] }),

            FormPicklistComponent.make('Schedule', 'scheduleId', 'schedule',
                { service: this.scheduleSvc },
                { cellOpts: { width: '30%' } }),

            bankAccountField,

            FormNumberComponent.make("Debit", "debit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.editDebit.bind(this), cellOpts: { width: '7em' } }
            ),

            FormNumberComponent.make("Credit", "credit",
                { format: 'currency', width: 9, formatParms: '1.2-2' },
                { valueChanges: this.editCredit.bind(this), cellOpts: { width: '7em' } }
            ),

            FieldMaker.notes().override({ cellOpts: { width: '50%' } }),
            FieldMaker.rev(),
            FieldMaker.deleteGridRow().override({ valueChanges: this.txnValueChanges.bind(this) }),
        ]
    }

    getChildGrid() {
        return new GridField({
            field:
                { label: $localize`Transaction Details`, value: 'details', visible: Field.formOnly, formRow: 2 },
            rowFactory: this.childRow.bind(this),
            objFactory: this.newChildTxn.bind(this),
        });
    }
}
