/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/

import { FormGroup } from "@angular/forms";
import { isArray } from "highcharts";
import { AbstractObject } from "src/app/model/abstract-object";
import { Field } from "src/app/shared/field/Field";
import { ITabField } from "../field/TabFIeld";
import { AppFormControl } from "../form/app-form-control";
import { GridControl } from "./grid-control";
import { GridControlParameters } from "./grid-control-parameters";
import { GridRow } from "./grid-row";

export class GridField extends Field implements ITabField {
    config: GridControlParameters;
    control: GridControl;
    relatedFormGroup: FormGroup;
    lazyTab = true;

    readonly isGrid = true;
    constructor(gridParms: GridControlParameters) {
        super(gridParms.field);
        this.type = 'grid';
        this.config = gridParms;
    }
    public makeControl(): AppFormControl | GridControl {
        this.control = new GridControl(this);
        return this.control;
    }

    public setValue(data: AbstractObject, readonly: boolean) {
        const dataItems = this.getValue(data);//  data[this.value];
        if (this.control.columns.length === 0) {
            this.control.setColumns();
        }
        if (readonly) {
            this.readonly = true;
        } else {
            this.readonly = this.defaultReadonly;
        }
        this.control.clear();
        if (!dataItems || typeof dataItems[Symbol.iterator] !== 'function') {
            console.warn('Really Non iterable Grid Data ', { gridField: this, data, dataItems });
            throw ('Data for Grid not iterable');
        }
        for (const item of dataItems) {
            this.control.addRow(item, false, readonly);
        }
        this.control.markAsPristine();
        this.control.markAsUntouched();
        this.control.refresh();
    }

    getTabLabel(): string {
        return this.label;
    }

    getTabChip(value: unknown[]): string {
        if (isArray(value) && value.length > 0) {
            return '' + value.length;
        }
        return '';
    }

    deleteAll() {
        while (this.control.length !== 0) {
            this.control.removeAt(0)
        }
        this.control.refresh();
    }

    deleteWhere( condition: (gr: GridRow) => boolean) {
        const rows = this.control.gridRows();
        const toDelete: GridRow[] = [];

        rows.forEach(row => {
            if (condition(row)) {
                toDelete.push(row);
            }
        })
        toDelete.forEach(gr => gr.delete());
    }
}
