/*
* Copyright Gregory Coburn 2020-2025, All Rights Reserved, See license for further details
*/
import { AbstractObject, uuid } from './abstract-object';
import { ForumRole } from './forum';
import { TeamUser } from './team-user';

export class Privilege extends AbstractObject {
    // Doesn't extend abstract, but we'll pretend it does to keep grid happy.
    //$routes[$uri] = ['uri' => $uri, 'create' => $create, 'read' => $read,  'update' => $update, 'delete' => $delete];
    name?: string;
    getIndex = false;
    get = false;
    put = false;
    post = false;
    delete = false;
}

type Privileges = {
    models: {
        [x: string]: Privilege;
    },
    workflows: {
        [x: string]: { [x: string]: string }
    }
}
export class AgentRole extends AbstractObject {
    omcName: string;
    omcTeamId: uuid;
    isAdmin: boolean;
    agentPrivs: Privileges;
}
export class Role extends AbstractObject {
    constructor(o: Partial<Role> = {}) {
        super(o);
    }
    name: string;
    isAdmin: boolean;
    forumRoles: ForumRole[] = [];
    unitLinked: boolean;
    /*
    privileges: {
        models: {
            [x: string]: Privilege;
        },
        workflows: {
            [x: string]: { [x: string]: string }
        }
    }
    allPrivileges: { models: { [x: string]: Privilege }, workflows: { [x: string]: { [x: string]: string } } };
    */
    privileges: Privileges;
    allPrivileges: Privileges;

    modelEdits: Privilege[] = [];
    workflowEdits: { name: string, workflowName: string, action: string, description: string, allowed: boolean }[] = [];
    users: TeamUser[] = [];

    agentRoles: AgentRole[];

    globalRoleId: uuid;

    static readonly ROLE = {
        ADMIN: { 'id': 99, 'name': $localize`System Admin` },

        OWNER: { 'id': 1, 'name': $localize`Owner` },
        RESIDENT: { 'id': 2, 'name': $localize`Resident` },
        DIRECTOR: { 'id': 3, 'name': $localize`Director` },
        AGENT: { 'id': 4, 'name': $localize`Agent` },
        OWNER_OCCUPIER: { 'id': 5, 'name': 'Owner Occupier' },
        KEYHOLDER: {id: 6, 'name': 'Keyholder'},

        PROPERTY_MGR: { 'id': 10, 'name': $localize`Property Manager` },
    }

    static readonly ROLES = [
        Role.ROLE.ADMIN,
        Role.ROLE.OWNER,
        Role.ROLE.OWNER_OCCUPIER,
        Role.ROLE.RESIDENT,
        Role.ROLE.DIRECTOR,
        Role.ROLE.PROPERTY_MGR,
    ];
}
